import { useEffect, useState, useRef } from "react";
import { Tabs, TabPanel } from "react-tabs";
import Start from "./Parts/Start";
import FlexWrapper from "../../../components/FlexWrapper";
import Text from "../../../components/Text/text";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Parts/Header";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import styled from "styled-components";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useTranslation } from "react-i18next";

import {
  registrationDataSelector,
} from "../../../redux/selectors";
import Img from "../../../components/Img";
import {
  Mobile,
  TabletOrDesktop,
} from "../../../components/responsive/responsive";
import { ContentWrapper } from "./Registration.styled";
import FullName from "./Parts/Fullname";
import Password from "./Parts/Password";
import EmailAndMobile from "./Parts/EmailAndMobile";
import { RegisterWithCode } from "../../../services/Services";
import { saveStorageObject } from "../../../utils/storage";
import { setRegistrationData } from "../../../redux/authSlice";
import InterruptionModal from "../../../components/InterruptionModal";
import { isEmpty } from "../../../utils/helpers";
import ProgressLines from "../../../components/ProgressLines/ProgressLines";

export type RegProps = {
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  password: string;
  phoneNumber: string;
  smsCode: string;
  countryId: number;
  hasAgreedToTerms: boolean;
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }: any) => ({
  height: 8,
  borderRadius: 8,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: "#EAECF0",
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 8,
    backgroundColor: "#212121",
  },
}));

const Registration = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [progress, setProgress] = useState(0);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [started, setStarted] = useState(false);
  const registrationData = useSelector(registrationDataSelector);
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const [isInterruptionOpen, setIsInterruptionOpen] = useState(false);
  const prevActiveTab = useRef(0);
  const [animationClass, setAnimationClass] = useState("");

  const handleGoBack = () => {
    if (isEmpty(registrationData)) {
      navigate("/home");
    } else {
      setIsInterruptionOpen(true);
    }
  };

  useEffect(() => {
    dispatch(setRegistrationData({ reset: true }));
  }, []);

  const handleRegistration = (
    email: string,
    phone: string,
    hasAgreedToTerms: boolean,
    countryId: number
  ) => {
    RegisterWithCode(
      registrationData.firstName,
      registrationData.lastName,
      registrationData.userName,
      email,
      registrationData.password,
      phone,
      registrationData.smsCode,
      countryId,
      hasAgreedToTerms
    )
      .then((res) => {
        saveStorageObject("registertoken", res.data.Content.Token);
        saveStorageObject("registeremail", email);
        navigate("/confirmemail");
      })
      .catch((err) => {
        if (err.response.data.errors) {
          return Object.values(err.response.data.errors).map((item: any) => {
            addToast(<div>{item}</div>, {
              appearance: "error",
              autoDismiss: true,
            });
          });
        } else {
          addToast(<div>{err.response.data.StatusMessage}</div>, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  };

  useEffect(() => {
    if (activeTab > prevActiveTab.current) {
      setAnimationClass("animate-in slide-in-from-right duration-500");
    } else if (activeTab < prevActiveTab.current) {
      setAnimationClass("animate-in slide-in-from-left duration-500");
    } else {
      setAnimationClass("");
    }
    prevActiveTab.current = activeTab;
  }, [activeTab]);

  if (!started) {
    return (
      <div className="bg-[#FBFBFB] min-h-[100vh] pb-10">
        <Header />
        <Start
          onStart={() => {
            setStarted(true);
          }}
        />
        <FlexWrapper justify="center" mt="2rem" mb="2rem">
          <Text lh="14px" size="14px" className="font-['Roboto']">
            {t("register.already")}
          </Text>
          <Link
            to="/login"
            className="underline ml-1 text-[12px] leading-1 color-[#171922] font-bold uppercase"
          >
            {t("login.logIn")}
          </Link>
        </FlexWrapper>
      </div>
    );
  }

  return (
    <div className="bg-[#FBFBFB] h-[100vh]">
      <ContentWrapper>
        <Mobile>
          <Header />
          <BorderLinearProgress
            className="mt-[-10px]"
            variant="determinate"
            value={33 * progress}
          />
        </Mobile>
        <Tabs selectedIndex={activeTab}>
          <FlexWrapper align="center" justify="between" mb="1.25rem">
            <TabletOrDesktop>
              <div onClick={handleGoBack}>
                <Img className="logo" src={"/logo.svg"} alt="logo" />
              </div>
              <ProgressLines
                translate={"translateX(-25px)"}
                activeTab={activeTab}
                tabs={[
                  {
                    title: "registration.tab1",
                  },
                  {
                    title: "registration.tab2",
                  },
                  {
                    title: "register.createaccount",
                  },

                ]}
                progress={progress}
                setActiveTab={(index: number) => setActiveTab(index)}
              />
 


              <div className="close" onClick={handleGoBack}>
                <CloseRoundedIcon />
              </div>
            </TabletOrDesktop>
          </FlexWrapper>

          <TabPanel
            key={`tab-panel-0-${activeTab}`}
            className={`animate-fadeIn`}
          >
            <FullName
              onNextPress={() => {
                setProgress(1);
                setActiveTab(1);
              }}
            />
          </TabPanel>
          <TabPanel
            key={`tab-panel-1-${activeTab}`}
            className={`tab-content ${animationClass}`}
          >
            <Password
              onBackClick={() => {
                setActiveTab(0);
              }}
              onNextPress={() => {
                setProgress(2);
                setActiveTab(2);
              }}
            />
          </TabPanel>
          <TabPanel
            key={`tab-panel-2-${activeTab}`}
            className={`tab-content ${animationClass}`}
          >
            <EmailAndMobile
              onBackClick={() => {
                setActiveTab(1);
              }}
              onNextPress={handleRegistration}
            />
          </TabPanel>
        </Tabs>
      </ContentWrapper>
      <InterruptionModal
        title={`${t("registration.registration")}`}
        text={t("common.interruption")}
        isOpen={isInterruptionOpen}
        onStayClick={() => setIsInterruptionOpen(false)}
        onCloseClick={() => {
          navigate("/login");
          setIsInterruptionOpen(false);
        }}
      />
    </div>
  );
};

export default Registration;
