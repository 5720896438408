import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import FlexWrapper from "../../../../components/FlexWrapper";
import Text from "../../../../components/Text/text";
import { userSelector } from "../../../../redux/selectors";
import {
  changePhoneConfirm,
  changePhoneInitiate,
  changePhoneVerify,
} from "../../../../services/Services";
import TwoFactorModal from "../../../../components/TwoFactor/Index";
import { getRefreshToken, saveStorageObject } from "../../../../utils/storage";
import PhoneChangeModal from "./PhoneChangeModal";

const Phone = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const user = useSelector(userSelector);
  const [phoneOpen, setPhoneOpen] = useState(false);
  const [isTwofactorOpen, setIsTwofactorOpen] = useState(false);
  const [newPhone, setNewPhone] = useState("");
  const [mode, setMode] = useState("initial");
  const [existingPhone, setExistingPhone] = useState("");

  useEffect(() => {
    setExistingPhone(user?.clientPhone);
  }, [user]);

  const handlePhoneInitiate = (
    phone: string,
    password: string,
    countryId: number
  ) => {
    changePhoneInitiate(user.userId, phone, password, countryId)
      .then((res) => {
        setNewPhone(phone);

        setPhoneOpen(false);
        setIsTwofactorOpen(true);
        addToast(<div>{res.data.StatusMessage}</div>, {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((err) => {
        if (err.response.data.errors) {
          return Object.values(err.response.data.errors).map((item: any) => {
            addToast(<div>{item}</div>, {
              appearance: "error",
              autoDismiss: true,
            });
          });
        } else {
          addToast(<div>{err.response.data.StatusMessage}</div>, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  };

  const handlePhoneVerify = (pin: any) => {
    changePhoneVerify(user.userId, pin, newPhone, 73)
      .then((res) => {
        addToast(<div>{res.data.StatusMessage}</div>, {
          appearance: "success",
          autoDismiss: true,
        });
        setIsTwofactorOpen(false);
        setMode("final");
        setTimeout(() => {
          setIsTwofactorOpen(true);
        }, 400);
      })
      .catch((err) => {
        if (err.response.data.errors) {
          return Object.values(err.response.data.errors).map((item: any) => {
            addToast(<div>{item}</div>, {
              appearance: "error",
              autoDismiss: true,
            });
          });
        } else {
          addToast(<div>{err.response.data.StatusMessage}</div>, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  };

  const handlePhoneConfirm = (pin: any) => {
    changePhoneConfirm(user.userId, getRefreshToken(), pin)
      .then((res: any) => {
        addToast(<div>{res.data.StatusMessage}</div>, {
          appearance: "success",
          autoDismiss: true,
        });
        setExistingPhone(newPhone);
        const newUser = user;
        user.clientPhone = newPhone;
        saveStorageObject("user", newUser);
        setIsTwofactorOpen(false);
      })
      .catch((err: any) => {
        if (err.response.data.errors) {
          return Object.values(err.response.data.errors).map((item: any) => {
            addToast(<div>{item}</div>, {
              appearance: "error",
              autoDismiss: true,
            });
          });
        } else {
          addToast(<div>{err.response.data.StatusMessage}</div>, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  };

  return (
    <>
      <FlexWrapper
        mb="0"
        justify="space-between"
        mobileDirection="column"
        mobileAlign="flex-start"
        gap="8px"
      >
        <FlexWrapper mb="0">
          <Text mb="0" color="#56575B">
            {t("common.phone")}:{" "}
          </Text>
          <Text mb="0" ml="0.35rem" color="#171922">
            {existingPhone}
          </Text>
        </FlexWrapper>
        <div onClick={() => setPhoneOpen(true)}>
          <Text
            color="var(--red)"
            weight="600"
            className="uppercase pointer hover:underline"
          >
            {t("profile.changephone")}
          </Text>
        </div>
      </FlexWrapper>
      {isTwofactorOpen && (
        <TwoFactorModal
          displayCode={mode === "final" ? newPhone : user.clientPhone}
          isOpen={isTwofactorOpen}
          // onResend={handleEmailInitiate}
          onClose={() => setIsTwofactorOpen(false)}
          onConfirm={(pin: any) =>
            mode === "final" ? handlePhoneConfirm(pin) : handlePhoneVerify(pin)
          }
        />
      )}
      <PhoneChangeModal
        isOpen={phoneOpen}
        onClose={() => setPhoneOpen(false)}
        onConfirm={handlePhoneInitiate}
      />
    </>
  );
};

export default Phone;
