import styled from "styled-components";
import { media } from "../../../utils/theme";

export const TitleSection = styled.div`
  display: flex;
  align-items: center;
  line-height: 1.5rem;
  padding-bottom: 0.5rem;
`;

export const DetailsForm = styled.form<{ disabled?: boolean }>`
  background: #fff;
  box-shadow: 0px 2px 3px rgba(111, 118, 137, 0.1);
  border: 1px solid #eaecf0;
  border-radius: 10px;
  width: 736px;
  padding: 1rem 1rem 1.5rem;
  margin-left: 1.5rem;
  flex-shrink: 0;
  margin: 0 auto;

  button {
    padding: 0.8rem;
    margin-top: 1rem;
  }

  .wrap {
    width: 360px;
    margin: auto;
  }

  .textual input {
    text-transform: uppercase;
  }

  input {
    flex: 1 !important;
    width: 100%;
  }

  .mobile-input {
    flex: 1 !important;

    input {
      padding-left: 124px !important;
    }
  }

  opacity: ${(p) => (p.disabled ? "0.5" : "1")};
  pointer-events: ${(p) => (p.disabled ? "none" : "auto")};

  ${media.lessThan("tablet")} {
    box-shadow: none;
    margin-left: 0;
    width: 80%;
    margin: 0 auto;

    input {
      min-width: auto;
    }

    .wrap {
      width: 100%;
    }
  }

  ${media.lessThan("small")} {
    box-shadow: none;
    margin-left: 0;
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100% !important;
  }

  @media (max-width: 480px) {
    .topwrapper {
      flex-direction: column;
      align-items: flex-start;
    }

    .title {
      font-size: 24px;
      margin-left: 0;
      line-height: 30px;
    }
  }
`;

export const Switcher = styled.div`
  background: #fbfbfb;
  display: flex;
  width: 360px;
  justify-content: space-around;
  border-bottom: 1px solid #e9e9e9;
  margin-bottom: 12px;

  div {
    cursor: pointer;
    padding: 18px;
  }

  div.active {
    border-bottom: 4px solid black;
  }

  ${media.lessThan("small")} {
    width: 100%;
  }
`;

export const TransactionType = styled.div`
  color: #212121;
  border-radius: 8px;
  border: 1px solid #848688;
  padding: 8px;
  cursor: pointer;

  &:hover,
  &.active {
    background: #212121;
    color: white;
  }
`;

export const CountryCode = styled.div`
  display: flex;
  align-items: center;
  max-height: 40px;
  padding: 27px 6px 27px 12px;
  // border: 1px solid rgba(0, 0, 0, 0.23);
  border-right: 0;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
  margin-left: -14px;

  svg {
    width: 24px;
    height: 24px;
  }
`;
