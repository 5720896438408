import styled from "styled-components";
import { media } from "../../utils/theme";

export const Wrapper = styled.div`
  display: flex;
  position: fixed;
  bottom: 0;
  left: 0;
  background: #fff;
  z-index: 101;
  width: 100%;
  padding: 0 0.5rem;
  gap: 0;
  justify-content: space-between;
  text-align: center;

  .close {
    position: absolute;
    left: -15px;
    border-radius: 8px;
    height: 30px;
    background: #fff;
    width: 30px;
    top: 48%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    border: 1px solid #d0d5dd;
  }

  ${media.lessThan("small")} {
    .bottom-link {
      padding-left: 36px;

      img {
        margin-left: 4px;
      }
    }
  }

  @media (max-width: 364px) {
    .bottom-link {
      padding-left: 36px !important;

      img {
        margin-left: 0px;
      }
    }
  }
`;

export const ItemWrapper = styled.div`
  padding: 0.5rem;
  .top {
    padding: 0 1rem;
  }

  .active {
    .top {
      background: var(--red);
      border-radius: 20px;

      img {
        flex-shrink: 0;
        filter: brightness(0) invert(1);
      }
    }
  }
`;

export const MainMenuWrapper = styled.div`
  padding: 0.875rem 1.5rem 0;

  ${media.lessThan("medium")} {
    padding: 0.875rem 1rem 0;
    background: #fff;
  }

  @media (max-width: 365px) {
    padding: 0.875rem 0.5rem 0;
  }
`;

export const ListItemWrapper = styled.div`
  a.disabled {
    opacity: 0.7;

    &:hover {
      font-weight: normal;
      color: var(--text-color);
      background: transparent;
    }
  }

  a.active {
    background: #f7f7fc;
  }

  a {
    transition: all 0.2s;
    display: flex;
    align-items: center;
    padding: 0.825rem;
    color: var(--text-color-2);
    cursor: pointer;
    font-size: 0.875rem;
    text-transform: capitalize;

    img {
      width: 1.5rem;
      margin-right: 0.875rem;
    }

    &:hover {
      background: #f9fafb;
    }
  }

  ${media.size("xlarge")} {
    a {
      padding: 0.825rem 2.275rem 0.825rem 2.475rem;
    }
  }

  ${media.size("large")} {
    a {
      padding: 0.825rem 2.075rem 0.825rem 2.275rem;
    }
  }

  ${media.lessThan("tablet")} {
    a.active {
      border-color: transparent;
    }

    a {
      img,
      svg {
        width: 1.5rem;
        margin-right: 12px;
      }
    }
  }

  ${media.lessThan("small")} {


    a {
      font-size: 14px;

      span {
        font-family: Roboto;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color:#171922;
        font-size: 14px;
      }

      img.chevron {
        margin-right: 0px;
      }
    }
  }

  @media (max-width: 420px) {
    a {
      padding:  0.75rem;
    }
    span {
      font-size: 13px;
    }
  }

  @media (max-width: 400px) {
    a {
      span {
        font-size: 14px;
      }
    }
  }
`;
