import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import cx from "classnames";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";

import { MainWrapper, TemplateWrapper } from "./Templates.styled";
import {
  GetFavouriteAccount,
  calculateBatchServices,
  checkService,
  getBillTemplates,
} from "../../../../services/Services";
import Title from "../../../../components/Text/title";
import Breadcrumbs from "../Breadcrumbs";
import NoResults from "./Noresults";
import Divider from "../../../../components/Divider";
import FlexWrapper from "../../../../components/FlexWrapper";
import Text from "../../../../components/Text/text";
import {
  formatBalance,
  getCurrencySymbol,
  isEmpty,
  replaceComma,
} from "../../../../utils/helpers";
import { ratesSelector, userIdSelector } from "../../../../redux/selectors";
import AccountsModal from "../../../../components/AccountsModal";
import Img from "../../../../components/Img";
import Balance from "../../../../components/Balance";
import { Account } from "../../../home/Accounts/accounts/types";
import { AccountWrap } from "../Service/Info";
import Star from "../../../../components/Icons/Star";
import { setBillTransferData } from "../../../../redux/billSlice";
import { useNavigate } from "react-router-dom";
import { setLoading } from "../../../../redux/commonSlice";
import { useToasts } from "react-toast-notifications";
import { preventNonNumeric } from "../../../../utils/utils";

export type BillTemplateProps = {
  AbonentName: string;
  CategoryId: string;
  CheckInfo: string;
  Debt: number | null;
  Id: number;
  Parameters: any;
  ServiceId: string;
  ServiceName: string;
  SubCategoryId: string;
  TemplateName: string;
  DebtAmount?: number | null;
  isSelected?: number;
};

const TemplatesInner = () => {
  const { t } = useTranslation();
  const [templates, setTemplates] = useState<BillTemplateProps[]>([]);
  const [totalAmounts, setTotalAmounts] = useState<any>(0);
  const [chosenServices, setChosenServices] = useState<BillTemplateProps[]>([]);
  const [addedIndexes, setAddedIndexes] = useState<number[]>([]);
  const userId = useSelector(userIdSelector);
  const [accountsModalOpen, setAccountsModalOpen] = useState(false);
  const [chosenAccount, setChosenAccount] = useState<Account>();
  const [batchedProcesses, setBatchedProcesses] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const rates = useSelector(ratesSelector);
  const { addToast } = useToasts();
  const [errorArray, setErrorArray] = useState<number[]>([]);
  const [aggregatedValue, setAggregatedValue] = useState<any>(0);
  const [favouriteAccount, SetFavouriteAccount] = useState<Account | null>(
    null
  );

  useEffect(() => {
    handleGetBillTemplates();
    dispatch(setBillTransferData({ isFromTemplate: false, processes: [] }));
  }, []);

  const handleGetBillTemplates = () => {
    getBillTemplates()
      .then((res) => {
        const content = res.data.Content.map((item: any) => ({
          ...item,
          DebtAmount: item.Debt,
        }));
        setTemplates(content);
        const totals = res.data.Content.map((item: any) => {
          if (item.Debt) {
            return {
              [item.ServiceId]: item.Debt,
            };
          }
        }).filter(Boolean);
        setTotalAmounts(totals);
      })
      .catch((err) => console.log(err.response.data));
  };

  const handleCheckService = (item: BillTemplateProps) => {
    dispatch(setLoading(true));
    checkService(userId, item?.ServiceId, [
      { key: item.Parameters[0].Key, value: item.Parameters[0].Value },
    ])
      .then((res) => {
        dispatch(setLoading(false));
        const newTemplate = templates.find(
          (template) => template.Id === item.Id
        );
        const updatedTemplate = {
          ...newTemplate,
          Debt: res.data.Content.Debt,
        };

        setTemplates((prevTemplates) =>
          prevTemplates.map((template: any) =>
            template.Id === item.Id ? updatedTemplate : template
          )
        );
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  useEffect(() => {
    if (userId) {
      GetFavouriteAccount(userId).then((res) => {
        SetFavouriteAccount(res.data.Content);
        setChosenAccount(res.data.Content);
      });
    }
  }, [userId]);

  const handleInputChange = (templateId: any, amount: any) => {
    let newTemplate = templates.find((template) => template?.Id === templateId);
    // @ts-ignore
    newTemplate.DebtAmount = amount == null ? 0 : amount;

    setTemplates((prevTemplates: any) =>
      prevTemplates.map((template: any) =>
        template.Id === templateId ? newTemplate : template
      )
    );
  };

  useEffect(() => {
    const totalDebtAmount = templates
      .filter((item) => item.isSelected)
      .reduce((accumulator, currentObject) => {
    
        const debtAmount = currentObject?.DebtAmount
            // @ts-ignore
          ? parseFloat(currentObject?.DebtAmount)
          : 0;
        return accumulator + debtAmount;
      }, 0);

    setAggregatedValue(totalDebtAmount);
  }, [templates]);

  useEffect(() => {
    const chosenServices = templates.filter((item) => item.isSelected);
    const services = chosenServices.map(
      ({
        ServiceId,
        Id,
        Parameters,
        ServiceName,
        AbonentName,
        DebtAmount,
      }) => ({
        serviceId: ServiceId,
        templateId: Id,
        parameters: Parameters,
        ServiceName,
        AbonentName,
        amount: DebtAmount,
      })
    );

    const resultArray = services.map((item) => ({
      ...item,
    }));

    if (
      services.length &&
      !isEmpty(totalAmounts) &&
      totalAmounts !== 0 &&
      resultArray.length
    ) {
      dispatch(setLoading(true));
      calculateBatchServices(userId, resultArray, chosenAccount?.Id)
        .then((res) => {
          setBatchedProcesses(res.data.Content.Processes);
          const totalFee = res.data.Content.Processes.reduce(
            (accumulator: any, currentValue: any) => {
              return accumulator + currentValue.Fee;
            },
            0
          );
          dispatch(setLoading(false));
          dispatch(
            setBillTransferData({
              batchServices: resultArray,
              amount: aggregatedValue,
              currency: chosenAccount?.CurrencyName,
              fee: totalFee,
            })
          );
        })
        .catch((err) => dispatch(setLoading(false)));
    }
  }, [
    totalAmounts,
    aggregatedValue,
    chosenAccount?.CurrencyName,
    chosenAccount?.Id,
    chosenServices,
    dispatch,
    userId,
  ]);

  const handleServiceChoose = (
    item: BillTemplateProps,
    // index: number,
    fromInput?: boolean
  ) => {
    let newTemplate = templates.find((template) => template?.Id === item.Id);
    const updatedTemplate = {
      ...newTemplate,
      isSelected: newTemplate?.isSelected && !fromInput ? false : true,
 
      DebtAmount:     // @ts-ignore
        parseFloat(item?.Debt) > 0 ? item.DebtAmount || item.Debt : "",
    };

    if (!(item?.Debt === null && item?.CheckInfo !== null)) {
      setTemplates((prevTemplates: any) =>
        prevTemplates.map((template: any) =>
          template.Id === item.Id ? updatedTemplate : template
        )
      );
    }
  };

  const handleGoToReview = () => {
    setErrorArray([]);

    const itemsWithoutAmount = templates.filter(
      (item) => item.isSelected && !item.DebtAmount
    );

    if (itemsWithoutAmount?.length) {
      setErrorArray(itemsWithoutAmount?.map((item) => item.Id));
      addToast(<div> {t("transfertowallet.enter")}</div>, {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }
    if (chosenAccount?.CurrencyName !== "GEL") {
      addToast(<div> {t("billpayments.gelpermitted")}</div>, {
        appearance: "error",
        autoDismiss: true,
      });
    } else {
      dispatch(setBillTransferData({ processes: batchedProcesses }));
      dispatch(setBillTransferData({ isFromTemplate: true }));
      navigate(`/billpayments/service/${1}`, {
        state: {
          isBatch: true,
        },
      });
    }
  };

  return (
    <TemplateWrapper>
      <Title size="2rem" weight="700" mb="2rem">
        {t("billpayments.billpayments")}
      </Title>
      <Breadcrumbs isTemplate />

      {templates?.length && (
        <>
          <Title mt="1rem">{t("billpayments.innertemplatestitle")}</Title>

          <AccountWrap
            onClick={() => setAccountsModalOpen(true)}
            style={{ maxWidth: 450 }}
          >
            <FlexWrapper gap="0.5rem" mb="0" style={{ flex: 1 }}>
              <FlexWrapper gap="0.5rem" style={{ flex: 1 }} mb="0">
                <span>
                  <Img src="/home/greece-wallet.svg" alt="wallet" />
                </span>
                <div>
                  <Text
                    weight="500"
                    color="var(--text-color)"
                    size="14px"
                    mb="0.2rem"
                  >
                    {chosenAccount?.CustomAccountName}
                  </Text>
                  <Text weight="500" color="#949494" mb="0.2rem">
                    {chosenAccount?.WalletName}
                  </Text>
                  <Text weight="700" color="var(--text-color)" size="16px">
                    {getCurrencySymbol(chosenAccount?.CurrencyName)}{" "}
                    <Balance currency={chosenAccount?.CurrencyName} />
                  </Text>
                </div>
              </FlexWrapper>

              {favouriteAccount?.CurrencyName ===
                chosenAccount?.CurrencyName && <Star />}

              <div>
                <Img src="/billpayments/arrows-down.svg" alt="arrows" />
              </div>
            </FlexWrapper>
          </AccountWrap>
          <MainWrapper>
            <Title
              size="12px"
              mb="0rem"
              className="uppercase"
              weight="700"
              color="#171922"
            >
              {t("banktransfer.mytemplates")}
            </Title>
            <Divider width="100%" mb="1rem" />

            <div className="grid">
              {templates?.map((item: BillTemplateProps, index: number) => (
                <FlexWrapper
                  key={index}
                  className={`item !mb-0 ${
                    addedIndexes.includes(index) ? "item-selected" : ""
                  }`}
                  justify="space-between"
                  //   @ts-ignore
                  onClick={(e: any) => {
                    handleServiceChoose(item);
                  }}
                >
                  <FlexWrapper mb="0">
                    {item.isSelected ? (
                      <div className="circle">
                        <CheckOutlinedIcon />
                      </div>
                    ) : (
                      <div className="shrink-0">
                        <object
                          width="60px"
                          data={`https://files2.mp.ge/uploads/comunalservices/${item?.ServiceId}.png`}
                          type="image/png"
                        >
                          <Img
                            width={36}
                            style={{
                              marginLeft: 12,
                              marginBottom: 6,
                              marginTop: 12,
                            }}
                            src="/billpayments/image-fill.svg"
                            alt="fallback"
                          />
                        </object>
                      </div>
                    )}

                    <div>
                      <Text color="#171922" weight="700" size="14px">
                        {item?.ServiceName}
                      </Text>
                      {item?.Debt === null && item?.CheckInfo !== null ? (
                        <div className="flex justify-between flex-1 mt-2">
                          <Text color="#E02629" lh="18px">
                            {t("billpayments.unavailable")}
                          </Text>
                          <ReplayOutlinedIcon
                            onClick={(e) => {
                              e.stopPropagation();
                              handleCheckService(item);
                            }}
                            style={{
                              fill: "#E02629",
                              marginTop: -2,
                              marginLeft: 4,
                            }}
                          />
                        </div>
                      ) : (
                        <>
                          {item?.Parameters?.length && (
                            <Text
                              size="12px"
                              lh="14px"
                              color="#56575B"
                              mt="0.25rem"
                              className="truncate max-w-full"
                            >
                              {item?.Parameters.length &&
                                item?.Parameters[0] &&
                                item?.Parameters[0]?.Value}
                            </Text>
                          )}
                          <Text
                            color="var(--text-color)"
                            size="10px"
                            weight="500"
                          >
                            {item?.TemplateName}
                          </Text>
                        </>
                      )}
                    </div>
                  </FlexWrapper>

                  {!(item?.Debt === null && item?.CheckInfo !== null) && (
                    <div>
                      <input
                        onClick={(e: any) => {
                          e.stopPropagation();
                          handleServiceChoose(item, true);
                        }}
                        className={cx(
                          "input text-[#757582]",
                          errorArray.includes(item.Id) && "error-input"
                        )}
                        // @ts-ignore
                        value={item.DebtAmount > 0 ? item?.DebtAmount : ""}
                        onKeyPress={preventNonNumeric}
                        onChange={(e) => {
                          handleInputChange(
                            item?.Id,
                            replaceComma(e.target.value)
                          );
                        }}
                        placeholder="0.00"
                      />
                      <Text
                        size="14px"
                        lh="12px"
                        mt="0.5rem"
                        ta="center"
                        color={
                          item?.Debt
                            ? item.Debt < 0
                              ? "#0FBD5B"
                              : "var(--red)"
                            : "var(--text-color-2)"
                        }
                      >
                        {item?.Debt && item?.Debt < 0
                          ? item?.Debt?.toString().replace("-", "+")
                          : item?.Debt || "0.00"}
                      </Text>
                    </div>
                  )}
                </FlexWrapper>
              ))}
            </div>
            <FlexWrapper gap="1rem" mt="1rem" justify="flex-end" mb="0">
              <div>
                <Text ta="right" mb="0.25rem">
                  {t("common.total")}:
                </Text>
                <Text size="1rem" weight="bold" color="#000">
                  {formatBalance(aggregatedValue)} {chosenAccount?.CurrencyName}
                </Text>
              </div>
              <div>
                <button
                  disabled={aggregatedValue == 0}
                  className="btn btn-red"
                  onClick={handleGoToReview}
                >
                  {t("billpayments.reviewandpay")}
                </button>
              </div>
            </FlexWrapper>
          </MainWrapper>
        </>
      )}

      {!templates?.length && <NoResults />}
      <AccountsModal
        isOpen={accountsModalOpen}
        onClose={() => setAccountsModalOpen(false)}
        onContinue={(acc) => {
          setChosenAccount(acc);
          setAccountsModalOpen(false);
        }}
      />
    </TemplateWrapper>
  );
};

export default TemplatesInner;
