import React from "react";
import { useEventListener } from "./useEvents";

export type windowType = "desktop" | "tablet" | "mobile";

type dimensionType = {
  width: number;
  height: number;
};

function getDimension() {
  return {
    width: window.innerWidth,
    height: window.innerHeight,
  };
}

function isChanged(prev: dimensionType, next: dimensionType) {
  return prev.width !== next.width || prev.height !== next.height;
}

export function useWindowSizeChange(onWindowSizeChange: any) {
  const prevDimensionRef = React.useRef(getDimension());

  const onResize = React.useCallback(() => {
    const nextDimension = getDimension();

    if (isChanged(prevDimensionRef.current, nextDimension)) {
      prevDimensionRef.current = nextDimension;
      onWindowSizeChange(nextDimension);
    }
  }, [onWindowSizeChange]);

  useEventListener("resize", onResize, window);
}

export function useDimension() {
  const [state, setState] = React.useState(getDimension());

  const updateDimensions = React.useCallback((nextDimension) => {
    setState(nextDimension);
  }, []);

  useWindowSizeChange(updateDimensions);

  return state;
}

export const TYPES = {
  desktop: "desktop",
  tablet: "tablet",
  mobile: "mobile",
};

function detectBrowserType({ width }: { width: number }) {
  if (width < 554) {
    return TYPES.mobile;
  }

  if (width < 1024) {
    return TYPES.tablet;
  }

  return TYPES.desktop;
}

export function useDimensionType() {
  const [browserType, setBrowserType] = React.useState(() =>
    detectBrowserType(getDimension())
  );

  const updateBrowserType = React.useCallback((dimension) => {
    setBrowserType(detectBrowserType(dimension));
  }, []);

  useWindowSizeChange(updateBrowserType);

  return browserType;
}

export function isTabletOrMobile(type: windowType) {
  return [TYPES.tablet, TYPES.mobile].indexOf(type) !== -1;
}

export function isTabletOrDesktop(type: windowType) {
  return [TYPES.tablet, TYPES.desktop].indexOf(type) !== -1;
}
