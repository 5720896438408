import { Skeleton } from "@mui/material";

const TransactionSkeleton = () => {
  return (
    <div className={"flex justify-between p-2"}>
      <div className="flex gap-2">
        <Skeleton
          variant="rounded"
          width={32}
          height={32}
          style={{ borderRadius: "50%" }}
        />
        <div>
          <Skeleton width="150px" variant="text" sx={{ fontSize: "1rem" }} />
          <Skeleton width="100px" variant="text" sx={{ fontSize: "1rem" }} />
        </div>
      </div>

      <div className="flex flex-col items-end">
        <Skeleton width="70px" variant="text" sx={{ fontSize: "1rem" }} />
        <Skeleton
          width="100px"
          variant="text"
          sx={{ fontSize: "1rem", textAlign: "right" }}
        />
      </div>
    </div>
  );
};

export default TransactionSkeleton;
