import { useTranslation } from "react-i18next";
import styled from "styled-components";

import Text from "../../../components/Text/text";
import Title from "../../../components/Text/title";
import ContentHeader from "../../../partials/ContentHeader";
import ContentFooter from "../../../partials/ContentFooter";

const Wrap = styled.div`
  @media (max-width: 640px) {
    .container {
      // max-width: 100% !important;
    }

    .content-container {
      max-width: 100vw;
    }
  }
`;

const ContentText = styled(Text)`
  font-size: 14px;
  line-height: 24px;
  color: #475467;
  margin-bottom: 1rem;
  word-break: break-word;
  font-family: notosans;
`;

const ContentTitle = styled(Title)`
  font-size: 30px;
  color: #101828;
  line-height: 38px;
  font-weight: 600;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  word-break: break-word;
  font-family: inter;
`;

const Privacy = () => {
  const { t } = useTranslation();

  return (
    <Wrap className="">
      <ContentHeader />
      <div className="bg-[#212121] ">
        <div className="mx-auto  px-4 md:px-8 py-[96px]">
          <div className="max-w-[720px] mx-auto">
            <Text ta="center" size="1rem" color="#CECFD2" weight="600">
              Current as of 10 april 2024
            </Text>
            <h2
              color="#fff"
              className=" md:leading-[60px] leading-10 text-white mx-auto md:text-[48px] text-[32px] text-center mt-3 mb-[24px] font-semibold break-words"
            >
              {t("common.privacy")} & {t("common.termsandconditions")}
            </h2>

            <Title
              mt="0.5rem"
              size="20px"
              ta="center"
              color="#94969C"
              lh="30px"
              ml="auto"
              mr="auto"
              className="max-w-[750px]"
            >
              {t("privacy.text")}
            </Title>
          </div>
        </div>
      </div>
      {/* TERMS */}
      <div className="container mx-auto py-16 xs:px-6 box-border content-container max-w-[720px]">
        <Title size="1.25rem" ta="center">
          შპს „ინექს ფეის“ ელექტრონული საფულის მუშაობის წესები და პირობები
        </Title>
        <ContentText>
          „საგადახდო სისტემისა და საგადახდო მომსახურების შესახებ“ საქართველოს
          კანონის შესაბამისად, საგადახდო მომსახურების მომხმარებელს უფლება აქვს,
          სისტემის ოპერატორისთვის საჩივრით მიმართვის დღიდან არაუგვიანეს 6 თვის
          განმავლობაში, სისტემის ოპერატორის წინააღმდეგ საჩივრით მიმართოს
          საქართველოს ეროვნულ ბანკთან არსებულ დავების განმხილველ კომისიას
          (შემდგომ − კომისია) მხოლოდ სისტემის ოპერატორისთვის წარდგენილი საჩივრის
          დადგენილ ვადაში დაუკმაყოფილებლობის (მათ შორის, პასუხის გაუცემლობის) ან
          ნაწილობრივ დაკმაყოფილების შემთხვევაში. მომხმარებელი უფლებამოსილია
          კომისიას მიმართოს, თუ მისი მოთხოვნა არ აღემატება 50 000 ლარს ან უცხოურ
          ვალუტაში 50 000 ლარის ეკვივალენტს (სადავო ქმედების განხორციელების
          თარიღით ან წარდგენის დღის თარიღით). კომისია დავას განიხილავს და
          გადაწყვეტილებას იღებს საჩივრის წარმოებაში მიღებიდან 90 კალენდარული
          დღის ვადაში (შესაძლოა გახანგრძლივდეს არაუმეტეს 30 კალენდარული დღით).
          კომისია დავას განიხილავს უსასყიდლოდ. მომხმარებელს უფლება აქვს,
          მიმართოს სასამართლოს კომისიისთვის მიმართვის გარეშეც.
        </ContentText>
        <ContentTitle>1. ზოგადი პირობები</ContentTitle>
        <ContentText>
          1.1. წინამდებარე დოკუმენტი წარმოადგენს სისტემის ოპერატორსა და
          მომხმარებელს შორის ელექტრონული საფულით მომსახურების ხელშეკრულებას.
          მომხმარებელი, წინამდებარე წესებსა და პირობებზე დათანხმების გზით
          გამოხატავს ნებას, რომ დადგენილი წესით, ელექტრონული საფულის გამოყენებით
          მიიღოს საგადახდო მომსახურება, რის სანაცვლოდაც გადაუხდის სისტემის
          ოპერატორს საკომისიო ანაზღაურებას, დადგენილი განაკვეთების მიხედვით;
        </ContentText>
        <ContentText>
          1.2. წინამდებარე დოკუმენტი სრულად შეესაბამება საგადახდო მომსახურების
          პროვაიდერის საქმიანობის კანონმდებლობით დადგენილ პრინციპებსა და წესებს;
        </ContentText>
        <ContentText>
          1.3. დოკუმენტი განსაზღვრავს სისტემის წესების შექმნისა და რეალიზაციის
          მეთოდებს, ასევე ადგენს სისტემის სუბიექტებს შორის ურთიერთობების ძირითად
          პრინციპებს. პირობების შესრულება უზრუნველყოფილია სისტემის პროგრამით და
          სავალდებულოა სისტემის ყველა სუბიექტისთვის;
        </ContentText>
        <ContentText>
          1.4. სისტემის სუბიექტებს შორის ტექნოლოგიური ურთიერთობები, სისტემის
          წესების შემუშავება, უსაფრთხოების სტანდარტების დადგენა, ითვლება
          სისტემის ოპერატორის ექსკლუზიურ უფლებად;
        </ContentText>
        <ContentText>
          1.5. წესებსა და პირობებზე დათანხმებით, მომხმარებელი ადასტურებს, რომ
          გაეცნო დოკუმენტის შინაარსს, უპირობოდ ეთანხმება მას და გამოთქვამს
          თანხმობას, რომ წინამდებარე წესებითა და პირობებით მოწესრიგდეს მასსა და
          სისტემის ოპერატორს შორის ურთიერთობა;
        </ContentText>
        <ContentText>
          1.6. სისტემის ოპერატორს უფლება აქვს საკუთარი შეხედულებისამებრ შეცვალოს
          სტანდარტული წესები და პირობები. წესებისა და პირობების ცვლილებების
          თაობაზე სისტემის მომხმარებლების ინფორმირება ფოსტის, ელექტრონული
          ფოსტის, სისტემის ოპერატორის ვებ-გვერდის ან კომუნიკაციის სხვა
          საშუალებით ხორციელდება შესატანი, ცვლილებების ძალაში შესვლამდე
          არაუგვიანეს 10 (ათი) დღისა. ცვლილების ძალაში შესვლის მომენტიდან,
          შეცვლილი პირობები იძენს სავალდებულო ძალას და ვრცელდება მომხმარებლებსა
          და სისტემის ყველა მონაწილეზე
        </ContentText>
        {/* part 1 */}
        <ContentTitle>2. ტერმინთა განმარტება</ContentTitle>
        <ContentText>
          2.1. სისტემა - ავტომატური პროგრამული უზრუნველყოფის და სისტემის
          სერვისების, ურთიერთთანამშრომლობისა და პროცედურების ერთობლიობა, რომელიც
          წარმოიშვება ზემოთ დასახელებულ სუბიექტებს შორის ელექტრონული ფულით
          გადახდის ოპერაციების წარმოების დროს ან/და ელექტრონული ფულის
          პროვაიდერის მიერ ელექტრონული ფულის ემისიისას. ფიზიკური და იურიდიული
          პირების მიერ დამოუკიდებლად მართვადი სისტემის მომხმარებლების პროგრამული
          მოდულების ერთობლიობა
        </ContentText>
        <ContentText>
          2.2. IP სისტემის ოპერატორი - (ელექტრონული ფულის პროვაიდერი) იურიდიული
          პირი (შპს ,,ინექს ფეი“; ს/კ 402040153), რომელიც რეგისტრირებულია
          საქართველოს კანონმდებლობის შესაბამისად, რომელმაც შეიმუშავა სისტემის
          წესები და აგრეთვე პასუხისმგებელია სისტემის მართვასა და გამართულ
          მუშაობაზე.
        </ContentText>
        <ContentText>
          2.3. ელექტრონული ფული - ფულადი სახსრების ექვივალენტური ღირებულება,
          რომელიც შენახულია საგადახდო ინსტრუმენტებზე და რომელიც აღიარებულია
          გადახდის საშუალებად მისი გამომშვების (შ.პ.ს. ,,ინექს ფეის“),
          მომხმარებლისა და სისტემის მონაწილეების მიერ. ელექტრონულ ფულსა და მის
          სანაცვლოდ მიღებულ ფულად სახსრებს შორის ელექტრონული ფულის პროვაიდერის
          მიერ მისი საქმიანობის ყველა ეტაპზე დაწესებულია შემდეგი თანაფარდობა: ·
          1 ლარი, აშშ დოლარი, ევრო, დიდი ბრიტანეთის გირვანქა სტერლინგი, რუსული
          რუბლი უდრის შესაბამისად, 1 ელექტრონულ ლარს, აშშ დოლარს, ევრო,ს დიდი
          ბრიტანეთის გირვანქა სტერლინგს, რუსულ რუბლს; · 1 სხვა ვალუტის ერთეული
          უდრის ლარის ექვივალენტი სისტემის ოპერატორის მიერ დადგენილი კომერციული
          კურსის შესაბამისად, ოპერაციის განხორციელების მომენტში;
        </ContentText>
        <ContentText>
          2.4. ელექტრონული საფულე - პირის უნიკალური ვირტუალური ანგარიში, რომელიც
          შედგება პროგრამული უზრუნველყოფისგან. საფულეში განთავსებულია
          ელექტრონული ფული, რომელიც იმართება მომხმარებლის მიერ,
          სისტემის-ოპერატორის წესების და პირობების შესაბამისად. ელ. საფულეში
          ავტორიზაციისთვის საჭირო მონაცემები, ცნობილია მხოლოდ საფულის
          მფლობელისთვის.
        </ContentText>
        <ContentText>
          2.5. საგადახდო ინსტრუმენტი - პროგრამული უზრუნველყოფა ან/და სისტემის
          ოპერატორს და მომხმარებელს შორის შეთანხმებული პროცედურების ერთობლიობა,
          რომლის მეშვეობითაც საგადახდო მომსახურების მომხმარებელი ახდენს
          საგადახდო დავალების ინიცირებას. საგადახდო ინსტრუმენტი შეიძლება იყოს
          კომპიუტერი, მობილური ტელეფონო ან/და სხვა ტექნიკური მოწყობილობა.
        </ContentText>
        <ContentText>
          2.6. ნომინალური მფლობელობის საბანკო ანგარიში - (ნომინალური მფლობელობის
          ანგარიში) საქართველოში რეგისტრირებულ კომერციულ ბანკში სისტემის
          ოპერატორის მიერ გახსნილი სპეციალური საბანკო ანგარიში, სადაც
          განთავსებულია ელექტრონული ფულის პროვაიდერის მიერ გამოშვებული
          ელექტრონული ფულის ექვივალენტური თანხა, რომელიც ნებისმიერ მომენტში
          ტოლია სისტემაში არსებული ელექტრონული ფულისა.
        </ContentText>
        <ContentText>
          2.7. ელექტრონული ფულის ინსტრუმენტი - პროგრამული უზრუნველყოფა,
          მოწყობილობა, მექანიზმი, საგადახდო ინსტრუმენტი, რომელიც შექმნილია
          სისტემის ოპერატორის მიერ შემუშავებული წესებისა და პროცედურების
          შესაბამისად და განთავსებულია ელექტრონულ მისამართზე:
          http://www.inexpay.ge აღნიშნული ინსტრუმენტი იძლევა ელექტრონული ფულის
          გამოყენების საშუალებას.
        </ContentText>
        <ContentText>
          2.8. სისტემის წესები - სისტემის ოპერატორის მიერ შემუშავებული წესებისა
          და პროცედურების ერთობლიობა, რომლის მიხედვითაც ფუნქციონირებს სისტემა.
        </ContentText>
        <ContentText>
          2.9. სისტემის მონაწილე - იურიდიული პირი, რომელსაც უშუალოდ შეუძლია
          საგადახდო დავალების გაცემა ან/და მიღება და რომელიც მიჩნეულია სისტემის
          მონაწილედ სისტემის წესების შესაბამისად. სისტემის მონაწილე სისტემის
          ოპერატორის წესების შესაბამისად არის აგენტი, მერჩანტი, კომერციული
          ბანკი, საგადახდო სისტემა, კონტრაქტორი და სხვა. სისტემის ოპერატორსა და
          სისტემის მონაწილეებს შორის ურთიერთობა რეგულირდება ინდივიდუალური
          ხელშეკრულებების საფუძველზე;
        </ContentText>
        <ContentText>
          2.10. საგადახდო დავალება - გადამხდელის ან მიმღების მიერ სისტემის
          ოპერატორისთვის მიცემული დავალება, გადახდის ოპერაციის
          განხორციელებისთვის, გადამხდელის მიერ საგადახდო საბუთის სახით
          გაფორმებული განკარგულება მისი სისტემის ოპერატორის, სახსრების მიმღების
          ამავე სისტემის ან სხვა ბანკში გახსნილ ანგარიშზე ფულადი სახსრების
          გადარიცხვის შესახებ.
        </ContentText>
        <ContentText>
          2.11. ტრანსფერ ორდერი - სისტემის ერთი მონაწილის მიერ სისტემის მეორე
          მონაწილის მიმართ გაცემული დავალება მიმღებისთვის ფულადი სახსრების
          ჩარიცხვის თაობაზე, ან დავალება, რომლის შედეგადაც ხდება მონაწილის მიერ
          გადახდის ვალდებულების საკუთარ თავზე აღება ან გადახდის ვალდებულების
          შესრულება სისტემის მიერ დადგენილი წესების შესაბამისად.
        </ContentText>
        <ContentText>
          2.12. გადახდის ოპერაცია - გადამხდელის ან მიმღების მიერ ინიცირებული
          ელექტრონული ფულის განთავსების/ჩარიცხვის, გადარიცხვის, კონვერტაციის ან
          გატანა/განაღდების ოპერაცია. ასევე, მომსახურების ან/და პროდუქციის
          მიღება ელექტრონული ფულის სანაცვლოდ.
        </ContentText>
        <ContentText mb="1rem">
          2.13. სისტემის მომხმარებლის იდენტიფიკაცია - სისტემის ოპერატორის მიერ
          შემუშავებული წესებისა და პროცედურების ერთობლიობა, რომლის საფუძველზეც,
          სისტემის ოპერატორი ახორციელებს მომხმარებლის ვინაობის დადგენას,
          იდენტიფიკაციას.
        </ContentText>
        <ContentText mb="1rem">
          2.14. საგადახდო მომსახურების მომხმარებელი - სისტემის ოპერატორის მიერ
          რეგისტრირებული ფიზიკური პირი, რომელიც სარგებლობს საგადახდო
          მომსახურებით, როგორც გადამხდელი ან მიმღები, ხოლო იურიდიული პირი,
          სარგებლობს საგადახდო მომსახურებით, როგორც მიმღები და ორივე მათგანი
          ვალდებულია იმოქმედოს სისტემის ოპერატორის მიერ დადგენილი წესების და
          პირობების შესაბამისად.
        </ContentText>
        <ContentText mb="1rem">
          2.15. რეგისტრაცია - პროცედურების ერთობლიობა, რომელიც უნდა გაიაროს
          საგადახდო სისტემის მომხმარებელმა საკუთარი ელექტრონული საფულის
          დასარეგისტრირებლად.
        </ContentText>
        <ContentText>
          2.16. ავტორიზაცია - მომხმარებლის მიერ ელექტრონულ საფულეში (სისტემაში)
          შესასვლელად საჭირო პროცედურის გავლა, რის შემდეგაც შესაძლებელი ხდება
          ნებისმიერი გადახდის ოპერაციის განხორციელება (სისტემის მომსახურებებით
          სარგებლობა).
        </ContentText>
        <ContentText>
          2.17. გადამხდელი - პირი, რომელიც გასცემს საგადახდო დავალებას ან
          ეთანხმება საგადახდო დავალების გაცემას.
        </ContentText>
        <ContentText>
          2.18. მიმღები - პირი, რომლის სასარგებლოდაც ხორციელდება გადახდის
          ოპერაცია.
        </ContentText>
        <ContentText>
          2.19. აგენტი - პირი, რომელიც პროვაიდერთან გაფორმებული ხელშეკრულების
          საფუძველზე საგადახდო მომსახურების მიწოდებისას მოქმედებს ამ პროვაიდერის
          სახელით.
        </ContentText>
        <ContentText>
          2.20. სისტემის სუბიექტი - ნებისმიერი ფიზიკური ან იურიდიული პირი,
          რომელსაც რაიმე ტიპის ურთიერთობა აქვს სისტემასთან.
        </ContentText>
        <ContentText>
          2.21. მერჩანტი - იურიდიული ან ფიზიკური პირი, რომლის სასარგებლოდაც
          მისგან მომსახურების/საქონლის მიღების მიზნით, ხორციელდება საგადახდო
          დავალების შესრულება, ელექტრონული მომსახურების პროვაიდერის მიერ
          გამოშვებული ელექტრონული ფულის მეშვეობით.
        </ContentText>
        <ContentText>
          2.22. შუამავალი - სისტემის სუბიექტი, რომლის გავლითაც მომხმარებელი
          ახორციელებს მერჩანტისგან მომსახურების ან საქონლის მიღებასა და
          ელექტრონული საფულის შევსებას.
        </ContentText>
        <ContentText>
          2.23. სამუშაო დღე - საქართველოს ორგანული კანონით დადგენილი სამუშაო
          დღე.
        </ContentText>
        <ContentText>
          2.24. არასამუშაო დღე - საქართველოს ორგანული კანონით დადგენილი
          უქმე/არასამუშაო დღე.
        </ContentText>
        <ContentText>
          2.25. გმპ - გადახდების მიმღები პუნქტი: ტერმინალი, თვითმომსახურების
          ავტომატი ან/და ნებისმიერი სხვა ტექნიკური საშუალება, რომლის მეშვეობითაც
          მომხმარებელი ახორციელებს გადახდების მიღებას.
        </ContentText>
        {/* part 2 */}
        <Title size="2.5rem" color="#101828" lh="42px" mt="2.5rem" mb="1rem">
          3. რეგისტრაცია ელექტრონულ საფულეში
        </Title>
        <ContentText>
          3.1. იმისათვის, რომ მომხმარებელი დარეგისტრირდეს სისტემაში საჭიროა:
          <ul>
            <li>
              · მომხმარებელმა რეგისტრაციის ფორმაში შეიყვანოს სრული და სწორი
              ინფორმაცია;
            </li>
            <li>
              · დაეთანხმოს სისტემის წესებს და პირობებს, შესაბამისი ველის
              მონიშვნის გზით და დააჭიროს ღილაკს რეგისტრაცია;
            </li>
            <li>
              · აღნიშნული პროცედურის გავლის შემდეგ რეგისტრაცია საფულეში ითვლება
              დასრულებულად და მომხმარებლის ელ. საფულეს ავტომატურად ენიჭება
              უნიკალური ნომერი.
            </li>
            <li>
              · სისტემის ოპერატორი უფლებამოსილია ელექტრონული ფოსტის სისწორის
              გადამოწმების მიზნით მომხმარებელს გაუგზავნოს ერთჯერადი კოდი ან
              ბმული, რომლის საშუალებითაც მომხმარებელი ადასტურებს ელ-ფოსტის
              ნამდვილობას;
            </li>
          </ul>
        </ContentText>
        <ContentText>
          3.2. ერთი მობილური ტელეფონის ნომრით პირს უფლება აქვს, დაარეგისტრიროს
          მხოლოდ ერთი ელექტრონული საფულე.
        </ContentText>
        <ContentTitle>4. პირის იდენტიფიკაცია საფულეში</ContentTitle>
        <ContentText>
          სისტემაში არის იდენტიფიკაციის ორი დონე. თითოეული მათგანი მომხმარებელს
          განუსაზღვრავს უფლებებს, სხვადასხვა მომსახურების მიღების შესაძლებლობაზე
          და ამ მომსახურების ლიმიტებზე.
        </ContentText>
        <ContentText weight="600">
          4.1. “არაიდენტიფიცირებული” მომხმარებელი:
        </ContentText>
        <ContentText>
          4.1.1. მომხმარებელს ელექტრონულ საფულეში რეგისტრაციის გავლის შემდეგ
          ავტომატურად ენიჭება სტატუსი ,,არაიდენტიფიცირებული“, რაც უზღუდავს
          მომხმარებელს სხვადასხვა ოპერაციის განხორციელების შესაძლებლობას.
        </ContentText>
        <ContentText>
          4.1.2. ,,არაიდენტიფიცირებულ“ მომხმარებელს არ შეუძლია ფინანსური
          მომსახურების მიღება. თუ მას სურს, რომ საფულის მეშვეობით განახორციელოს
          ყველა სახის ოპერაცია, საფულის შესაძლებლობების ფარგლებში, მან უნდა
          გაიაროს იდენტიფიკაცია ოფისში ვიზიტით.
        </ContentText>
        <ContentText weight="600">
          4.2. მომხმარებლის დისტანციური იდენტიფიკაცია:
        </ContentText>
        <ContentText>4.2.1. ამ ეტაპზე არ არის ხელმისაწვდომი. </ContentText>
        <ContentText weight="600">
          4.3. იდენტიფიკაცია ოფისში ვიზიტით
        </ContentText>
        <ContentText>
          4.3.1. მომხმარებელი, რომელსაც სურს ელექტრონული საფულით შეუზღუდავი
          სარგებლობა მაქსიმალური ლიმიტებით, ვალდებულია გაიაროს იდენტიფიკაცია
          ოფისში ვიზიტით;
        </ContentText>
        <ContentText>
          4.3.2. ამ მუხლით გათვალისწინებული იდენტიფიკაციის მიზნებისთვის,
          მომხმარებელი ვალდებულია ეწვიოს სისტემის ოპერატორის (ან მისი აგენტების)
          ოფისს. მომხმარებელი ვალდებულია თან იქონიოს პირადობის დამადასტურებელი
          დოკუმენტი;
        </ContentText>
        <ContentText>
          4.3.3. ოფისში ვიზიტისას, მომხმარებელი ვალდებულია შესაბამის
          თანამშრომელს წარმოუდგინოს პირადობის დამადასტურებელი დოკუმენტი და
          მიაწოდოს ინფორმაცია ელ. საფულის იდენტიფიკატორის (საფულის ნომერი,
          მობილურის ნომერი ან მომხმარებლის სახელი) შესახებ.
        </ContentText>
        <ContentText>
          4.3.4. მომხმარებლის მიერ მიწოდებული ინფორმაციის გადამოწმების შემდგომ,
          ოპერატორს სისტემაში შეჰყავს მომხმარებლის მონაცემები:
          <ul>
            <li>· გვარი</li>
            <li>· სახელი</li>
            <li>· სქესი</li>
            <li>· საცხოვრებელი მისამართი</li>
            <li>· საკონტაქტო ტელეფონის ნომერი</li>
            <li>· ელ-ფოსტა</li>
            <li>· პირადი ნომერი</li>
            <li>· პირადობის დამადასტურებელი დოკუმენტის ტიპი</li>
            <li>· დოკუმენტის ნომერი</li>
            <li>· გაცემის თარიღი</li>
            <li>· მოქმედების ვადა</li>
            <li>· გამცემი ორგანო</li>
            <li>· რეგისტრაციის ქვეყანა</li>
            <li>· რეგისტრაციის მისამართი</li>
            <li>· დაბადების ადგილი</li>
            <li>· დაბადების თარიღი</li>
          </ul>
        </ContentText>
        <ContentText>
          4.3.5. მონაცემების სისტემაში შეყვანის შემდგომ იბეჭდება განაცხადის
          ფორმა. მომხმარებელი ვალდედბულია ხელი მოაწეროს ამობეჭდილ დოკუმენტს.
        </ContentText>
        <ContentText>
          4.3.6. ოპერატორი ვალდებულია ხელმოწერილი ფორმა და მომხმარებლის
          პირადობის დამადასტურებელი დოკუმენტი დასკანერებული ფორმით ატვირთოს
          სისტემაში და დაადასტუროს/დაამახსოვრობს მონაცემები.
        </ContentText>
        <ContentText>
          4.3.7. 4.3.1.-4.3.6 პუნქტებით გათვალისწინებული პროცედურის გავლის
          შემდგომ მომხმარებელი ითვლება იდენტიფიცირებულად, რაც განაპირობებს
          საფულეში მოქმედი ლიმიტების გაზრდას, კერძოდ ლიმიტები ხდება შემდეგი: 1
          ოპერაციაზე 5,000 ლარი; დღეში 30,000 ლარი; თვეში 100,000 ლარი; საფულის
          მეშვეობით სხვადასხვა ოპერაციების განხორციელებაზე სრულად მოიხსნება
          შეზღუდვები, რაც მომხმარებელს მისცემს საშუალებას განახორციელოს
          ნებისმიერი სახის ოპერაცია, რაც ნებადართულია სისტემის ოპერატორის მიერ.
        </ContentText>
        <ContentText>
          4.3.8. იდენტიფიცირებულ მომხმარებელს შეუძლია ყველა სახის მომსახურების
          გადახდა, კერძოდ:
          <ul>
            <li>· კომუნალური მომსახურება</li>
            <li>· სხვადასხვა გადასახადების გადახდა</li>
            <li>· თანხის საფულიდან გატანა</li>
            <li>· ფულადი გზავნილების გაგზავნა და მიღება</li>
            <li>· თანხის ჩარიცხვა/გადარიცხვა</li>
            <li>
              · სხვა, რაც პერიოდულად შეიძლება დამატებული იყოს საფულის მენიუში.
            </li>
          </ul>
        </ContentText>
        <ContentText>
          4.4. სისტემაში შეიძლება იცვლებოდეს იდენტიფიკაციის მოთხოვნები და ასევე
          ლიმიტები. მომხმარებელს შეუძლია ონლაინ რეჟიმში იხილოს განახლებული
          პირობები და ლიმიტები საფულის მენიუში, საკუთარი პროფილის ქვეშ,
          „ლიმიტები და იდენტიფიკაცია“ მენიუში.
        </ContentText>
        <ContentTitle>5. ფულადი სახსრების უსაფრთხოება</ContentTitle>
        <ContentText>
          5.1. ელექტრონული ფულის გამოშვება სისტემის ოპერატორის მიერ ხორციელდება
          საფულის ანგარიშზე თანხის ჩარიცხვის შესახებ მოთხოვნის მიღების
          (საგადახდო დავალების) შესრულების შედეგად. აღნიშნული საგადახდო
          დავალების შესრულების პარალელურად, ელექტრონული ფულის პროვაიდერი თავის
          მომსახურე ბანკში გახსნილ ნომინალური მფლობელობის ანგარიშზე ათავსებს
          (რიცხავს) გამოსაშვები ელ-ფულის ოდენობის შესაბამის ჯამურ თანხას ლარში.
          ყოველი საბანკო დღის ბოლოს ნომინალური მფლობელობის ანგარიშზე
          განსათავსებელი ფულადი სახსრები შეადგენდეს მომხმარებლის ფულადი
          სახსრების (გამოშვებული ელექტრონული ფულის) 100%-ს.
        </ContentText>
        <ContentText>
          5.2. სისტემის პირობების თანახმად, 1 ლარი, აშშ დოლარი, ევრო, დიდი
          ბრიტანეთის გირვანქა სტერლინგი, რუსული რუბლი, უდრის შესაბამისად 1
          ელექტრონული ლარს, აშშ დოლარს, ევროს, დიდი ბრიტანეთის გირვანქა
          სტერლინგს ან რუსულს რუბლს;
        </ContentText>
        <ContentText>
          5.3. ელექტრონული ფულის პროვაიდერი სისტემაში შესაბამის ღილაკზე დაჭერით
          ახორციელებს ელექტრონული ფულის ემისიას ლარში, აშშ დოლარში, გირვანქა
          სტერლინგში, რუსულ რუბლში ან ევროში, რომელიც თავსდება სისტემაში არსებულ
          ,,ინექს ფეის“ სპეციალურ ელექტრონული საფულის ანგარიშზე, საიდანაც შემდეგ
          ივსება მომხმარებლების ელექტრონული საფულეები, მომხმარებლების საგადახდო
          დავალებების საფუძველზე. სისტემაში არსებული ელექტრონული ლარი ნებისმიერ
          მომენტში ექვივალენტურია ზემოთ ნახსენებ ანგარიშზე განთავსებული თანხის
          ოდენობისა.
        </ContentText>
        <ContentText>
          5.4. მომხმარებელთა ფულადი სახსრების უსაფრთხოების მიზნით ელექტრონული
          ფულის პროვაიდერის მიერ, საქართველოს ეროვნული ბანკის რეგულაციების
          გათვალისწინებით, შესაბამისი საგადახდო ოპერაციების განხორციელებისთვის
          დადგენილია ძლიერი ავთენტიფიკაციის პროცედურა. მომხმარებელი ვალდებულია
          მიჰყვეს და შეასრულოს ელექტორნული ფულის პროვაიდერის მიერ განსაზღვრული
          ძლიერი ავთენტიფიკაციის პროცედურები;
        </ContentText>
        <ContentTitle>
          6. მომხმარებლის მიერ განხორციელებადი გადახდის ოპერაციები
        </ContentTitle>
        <ContentText>
          6.1. მომხმარებელს შეუძლია განახორციელოს საგადახდო დავალების ინიცირება,
          სისტემის ოპერატორის მიერ გამოშვებული ელექტრონული ფულით, საფულეში
          რეგისტრაციის შემდეგ, საგადახდო ინსტრუმენტის გამოყენებით, მერჩანტის
          მიერ გაწეული ან სისტემაში არსებული სხვა მომსახურების სანაცვლოდ.
        </ContentText>
        <ContentText>
          6.2. მომხმარებელს ელექტრონული ფულით შეუძლია განახორციელოს შემდეგი
          ოპერაციები:
          <ul>
            <li>
              ა) მერჩანტებისთვის მომსახურების/საქონლის ღირებულების ანაზღაურების
              გადახდა;
            </li>
            <li>
              ბ) საკუთარი საფულიდან სხვა მომხმარებლის ელექტრონულ საფულეზე
              ელექტრონული ფულის გადარიცხვა;
            </li>
            <li>
              გ) ელექტრონულ საფულეზე არსებული თანხის ან მისი ნაწილის კონვერტაცია
              სხვა ვალუტაში;
            </li>
            <li>დ) ელექტრონული საფულიდან თანხის გატანა/გადარიცხვა;</li>
            <li>ე) ელექტრონულ საფულეზე თანხის ჩარიცხვა/შეტანა;</li>
            <li>
              ვ) ფულადი გზავნილების სისტემების მეშვეობით ფულადი გზავნილების
              განხორციელება, თანხის გაგზავნა და მიღება;
            </li>
          </ul>
        </ContentText>
        <ContentText>
          6.3. ელ. საფულეში პერიოდულად დაემატება ახალი მომსახურებები, რაც
          მომხმარებლისთვის ხელმისაწვდომი გახდება ონლაინ რეჟიმში, ელ. საფულის
          მენიუში.
        </ContentText>
        <ContentTitle>7. მომსახურების საფასურის გადახდის წესი</ContentTitle>
        <ContentText>
          7.1. ელექტრონული საფულის მენიუში მომხმარებელმა უნდა აირჩიოს სასურველი
          სერვისის გადახდა (მაგალითად: დენის გადასახადი, „თელასი“), რის
          შემდეგაც, მომხმარებელმა უნდა შეავსოს შესაბამისი ფორმა, რომელშიც უნდა
          მიუთითოს:
          <ul>
            <li>
              · მიმღების აბონენტის ნომერი (გრაფაში „აბონენტის ნომერი“ ივსება იმ
              აბონენტის ნომერი, რომლის სასარგებლოდაც უნდა შესრულდეს გადახდა)
            </li>
            <li>
              · გადასახდელი თანხა (ფორმაში მოცემულია, გადახდის ლიმიტები და
              საკომისიოს ოდენობა)
            </li>
          </ul>
        </ContentText>
        <ContentText>
          7.2. გადახდის განაცხადის შევსების შემდეგ, მომხმარებელი ვალდებულია
          დაადასტუროს შევსებული ინფორმაციის სისწორე შესაბამის ღილაკზე დაჭერის
          გზით. ამ დროს სისტემა ავტომატურად ამოწმებს შევსებული მონაცემების
          სისწორეს და თუ ყველა ველი შევსებულია სწორად, მომხმარებელს მონიტორზე
          გამოუჩნდება შეტყობინება (დასტური ოპერაციის წარმატებით შესრულების
          შესახებ), ხოლო თუ მონაცემები არასწორია, მონიტორზე გამოჩნდება
          შესაბამისი შეტყობინება და რის მიხედვითაც მომხმარებელმა უნდა შეავსოს
          დაზუსტებული მონაცემები. გადახდის ოპერაციის შესრულების შემდგომ
          მომხმარებლის ელექტრონული საფულის ბალანსი შემცირდება საგადახდო
          დავალებაში მითითებული თანხისა და საკომისიოს ოდენობით.
        </ContentText>
        <ContentTitle>
          8. საკუთარი საფულიდან სხვა მომხმარებლის ელექტრონულ საფულეზე
          ელექტრონული ფულის გადარიცხვის ოპერაციის განხორციელების წესი
        </ContentTitle>
        <ContentText>
          8.1. ელექტრონული საფულის მომხმარებელს, რომელსაც ელექტრონულ საფულეში
          გავლილი აქვს რეგისტრაცია და იდენტიფიკაცია, უფლება აქვს თავისი
          საფულიდან გადაურიცხოს ელექტრონული ფული, სისტემაში არსებულ სხვა
          მომხმარებელს საფულეში.
        </ContentText>
        <ContentText>
          8.2. სხვა მომხმარებლის ელექტრონულ საფულეზე ელექტრონული ფულის
          გადარიცხვის მიზნით მომხმარებელი ვალდებულია ელ-საფულის სისტემის მენიუში
          აირჩიოს ღილაკი - „თანხის გადარიცხვა“, შექმნას საგადახდო დავალება ,
          სადაც უნდა მიუთითოს:
          <ul>
            <li>· გადასარიცხი თანხის ოდენობა;</li>
            <li>
              · მიმღების საფულის რეკვიზიტები (მობილური ტელეფონის ნომერი ან
              ელ-საფულის ნომერი ან ელ-ფოსტა);
            </li>
            <li>· დანიშნულება;</li>
          </ul>
        </ContentText>
        <ContentText>
          8.3. მომხმარებლის მიერ 8.2. პუნქტში მითითებული მონაცემების სისტემაში
          შეყვანის შემდგომ, სისტემა ავტომატურად პოულობს გადარიცხვის მიმღებს და
          თანხის გადამრიცხავს უჩვენებს მიმღების სახელს და გვარს ან მის
          ინიციალებს.
        </ContentText>
        <ContentText>
          8.4. მომხმარებელი, 8.2. პუნქტში მითითებული მონაცემების სისტემაში
          შეყვანის შემდგომ, სპეციალურ ღილაკზე დაჭერით ახდენს საგადახდო დავალების
          ინიცირებას, რის შემდეგაც სისტემა აჭრის მომხმარებელს საფულიდან თანხას,
          გადარიცხვის საკომისიოსთან ერთად (საკომისიოს არსებობის შემთხვევაში),
          რომელიც წინასწარ არის მომხმარებლისთვის ცნობილი და მიმღებს ურიცხავს
          დავალებაში მითითებულ თანხას საფულის ანგარიშზე.
        </ContentText>
        <ContentTitle>
          9. ელექტრონულ საფულეზე არსებული თანხის ან მისი ნაწილის სხვა ვალუტაში
          კონვერტაციის წესი
        </ContentTitle>
        <ContentText>
          9.1. ელ. საფულის მომხმარებელს შესაბამისი ლიმიტების ფარგლებში უფლება
          აქვს მოახდინოს თანხის კონვერტაცია ერთი ვალუტიდან მეორეში, რაც
          ხორციელდება ამ პუნქტით განსაზღვრული წესით;
        </ContentText>
        <ContentText>
          9.2. კონვერტაციის განსახორციელებლად, მომხმარებელი ვალდებულია თავისი
          ელექტრონული საფულის მენიუში დააჭიროს ღილაკს ,,ვალუტის გადაცვლა“, რის
          შემდეგაც გამოდის ფანჯარა, სადაც მითითებულია (გასაყიდი თანხის მხარეს)
          მომხმარებლის ბალანსი ლარში და (შესაძენი თანხის მხარეს) ვალუტების
          ჩამონათვალი, რომლებზეც ხორციელდება ვალუტის გადაცვლა (კონვერტაცია).
          მომხმარებელი ვალდებულია სპეციალურ გრაფაში მიუთითოს თანხის ოდენობა,
          რომლის გადაცვლაც სურს, ხოლო მეორე გრაფაში აირჩიოს ვალუტა, რომლის
          შეძენაც სურს. ამ დროს სისტემა ავტომატურად ითვლის შესაძენი თანხის
          ოდენობას შპს „ინექს ფეის“ კომერციული კურსის შესაბამისად და ასახავს
          განაცხადის ფორმაში. მომხმარებლის მიერ საგადახდო დავალების გაცემის
          შემდეგ სისტემა მას საფულიდან ჩამოაჭრის თანხას, რომლის კონვერტირებაც
          სურს მომხმარებელს, ხოლო მომხმარებელს შესაბამისი შესაძენი თანხა
          ჩაერიცხება ვალუტაში თავის საფულეზე, სისტემის ოპერატორის შესაბამისი
          ვალუტის კონვერტაციის ანგარიშიდან. ამ დროს მომხმარებელი სისტემაში
          მიიღებს შეტყობინებას, რომ ტრანზაქცია დასრულდა წარმატებით. კონვერტაციის
          კურსი წინასწარ ცნობილია მომხმარებლისთვის და ნაჩვენებია საგადახდო
          დავალების ფორმაში.
        </ContentText>
        <ContentTitle>
          10. ელექტრონული საფულიდან თანხის გატანის წესი
        </ContentTitle>
        <ContentText>
          10.1. ელ. საფულიდან თანხის გატანა შეუძლია მხოლოდ იდენტიფიცირებულ
          მომხმარებელს. ელექტრონული საფულიდან თანხის გატანა შესაძლებელია ნაღდი
          ან უნაღდო ანგარიშსწორების, მათ შორის: საბანკო ანგარიშზე ან საბანკო
          ბარათზე გადარიცხვის გზით, ფულადი გზავნილების საშუალებით გადარიცხვის
          გზით, ინექს ფეის ან/და აგენტების სალაროებიდან ნაღდი ფულის სახით
          გატანის გზით;
        </ContentText>
        <ContentText>
          10.2. ელექტრონული საფულიდან თანხის გატანის მსურველი მომხმარებელი
          ვალდებულია საფულის მენიუში აირჩიოს თანხის მიღების მეთოდი და შეავსოს
          შესაბამისი განაცხადი, სადაც ივსება განაცხადის ფორმის სავალდებულო
          ველები და სასურველი თანხის ოდენობა, ხოლო მომხმარებლის საიდენტიფიკაციო
          მონაცემები ფორმაში ივსება ავტომატურად.
        </ContentText>
        <ContentText>
          10.3. განაცხადის 11.2. პუნქტით გათვალისწინებული ველების შემდგომ,
          განაცხადის ფორმაში ავტომატურად დაანგარიშდება მომსახურების საკომისიო.
          ოპერაციის გაგრძელების მიზნით მომხმარებელი ვალდებულია შესაბამისი ველის
          მონიშვნის გზით დაეთანხმოს საკომისიოს ოდენობას და დაადასტუროს შევსებული
          ინფორმაციის სისწორე. ამ დროს სისტემა ამოწმებს, არის თუ არა
          მომხმარებლის საფულის ანგარიშზე საკმარისი თანხა (საკომისიოს ჩათვლით),
          რათა მომხმარებელმა შეძლოს სასურველი თანხის მიღება. თუ მითითებული თანხა
          საკომისიოს ჩათვლით აღემატება მომხმარებლის მიმდინარე ნაშთს საფულის
          ანგარიშზე, სისტემა გამოიტანს შეცდომის შესაბამის ტექსტს, ხოლო თუ
          საფულის ნაშთი საკმარისია ოპერაციის განსახორციელებლად, მომხმარებელს
          გამოუჩნდება შეტყობინება, რომ ტრანზაქცია დასრულდა წარმატებით. ასეთ
          შემთხვევაში მომხმარებლის საფულის ბალანსი შემცირდება საგადახდო
          დავალებაში მითითებული თანხის პროპორციულად, საკომისიოს ჩათვლით.
        </ContentText>
        <ContentText>
          10.4. 10.3. პუნქტით გათვალისწნებული ოპერაციის შესრულების შემდგომ,
          მომხმარებელს შეუძლია, იმის მიხედვით თუ განაღდების რა ფორმა აირჩია,
          მიმართოს „ინექს ფეის“ ან/და აგენტის სალაროს, ბანკომატს ან თანხა მიიღოს
          მის მიერ მითითებულ საბანკო ანგარიშზე/ბარათზე ან ის გაანაღდოს თანხას
          ფულადი გზავნილების სისტემის მეშვეობით.
        </ContentText>
        <ContentText>
          10.5. მომხმარებლის მიერ თანხის ელექტრონული საფულიდან გატანის ან
          გადარიცხვის მოთხოვნის დადასტურების დროს, სისტემა ავტომატურად უგზავნის
          მომხმარებელს SMS შეტყობინებას, სადაც მითითებულია ერთჯერადი კოდი,
          რომლის შესაბამის გრაფაში ჩაწერის შემდეგ, სისტემა ადასტურებს
          ტრანზაქციის წარმატებით დასრულებას.
        </ContentText>
        <ContentText>
          10.6. მომხმარებელს, სისტემის მენიუში აქვს თანხის გატანის ან
          გადარიცხვის დროს SMS დადასტურების ფუნქციონალის გამორთვის და ასევე
          ნებისმიერ დროს ამ მომსახურების ხელახლა ჩართვის შესაძლებლობა.
        </ContentText>
        <ContentTitle>11. ელექტრონულ საფულეში თანხის შეტანის წესი</ContentTitle>
        <ContentText>
          11.1. მომხმარებელს უფლება აქვს ელექტრონულ საფულეში თანხა შეიტანოს
          უნაღდო ან ნაღდი ფორმით, მათ შორის: პარტნიორი კომპანიის ან ,,ინექს
          ფეის“ სალაროსა და პარტნიორი კომპანიების ტერმინალების, აგრეთვე ფულადი
          გზავნილების სისტემებისა და საბანკო ანგარიშების/ბარათების გამოყენებით.
        </ContentText>
        <ContentText>
          11.2. ელექტორნულ საფულეში თანხის შეტანის მიზნით, მომხმარებელი
          ვალდებულია აირჩიოს თანხის შეტანის სასურველი მეთოდი (ნაღდი ან უნაღდოს
          ფორმა). მომხმარებელს უფლება აქვს მიმართოს როგორც ინექს ფეის ასევე მისი
          პარტნიორი კომპანიის სალაროს, საგადახდო ტერმინალს და ვებგვერდს.
          ამასთანავე, ინექს ფეი პასუხისმგებლობას არ იღებს მომსახურების
          საკომისიოს რაოდენობისა თუ მისი ანაზღაურების პირობებთან დაკავშირებით,
          თუკი ელექტრონულ საფულეში თანხის შეტანა ხორციელდება არა უშუალოდ ინექს
          ფეის ან მისი აგენტების მეშვეობით;
        </ContentText>
        <ContentText>
          11.3. ელექტრონულ საფულეში თანხის შეტანის ოპერაციის განხორციელებისთვის
          მომხმარებელი ვალდებულია შეავსოს სპეციალური განაცხადი, სადაც ეთითება
          საფულის რეკვიზიტები, პირადი მონაცემები, მათ შორის საფულის უნიკალურ
          ნომერი. აგრეთვე, მომხმარებელი ვალდებულია მიუთითოს სასურველი თანხა და
          ვალუტა. აღნიშნული მონაცემების სისწორის შემოწმების შემდეგ, შესაბამის
          ღილაკზე დაჭერით ხორციელდება საგადახდო დავალების ინიცირება, რის
          შემდეგაც, საგადახდო დავალებაში მითითებული თანხის შესაბამისი
          ელექტრონული ფული ირიცხება მომხმარებლის საფულის ანგარიშზე.
        </ContentText>
        <ContentText>
          11.4. თანხის ერთ ვალუტაში შეტანის და მეორე ვალუტაში მიღების
          შემთხვევაში, მოხდება ავტომატური კონვერტაცია, „ინექს ფეის“ კომერციული
          კურსის შესაბამისად. კომერციული კურსი ონლაინ რეჟიმში მოცემულია საფულის
          მენიუში.
        </ContentText>
        <ContentTitle>
          12. საგადახდო დავალების მიღება, შესრულება და შესრულების ვადები
        </ContentTitle>
        <ContentText>
          12.1. მომხმარებელს შეუძლია საგადახდო დავალება გასცეს, როგორც
          წერილობით, ისე ელექტრონული, ინფორმაციულ-სატელეკომუნიკაციო ან/და სხვა
          ტექნოლოგიური საშუალებების გამოყენებით. მომხმარებელმა დავალებაში
          აუცილებელია მიუთითოს თავისი და მიმღების რეკვიზიტები სრულად. გადახდის
          ოპერაციას დადასტურების მომენტში ენიჭება უნიკალური ნომერი, რომელიც
          წარმოადგენს ამ ტრანზაქციის იდენტიფიკატორს და ის უცვლელია ოპერაციის
          შესრულების ყველა ეტაპზე.
        </ContentText>
        <ContentText>
          12.2. საგადახდო დავალების მიღების დროდ ითვლება მომენტი, როდესაც
          გადამხდელის საგადახდო მომსახურების სისტემის ოპერატორი მიიღებს
          საგადახდო დავალებას შესასრულებლად.
        </ContentText>
        <ContentText>
          12.3. თუ საგადახდო დავალება მიღებულია, მიმდინარე სამუშაო დღის 17:00
          საათის შემდეგ, შესაძლოა საგადახდო დავალება მიღებულად ჩაითვალოს
          მომდევნო სამუშაო დღეს, რაც დამოკიდებულია მიმღების პროვაიდერის მუშაობის
          პრინციპზე.
        </ContentText>
        <ContentText>
          12.4. სისტემის ოპერატორი ვალდებულია, დავალების მიღების შესახებ აცნობოს
          გადამხდელს იმ ფორმით, რა ფორმითაც გადამხდელმა მოახდინა საგადახდო
          დავალების ინიცირება.
        </ContentText>
        <ContentText>
          12.5. თუ საგადახდო დავალება უნდა შესრულდეს მხარეებს შორის შეთანხმებულ
          დღეს, სისტემის ოპერატორი უფლებამოსილია მიიღოს საგადახდო დავალება ამ
          თარიღის დადგომამდე.
        </ContentText>
        <ContentText>
          12.6. იმ შემთხვევაში, თუ სისტემის ოპერატორი არის როგორც მიმღების, ისე
          გადამხდელის პროვაიდერი, მაშინ მან უნდა უზრუნველყოს საგადახდო
          დავალებაში მითითებული თანხის მიმღების ანგარიშზე ასახვა ან/და
          ხელმისაწვდომობა საგადახდო დავალების მიღების დღესვე.
        </ContentText>
        <ContentText>
          12.7. იმ შემთხვევაში, თუ სისტემის ოპერატორი არის გადამხდელის საგადახდო
          მომსახურების პროვაიდერი, მან უნდა უზრუნველყოს საგადახდო დავალებაში
          მითითებული თანხის ჩარიცხვა მიმღების პროვაიდერის ანგარიშზე საგადახდო
          დავალების მიღებიდან არაუგვიანეს მეორე სამუშაო დღისა, თუ მხარეებს შორის
          შეთანხმებით სხვა რამ არ არის გათვალისწინებული.
        </ContentText>
        <ContentText>
          12.8. იმ შემთხვევაში თუ სისტემის ოპერატორი არის მიმღების საგადახდო
          პროვაიდერი, იგი ვალდებულია, იმავე დღეს ჩარიცხული თანხა ასახოს მიმღების
          ანგარიშზე, თუ მხარეებს შორის შეთანხმებით სხვა პირობა არ არის
          შეთანხმებული.
        </ContentText>
        <ContentText>
          12.9. იმ შემთხვევაში თუ სისტემის ოპერატორი ვერ ახერხებს მიმღების
          იდენტიფიცირებას მიღებული ინფორმაციის საფუძველზე და დავალება არ
          შესრულდება, მაშინ მან უნდა უზრუნველყოს მიღებული თანხის უკან დაბრუნება
          უმოკლეს ვადაში.
        </ContentText>
        <ContentText>
          12.10. სისტემის ოპერატორი ვალდებულია, მომხმარებლის მიერ ელექტრონული
          ფულის შეძენის შემთხვევაში, დაუყოვნებლივ გახადოს ეს თანხა მისთვის
          ხელმისაწვდომი.
        </ContentText>
        <ContentText>
          12.11. საგადახდო დავალების დადასტურების მიზნით, სისტემის ოპერატორი
          ვალდებულია მომხმარებელს გადასცეს სპეციალური დადასტურებული დოკუმენტი,
          მატერიალური ან ელექტრონული ფორმით (გადახდის ქვითარი).
        </ContentText>
        <ContentText>
          12.12. გადახდა შესრულებულად ითვლება, მიმღების ანგარიშზე (ელექტრონული)
          ფულადი სახსრების ასახვის მომენტიდან, გარდა კანონით გათვალისწინებული
          შემთხვევებისა.
        </ContentText>
        <ContentTitle>13. საგადახდო დავალების უარყოფა</ContentTitle>
        <ContentText>
          13.1. სისტემის ოპერატორი საგადახდო დავალების შესრულებას უარყოფს შემდეგ
          შემთხვევებში:
          <ul>
            <li>
              ა) თუ მომხმარებელმა მიუთითა შეცდომით პირადი ან/და ელექტრონული
              საფულის რეკვიზიტები;
            </li>
            <li>ბ) მომხმარებელი არასრულწლოვანია;</li>
            <li>
              გ) მომხმარებლის ანგარიში სისტემის წესების დარღვევისთვის არის
              ბლოკირებული;
            </li>
            <li>
              დ) ჩნდება გონივრული ეჭვი, რომ მომხმარებელი იმყოფება ნარკოტიკული,
              ფსიქოტროპული ან ალკოჰოლური ან სხვა ნივთიერებების ზემოქმედების
              ქვეშ;
            </li>
            <li>
              ე) სისტემის ოპერატორმა შესაბამისი მიმღების პროვაიდერისგან მიიღო
              უარყოფითი პასუხი, რაც შესაძლებელია შემდეგ შემთხვევებში, თუ
              მომხმარებლისგან წარდგენილი ანგარიში/იდენტიფიკატორი არ არის სწორი
              და სისტემამ ვერ მოძებნა ასეთი მონაცემები, ან იმ შემთხვევაში თუ
              მომხმარებლის მიერ ინიცირებული თანხის ოდენობა აღემატება წარდგენილ
              ანგარიშზე სისტემის მიერ დადგენილ ლიმიტებს (ანგარიშის ან
              იდენტიფიცირების დონის მიხედვით). ასევე, თუ მიმღები ან მიმღების
              პროვაიდერი, რაიმე ტექნიკური თუ სხვა მიზეზით ვერ იღებს გადახდის
              თანხას, რის გამოც ვერ ასრულებს გადახდის ოპერაციას.
            </li>
            <li>
              ვ) თუ გადამხდელის ელექტრონულ საფულეზე არსებული თანხა არ არის
              საკმარისი გადასარიცხი თანხის ან/და საკომისიოსათვის;
            </li>
            <li>
              ზ) საქართველოს კანონმდებლობით გათვალისწინებულ სხვა შემთხვევებში.
            </li>
          </ul>
        </ContentText>
        <ContentText>
          13.2. იმ შემთხვევაში თუ სისტემის ოპერატორი უარს ამბობს საგადახდო
          დავალების შესრულებაზე ამ მუხლის პირველი ნაწილით გათვალისწინებული
          პირობებიდან ერთ-ერთის ან რამდენიმეს დადგომის შემთხვევაში, იგი
          ვალდებულია აღნიშნულის შესახებ უმოკლეს ვადაში აცნობოს მომხმარებელს,
          მაგრამ არაუგვიანეს საგადახდო დავალების შესრულების დროისა.
        </ContentText>
        <ContentText>
          13.3. მომხმარებელს არ აქვს უფლება გააუქმოს საგადახდო დავალება, თუ იგი
          მიღებულია გადამხდელის ან მიმღების პროვაიდერის მიერ.
        </ContentText>
        <ContentText>
          13.4. იმ შემთხვევაში თუ მომხმარებელს და სისტემის ოპერატორის შორის
          საგადახდო დავალების შესრულების დღე წინასწარ არის შეთანხმებული,
          მომხმარებელს უფლება აქვს გააუქმოს საგადახდო დავალება არაუგვიანეს
          შეთანხმებული დღის წინა სამუშაო დღისა. მომხმარებელს და სისტემის
          ოპერატორს შორის შესაბამისი შეთანხმების არსებობის შემთხვევაში,
          საგადახდო დავალება შესაძლოა გამოთხოვილ იქნას მიმღების ანგარიშზე თანხის
          ასახვამდე.
        </ContentText>
        <ContentTitle>
          14. საგადახდო დავალების შეუსრულებლობა ან არასწორად შესრულება
        </ContentTitle>
        <ContentText>
          14.1. სისტემის ოპერატორი პასუხს არ აგებს საგადახდო დავალების
          შეუსრულებლობაზე ან არასწორად შესრულებაზე, თუ ეს გამოწვეულია
          მომხმარებლის მიერ მითითებული არასწორი ინფორმაციით. მიუხედავად ამისა,
          სისტემის ოპერატორი ვალდებულია არასწორად გადმორიცხული ელექტრონული ფული
          დაუბრუნოს მომხმარებელს, თუ დავალება არ არის შესრულებული მიმღების
          პროვაიდერის მიერ.
        </ContentText>
        <ContentText>
          14.2. იმ შემთხვევაში თუ სისტემის ოპერატორმა (პროვაიდერმა), რომლის
          საშუალებითაც მომხმარებელი ახორციელებს გადახდას, არასწორად შეასრულა
          გადახდის ოპერაცია თავისი ბრალეულობით, იგი ვალდებულია, გადამხდელს
          დაუბრუნოს არასწორად გადარიცხული თანხა და ამ ტრანზაქციის საკომისიო.
        </ContentText>
        <ContentText>
          14.3. იმ შემთხვევაში თუ სისტემის ოპერატორი არის მიმღების პროვაიდერი და
          მან არასწორად შეასრულა გადახდის ოპერაცია, იგი ვალდებულია მიმღებს
          დაუბრუნოს გადახდილი თანხა საკომისიოსთან ერთად.
        </ContentText>
        <ContentText>
          14.4. სისტემის ოპერატორი არ არის ვალდებული მომხმარებელს დაუბრუნოს
          თანხა თუ საგადახდო დავალებაში მითითებული მომსახურება მომხმარებლის მიერ
          იქნა მიღებული, რასაც ადასტურებს ტრანსფერ ორდერის შესაბამისი სტატუსი
          სისტემაში. ხოლო, თუ მომხმარებლის მიერ გადახდილი თანხის შესაბამისად,
          ტექნიკური მიზეზების გამო მან არ მიიღო მომსახურება (მომხმარებლის
          წერილობითი განაცხადის საფუძველზე, ან ავტომატურად), სისტემის ოპერატორი
          დაუბრუნებს მას თანხას ელ. საფულეზე.
        </ContentText>
        <ContentTitle>15. მომსახურების ტარიფები</ContentTitle>
        <ContentText>
          15.1. სისტემის ოპერატორი, ელექტრონული საფულით მომსახურებისთვის ადგენს
          ტარიფებს, რომლებიც განთავსებულია სისტემის ოპერატორის ვებგვერდზე
          www.inexpay.ge. სისტემის ოპერატორს უფლება აქვს ყოველგვარი თანხმობის
          გარეშე შეცვალოს ტარიფები ან დააწესოს ახალი ტარიფები. ასეთ შემთხვევაში
          სისტემის ოპერატორი განაახლებს ტარიფებს ოფიციალურ ვებგვერდზე და
          ელექტრონული საფულის მენიუში. მომსახურების განახლებული საკომისიოები
          აგრეთვე აისახება კონკრეტული მომსახურების მიღების მომენტში, რომელიც
          თვალსაჩინოა მომხმარებლისთვის. თუ მომხმარებელი გადაწყვეტს, რომ შეცვლილი
          ტარიფების პირობებში გააგრძელოს ელექტრონული საფულით სარგებლობა,
          აღნიშნული უფლებას აძლევს სისტემის ოპერატორს ჩამოაჭრას მომხმარებელს
          მომსახურების შეცვლილი საკომისიო.
        </ContentText>
        <ContentText>
          15.2. სისტემის ოპერატორის ჩანაწერები წარმოადგენს მომხმარებელის
          სისტემის ოპერატორისთან ურთიერთობის დამადასტურებელ საბუთს, გარდა იმ
          შემთხვევისა, როდესაც ჩანაწერი აშკარად უზუსტოა.
        </ContentText>
        <ContentTitle>16. პირობების მოქმედების ვადები</ContentTitle>
        <ContentText>
          16.1. სისტემის ოპერატორსა და მომხმარებელს შორის შეთანხმება
          გაფორმებულად ითვლება მომხმარებლის მიერ წინამდებარე წესებსა და
          პირობებზე თანხმობის დადასტურების მომენტიდან;
        </ContentText>
        <ContentText>
          16.2. წინამდებარე პირობები მოქმედებს განუსაზღველი ვადით, ელექტრონული
          საფულის გაუქმებამდე, რომელიც შესაძლებელია განხორციელდეს მომხმარებლის
          სურვილით, ან სისტემის ოპერატორის გადაწყვეტილებით;
        </ContentText>
        <ContentText>
          16.3. სისტემის ოპერატორს უფლება აქვს მომსახურებით სარგებლობის
          შეჩერების პარალელურად გააუქმოს ელექტრონული საფულე, თუკი დამტკიცდება,
          რომ მომხმარებელი მას იყენებდა აკრძალული და უკანონო ქმედებების
          განხორციელებისთვის;
        </ContentText>
        <ContentText>
          16.4. ელექტრონული საფულის გაუქმების შემთხვევაში მომხმარებელს უკან
          უბრუნდება ელექტრონულ საფულეზე რიცხული ნაშთი, გარდა იმ შემთხვევისა,
          როდესაც კანონმდებლობით გათვალისწინებულ შემთხვევებში, ფულადი სახსრების
          უკანონობის საფუძვლით, ხორციელდება თანხების სასამართლო წესით
          ჩამორთმევა.
        </ContentText>
        <ContentText>
          16.5. ხელშეკრულების შეწყვეტა ან/და ელექტრონული საფულის გაუქმება არ
          ათავისუფლებს მხარეებს მანამდე არსებული ვალდებულებების შესრულებისგან;
        </ContentText>
        <ContentTitle>17. კონფდიენციალობა და უსაფრთხოება</ContentTitle>
        <ContentText>
          17.1. მომხმარებელი ვალდებულია უსაფრთხოდ შეინახოს მისი ელექტრონული
          საფულის მონაცემები და არასდროს გაუმჟღავნოს ისინი მესამე პირებს.
          მომხმარებელი ვალდებულია აცნობოს სისტემის ოპერატორის მის მიერ მიღებული
          ნებისმიერი გზავნილის, ან ნებისმიერი ვებ-გვერდის შესახებ, გარდა
          სისტემის ოპერატორის ვებ-გვერდისა, სისტემის ოპერატორთან დაკავშირებული
          პირების (მათ შორის სისტემის ოპერატორის პარტნიორი კომპანიების)
          ვებ-გვერდებისა ან საგადახდო არხის ან მერჩანტის ვებ-გვერდისა, რომელიც
          მოითხოვს სისტემის ოპერატორის ავტორიზაციის მონაცემების გამოყენებას.
          მომხმარებელი ვალდებულია დაუკავშირდეს სისტემის ოპერატორის კლიენტთა
          მომსახურების ცენტრს, თუ ეჭვი გაჩნდა რომ ზემოაღნიშნული რომელიმე
          ვებ-გვერდი არ არის ნამდვილი.
        </ContentText>
        <ContentText weight="600"> 17.2. მომხმარებელი ვალდებულია: </ContentText>
        <ContentText>
          17.2.1. არ გაუმხილოს დეტალები ავტორიზაციის მონაცემების შესახებ მესამე
          პირს (აღნიშნულ შემთხვევაში მესამე პირებად არ განიხილებიან სისტემის
          ოპერატორის ის თანამშრომლები, რომლებიც უშუალოდ გადასცემენ მომხმარებელს
          ავტორიზაციის მონაცემებს ან მომხმარებელის დავალების საფუძველზე
          ახორციელებენ ავტორიზაციის მონაცემების შეცვლას ან ავტორიზაციის
          მონაცემების გააქტიურებას. ასევე ნებისმიერ სხვა შემთხვევაში, როდესაც
          სისტემის ოპერატორის თანამშრომლის დაშვება ავტორიზაციის მონაცემებთან
          აუცილებელია კონკრეტული პრობლემის გადაწყვეტისათვის ან/და შესაბამისი
          მომსახურების განხორციელებისათვის);
        </ContentText>
        <ContentText>
          17.3. სისტემის ოპერატორი უფლებამოსილია ნებისმიერ დროს შეაჩეროს ან
          სხვაგვარად შეზღუდოს მომხმარებელის სისტემის ოპერატორი ანგარიშის
          ფუნქციონირება, თუ სისტემის ოპერატორის ეჭვი გაუჩნდება, რომ მომხმარებლის
          სისტემის ოპერატორი ანგარიში გამოყენებულია სხვა პირის მიერ
          მომხმარებელის ნებართვის გარეშე ან იგი სხვაგვარად იქნა მითვისებული;
        </ContentText>
        <ContentText>
          17.4. მომხმარებელის შესახებ არსებული ინფორმაციის და მისი ოპერაციების
          უსაფრთხოების დაცვის მიზნით, სისტემის ოპერატორი უფლებამოსილია ნებისმიერ
          დროს შექმნას მომხმარებლის იდენტიფიცირების დამატებითი ღონისძიებები, რაც
          შეზღუდვის გარეშე მოიცავს, მოითხოვოს სპეციალური მოწყობილობის, პროგრამის
          საშუალებით შექმნილი დამატებითი ავტორიზაციის მონაცემების ან სხვა სახის
          ციფრული ან/და ელექტრონული ავტორიზაციის მონაცემების გამოყენებას,
          რომელიც სისტემის ოპერატორის მიერ არის მოწოდებული გარკვეული ოპერაციების
          შესასრულებლად;
        </ContentText>
        <ContentText>
          17.5. მომხმარებელი ვალდებულია დაიცვას სისტემის ოპერატორი ანგარიშით
          სარგებლობასთან დაკავშირებული ნებისმიერი ინფორმაციის უსაფრთხოება და
          კონფიდენციალურობა, რაც შეზღუდვის გარეშე მოიცავს საიდენტიფიკაციო
          მონაცემებს, სისტემის ოპერატორის მიერ მოწოდებულ ციფრული, პროგრამული ან
          სხვა სახის ციფრული თუ ელექტრონული წვდომის საშუალებებს ან აღნიშნულ
          საშუალებებთან დაკავშირებულ ავტორიზაციის მონაცემებს;
        </ContentText>
        <ContentText>
          17.6. მომხმარებელის მიერ ავტორიზაციის მონაცემების ზედიზედ სამჯერ
          არასწორად გამოყენების შემთხვევაში, მომხმარებლის საფულე დაიბლოკება და
          მისი განბლოკვა შესაძლებელი იქნება სატელეფონო ცენტრში დარეკვის და
          სხვადასხვა კითხვაზე პასუხის შემდეგ ან ავტომატურად, გარკვეული პერიოდის
          შემდეგ.
        </ContentText>
        <ContentTitle>
          18. მომხმარებლის უფლებაუნარიანობა და აკრძალული ოპერაციები
        </ContentTitle>
        <ContentText>
          18.1. სისტემის ოპერატორი არ მოემსახურება პირებს, რომლებიც შედიან
          საერთაშორისო ტერორისტთა სიაში, არ არიან სრულწლოვანები და პირებს,
          რომელთა მომსახურებასაც კრძალავს საქართველოში მოქმედი კანონმდებლობა.
        </ContentText>
        <ContentText>
          18.2. სისტემის ოპერატორს უფლება აქვს მოემსახუროს პირებს, რომლებსაც
          გააჩნიათ პირადობის დამადასტურებელი მოწმობა ან ID ბარათი, არიან
          არანაკლებ 16 წლისა მაგრამ ჯერ არ შეუსრულდათ 18 წელი. ამ შემთხვევაში,
          სისტემის ოპერატორი ასეთ პირებს მოემსახურება, თუ პირი წარადგენს მშობლის
          წერილობით თანხმობას.
        </ContentText>
        <ContentText>
          18.3. მომხმარებლებს ეკრძალებათ ისეთი გადახდის მოთხოვნის გაგზავნა ან
          მიღება, რომელიც ემსახურება შემდეგი ტიპის საქმიანობებსა თუ პროდუქციის
          შეძენა-გაყიდვას:
          <ul>
            <li>· თაღლითობა, ფულის გათეთრება ან მსგავსი უკანონო ოპერაციები;</li>
            <li>
              · ნარკოტიკული საშუალებები ან მასთან დაკავშირებული ატრიბუტები;
            </li>
            <li>
              · იარაღი, მათ შორის ცეცხლსასროლი და ცივი იარაღი ან საბრძოლო
              მასალა;
            </li>
            <li>
              · საკაბელო და სატელიტური ტელევიზიების სიგნალის ჩამხშობი
              მოწყობილობები;
            </li>
            <li>· პორნოგრაფიული მასალა;</li>
            <li>
              · არალიცენზირებულ ლატარიასა და აზარტულ თამაშობებთან დაკავშირებული
              მომსახურება;
            </li>
            <li>
              · საქონელი ან მომსახურება, რომელიც არღვევს მესამე პირთა
              ინტელექტუალური საკუთრების უფლებებს, რომელიც შეზღუდვის გარეშე
              მოიცავს ელექტრონული სახის გადმოსაწერი პროგრამებით არაკანონიერ
              სარგებლობას;
            </li>
          </ul>
        </ContentText>
        <ContentText>
          18.4. ამ მუხლით გათვალისწინებული ოპერაციების სია არ არის ამომწურავი.
          შესაბამისად, სისტემის ოპერატორს უფლება აქვს კანონმდებლობით
          გათვალისწინებული საფუძვლით შეაჩეროს სხვა ნებისმიერი ტრანზაქცია,
          რომელიც შესაძლოა რაიმე ფორმით არღვევდეს კანონმდებლობას. სისტემის
          ოპერატორი უფლებას იტოვებს, საკუთარი დისკრეციული უფლებამოსილების
          ფარგლებში გაზარდოს, შეამციროს ან დააზუსტოს აკრძალული ოპერაციების
          კატეგორები;
        </ContentText>
        <ContentTitle>19. მხარეთა პასუხისმგებლობა</ContentTitle>
        <ContentText>
          19.1. მხარეები ვალდებული არიან კეთილსინდისიერად და ჯეროვნად შეასრულონ
          მათ მიერ წინამდებარე ხელშეკრულებითა და კანონმდებლობით გათვალისწინებული
          ვალდებულებები;
        </ContentText>
        <ContentText>
          19.2. სისტემის ოპერატორს მომხმარებელის წინაშე არ დაეკისრება
          პასუხისმგებლობა, თუ:
        </ContentText>
        <ContentText>
          19.2.1. სისტემა სათანადოდ არ მუშაობდა და მომხმარებლმა ოპერაციის
          დაწყებამდე იცოდა ან უნდა სცოდნოდა აღნიშნული სისტემური გაუმართაობის
          შესახებ;
        </ContentText>
        <ContentText>
          19.2.2. სისტემის ოპერატორის კონტროლს მიღმა არსებული პირები ან ვითარება
          ხელს უშლის ოპერაციის განხორციელებას, სისტემის ოპერატორის მიერ მიღებული
          ზომების მიუხედავად;
        </ContentText>
        <ContentText>
          19.2.3. სისტემის ოპერატორის არ მიუღია სრულყოფილი დავალება კონკრეტული
          ოპერაციის თაობაზე;
        </ContentText>
        <ContentText>
          19.2.4. ოპერაციის განხორციელებისათვის საჭირო სისტემის ოპერატორი
          ანგარიშ(ებ)ზე არსებული თანხები ან მათი ნაწილი დაბლოკილია;ოპერაცია
          გადააჭარბებს სისტემის ოპერატორი ანგარიშ(ებ)ის ან სისტემის ოპერატორის
          მიერ დაწესებულ ლიმიტს;
        </ContentText>
        <ContentText>
          19.2.5. თანხები ექვემდებარება სამართლებრივ შეზღუდვას;
        </ContentText>
        <ContentText>
          19.2.6. მომხმარებელი არღვევს წინამდებარე პირობებით გათვალისწინებულ
          პირობებს;
        </ContentText>
        <ContentText>
          19.2.7. სისტემის ოპერატორმა არ განახორციელა ოპერაცია, იმ შემთხვევაში,
          თუ აქვს ეჭვი თაღლითობის ან უკანონო ქმედების შესახებ;
        </ContentText>
        <ContentText>
          19.3. სისტემის ოპერატორს არ შეიძლება დაეკისროს რაიმე სახის
          პასუხისმგებლობა სისტემის მონაწილეების (მერჩანტების) მიერ მომსახურების
          შეუსრულებლობის, არაჯეროვანი ან დაგვიანებული შესრულებისთვის. აგრეთვე,
          სისტემის ოპერატორი პასუხს არ აგებს მომსახურების გამწევი პირებისგან
          (მერჩანტებისგან), მომსახურების ნაკლის პირობებში, ანაზღაურების უკან
          დაბრუნების უზრუნველყოფასა თუ სხვა ნებისმიერ საკითხზე, რაც
          გამომდინარეობს უშუალოდ მომხმარებელსა და მომსახურების გამწევ პირებს
          (მერჩანტებს) შორის ურთიერთობიდან. ასეთი ურთიერთობები წესრიგდება
          მომხმარებლებსა და მერჩანტებს შორის არსებული ხელშეკრულებებისა და
          მერჩანტების მიერ დადგენილი წესებისა და პირობების შესაბამისად.
        </ContentText>
        <ContentText>
          19.4. მხარეები თანხმდებიან, რომ წინამდებარე ხელშეკრულებით
          გათვალისწინებული მომსახურების არაჯეროვანი შესრულების შემთხვევაში,
          სისტემის ოპერატორს, გარდა პირდაპირი ზიანისა არ შეიძლება დაეკისროს სხვა
          ნებისმიერი სახის ზიანის, მათ შორის მიუღებელი შემოსავლის ანაზღაურების
          მოვალეობა;
        </ContentText>
        <ContentText>
          19.5. მომხმარებლის მიერ წინამდებარე წინამდებარე წესებისა და პირობების
          დარღვევის შედეგად სისტემის ოპერატორისთვის ზიანის მიყენების
          შემთხვევაში, სისტემის ოპერატორი უფლებამოსილია მომხმარებლის ელექტრონული
          საფულიდან უაქცეპტო წესით ჩამოაჭრას თანხა ხოლო ელექტრონულ საფულეზე
          არასაკმარისი თანხის არსებობის შემთხვევაში, მიმართოს სასამართლოს.
        </ContentText>
        <ContentTitle>20. განცხადებები და გარანტიები</ContentTitle>
        <ContentText>20.1. თანხმობით მომხმარებელი ადასტურებს, რომ:</ContentText>
        <ContentText>
          20.1.1. არის სრულწლოვანი ან მასთან გათანაბრებული პირი;
        </ContentText>
        <ContentText>
          20.1.2. გააჩნია სრული (შეუზღუდავი) ქმედუნარიანობა და მოქმედებს ნების
          ავტონომიის ფარგლებში;
        </ContentText>
        <ContentText>
          20.1.3. კლიენტს აქვს სრული უფლებამოსილება იმისათვის, რომ ხელი მოაწეროს
          და შეასრულოს ამ პირობებით ან ნებისმიერი სხვა ხელშეკრულებებითა და
          განაცხადებით გათვალისწინებული ვალდებულებები;
        </ContentText>
        <ContentText>
          20.1.4. პირობებში გათვალისწინებული ოპერაციის შესრულებით არ დაირღვევა
          სხვა ხელშეკრულებების პირობები ან მომხმარებელის სხვა ვალდებულებები
          (ამგვარის არსებობის შემთხვევაში) ან რაიმე სახის კანონი, დებულება,
          წესი, განკარგულება, სასამართლო გადაწყვეტილება, ბრძანება, მითითება,
          სასამართლო დადგენილება ან სახელმწიფოს, სახელმწიფო ან მარეგულირებელი,
          სასამართლო ან საარბიტრაჟო ორგანოს მიერ დაწესებული რაიმე შეზღუდვა,
          რომლის დაცვაზეც მომხმარებელი პასუხისმგებელია. ამ პირობებით
          განსაზღვრული ოპერაციის შესრულება არ მოვა წინააღმდეგობაში ან არ
          დაარღვევს რაიმე სახის ხელშეკრულებას, კონტრაქტს, ლიცენზიას ან სხვა
          სახის შეთანხმებას, რომლის მხარესაც მომხმარებელი წარმოადგენს;
        </ContentText>
        <ContentText>
          20.1.5. გაეცნო პირობებს და ეს პირობები არის მისთვის მისაღები;
        </ContentText>
        <ContentText>
          20.1.6. მის მიერ წარმოდგენილი ყველა მონაცემი არის ზუსტი;
        </ContentText>
        <ContentText>
          20.1.7. მომხმარებელი არ არის ჩაბმული ან არ მონაწილეობს არცერთი
          იურისდიქციის კანონმდებლობით (რაც მოიცავს საქართველოს კანონმდებლობას და
          იმ ქვეყნის კანონმდებლობას, რომლის მოქალაქეც არის მომხმარებელი)
          გათვალისწინებულ რაიმე სახის უკანონო საქმიანობაში (მათ შორის, ფულის
          გათეთრებას, იარაღით ვაჭრობას, ტერორიზმს ან სხვა სახის უკანონო
          საქმიანობას);
        </ContentText>
        <ContentText>
          20.1.8. სისტემის ოპერატორი ელექტრონული ანგარიშის გახსნისას, ოფერტისას
          და ხელშეკრულების მოქმედების მთელი პერიოდისათვის მისი საქმიანობა ან/და
          ქმედება შესაბამისობაში არის/იქნება ადგილობრივ ან/და საერთაშორისო
          კანონმდებლობასთან;
        </ContentText>
        <ContentText>
          20.1.9. ხელშეკრულების მოქმედების მთელი პერიოდისათვის იგი დაიცავს
          ნებისმიერი ხელშეკრულების ან/და სხვა გარიგების პირობებს, ან/და სხვა
          ვალდებულებებს, რომელთა შეუსრულებლობამაც შესაძლოა უარყოფითი ზეგავლენა
          იქონიოს მომხმარებელის მიერ პირობებით ნაკისრი ვალდებულებების
          შესრულებაზე;
        </ContentText>
        <ContentText>
          20.1.10. მისი ქმედება არ არის/იქნება მიმართული სისტემის ოპერატორის
          ან/და ნებისმიერი მესამე პირის/მხარის მოტყუებისაკენ. აღნიშნული
          პრინციპის გათვალისწინებით ხელშეკრულების დასადებად ან მის საფუძველზე და
          შესასრულებლად, მის მიერ სისტემის ოპერატორისთვის წარდგენილი დოკუმენტი
          ან/და ინფორმაცია მისი წარმოდგენის მომენტისათვის ასევე არის/იქნება
          უტყუარი, ზუსტი და სრული;
        </ContentText>
        <ContentText>
          20.1.11. თუ სისტემის ოპერატორი სხვაგვარ თანხმობას არ გასცემს, მ
          მხმარებელი ვალდებულია არ იკისროს იმგვარი ვალდებულებები, რითაც საფრთხე
          შეექმნება მის მის მიერ წინამდებარე პირობების შესრულებას;
        </ContentText>
        <ContentTitle>21. პერსონალური მონაცემების დაცვა</ContentTitle>
        <ContentText>
          21.1. პერსონალურ მონაცემებს განეკუთვნება წინამდებარე დოკუმენტის 4.3.4.
          პუნქტით გათვალისწინებული ინფორმაცია;
        </ContentText>
        <ContentText>
          21.2. სისტემის ოპერატორი ვალდებულია დაიცვას მომხმარბელთა პერსონალური
          მონაცემები და დაამუშაოს ისინი მხოლოდ წინამდებარე დოკუმენტით
          გათვალისწინებული, მათ შორის მომსახურების გაწევის მიზნებიდან
          გამომდინარე;
        </ContentText>
        <ContentText>
          21.3. სისტემის ოპერატორი ვალდებულია შეზღუდოს თანამშრომელთა წვდომა
          მომხმარებლების პერსონალურ მონაცემზე და ამგვარი ინფორმაცია
          ხელმმისაწვდომი გახადოს მხოლოდ იმ თანამშრომლებისთვის, ვის უშუალო
          ფუნქცია-მოვალეობებსაც განეკუთვნება, მომსახურების გაწევის თუ ამ
          ხელშეკრულებით გათვალისწინებული მიზნებიდან გამომდინარე შესაბამისი
          მონაცემების დამუშავება;
        </ContentText>
        <ContentText>
          21.4. წინამდებარე წესებსა და პირობებზე დათანხმებით, მომხმარებელი
          გამოთქვამს თანხმობას, რომ ხელშეკრულების მთელი პერიოდის განმავლობაში
          ანიჭებს სისტემის ოპერატორს უფლებამოსილებას, რომ მოიძიოს/გადაამოწმოს და
          გადასცეს მომხმარებლის მიერ მითითებული ან მასთან დაკავშირებული
          ნებისმიერი, მათ შორის პერსონალური ინფორმაცია, სისტემის ოპერატორის
          პარტნიორ კომპანიებს (მათ შორის მარკეტინგული დამუშავების მიზნით), სს
          „კრედიტინფო საქართველოს“, საჯარო/სამოქალაქო რეესტრის ეროვნულ
          სააგენტოს, მომსახურების სააგენტოს, პოლიციას, იუსტიციისა და შინაგან
          საქმეთა სამინისტროებს დაქვემდებარებულ ყველა უწყებას;
        </ContentText>
        <ContentTitle>22. ზოგადი დებულებები</ContentTitle>
        <ContentText>
          22.1. მხარეები თანხმდებიან, რომ ელექტრონულ საფულის შეტყობინების
          მენიუში მიღებული ან გაგზავნილი შეტყობინება ითვლება ოფიციალურ
          კორესპონდენციად;
        </ContentText>
        <ContentText>
          22.2. შეთანხმების რომელიმე მუხლის ან მისი ნაწილის ბათილობა არ
          გამოიწვევს მთლიანი შეთანხმების ბათილობას;
        </ContentText>
        <ContentText>
          22.3. მომხმარებელი აცნობიერებს და თანახმაა, რომ სისტემის ოპერატორი და
          სისტემის ოპერატორის ლოგო წარმოადგენს რეგისტრირებულ სავაჭრო ნიშნებს.
          სისტემის ოპერატორს აქვს ლიცენზია და ფლობს ყველა საავტორო უფლებას
          სისტემის ოპერატორის ვებ-გვერდზე და სხვა არხებზე რომლის მეშვეობითაც
          მომხმარებელი სარგებლობს მომსახურებებით;
        </ContentText>
        <ContentText>
          22.4. შეკითხვების ან პრეტენზიების შემთხვევაში, მომხმარებელს შეუძლია
          დაუკავშირდეს სისტემის ოპერატორს ტელეფონით (სამუშაო საათებში) ნომერზე
          +995 (32) 2833383 ან ელ-ფოსტაზე info@inexpay.ge
        </ContentText>
        <ContentText>
          22.5. „საგადახდო სისტემისა და საგადახდო მომსახურების შესახებ“
          საქართველოს კანონის შესაბამისად, საგადახდო მომსახურების მომხმარებელს
          უფლება აქვს, სისტემის ოპერატორისთვის საჩივრით მიმართვის დღიდან
          არაუგვიანეს 6 თვის განმავლობაში, სისტემის ოპერატორის წინააღმდეგ
          საჩივრით მიმართოს საქართველოს ეროვნულ ბანკთან არსებულ დავების
          განმხილველ კომისიას (შემდგომ − კომისია) მხოლოდ სისტემის ოპერატორისთვის
          წარდგენილი საჩივრის დადგენილ ვადაში დაუკმაყოფილებლობის (მათ შორის,
          პასუხის გაუცემლობის) ან ნაწილობრივ დაკმაყოფილების შემთხვევაში.
          მომხმარებელი უფლებამოსილია კომისიას მიმართოს, თუ მისი მოთხოვნა არ
          აღემატება 50 000 ლარს ან უცხოურ ვალუტაში 50 000 ლარის ეკვივალენტს
          (სადავო ქმედების განხორციელების თარიღით ან წარდგენის დღის თარიღით).
          კომისია დავას განიხილავს და გადაწყვეტილებას იღებს საჩივრის წარმოებაში
          მიღებიდან 90 კალენდარული დღის ვადაში (შესაძლოა გახანგრძლივდეს
          არაუმეტეს 30 კალენდარული დღით). კომისია დავას განიხილავს უსასყიდლოდ.
          მომხმარებელს უფლება აქვს, მიმართოს სასამართლოს კომისიისთვის მიმართვის
          გარეშეც.
        </ContentText>
        <ContentText>
          22.6. თუ რაიმე პირობა არ არის გათვალისწინებული მოცემული დოკუმენტით,
          მომხმარებელი უფლებამოსილია მიმართოს სისტემის ოპერატორს წერილობით და
          მიიღოს პასუხი მოთხოვნაზე.
        </ContentText>
        {/* PRIVACY */}
        <ContentTitle>
          შპს „ინექს ფეის“ კონფიდენციალურობის პოლიტიკა
        </ContentTitle>
        <ContentText>
          შპს ინექს ფეი მოქმედი ბრენდით InexPay აფასებს www.inexpay.ge
          ვებგვერდზე (შემდგომში - ვებგვერდი) თქვენს ვიზიტს და იმ ინტერესს,
          რომელსაც ჩვენი სერვისებისა და პროდუქტების მიმართ იჩენთ. თქვენი
          კონფიდენციალური ინფორმაციის დაცვა ჩვენთვის მნიშვნელოვანია და ჩვენი
          ვებგვერდის სტუმრობის განმავლობაში, გვსურს თავს კომფორტულად გრძნობდეთ.
        </ContentText>
        <ContentText>
          ჩვენ ვუფრთხილდებით და ვიცავთ თქვენს პერსონალურ მონაცემებს, რომელიც
          შეგროვდა, მუშავდება და გამოიყენება ვებგვერდზე თქვენი სტუმრობის
          განმავლობაში და შემდგომში. ვებგვერდით სარგებლობისას პერსონალური
          მონაცემების დამუშავების პირობები განსაზღვრულია წინამდებარე
          კონფიდენციალურობის პოლიტიკით.
        </ContentText>
        <ContentText>
          ვებგვერდზე შემოსვლით თქვენ ეთანხმებით წინამდებარე კონფიდენციალურობის
          პოლიტიკას (შემდგომში - პოლიტიკა)
        </ContentText>
        <ContentText>
          თუ თქვენ არ ეთანხმებით წინამდებარე პოლიტიკას, არ განაგრძოთ ვებგვერდის
          მომდევნო გვერდებით სარგებლობა.
        </ContentText>
        <ContentText>
          წინამდებარე პოლიტიკა, შესაძლოა, დროდადრო განახლდეს. აქედან
          გამომდინარე, გთხოვთ, რომ მას გაეცნოთ რეგულარულად.
        </ContentText>
        <ContentTitle>
          რა მიზანს ემსახურება წინამდებარე პოლიტიკა და რა არის მისი მოქმედების
          სფერო?
        </ContentTitle>
        <ContentText>
          შპს ინექს ფეი, მისი შვილობილი კომპანიები ან/და აფილირებული პირები (შპს
          ინექს ფეი ან „ჩვენ“) ცდილობენ დაიცვან იმ პირების პერსონალური
          მონაცემები, რომლებიც სარგებლობენ ვებგვერდით. შესაბამისად, შპს ინექს
          ფეი-მ შეიმუშავა წინამდებარე პოლიტიკა იმის აღსაწერად, თუ როგორ და რა
          სახის ინფორმაცია შეგროვდება ვებგვერდის მომხმარებლებისგან და იმ
          მიზნების განსასაზღვრად, რომლის მისაღწევადაც შპს ინექს ფეი-მ შესაძლოა
          შეაგროვოს, გადასცეს ან სხვაგვარად ხელმისაწვდომი გახადოს აღნიშნული
          მონაცემები. შპს ინექს ფეი ასევე განსაზღვრავს ყველა შესაძლო გონივრულ
          ზომას, რომელიც თქვენი მონაცემების უსაფრთხოების დასაცვად არის
          აუცილებელი.
        </ContentText>
        <ContentText>
          წინამდებარე პოლიტიკა ვრცელდება ყველა სახის ინფორმაციაზე, რომელსაც
          თქვენ მიერ ვებგვერდის გამოყენების შედეგად მოიპოვებს შპს ინექს ფეი.
          პოლიტიკა არ ვრცელდება მესამე პირების იმ ვებგვერდებზე, რომლებიც არ
          არიან შპს ინექს ფეისთან აფილირებული პირები, მაგრამ შესაძლოა ასეთ
          ვებგვერდებთან კავშირი შპს ინექს ფეის ვებგვერდის საშუალებით იყოს
          შესაძლებელი (შემდგომში - მესამე პირების ვებგვერდები). გთხოვთ, გაეცნოთ
          ასეთი მესამე პირების ვებგვერდების კონფიდენციალურობის პოლიტიკას, თუკი
          თქვენ მათ ვებ გვერდებს იყენებთ, იმდენად, რამდენადაც შპს ინექს ფეი არ
          არის პასუხისმგებელი და არ აქვს გავლენა არც მესამე პირების ვებგვერდების
          შემცველ მასალებზე და არც მათი კონფიდენციალურობის დაცვის პრაქტიკაზე.
        </ContentText>
        <ContentText>
          წინამდებარე პოლიტიკის პირობები ექვემდებარება, შპს ინექს ფეისთან თქვენი
          სახელშეკრულებო ურთიერთობის ფარგლებში გაფორმებულ ნებისმიერ დამატებით
          განაცხადს ან სახელშეკრულებო პირობას (ასეთის არსებობის შემთხვევაში) და
          სხვა და ნებისმიერ სავალდებულო ძალის მქონე მოქმედ კანონს ან რეგულაციას.
        </ContentText>
        <ContentTitle>
          რა ინფორმაციას ვაგროვებთ თქვენგან და რა მიზნით?
        </ContentTitle>
        <ContentText>
          როდესაც თქვენ სტუმრობთ შპს ინექს ფეის ვებგვერდს, ჩვენი სერვერი
          ავტომატურად იწერს დეტალებს თქვენი ვიზიტის შესახებ (მაგალითად,
          შეზღუდვის გარეშე, თქვენი IP მისამართი, ვებგვერდი, რომლიდანაც ჩვენს
          ვებგვერდს ესტუმრეთ, ვიზიტის დროს გამოყენებული ინტერნეტ-ბრაუზერის ტიპი,
          მოწყობილობის ტიპი რომელშიც აღნიშნული ბაუზერია განთავსებული, შპს ინექს
          ფეის ვებგვერდის ის კონკრეტული გვერდები, რომლებსაც ფაქტობრივად ესტუმრეთ
          და ვებგვერდზე თქვენი სტუმრობის თარიღი და ხანგრძლივობა და სხვა).
        </ContentText>
        <ContentText>
          დამატებით, ჩვენი ვაგროვებთ პერსონალურ მონაცემებს, რომელსაც თქვენ
          გვაწვდით შპს ინექს ფეის ვებგვერდის მეშვეობით, მაგალითად როდესაც
          შეგყავთ პერსონალური მონაცემები (სახელი, გვარი, სქესი, მისამართი,
          ელ-ფოსტის მისამართი, ტელეფონის/ფაქსის ნომერი და სხვა) ვებგვერდის
          სარეგისტრაციო გვერდზე ან მაშინ, როდესაც ჩვენს ვებგვერდზე სიახლეების
          მისაღებად რეგისტრირდებით, ან რომელსაც გვაწვდიან ისეთი მესამე პირები,
          რომელთა კლიენტი/აბონენტიც თქვენ ბრძანდებით, მაგალითად როდესაც იხდით
          ასეთი მესამე პირებისადმი დავალიანებას ან/და ავსებთ თქვენი სააბონენტო
          ანგარიშის ბალანსს. შპს ინექს ფეი გამოიყენებს თქვენს პერსონალურ
          მონაცემებს: - ვებგვერდის ტექნიკური ადმინისტრირების და მისი კვლევისა და
          განვითარების მიზნებისათვის; - კლიენტისა და მომხმარებლის
          ადმინისტრირებისა და მარკეტინგული მიზნებისათვის; - შპს ინექს ფეის სხვა
          ელექტრონულ არხებთან მიმართებით, ზემოხსენებული მიზნებისთვის; - ჩვენი
          სერვისებისა და პროდუქტების შესახებ თქვენთვის ინფორმაციის მოსაწოდებლად;
          - წინამდებარე პოლიტიკით განსაზღვრულ სხვა შემთხვევებისთვის.
        </ContentText>
        <ContentText>
          შპს ინექს ფეი იცავს ყველა მოქმედ კანონს და რეგულაციას, რომელიც ჩვენ
          მიერ პერსონალური მონაცემების დამუშავების საკითხს ეხება.
        </ContentText>
        <ContentTitle>
          როგორ ვაგროვებთ და ვინახავთ ინფორმაციას თქვენ შესახებ?
        </ContentTitle>
        <ContentText>
          შპს ინექს ფეი იყენებს ისეთ ტექნოლოგიას, როგორიცაა მზა ჩანაწერები
          (cookies) ან თეგი (შემდგომში - თრექინგის ტექნოლოგია), იმისთვის, რომ
          შევაგროვოთ ზემოაღნიშნული ინფორმაცია (იხილეთ: რა ინფორმაციას ვაგროვებთ
          თქვენგან და რა მიზნით?) და გავიგოთ, თუ როგორ იყენებენ ვიზიტორები ჩვენს
          ვებგვერდს.
        </ContentText>
        <ContentText>
          მზა ჩანაწერების ან თეგების ტექნოლოგია გვეხმარება ვმართოთ და
          გავაუმჯობესოთ ვებგვერდის ეფექტურობა, მაგალითად იმის მიხედვით, ყოფილა
          თუ არა თქვენს კომპიუტერსა და ჩვენს ვებგვერდს შორის წარსულში რამე
          კავშირი, მოვახდინოთ ვებგვერდზე ყველაზე პოპულარული სექციების
          იდენტიფიცირება და სხვა.
        </ContentText>
        <ContentText>
          გთხოვთ დარწმუნდეთ, რომ თქვენი მზა ჩანაწერების წესები გაწერილია
          იმგვარად, რომ მონიშნული არ გაქვთ მზა ჩანაწერის ის ტიპი, რომლის
          გამოყენებაზე შპს ინექს ფეის ნებას არ რთავთ.
        </ContentText>
        <ContentText>
          როდესაც ინახავთ თქვენი მზა ჩანაწერების წესებს, ისინი ასევე ვრცელდება
          შპს ინექს ფეის ვებგვერდზე თქვენს სამომავლო ვიზიტებზეც. თუმცა, შპს
          ინექს ფეის მიღმა არსებული ტექნიკური მიზეზებით, ეს გარანტირებული არ
          არის. მაგალითად, თუ თქვენს მიერ მოხდა ინტერნეტ-ბრაუზერის
          აღდგენა/ხელახალი ინსტალაცია, მზა ჩანაწერებისგან გაწმენდა ან შპს ინექს
          ფეის ვებგვერდზე ვიზიტი სხვა ინტერნეტბრაუზერის ან მოწყობილობის
          გამოყენებით, თქვენი მზა ჩანაწერების წესები, შესაძლოა, დაიკარგოს.
        </ContentText>
        <ContentText>
          ხშირ შემთხვევაში, თქვენ ასევე შეგიძლიათ თქვენი ინტერნეტ-ბრაუზერით
          თრექინგის ტექნოლოგიების კონტროლი. გთხოვთ, დარწმუნდეთ რომ თქვენი
          ინტერნეტ-ბრაუზერი ასახავს თქვენ სურვილს გსურთ თუ არა, სადაც ეს
          შესაძლებელია, გაგაფრთხილოთ თრექინგ ტექნოლოგიების და ან მათი მიღების
          შესახებ. თქვენი ინტერნეტ-ბრაუზერის სპეციალურ შესაძლებლობებს და
          ინსტრუქციებს მათი მოხმარების შესახებ, როგორც წესი, შეგიძლიათ გაეცნოთ
          თქვენი ინტერნეტ-ბრაუზერის სახელმძღვანელოში ან სექციაში „დახმარება“.
        </ContentText>
        <ContentText>
          თრექინგ ტექნოლოგიების გამოყენებაზე უარის თქმამ ან მათმა დეაქტივაციამ
          შესაძლოა უარყოფითი გავლენა იქონიოს შპს ინექს ფეის ვებგვერდის ან მისი
          სხვადასხვა ნაწილების მიერ შემოთავაზებული სერვისების ხელმისაწვდომობაზე
          და მათ გამართულ ფუნქციონირებაზე.
        </ContentText>
        <ContentTitle>
          ვის გადავცემთ ვებგვერდიდან თქვენზე შეგროვებულ ინფორმაციას?
        </ContentTitle>
        <ContentText>
          შპს ინექს ფეიმ შესაძლოა თქვენი ინფორმაცია გადასცეს მასთან აფილირებულ
          პირებს, მის ან მისი აფილირებული პირების აგენტებს ან შპს ინექს ფეისთვის
          სერვისის მომწოდებელ მესამე პირებს თქვენი ქვეყნის გარეთ ან შიგნით,
          ზემოაღნიშნული მიზნების მისაღწევად. ჩვენთან აფილირებული პირები,
          აგენტები და სერვისის მომწოდებელი მესამე პირები, რომლებსაც შპს ინექს
          ფეის ვებგვერდის მეშვეობით მოპოვებულ თქვენს პერსონალურ მონაცემებზე
          წვდომა აქვთ, ასევე გააჩნიათ ამ მონაცემების კონფიდენციალურობის დაცვის
          ვალდებულება.
        </ContentText>
        <ContentText>
          როდესაც ვახდენთ პერსონალური მონაცემების საერთაშორისო ტრანსფერს, ჩვენ
          ვრწმუნდებით ვართ თუ არა მოქმედ კანონმდებლობასთან და რეგულაციებთან
          შესაბამისობაში, მაგალითად, ვაფორმებთ შესაბამის ხელშეკრულებებს იმ
          პირებთან, რომლებიც თქვენს პერსონალურ მონაცემებს ჩვენგან იღებენ, რათა
          მათ უზრუნველყონ ამ მონაცემების ადეკვატური დაცვა.
        </ContentText>
        <ContentText>
          ასევე, ჩვენ მიერ თქვენი ინფორმაცია შესაძლოა გადაეცეს სახელმწიფო
          ორგანოებს ან ერთეულებს,
          <ContentText>
            მარეგულირებელ ორგანოებს ან ნებისმიერ სხვა პირს, მოქმედი
            კანონმდებლობის, რეგულაციების, სასამართლო განჩინების ან ოფიციალური
            მოთხოვნის, სახელმწიფო მარეგულირებელი ორგანოს გამოცემული ინსქტრუქციის
            საფუძველზე და მიზნების შესაბამისად ან მოქმედი კანონმდებლობის
            საფუძველზე წარმართული ნებისმიერი მსგავსი პროცესის საფუძველზე.
          </ContentText>
          უსაფრთხოების რა ზომები დავნერგეთ ვებგვერდის მეშვეობით მოპოვებული
          თქვენი ინფორმაციის დასაცავად?
        </ContentText>
        <ContentText>
          შპს ინექს ფეი-მ მოახდინა მისთვის ხელმისაწვდომი ყველა გონივრული
          ტექნიკური და ორგანიზაციული უსაფრთხოების ზომის იმპლემენტაცია,
          იმისათვის, რომ შპს ინექს ფეის მიერ ვებგვერდის მეშვეობით მოპოვებული
          თქვენი პერსონალური მონაცემები დაცული იყოს უნებართვო წვდომისგან,
          გამოყენებისგან, დაკარგვისგან ან განადგურებისგან.
        </ContentText>
        <ContentText>
          როგორ ვექცევით შპს ინექს ფეის მიერ და შპს ინექს ფეისთვის გაგზავნილ
          ელექტონულ შეტყობინებებს?
        </ContentText>
        <ContentText>
          შპს ინექს ფეის მიერ და შპს ინექს ფეისთვის გაგზავნილ ყველა ელექტრონულ
          შეტყობინებას (SMS, მაილი და სხვა) ჩვენ ავტომატურად ვინახავთ სპეციალური
          ელექტრონული ჟურნალის სისტემაში, რომელსაც გააჩნია სამართლებრივი
          მტკიცებულების ძალა. მათ ჩვენ ვიცავთ გონივრული ტექნიკური და
          ორგანიზაციული ზომების გამოყენებით და მათთან წვდომა დასაშვებია მხოლოდ
          განსაზღვრულ შემთხვევებში, მოქმედი კანონმდებლობის და რეგულაციების
          ზედმოწევნითი დაცვით (მაგალითად სასამართლოს განჩინება, დანაშაულის
          ჩადენის ეჭვი, რეგულატორული ვალდებულებების დარღვევა, შრომითი
          ხელშეკრულების არსებითი დარღვევა) სპეციალური ფუნქციებით აღჭურვილი
          კონკრეტული პიროვნებების მიერ (მაგალითად სამართლებრივი შესაბამისობის ან
          რისკების სამსახური). პროცესის ყოველი ნაბიჯი, მათ შორის გამოყენებული
          ძებნის კრიტერიუმები, შეიყვანება სპეციალურ აუდიტორულ ველში. ყველა
          შეტყობინება ნადგურდება მას შემდეგ, რაც ამოიწურება მათი შენახვისთვის
          განსაზღვრული გონივრული პერიოდი.
        </ContentText>
        <ContentTitle>
          ვის გადავცემთ ვებგვერდიდან თქვენზე შეგროვებულ ინფორმაციას?
        </ContentTitle>
        <ContentText>
          მიიჩნევა, რომ ინტერნეტი არ წარმოადგენს დაცულ გარემოს, და ინფორმაციაზე,
          რომელსაც ინტერნეტის მეშვეობით იგზავნება (მაგალითად ვებგვერდის ან
          ელექტრონული შეტყობინების სისტემის გამოყენებით) შესაძლოა
          არასანქცირებული წვდომა მოიპოვონ მესამე პირებმა, რამაც, შესაძლებელია,
          გამოიწვიოს ინფორმაციის გამჟღავნება, ინფორმაციის შემცველი მასალის
          ცვლილება ან სხვა ტექნიკური შეცდომა. იმის მიუხედავად, იმყოფებიან თუ არა
          ინფორმაციის გამგზავნიც და მისი მიმღებიც ერთი და იმავე ქვეყანაში,
          ინტერნეტის მეშვეობით გაგზავნილი ინფორმაცია შესაძლოა გადაიცეს
          საერთაშორისო საზღვრების მიღმა და შესაძლებელია აღმოჩნდეს ისეთ
          ქვეყანაში, სადაც თქვენი ქვეყნისგან განსხვავებით, პერსონალური
          მონაცემების დაცვისათვის სუსტი მექანიზმები არსებობს.
        </ContentText>
        <ContentText>
          გთხოვთ, გაითვალისწინოთ, რომ ჩვენ არ ვაგებთ პასუხს თქვენი ინფორმაციის
          უსაფრთხოებაზე იმ ეტაპზე, როდესაც ეს ინფორმაცია ინტერნეტის მეშვეობით
          შპს ინექს ფეისთვის გადაცემის მდგომარეობაში იმყოფება. იმისათვის, რომ
          დაიცვათ თქვენი მონაცემების კონფიდენციალურობა, გვსურს შეგახსენოთ, რომ
          თქვენ შეგიძლიათ შპს ინექს ფეისთან კომუნიკაციისათვის აირჩიოთ ის მეთოდი,
          რომელსაც შესაფერისად მიიჩნევთ.
        </ContentText>
        <ContentTitle>
          როგორ ვეპყრობით 18 წელს მიუღწეველი პირების შესახებ ინფორმაციას?
        </ContentTitle>
        <ContentText>
          შპს ინექს ფეი არ ემსახურება პირებს, რომელთაც 18 წლის ასაკისთვის არ
          მიუღწევიათ და შესაბამისად, ვებგერდი არ აგროვებს პერსონალურ მონაცემებს
          აღნიშნული პირებისგან. შპს ინექს ფეისთვის ვებგვერდის მეშვეობით
          პერსონალური მონაცემების გადაცემამდე 18 წელს მიუღწეველმა პირებმა უნდა
          მოიპოვონ ნებართვა მშობლებისგან ან კანონიერი წარმომადგენლებისგან.{" "}
        </ContentText>
        <ContentTitle>
          როგორ შეგიძლიათ თქვენ პერსონალური მონაცემებზე წვდომა ან მათი გაცნობა?
        </ContentTitle>
        <ContentText>
          მოქმედი კანონმდებლობით ან რეგულაციებით გათვალისწინებული წესით, თქვენ
          შეგიძლიათ: - გადაამოწმოთ ვინახავთ თუ არა თქვენს პერსონალურ მონაცემებს;
          - გვთხოვოთ ჩვენთან დაცული თქვენი პერსონალური მონაცემების ასლის
          გადმოცემა; ან - მოითხოვოთ ჩვენთან დაცული თქვენი პერსონალურ მონაცემებში
          უსწორობის აღმოფხვრა.
        </ContentText>
        <ContentText>
          თქვენი პერსონალური მონაცემების დამუშავებასთნ დაკავშირებული მოთხოვნების
          არსებობის შემთხვევაში, გთხოვთ გამოგვიგზავნოთ წერილი შემდეგ მისამართზე:
          info@inexpay.ge
        </ContentText>
        <Title size="0.9rem" mt="1rem">
          ბოლო ცვლილების თარიღი 31/12/2022
        </Title>
        {/* Delivery */}
        <ContentTitle>
          შპს „ინექს ფეის“ ელექტრონულ საფულეში მომსახურების მიწოდების პირობები
        </ContentTitle>
        <ContentTitle>რეგისტრაცია ელექტრონულ საფულეში</ContentTitle>
        <ContentText>
          იმისათვის, რომ მომხმარებელი დარეგისტრირდეს სისტემაში საჭიროა:
        </ContentText>
        <ContentText>
          მომხმარებელმა რეგისტრაციის ფორმაში შეიყვანოს სრული და სწორი
          ინფორმაცია;
        </ContentText>
        <ContentText>
          დაეთანხმოს სისტემის წესებს და პირობებს, შესაბამისი ველის მონიშვნის
          გზით და დააჭიროს ღილაკს რეგისტრაცია;
        </ContentText>
        <ContentText>
          აღნიშნული პროცედურის გავლის შემდეგ რეგისტრაცია საფულეში ითვლება
          დასრულებულად და მომხმარებლის ელ. საფულეს ავტომატურად ენიჭება უნიკალური
          ნომერი.
        </ContentText>
        <ContentText>
          სისტემის ოპერატორი უფლებამოსილია ელექტრონული ფოსტის სისწორის
          გადამოწმების მიზნით მომხმარებელს გაუგზავნოს ერთჯერადი კოდი ან ბმული,
          რომლის საშუალებითაც მომხმარებელი ადასტურებს ელ-ფოსტის ნამდვილობას;
        </ContentText>
        <ContentText>
          ერთი მობილური ტელეფონის ნომრით პირს უფლება აქვს, დაარეგისტრიროს მხოლოდ
          ერთი ელექტრონული საფულე.
        </ContentText>
        <ContentTitle>
          იდენტიფიცირებულ მომხმარებელს შეუძლია ყველა სახის მომსახურების გადახდა,
          კერძოდ:
        </ContentTitle>
        <ContentText>
          <ul>
            <li>· კომუნალური მომსახურება</li>
            <li>· სხვადასხვა გადასახადების გადახდა</li>
            <li>· თანხის საფულიდან გატანა</li>
            <li>· ფულადი გზავნილების გაგზავნა და მიღება</li>
            <li>· თანხის ჩარიცხვა/გადარიცხვა</li>
            <li>
              · სხვა, რაც პერიოდულად შეიძლება დამატებული იყოს საფულის მენიუში.
            </li>
          </ul>
        </ContentText>
        <ContentText>
          სისტემაში შეიძლება იცვლებოდეს იდენტიფიკაციის მოთხოვნები და ასევე
          ლიმიტები. მომხმარებელს შეუძლია ონლაინ რეჟიმში იხილოს განახლებული
          პირობები და ლიმიტები საფულის მენიუში, საკუთარი პროფილის ქვეშ,
          „ლიმიტები და იდენტიფიკაცია“ მენიუში.
        </ContentText>
        <ContentTitle>
          მომხმარებლის მიერ განხორციელებადი გადახდის ოპერაციები
        </ContentTitle>
        <ContentText>
          მომხმარებელს შეუძლია განახორციელოს საგადახდო დავალების ინიცირება,
          სისტემის ოპერატორის მიერ გამოშვებული ელექტრონული ფულით, საფულეში
          რეგისტრაციის შემდეგ, საგადახდო ინსტრუმენტის გამოყენებით, მერჩანტის
          მიერ გაწეული ან სისტემაში არსებული სხვა მომსახურების სანაცვლოდ.
        </ContentText>
        <ContentText>
          მომხმარებელს ელექტრონული ფულით შეუძლია განახორციელოს შემდეგი
          ოპერაციები:
        </ContentText>
        <ContentText>
          ა) მერჩანტებისთვის მომსახურების/საქონლის ღირებულების ანაზღაურების
          გადახდა;
        </ContentText>
        <ContentText>
          ბ) საკუთარი საფულიდან სხვა მომხმარებლის ელექტრონულ საფულეზე
          ელექტრონული ფულის გადარიცხვა;
        </ContentText>
        <ContentText>
          გ) ელექტრონულ საფულეზე არსებული თანხის ან მისი ნაწილის კონვერტაცია
          სხვა ვალუტაში;
        </ContentText>
        <ContentText>
          დ) ელექტრონული საფულიდან თანხის გატანა/გადარიცხვა;
        </ContentText>
        <ContentText>
          ე) ელექტრონულ საფულეზე თანხის ჩარიცხვა/შეტანა;
        </ContentText>
        <ContentText>
          ვ) ფულადი გზავნილების სისტემების მეშვეობით ფულადი გზავნილების
          განხორციელება, თანხის გაგზავნა და მიღება;
        </ContentText>
        <ContentText>
          ელ. საფულეში პერიოდულად დაემატება ახალი მომსახურებები, რაც
          მომხმარებლისთვის ხელმისაწვდომი გახდება ონლაინ რეჟიმში, ელ. საფულის
          მენიუში.
        </ContentText>
        <ContentTitle>მომსახურების საფასურის გადახდის წესი</ContentTitle>
        <ContentText>
          ელექტრონული საფულის მენიუში მომხმარებელმა უნდა აირჩიოს სასურველი
          სერვისის გადახდა (მაგალითად: დენის გადასახადი, „თელასი“), რის
          შემდეგაც, მომხმარებელმა უნდა შეავსოს შესაბამისი ფორმა, რომელშიც უნდა
          მიუთითოს:
        </ContentText>
        <ContentText>
          მიმღების აბონენტის ნომერი (გრაფაში „აბონენტის ნომერი“ ივსება იმ
          აბონენტის ნომერი, რომლის სასარგებლოდაც უნდა შესრულდეს გადახდა)
        </ContentText>
        <ContentText>
          გადასახდელი თანხა (ფორმაში მოცემულია, გადახდის ლიმიტები და საკომისიოს
          ოდენობა)
        </ContentText>
        <ContentText>
          გადახდის განაცხადის შევსების შემდეგ, მომხმარებელი ვალდებულია
          დაადასტუროს შევსებული ინფორმაციის სისწორე შესაბამის ღილაკზე დაჭერის
          გზით. ამ დროს სისტემა ავტომატურად ამოწმებს შევსებული მონაცემების
          სისწორეს და თუ ყველა ველი შევსებულია სწორად, მომხმარებელს მონიტორზე
          გამოუჩნდება შეტყობინება (დასტური ოპერაციის წარმატებით შესრულების
          შესახებ), ხოლო თუ მონაცემები არასწორია, მონიტორზე გამოჩნდება
          შესაბამისი შეტყობინება და რის მიხედვითაც მომხმარებელმა უნდა შეავსოს
          დაზუსტებული მონაცემები. გადახდის ოპერაციის შესრულების შემდგომ
          მომხმარებლის ელექტრონული საფულის ბალანსი შემცირდება საგადახდო
          დავალებაში მითითებული თანხისა და საკომისიოს ოდენობით.
        </ContentText>
        <ContentTitle>
          საკუთარი საფულიდან სხვა მომხმარებლის ელექტრონულ საფულეზე ელექტრონული
          ფულის გადარიცხვის ოპერაციის განხორციელების წესი
        </ContentTitle>
        <ContentText>
          ელექტრონული საფულის მომხმარებელს, რომელსაც ელექტრონულ საფულეში გავლილი
          აქვს რეგისტრაცია და იდენტიფიკაცია, უფლება აქვს თავისი საფულიდან
          გადაურიცხოს ელექტრონული ფული, სისტემაში არსებულ სხვა მომხმარებელს
          საფულეში.
        </ContentText>
        <ContentText>
          სხვა მომხმარებლის ელექტრონულ საფულეზე ელექტრონული ფულის გადარიცხვის
          მიზნით მომხმარებელი ვალდებულია ელ-საფულის სისტემის მენიუში აირჩიოს
          ღილაკი - „თანხის გადარიცხვა“, შექმნას საგადახდო დავალება , სადაც უნდა
          მიუთითოს:
        </ContentText>
        <ContentText>გადასარიცხი თანხის ოდენობა;</ContentText>
        <ContentText>
          მიმღების საფულის რეკვიზიტები (მობილური ტელეფონის ნომერი ან ელსაფულის
          ნომერი ან ელ-ფოსტა);
        </ContentText>
        <ContentText>დანიშნულება;</ContentText>
        <ContentText>
          მომხმარებლის მიერ წინამდებარე პუნქტში მითითებული მონაცემების სისტემაში
          შეყვანის შემდგომ, სისტემა ავტომატურად პოულობს გადარიცხვის მიმღებს და
          თანხის გადამრიცხავს უჩვენებს მიმღების სახელს და გვარს ან მის
          ინიციალებს.
        </ContentText>
        <ContentText>
          მომხმარებელი, წინამდებარე პუნქტში მითითებული მონაცემების სისტემაში
          შეყვანის შემდგომ, სპეციალურ ღილაკზე დაჭერით ახდენს საგადახდო დავალების
          ინიცირებას, რის შემდეგაც სისტემა აჭრის მომხმარებელს საფულიდან თანხას,
          გადარიცხვის საკომისიოსთან ერთად (საკომისიოს არსებობის შემთხვევაში),
          რომელიც წინასწარ არის მომხმარებლისთვის ცნობილი და მიმღებს ურიცხავს
          დავალებაში მითითებულ თანხას საფულის ანგარიშზე.
        </ContentText>
        <ContentTitle>
          ელექტრონულ საფულეზე არსებული თანხის ან მისი ნაწილის სხვა ვალუტაში
          კონვერტაციის წესი
        </ContentTitle>
        <ContentText>
          ელ. საფულის მომხმარებელს შესაბამისი ლიმიტების ფარგლებში უფლება აქვს
          მოახდინოს თანხის კონვერტაცია ერთი ვალუტიდან მეორეში, რაც ხორციელდება
          ამ პუნქტით განსაზღვრული წესით;
        </ContentText>
        <ContentText>
          კონვერტაციის განსახორციელებლად, მომხმარებელი ვალდებულია თავისი
          ელექტრონული საფულის მენიუში დააჭიროს ღილაკს ,,ვალუტის გადაცვლა“, რის
          შემდეგაც გამოდის ფანჯარა, სადაც მითითებულია (გასაყიდი თანხის მხარეს)
          მომხმარებლის ბალანსი ლარში და (შესაძენი თანხის მხარეს) ვალუტების
          ჩამონათვალი, რომლებზეც ხორციელდება ვალუტის გადაცვლა (კონვერტაცია).
          მომხმარებელი ვალდებულია სპეციალურ გრაფაში მიუთითოს თანხის ოდენობა,
          რომლის გადაცვლაც სურს, ხოლო მეორე გრაფაში აირჩიოს ვალუტა, რომლის
          შეძენაც სურს. ამ დროს სისტემა ავტომატურად ითვლის შესაძენი თანხის
          ოდენობას შპს „ინექს ფეის“ კომერციული კურსის შესაბამისად და ასახავს
          განაცხადის ფორმაში. მომხმარებლის მიერ საგადახდო დავალების გაცემის
          შემდეგ სისტემა მას საფულიდან ჩამოაჭრის თანხას, რომლის კონვერტირებაც
          სურს მომხმარებელს, ხოლო მომხმარებელს შესაბამისი შესაძენი თანხა
          ჩაერიცხება ვალუტაში თავის საფულეზე, სისტემის ოპერატორის შესაბამისი
          ვალუტის კონვერტაციის ანგარიშიდან. ამ დროს მომხმარებელი სისტემაში
          მიიღებს შეტყობინებას, რომ ტრანზაქცია დასრულდა წარმატებით. კონვერტაციის
          კურსი წინასწარ ცნობილია მომხმარებლისთვის და ნაჩვენებია საგადახდო
          დავალების ფორმაში.
        </ContentText>
        <ContentTitle>ელექტრონული საფულიდან თანხის გატანის წესი</ContentTitle>
        <ContentText>
          ელ. საფულიდან თანხის გატანა შეუძლია მხოლოდ იდენტიფიცირებულ
          მომხმარებელს. ელექტრონული საფულიდან თანხის გატანა შესაძლებელია ნაღდი
          ან უნაღდო ანგარიშსწორების, მათ შორის: საბანკო ანგარიშზე ან საბანკო
          ბარათზე გადარიცხვის გზით, ფულადი გზავნილების საშუალებით გადარიცხვის
          გზით, ინექს ფეის ან/და აგენტების სალაროებიდან ნაღდი ფულის სახით
          გატანის გზით;
        </ContentText>
        <ContentText>
          ელექტრონული საფულიდან თანხის გატანის მსურველი მომხმარებელი ვალდებულია
          საფულის მენიუში აირჩიოს თანხის მიღების მეთოდი და შეავსოს შესაბამისი
          განაცხადი, სადაც ივსება განაცხადის ფორმის სავალდებულო ველები და
          სასურველი თანხის ოდენობა, ხოლო მომხმარებლის საიდენტიფიკაციო მონაცემები
          ფორმაში ივსება ავტომატურად.
        </ContentText>
        <ContentText>
          განაცხადის წინამდებარე პუნქტით გათვალისწინებული ველების შემდგომ,
          განაცხადის ფორმაში ავტომატურად დაანგარიშდება მომსახურების საკომისიო.
          ოპერაციის გაგრძელების მიზნით მომხმარებელი ვალდებულია შესაბამისი ველის
          მონიშვნის გზით დაეთანხმოს საკომისიოს ოდენობას და დაადასტუროს შევსებული
          ინფორმაციის სისწორე. ამ დროს სისტემა ამოწმებს, არის თუ არა
          მომხმარებლის საფულის ანგარიშზე საკმარისი თანხა (საკომისიოს ჩათვლით),
          რათა მომხმარებელმა შეძლოს სასურველი თანხის მიღება. თუ მითითებული თანხა
          საკომისიოს ჩათვლით აღემატება მომხმარებლის მიმდინარე ნაშთს საფულის
          ანგარიშზე, სისტემა გამოიტანს შეცდომის შესაბამის ტექსტს, ხოლო თუ
          საფულის ნაშთი საკმარისია ოპერაციის განსახორციელებლად, მომხმარებელს
          გამოუჩნდება შეტყობინება, რომ ტრანზაქცია დასრულდა წარმატებით. ასეთ
          შემთხვევაში მომხმარებლის საფულის ბალანსი შემცირდება საგადახდო
          დავალებაში მითითებული თანხის პროპორციულად, საკომისიოს ჩათვლით.
        </ContentText>
        <ContentText>
          წინამდებარე პუნქტით გათვალისწნებული ოპერაციის შესრულების შემდგომ,
          მომხმარებელს შეუძლია, იმის მიხედვით თუ განაღდების რა ფორმა აირჩია,
          მიმართოს „ინექს ფეის“ ან/და აგენტის სალაროს, ბანკომატს ან თანხა მიიღოს
          მის მიერ მითითებულ საბანკო ანგარიშზე/ბარათზე ან ის გაანაღდოს თანხას
          ფულადი გზავნილების სისტემის მეშვეობით.
        </ContentText>
        <ContentText>
          მომხმარებლის მიერ თანხის ელექტრონული საფულიდან გატანის ან გადარიცხვის
          მოთხოვნის დადასტურების დროს, სისტემა ავტომატურად უგზავნის მომხმარებელს
          SMS შეტყობინებას, სადაც მითითებულია ერთჯერადი კოდი, რომლის შესაბამის
          გრაფაში ჩაწერის შემდეგ, სისტემა ადასტურებს ტრანზაქციის წარმატებით
          დასრულებას.
        </ContentText>
        <ContentText>
          მომხმარებელს, სისტემის მენიუში აქვს თანხის გატანის ან გადარიცხვის დროს
          SMS დადასტურების ფუნქციონალის გამორთვის და ასევე ნებისმიერ დროს ამ
          მომსახურების ხელახლა ჩართვის შესაძლებლობა.
        </ContentText>
        <ContentTitle>ელექტრონულ საფულეში თანხის შეტანის წესი</ContentTitle>
        <ContentText>
          მომხმარებელს უფლება აქვს ელექტრონულ საფულეში თანხა შეიტანოს უნაღდო ან
          ნაღდი ფორმით, მათ შორის: პარტნიორი კომპანიის ან ,,ინექს ფეის“ სალაროსა
          და პარტნიორი კომპანიების ტერმინალების, აგრეთვე ფულადი გზავნილების
          სისტემებისა და საბანკო ანგარიშების/ბარათების გამოყენებით.
        </ContentText>
        <ContentText>
          ელექტორნულ საფულეში თანხის შეტანის მიზნით, მომხმარებელი ვალდებულია
          აირჩიოს თანხის შეტანის სასურველი მეთოდი (ნაღდი ან უნაღდოს ფორმა).
          მომხმარებელს უფლება აქვს მიმართოს როგორც ინექს ფეის ასევე მისი
          პარტნიორი კომპანიის სალაროს, საგადახდო ტერმინალს და ვებგვერდს.
          ამასთანავე, ინექს ფეი პასუხისმგებლობას არ იღებს მომსახურების
          საკომისიოს რაოდენობისა თუ მისი ანაზღაურების პირობებთან დაკავშირებით,
          თუკი ელექტრონულ საფულეში თანხის შეტანა ხორციელდება არა უშუალოდ ინექს
          ფეის ან მისი აგენტების მეშვეობით;
        </ContentText>
        <ContentText>
          ელექტრონულ საფულეში თანხის შეტანის ოპერაციის განხორციელებისთვის
          მომხმარებელი ვალდებულია შეავსოს სპეციალური განაცხადი, სადაც ეთითება
          საფულის რეკვიზიტები, პირადი მონაცემები, მათ შორის საფულის უნიკალურ
          ნომერი. აგრეთვე, მომხმარებელი ვალდებულია მიუთითოს სასურველი თანხა და
          ვალუტა. აღნიშნული მონაცემების სისწორის შემოწმების შემდეგ, შესაბამის
          ღილაკზე დაჭერით ხორციელდება საგადახდო დავალების ინიცირება, რის
          შემდეგაც, საგადახდო დავალებაში მითითებული თანხის შესაბამისი
          ელექტრონული ფული ირიცხება მომხმარებლის საფულის ანგარიშზე.
        </ContentText>
        <ContentText>
          თანხის ერთ ვალუტაში შეტანის და მეორე ვალუტაში მიღების შემთხვევაში,
          მოხდება ავტომატური კონვერტაცია, „ინექს ფეის“ კომერციული კურსის
          შესაბამისად. კომერციული კურსი ონლაინ რეჟიმში მოცემულია საფულის
          მენიუში.
        </ContentText>
        <ContentTitle>
          საგადახდო დავალების მიღება, შესრულება და შესრულების ვადები
        </ContentTitle>
        <ContentText>
          მომხმარებელს შეუძლია საგადახდო დავალება გასცეს, როგორც წერილობით, ისე
          ელექტრონული, ინფორმაციულ-სატელეკომუნიკაციო ან/და სხვა ტექნოლოგიური
          საშუალებების გამოყენებით. მომხმარებელმა დავალებაში აუცილებელია
          მიუთითოს თავისი და მიმღების რეკვიზიტები სრულად. გადახდის ოპერაციას
          დადასტურების მომენტში ენიჭება უნიკალური ნომერი, რომელიც წარმოადგენს ამ
          ტრანზაქციის იდენტიფიკატორს და ის უცვლელია ოპერაციის შესრულების ყველა
          ეტაპზე.
        </ContentText>
        <ContentText>
          საგადახდო დავალების მიღების დროდ ითვლება მომენტი, როდესაც გადამხდელის
          საგადახდო მომსახურების სისტემის ოპერატორი მიიღებს საგადახდო დავალებას
          შესასრულებლად.
        </ContentText>
        <ContentText>
          თუ საგადახდო დავალება მიღებულია, მიმდინარე სამუშაო დღის 17:00 საათის
          შემდეგ, შესაძლოა საგადახდო დავალება მიღებულად ჩაითვალოს მომდევნო
          სამუშაო დღეს, რაც დამოკიდებულია მიმღების პროვაიდერის მუშაობის
          პრინციპზე.
        </ContentText>
        <ContentText>
          სისტემის ოპერატორი ვალდებულია, დავალების მიღების შესახებ აცნობოს
          გადამხდელს იმ ფორმით, რა ფორმითაც გადამხდელმა მოახდინა საგადახდო
          დავალების ინიცირება.
        </ContentText>
        <ContentText>
          თუ საგადახდო დავალება უნდა შესრულდეს მხარეებს შორის შეთანხმებულ დღეს,
          სისტემის ოპერატორი უფლებამოსილია მიიღოს საგადახდო დავალება ამ თარიღის
          დადგომამდე.
        </ContentText>
        <ContentText>
          იმ შემთხვევაში, თუ სისტემის ოპერატორი არის როგორც მიმღების, ისე
          გადამხდელის პროვაიდერი, მაშინ მან უნდა უზრუნველყოს საგადახდო
          დავალებაში მითითებული თანხის მიმღების ანგარიშზე ასახვა ან/და
          ხელმისაწვდომობა საგადახდო დავალების მიღების დღესვე.
        </ContentText>
        <ContentText>
          იმ შემთხვევაში, თუ სისტემის ოპერატორი არის გადამხდელის საგადახდო
          მომსახურების პროვაიდერი, მან უნდა უზრუნველყოს საგადახდო დავალებაში
          მითითებული თანხის ჩარიცხვა მიმღების პროვაიდერის ანგარიშზე საგადახდო
          დავალების მიღებიდან არაუგვიანეს მეორე სამუშაო დღისა, თუ მხარეებს შორის
          შეთანხმებით სხვა რამ არ არის გათვალისწინებული.
        </ContentText>
        <ContentText>
          იმ შემთხვევაში თუ სისტემის ოპერატორი არის მიმღების საგადახდო
          პროვაიდერი, იგი ვალდებულია, იმავე დღეს ჩარიცხული თანხა ასახოს მიმღების
          ანგარიშზე, თუ მხარეებს შორის შეთანხმებით სხვა პირობა არ არის
          შეთანხმებული.
        </ContentText>
        <ContentText>
          იმ შემთხვევაში თუ სისტემის ოპერატორი ვერ ახერხებს მიმღების
          იდენტიფიცირებას მიღებული ინფორმაციის საფუძველზე და დავალება არ
          შესრულდება, მაშინ მან უნდა უზრუნველყოს მიღებული თანხის უკან დაბრუნება
          უმოკლეს ვადაში.
        </ContentText>
        <ContentText>
          სისტემის ოპერატორი ვალდებულია, მომხმარებლის მიერ ელექტრონული ფულის
          შეძენის შემთხვევაში, დაუყოვნებლივ გახადოს ეს თანხა მისთვის
          ხელმისაწვდომი.
        </ContentText>
        <ContentText>
          საგადახდო დავალების დადასტურების მიზნით, სისტემის ოპერატორი ვალდებულია
          მომხმარებელს გადასცეს სპეციალური დადასტურებული დოკუმენტი, მატერიალური
          ან ელექტრონული ფორმით (გადახდის ქვითარი).
        </ContentText>
        <ContentText>
          გადახდა შესრულებულად ითვლება, მიმღების ანგარიშზე (ელექტრონული) ფულადი
          სახსრების ასახვის მომენტიდან, გარდა კანონით გათვალისწინებული
          შემთხვევებისა.
        </ContentText>
        <ContentTitle>საგადახდო დავალების უარყოფა</ContentTitle>
        <ContentText>
          სისტემის ოპერატორი საგადახდო დავალების შესრულებას უარყოფს შემდეგ
          შემთხვევებში:
        </ContentText>
        ა) თუ მომხმარებელმა მიუთითა შეცდომით პირადი ან/და ელექტრონული საფულის
        რეკვიზიტები;
        <ContentText>ბ) მომხმარებელი არასრულწლოვანია;</ContentText>
        <ContentText>
          გ) მომხმარებლის ანგარიში სისტემის წესების დარღვევისთვის არის
          ბლოკირებული;
        </ContentText>
        <ContentText>
          დ) ჩნდება გონივრული ეჭვი, რომ მომხმარებელი იმყოფება ნარკოტიკული,
          ფსიქოტროპული ან ალკოჰოლური ან სხვა ნივთიერებების ზემოქმედების ქვეშ;
        </ContentText>
        <ContentText>
          ე) სისტემის ოპერატორმა შესაბამისი მიმღების პროვაიდერისგან მიიღო
          უარყოფითი პასუხი, რაც შესაძლებელია შემდეგ შემთხვევებში, თუ
          მომხმარებლისგან წარდგენილი ანგარიში/იდენტიფიკატორი არ არის სწორი და
          სისტემამ ვერ მოძებნა ასეთი მონაცემები, ან იმ შემთხვევაში თუ
          მომხმარებლის მიერ ინიცირებული თანხის ოდენობა აღემატება წარდგენილ
          ანგარიშზე სისტემის მიერ დადგენილ ლიმიტებს (ანგარიშის ან
          იდენტიფიცირების დონის მიხედვით). ასევე, თუ მიმღები ან მიმღების
          პროვაიდერი, რაიმე ტექნიკური თუ სხვა მიზეზით ვერ იღებს გადახდის თანხას,
          რის გამოც ვერ ასრულებს გადახდის ოპერაციას.
        </ContentText>
        <ContentText>
          ვ) თუ გადამხდელის ელექტრონულ საფულეზე არსებული თანხა არ არის საკმარისი
          გადასარიცხი თანხის ან/და საკომისიოსათვის;
        </ContentText>
        <ContentText>
          ზ) საქართველოს კანონმდებლობით გათვალისწინებულ სხვა შემთხვევებში.
        </ContentText>
        <ContentText>
          იმ შემთხვევაში თუ სისტემის ოპერატორი უარს ამბობს საგადახდო დავალების
          შესრულებაზე ამ მუხლის პირველი ნაწილით გათვალისწინებული პირობებიდან
          ერთ-ერთის ან რამდენიმეს დადგომის შემთხვევაში, იგი ვალდებულია
          აღნიშნულის შესახებ უმოკლეს ვადაში აცნობოს მომხმარებელს, მაგრამ
          არაუგვიანეს საგადახდო დავალების შესრულების დროისა.
        </ContentText>
        <ContentText>
          მომხმარებელს არ აქვს უფლება გააუქმოს საგადახდო დავალება, თუ იგი
          მიღებულია გადამხდელის ან მიმღების პროვაიდერის მიერ.
        </ContentText>
        <ContentText>
          იმ შემთხვევაში თუ მომხმარებელს და სისტემის ოპერატორის შორის საგადახდო
          დავალების შესრულების დღე წინასწარ არის შეთანხმებული, მომხმარებელს
          უფლება აქვს გააუქმოს საგადახდო დავალება არაუგვიანეს შეთანხმებული დღის
          წინა სამუშაო დღისა. მომხმარებელს და სისტემის ოპერატორს შორის
          შესაბამისი შეთანხმების არსებობის შემთხვევაში, საგადახდო დავალება
          შესაძლოა გამოთხოვილ იქნას მიმღების ანგარიშზე თანხის ასახვამდე.
        </ContentText>
        <ContentTitle>
          საგადახდო დავალების შეუსრულებლობა ან არასწორად შესრულება
        </ContentTitle>
        <ContentText>
          სისტემის ოპერატორი პასუხს არ აგებს საგადახდო დავალების შეუსრულებლობაზე
          ან არასწორად შესრულებაზე, თუ ეს გამოწვეულია მომხმარებლის მიერ
          მითითებული არასწორი ინფორმაციით. მიუხედავად ამისა, სისტემის ოპერატორი
          ვალდებულია არასწორად გადმორიცხული ელექტრონული ფული დაუბრუნოს
          მომხმარებელს, თუ დავალება არ არის შესრულებული მიმღების პროვაიდერის
          მიერ.
        </ContentText>
        <ContentText>
          იმ შემთხვევაში თუ სისტემის ოპერატორმა (პროვაიდერმა), რომლის
          საშუალებითაც მომხმარებელი ახორციელებს გადახდას, არასწორად შეასრულა
          გადახდის ოპერაცია თავისი ბრალეულობით, იგი ვალდებულია, გადამხდელს
          დაუბრუნოს არასწორად გადარიცხული თანხა და ამ ტრანზაქციის საკომისიო
        </ContentText>
        <ContentText>
          იმ შემთხვევაში თუ სისტემის ოპერატორი არის მიმღების პროვაიდერი და მან
          არასწორად შეასრულა გადახდის ოპერაცია, იგი ვალდებულია მიმღებს დაუბრუნოს
          გადახდილი თანხა საკომისიოსთან ერთად.
        </ContentText>
        <ContentText>
          სისტემის ოპერატორი არ არის ვალდებული მომხმარებელს დაუბრუნოს თანხა თუ
          საგადახდო დავალებაში მითითებული მომსახურება მომხმარებლის მიერ იქნა
          მიღებული, რასაც ადასტურებს ტრანსფერ ორდერის შესაბამისი სტატუსი
          სისტემაში. ხოლო, თუ მომხმარებლის მიერ გადახდილი თანხის შესაბამისად,
          ტექნიკური მიზეზების გამო მან არ მიიღო მომსახურება (მომხმარებლის
          წერილობითი განაცხადის საფუძველზე, ან ავტომატურად), სისტემის ოპერატორი
          დაუბრუნებს მას თანხას ელ. საფულეზე.
        </ContentText>
        <ContentTitle>მომსახურების ტარიფები</ContentTitle>
        <ContentText>
          სისტემის ოპერატორი, ელექტრონული საფულით მომსახურებისთვის ადგენს
          ტარიფებს, რომლებიც განთავსებულია სისტემის ოპერატორის ვებგვერდზე
          www.inexpay.ge. სისტემის ოპერატორს უფლება აქვს ყოველგვარი თანხმობის
          გარეშე შეცვალოს ტარიფები ან დააწესოს ახალი ტარიფები. ასეთ შემთხვევაში
          სისტემის ოპერატორი განაახლებს ტარიფებს ოფიციალურ ვებგვერდზე და
          ელექტრონული საფულის მენიუში. მომსახურების განახლებული საკომისიოები
          აგრეთვე აისახება კონკრეტული მომსახურების მიღების მომენტში, რომელიც
          თვალსაჩინოა მომხმარებლისთვის. თუ მომხმარებელი გადაწყვეტს, რომ შეცვლილი
          ტარიფების პირობებში გააგრძელოს ელექტრონული საფულით სარგებლობა,
          აღნიშნული უფლებას აძლევს სისტემის ოპერატორს ჩამოაჭრას მომხმარებელს
          მომსახურების შეცვლილი საკომისიო.
        </ContentText>
        <ContentText>
          სისტემის ოპერატორის ჩანაწერები წარმოადგენს მომხმარებელის სისტემის
          ოპერატორისთან ურთიერთობის დამადასტურებელ საბუთს, გარდა იმ შემთხვევისა,
          როდესაც ჩანაწერი აშკარად უზუსტოა.
        </ContentText>
        <ContentTitle>პირობების მოქმედების ვადები</ContentTitle>
        <ContentText>
          სისტემის ოპერატორსა და მომხმარებელს შორის შეთანხმება გაფორმებულად
          ითვლება მომხმარებლის მიერ წინამდებარე წესებსა და პირობებზე თანხმობის
          დადასტურების მომენტიდან;
        </ContentText>
        <ContentText>
          წინამდებარე პირობები მოქმედებს განუსაზღველი ვადით, ელექტრონული საფულის
          გაუქმებამდე, რომელიც შესაძლებელია განხორციელდეს მომხმარებლის სურვილით,
          ან სისტემის ოპერატორის გადაწყვეტილებით;
        </ContentText>
        <ContentText>
          სისტემის ოპერატორს უფლება აქვს მომსახურებით სარგებლობის შეჩერების
          პარალელურად გააუქმოს ელექტრონული საფულე, თუკი დამტკიცდება, რომ
          მომხმარებელი მას იყენებდა აკრძალული და უკანონო ქმედებების
          განხორციელებისთვის;
        </ContentText>
        <ContentText>
          ელექტრონული საფულის გაუქმების შემთხვევაში მომხმარებელს უკან უბრუნდება
          ელექტრონულ საფულეზე რიცხული ნაშთი, გარდა იმ შემთხვევისა, როდესაც
          კანონმდებლობით გათვალისწინებულ შემთხვევებში, ფულადი სახსრების
          უკანონობის საფუძვლით, ხორციელდება თანხების სასამართლო წესით
          ჩამორთმევა.
        </ContentText>
        <ContentText>
          ხელშეკრულების შეწყვეტა ან/და ელექტრონული საფულის გაუქმება არ
          ათავისუფლებს მხარეებს მანამდე არსებული ვალდებულებების შესრულებისგან;
        </ContentText>
        <ContentText>
          შეკითხვების ან პრეტენზიების შემთხვევაში, მომხმარებელს შეუძლია
          დაუკავშირდეს სისტემის ოპერატორს ტელეფონით (სამუშაო საათებში) ნომერზე
          +995 (32) 2833383 ან ელ-ფოსტაზე{" "}
          <a href="mailto:info@inexpay.ge" className="underline">
            info@inexpay.ge
          </a>
        </ContentText>
        <ContentText>
          თუ რაიმე პირობა არ არის გათვალისწინებული მოცემული დოკუმენტით,
          მომხმარებელი უფლებამოსილია მიმართოს სისტემის ოპერატორს წერილობით და
          მიიღოს პასუხი მოთხოვნაზე.
        </ContentText>
      </div>
      <ContentFooter />
    </Wrap>
  );
};

export default Privacy;
