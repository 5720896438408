import { Link } from "react-router-dom";

import FlexWrapper from "../../../../components/FlexWrapper";
import Img from "../../../../components/Img";

const Header = () => {
  return (
    <>
      <FlexWrapper justify="space-between" style={{ padding: 30 }}>
        <div>
          <Link to="/login">
            <Img width={85} src={"/logo.svg"} alt="logo" />
          </Link>
        </div>

        <div>
          <Link to="/home">
            <Img src="/banktransfer/close.png" alt="cancel" />
          </Link>
        </div>
      </FlexWrapper>
    </>
  );
};

export default Header;
