import { IconProps } from "./types";

const HomeIcon = ({ fill, width, height, ...rest }: IconProps) => {
  return (
    <svg
      width={width || "20"}
      height={height || "17"}
      viewBox="0 0 20 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11 15H9V13H11V15ZM13 11H7V17H13V11ZM17 6.3V1H14V3.6L10 0L0 9H3L10 2.69L17 9H20L17 6.3Z"
        fill={fill || "#20202C"}
      />
    </svg>
  );
};

export default HomeIcon;
