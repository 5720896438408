import styled from "styled-components";
import { media } from "../../../utils/theme";

export const ReviewForm = styled.div<{ disabled?: boolean; }>`
  background: #fff;
  box-shadow: 0px 2px 3px rgba(111, 118, 137, 0.1);
  border-radius: 10px;
  width: 736px;
  padding: 1rem;
  margin-left: 1.5rem;
  flex-shrink: 0;
  border: 1px solid #eaecf0;
  margin: 0 auto;

  button {
    padding: 0.8rem;
    margin-top: 1rem;
  }

  opacity: ${(p) => (p.disabled ? "0.5" : "1")};
  pointer-events: ${(p) => (p.disabled ? "none" : "auto")};

  ${media.lessThan("tablet")} {
    box-shadow: none;
    margin: 0 auto;
    width: 100%;
    max-width: 80%;
  }

  ${media.lessThan("small")} {
    box-shadow: none;
    margin-left: 0;
    width: 100%;
    max-width: 100%;
  }

  .contain {
    padding: 10px 25px;
  }

  @media (max-width: 768px) {
    width: 100% !important;
  }

  @media (max-width: 480px) {
    .topwrapper {
      flex-direction: column;
      align-items: flex-start;
      padding-left: 20px;
      padding-top:16px;
    }

    padding: 0rem;

    .contain {
      padding: 10px 0;
    }

    .title {
      font-size: 24px;
      margin-left: 24px;
      line-height: 30px;
    }
  }
`;

export const ListItem = styled.p`
  margin-bottom: 0.5rem;
  span {
    color: var(--text-color-2);
  }

  span.red {
    color: var(--red);
    font-weight: bold;
  }
`;

export const ResponsiveWrapper = styled.div`
  padding: 0.5rem 1rem;
  position: relative;
  background: #FBFBFB;


  .wrap {
    background: #fff;
    padding: 1rem;
    border: 1px solid #EDEDED;
    border-radius: 4px;
  }
`;
