import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";

import BaseModal from "../../components/BaseModal";
import Text from "../../components/Text/text";
import Title from "../../components/Text/title";
import Divider from "../../components/Divider";
import Img from "../../components/Img";
import AtSign from "../../components/Icons/AtSign";
import PhoneIcon from "../../components/Icons/phone";
import { FAQLinks } from "./data";
import { media } from "../../utils/theme";
import { useTranslation } from "react-i18next";
import { Desktop, TabletOrMobile } from "../../components/responsive/responsive";
import Header from "../../partials/Header";

const ContentWrapper = styled.div`
  background: #fff;
  padding: 1rem;
  border-radius: 11px;
  width: 808px;

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 1rem;
    width: 140px;

    span {
      margin-left: 0.3rem;
    }
  }

  ${media.lessThan("tablet")} {
    width: 750px;
  }

  ${media.lessThan("small")} {
    width: 100%;
    height: auto;
    border-radius: 0;
    margin-top: 1rem;
  }
`;
const TitleHeader = styled.p`
  color: var(--text-color);
  font-size: 1.35rem;
  margin-bottom: 0.5rem;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
`;

const FAQFooter = styled.div`
  background: var(--light-bg);
  border: 0.5px solid #e4e4e4;
  border-radius: 4px;
  padding: 0rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;

  .leftSide {
    flex: 1;
  }
`;

const RightSide = styled.div`
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const ContactItemInfo = styled.p`
  color: var(--light-red);
  display: flex;
  align-items: center;

  svg {
    margin-right: 1rem;
  }
`;

const MainFAQContentWrapper = styled.div`
  border: 1px solid #e4e4e4;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 1rem 1rem 0 1rem;
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-height: 380px;
  overflow: auto;

  a:nth-child(2n) {
    margin-left: 1rem;
  }

  ${media.lessThan("small")} {
    grid-template-columns: repeat(1, 1fr);

    a:nth-child(2n) {
      margin-left: 0;
    }
  }
`;

const FAQLinkWrapper = styled(Link)`
  border: 0.5px solid #e4e4e4;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 1rem;
  display: inline-flex;
  align-items: center;
  min-height: 100px;
  margin-bottom: 1rem;

  svg {
    transition-duration: 0s !important;
  }

  &:hover {
    background: var(--pink);
    border-color: var(--pink);

    .title {
      font-weight: 700;
    }

    .circle {
      transition: all 0s;
      background: var(--text-color);

      svg,
      path {
        fill: white;
      }
    }
  }

  .circle {
    width: 2.5rem;
    height: 2.5rem;
    background: #f8f8f8;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5rem;
    flex-shrink: 0;
  }
`;

const FaqText = styled(Text)`
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const FAQLink = ({ title, text, icon, to }: any) => {
  const { t } = useTranslation();

  return (
    <FAQLinkWrapper to={`/faq/${to}`}>
      <span className="circle">
        {icon}
        {/* <Img src='/sidebar/transfer.svg' alt={text} /> */}
      </span>
      <span>
        <Title className="title">{t(title)}</Title>
        <FaqText mt="-0.2rem" size="0.875rem">
          {t(text)}
        </FaqText>
      </span>
    </FAQLinkWrapper>
  );
};

const FAQ = (props: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <BaseModal
      mobileStyle={{
        content: {
          top: 0,
          transform: "none",
          width: "100%",
          left: 0,
          height: "100%",
          borderRadius: 0
        },
      }}
      isOpen={true}
      onRequestClose={() => navigate("/home")}
    >
      <TabletOrMobile>
        <Header></Header>
      </TabletOrMobile>
      <ContentWrapper>
        <TitleHeader>{t("FAQ.title")}</TitleHeader>
        <Divider mb={"1rem"} color="var(--red)" />
        <Text color="var(--text-color)" ta="center" size={"0.875rem"}>
          {t("FAQ.subtitle")}
        </Text>

        <MainFAQContentWrapper>
          {FAQLinks.map((item: any) => (
            <FAQLink
              to={item.to}
              title={item.title}
              text={item.text}
              icon={item.icon}
            />
          ))}
        </MainFAQContentWrapper>
       
       <Desktop>
       <FAQFooter>
          <span className="leftSide">
            <Img src="/FAQ/illustartion.png" alt="faq" />
          </span>
          <RightSide>
            <Text size="0.875rem" style={{ textDecoration: "underline" }}>
              {t("FAQ.needhelp")}
            </Text>
            <Text
              color="var(--text-color)"
              mb={"1.5rem"}
              style={{ width: 333, lineHeight: "20px" }}
            >
              {t("FAQ.helptext")}
            </Text>
            <ContactItemInfo>
              <AtSign width="22px" fill="var(--text-color)" />{" "}
              <span> info@inexpay.ge</span>
            </ContactItemInfo>
            <ContactItemInfo>
              <PhoneIcon
                style={{ marginLeft: 2 }}
                width="18px"
                fill="var(--text-color)"
              />{" "}
              <span>032 2 83-33-83</span>
            </ContactItemInfo>
          </RightSide>
        </FAQFooter>
        </Desktop> 
      </ContentWrapper>
    </BaseModal>
  );
};

export default FAQ;
