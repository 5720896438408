import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";

import {
  BackButton,
  ButtonsWrapper,
  ContentWrapper,
  PlainButton,
  SaveasTempalateWrapper,
  TopSection,
} from "./ResultModal.styled";
import BaseModal from "../../../../components/BaseModal";
import Img from "../../../../components/Img";
import Text from "../../../../components/Text/text";
import Title from "../../../../components/Text/title";
import { bankTransferDataSelector } from "../../../../redux/selectors";
import { useEffect, useState } from "react";
import {
  getImage,
  handlePdfDownloadService,
} from "../../../../services/Services";
import { getUserId } from "../../../../utils/storage";
import { useToast } from "../../../../hooks/useToast";
import { saveFile } from "../../../../utils/utils";

interface ResultModalProps {
  isOpen: boolean;
  onClose: () => void;
  imgSrc?: string;
  mode?: string;
  addTemplate: () => void;
  isTemplateSuccess?: boolean;
  transferId: number;
}

const ResultModal = ({
  isOpen,
  onClose,
  mode,
  addTemplate,
  isTemplateSuccess,
  transferId,
}: ResultModalProps) => {
  const { t } = useTranslation();
  const bankTransferData = useSelector(bankTransferDataSelector);
  const [countrySvg, setCountrySvg] = useState(null);
  const isSuccess = mode === "success";
  const toast = useToast();
  const userId = getUserId();

  const isFromActiveTemplate = bankTransferData?.isFromActiveTemplate;

  useEffect(() => {
    if (bankTransferData?.CountryCode) {
      getImage(bankTransferData.CountryCode).then((res) =>
        setCountrySvg(res.data)
      );
    }
  }, [bankTransferData]);

  const handlePdfDownload = () => {
    handlePdfDownloadService(userId, transferId)
      .then((res) => {
        saveFile(res, `bank-transfer-document-${transferId}`);
      })
      .catch((err) => toast(err.response?.data.StatusMessage, "error"));
  };

  return (
    <BaseModal isOpen={isOpen} onRequestClose={() => onClose()}>
      <ContentWrapper>
        <TopSection>
          <Img
            src={isSuccess ? "/SEPA/success.gif" : "/SEPA/fail.png"}
            alt="result"
          />
          <Title size="2rem" mt="1rem" weight="700" ta="center" lh="2.3rem">
            {isSuccess
              ? t("banktransfer.success.title")
              : t("banktransfer.fail.title")}
          </Title>
        </TopSection>
        {isSuccess && (
          <SaveasTempalateWrapper>
            <div
              style={{ marginRight: 8 }}
              // @ts-ignore
              dangerouslySetInnerHTML={{ __html: countrySvg }}
            />
            <Text size="14px" color="#171922" mb="0.2rem" mt="0.3rem">
              {bankTransferData?.firstName} {bankTransferData?.lastName}
            </Text>
            <Text color="#171922">
              {bankTransferData?.creditAmount}{" "}
              {bankTransferData?.creditCurrency}
            </Text>
            {!isTemplateSuccess && !isFromActiveTemplate && (
              <button onClick={addTemplate} className="text-xs pt-2">
                <AddIcon /> {t("banktransfer.result.saveastemplate")}
              </button>
            )}
          </SaveasTempalateWrapper>
        )}
        <Text ta="center" mb="1.5rem" ml="1rem" mr="1rem">
          {isSuccess
            ? t("banktransfer.success.text")
            : t("banktransfer.fail.text")}
        </Text>
        <ButtonsWrapper>
          <BackButton className="btn btn-red" onClick={() => onClose()}>
            {t("common.backtohome")}
          </BackButton>
          <PlainButton className="btn" onClick={handlePdfDownload}>
            {t("common.downloaddocument")}
          </PlainButton>
        </ButtonsWrapper>
      </ContentWrapper>
    </BaseModal>
  );
};

export default ResultModal;
