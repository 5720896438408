import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import moment from "moment";

import { TabletOrMobile } from "../../components/responsive/responsive";
import { langSelector } from "../../redux/selectors";
import { getToken, getUserId } from "../../utils/storage";
import { media } from "../../utils/theme";
import Transactions from "./Transactions";
import { GetIECards, ListTransactionsByDate } from "../../services/Services";
import Accounts from "./Accounts/accounts/accounts";
// import Application from "./Application";
import IntelexpressCreditCard, {
  IECard,
} from "./IntelexpressCard/IntelexpressCard";
import { setActiveIECard } from "../../redux/reportSlice";
import Rates from "./Rates/Rates";
import Templates from "./Templates";

const Wrapper = styled.div`
  flex: 1;

  ${media.lessThan("tablet")} {
  }

  ${media.lessThan("small")} {
    padding-bottom: 4rem;

    .rates {
      margin-top: 24px;
    }
  }
`;

const HomeSection = () => {
  const date1 = moment(new Date()).subtract(15, "days").toISOString();
  const date2 = moment(new Date()).add(4, "hours").toISOString();
  const token = getToken();
  const [transactionList, setTransactionList] = useState<any>([]);
  const { t } = useTranslation();
  const userId = getUserId();
  const [IECards, setIECards] = useState<IECard[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const lang = useSelector(langSelector);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (token && userId) {
      ListTransactionsByDate(date1, date2, userId, "Descending", 11)
        .then((res) => {
          setLoading(false);
          setTransactionList(res.data.Content.Items);
        })
        .catch((err) => {setLoading(false)});
    }
    // eslint-disable-next-line
  }, [token, userId, lang]);

  useEffect(() => {
    if (userId) {
      GetIECards(userId)
        .then((res: any) => {
          setIECards(res.data.Content);
          if (res.data.Content?.length) {
            dispatch(setActiveIECard(res.data.Content[0]));
          }
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  }, [userId]);

  return (
    <Wrapper>
      <Accounts />
      <IntelexpressCreditCard
        IECards={IECards}
        isLoading={isLoading}
        handleRefetch={() =>
          GetIECards(userId)
            .then((res: any) => {
              setIECards(res.data.Content);
              if (res.data.Content?.length) {
                dispatch(setActiveIECard(res.data.Content[0]));
              }
              setIsLoading(false);
            })
            .catch((err) => {
              setIsLoading(false);
            })
        }
      />
      {/* <Mobile>
        <Application />
      </Mobile> */}
      <TabletOrMobile>
        <Templates />
        <div className="rates mt-6 mb-[-8px]">
          <Rates />
        </div>
      </TabletOrMobile>

      <Transactions
        dataLength={transactionList.length}
        data={transactionList.slice(0, 10)}
        title={t("home.lasttransactions")}
        hasShowMore={true}
        isLoading={loading}
      />
    </Wrapper>
  );
};

export default HomeSection;
