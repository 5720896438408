import React from "react";
import { CSSProperties } from "styled-components";


interface ImgProps {
    src: string
    width?: string | number
    height?: string | number
    alt: string
    className?: string
    isAbsolute?: boolean
    style?: CSSProperties | undefined
    onClick?: () => void
}
 

const Img = ({ src, width, height, alt, className, isAbsolute, style, onClick }: ImgProps) => {
    return <img
        className={className}
        src={`${__dirname}assets${src}`}
        height={height}
        width={width}
        style={style}
        alt={alt}
        onClick={onClick}
    />;
};

export default Img;
