import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { balancesSelector, userIdSelector } from "../redux/selectors";
import { getAllBalances } from "../services/Services";
import { setBalances as setReduxBalances } from "../redux/authSlice";
import { Balance as BalanceProps } from "../containers/home/Accounts/accounts/types";
import { formatBalance } from "../utils/helpers";

interface BalanceProp {
  currency?: string;
}


const Balance = ({ currency }: BalanceProp) => {
  const balancesFromRedux = useSelector(balancesSelector);
  const [balances, setBalances] = useState(balancesFromRedux);
  const dispatch = useDispatch();
  const userId = useSelector(userIdSelector);
  const [displayBalance, setDisplayBalance] = useState<any>();

  useEffect(() => {
    if (!balancesFromRedux.length && userId) {
      getAllBalances(userId).then((res) => {
        dispatch(setReduxBalances(res.data.Content));
        setBalances(res.data.Content);
      });
    }
  }, [balancesFromRedux, userId, balances, dispatch]);

  useEffect(() => {
    if (balances?.length) {
      setDisplayBalance(
        balances.find((item: BalanceProps) => item.CurrencyName === currency)
          ?.Balance
      );
    }
  }, [balances, currency]);
 

  return <span>{formatBalance(displayBalance)}</span>;
};

export default Balance;
