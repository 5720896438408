import React, { useCallback, useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import Img from "../../components/Img";
import Text from "../../components/Text/text";
import Select from "../../components/Form/Select";
import InterruptionModal from "../../components/InterruptionModal";
import {
  accountsSelector,
  balancesSelector,
  ratesSelector,
} from "../../redux/selectors";
import {
  setAccounts,
  setBalances as setReduxBalances,
} from "../../redux/authSlice";
import Title from "../../components/Text/title";
import { formatBalance, removeComma, replaceComma } from "../../utils/helpers";
import {
  Account,
  Balance as BalanceProps,
} from "../home/Accounts/accounts/types";
import {
  AmountItem,
  BlockWrapper,
  ButtonWrapper,
  ContentWrapper,
  InputTextStateWrapper,
  SwapButton,
  WhiteWrapper,
  Wrapper,
} from "./Exchange.styled";
import {
  CalculatExchange,
  GetUserAccounts,
  DoExchange,
  getAllBalances,
  getImage,
} from "../../services/Services";
import { getSellRate } from "../../components/SellRate";
import { getBuyRate } from "../../components/BuyRate";
import FlexWrapper from "../../components/FlexWrapper";
import { debounce, getRate, preventNonNumeric } from "../../utils/utils";
import Divider from "../../components/Divider";
import ResultModal from "./ResultModal";
import type { Rate } from "../home/Rates/Rates";
import { getUserId } from "../../utils/storage";

const AMOUNTS = [10, 20, 50, 100];

const Exchange = () => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const dispatch = useDispatch();
  const rates = useSelector(ratesSelector);
  const [accounts, setLocalAccounts] = useState<any>([]);
  const balancesFromRedux = useSelector(balancesSelector);
  const [balances, setBalances] = useState<BalanceProps[]>(balancesFromRedux);
  const [fromValue, setFromValue] = useState<any>("");
  const [toValue, setToValue] = useState<any>("");
  const [curr1, setCurr1] = useState<string>("GEL");
  const [curr2, setCurr2] = useState<string>("USD");
  const [isSuccessOpen, setIsSuccessOpen] = useState<boolean>(false);
  const [isPromptOpen, setIsPromptOpen] = useState<boolean>(false);
  const [inputStatusText, setInpuStatusText] = useState<string>("");
  const [isError, setIsError] = useState(false);
  const accountsFromRedux = useSelector(accountsSelector);
  const userId = getUserId();
  const [rate, setRate] = useState<any>("");
  const debitBalance = formatBalance(
    balances.find((item: BalanceProps) => item.CurrencyName === curr1)?.Balance
  );
  const creditBalance = formatBalance(
    balances.find((item: BalanceProps) => item.CurrencyName === curr2)?.Balance
  );
  const [sendImage, setSendImage] = useState<any>(null);
  const [receiveImage, setReceiveImage] = useState<any>(null);
  const [mode, setMode] = useState("");
  const [balanceError, setBalanceError] = useState("");
  const [transferId, setTransferId] = useState<any>()

  const updateValue = (value: any, type: string) => {
    if (
      String(value).split(".")[1]?.length === 3 ||
      String(value).split(",")[1]?.length === 3
    ) {
      return null;
    } else {
      type === "from" ? setFromValue(value) : setToValue(value);
    }
  };

  useEffect(() => {
    if (rates.length && curr1 && curr2) {
      let rate = rates.find(
        (item: Rate) =>
          item.DebitCurrency === curr1 && item.CreditCurrency === curr2
      )?.SellingRate;

      setRate(rate);
      setInpuStatusText(`${t("exchange.rate")} = ${rate}`);
    }
  }, [rates, t]);

  useEffect(() => {
    if (!balancesFromRedux.length && userId) {
      getAllBalances(userId)
        .then((res) => setBalances(res.data.Content))
        .catch((res) => {});
    }
  }, [balancesFromRedux, userId]);

  useEffect(() => {
    if (!accountsFromRedux.length && userId) {
      GetUserAccounts(userId).then((res) => setLocalAccounts(res.data.Content));
    } else if (accounts?.length) {
      setAccounts(accounts);
    } else {
      setLocalAccounts(accountsFromRedux);
    }
  }, [userId]);

  useEffect(() => {
    if (curr1) {
      getImage(curr1).then((res) => setSendImage(res.data));
    }

    if (curr1 && curr2) {
      let rate = getRate(curr1, curr2, rates);

      setRate(rate);
      setInpuStatusText(`${t("exchange.rate")} = ${rate}`);
    }
  }, [curr1]);

  useEffect(() => {
    if (curr2) {
      getImage(curr2).then((res) => setReceiveImage(res.data));
    }
  }, [curr2]);

  const handleBalanceError = (value?: any, currency?: string) => {
    const balance = balances.find(
      (item: BalanceProps) => item.CurrencyName === (currency || curr1)
    )?.Balance;
 
    if (value !== undefined && balance !== undefined) {
      if (parseFloat(value.toString().replace(" ", "")) > Number(balance)) {
        setBalanceError("debit");
      } else {
        setBalanceError("");
      }
    } else {
      if (fromValue && balance !== undefined) {
    
        if (parseFloat(fromValue.toString().replace(" ", "")) > Number(balance)) {
          setBalanceError("debit");
        } else {
          setBalanceError("");
        }
      }
    }
  };

  useEffect(() => {
    handleBalanceError();
  }, [fromValue]);

  const handleAmountChangeDebounced = useCallback(
    debounce(
      (amount: any, type: string, buyAccountId?: any, sellAccountId?: any) => {
        setIsError(false);
        if (curr1 === curr2) {
          updateValue(amount, "from");
          updateValue(amount, "to");
        } else if (type === "from" && amount) {
 
          if (amount > 0) {
            CalculatExchange(
              sellAccountId,
              buyAccountId,
              userId,
              amount > 0 ? replaceComma(amount) : 0,
              undefined
            )
              .then((res: any) => {
                setToValue(res.data.Content.ExchangedAmount);
                setInpuStatusText(
                  `${t("exchange.rate")} = ${res.data.Content.Rate}`
                );
                setRate(res.data.Content.Rate);
              
              })
              .catch((err) =>
                addToast(<div>{err.response.data.StatusMessage}</div>, {
                  appearance: "error",
                  autoDismiss: true,
                })
              );
          }
        } else if (type === "to" && amount) {
          if (amount > 0) {
            CalculatExchange(
              sellAccountId,
              buyAccountId,
              userId,
              undefined,
              replaceComma(amount)
            )
              .then((res: any) => {
                setFromValue(res.data.Content.ExchangedAmount);
                setInpuStatusText(
                  `${t("exchange.rate")} = ${res.data.Content.Rate}`
                );
                setRate(res.data.Content.Rate);
              })
              .catch((err) =>
                addToast(<div>{err.response?.data?.StatusMessage}</div>, {
                  appearance: "error",
                  autoDismiss: true,
                })
              );
          }
        }
      },
      400
    ),
    []
  );

  const handlePropmtOpen = () => {
    const balance = balances.find(
      (item: BalanceProps) => item.CurrencyName === curr1
    )?.Balance;
    // @ts-ignore
    if (fromValue > balance) {
      setIsError(true);
      return; // @ts-ignore
    } else {
      if (isError) {
        setIsError(false);
      }
      setIsPromptOpen(true);
    }
  };

  const handleAmountChange = (amount: any, type: string) => {
    if (!/^\d+(\.\d{0,2})?$/.test(amount) && amount) {
      return;
    } else {
      if (amount === "") {
        setFromValue("");
        setToValue("");
      } else if (type === "from" && amount) {
        setFromValue(replaceComma(amount));
      } else if (type === "to" && amount) {
        setToValue(replaceComma(amount));
      }

      const sellAccountId = accounts.find(
        (item: Account) => item.CurrencyName === curr1
      ).Id;
      const buyAccountId = accounts.find(
        (item: Account) => item.CurrencyName === curr2
      ).Id;

      handleAmountChangeDebounced(amount, type, buyAccountId, sellAccountId);
    }
  };

  const handleSwap = () => {
    if (accounts?.length) {
      setCurr1(curr2);
      setCurr2(curr1);
      const sellAccountId = accounts.find(
        (item: Account) => item.CurrencyName === curr2
      ).Id;

      const buyAccountId = accounts.find(
        (item: Account) => item.CurrencyName === curr1
      ).Id;
      handleBalanceError(fromValue, curr2);
      setFromValue(toValue);
      // setToValue(from)

      if (curr1 === curr2) {
        updateValue(fromValue, "to");
      } else {
        if (
          toValue !== undefined &&
          fromValue !== undefined &&
          fromValue &&
          fromValue > 0
        ) {
          CalculatExchange(
            sellAccountId,
            buyAccountId,
            userId,
            toValue?.toString().replace(/,/, "."),
            undefined
          )
            .then((res: any) => {
              setToValue(res.data.Content.ExchangedAmount);
              setInpuStatusText(
                `${t("exchange.rate")} = ${res.data.Content.Rate}`
              );
              setRate(res.data.Content.Rate);
            })
            .catch((err) =>
              addToast(<div>{err.response.data.StatusMessage}</div>, {
                appearance: "error",
                autoDismiss: true,
              })
            );
        }
      }
    }
  };

  const handleExchange = () => {
    const sellAccountId = accounts.find(
      (item: Account) => item.CurrencyName === curr2
    ).Id;

    const buyAccountId = accounts.find(
      (item: Account) => item.CurrencyName === curr1
    ).Id;
    if (fromValue !== null) {
      DoExchange(
        userId,
        buyAccountId,
        sellAccountId,
        getBuyRate(rates, curr1, curr2),
        getSellRate(rates, curr1, curr2),
        parseFloat(removeComma(fromValue)),
        parseFloat(removeComma(toValue))
      )
        .then((res) => {
          setTransferId(res.data.Content.TransferId)
          setMode("success");
          setIsSuccessOpen(true);
          setIsPromptOpen(false);
          setFromValue("");
          setToValue("");
          setRate("");
          setTimeout(() => {
            getAllBalances(userId).then((res) => {
              setBalances(res.data.Content)
              dispatch(setReduxBalances(res.data.Content));
            });
          }, 300);
        })
        .catch((err) => {
          setMode("fail");
          setIsSuccessOpen(true);
          setIsPromptOpen(false);
        });
    }
  };

  return (
    <Wrapper>
      <Title
        mb="1.5rem"
        size="1.5rem"
        color="#171922"
        weight={700}
        className="title !ml-0 !mt-[30px] w-[calc(100%-111px)]"
      >
        {t("exchange.title")}
      </Title>

      <WhiteWrapper>
        <ContentWrapper>
          <BlockWrapper>
            <Text color={"#171922"} mb="0.25rem"  size="14px" className="block text-left w-full">
              {t("exchange.fromaccount")}
            </Text>
            <div className="d-flex align-tems-center w-100">
              <span>
                <input
                  autoFocus
                  disabled={(!curr1 && !curr2) || !accounts?.length}
                  onKeyPress={preventNonNumeric}
                  inputMode="numeric"
                  value={fromValue}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    handleAmountChange(e.target.value, "from")
                  }
                />
              </span>
              <Select
                withCountryFlag
                selectImage={sendImage}
                options={accounts?.length ? accounts : []}
                placeholder="GEL"
                timeout={0}
                initialValue={curr1}
                height={184}
                className="select"
                onChange={(val: string) => {
                  if (val === curr2) {
                    handleSwap();
                  } else {
                    setCurr1(val);
                    handleBalanceError(fromValue, val);
                    const sellAccountId = accounts.find(
                      (item: Account) => item.CurrencyName === val
                    ).Id;
                    const buyAccountId = accounts.find(
                      (item: Account) => item.CurrencyName === curr2
                    ).Id;
                    if (fromValue && fromValue > 0) {
                      CalculatExchange(
                        sellAccountId,
                        buyAccountId,
                        userId,
                        fromValue?.toString().replace(/,/, "."),
                        undefined
                      )
                        .then((res: any) => {
                          setToValue(res.data.Content.ExchangedAmount);
                          setRate(res.data.Content.Rate);
                          setInpuStatusText(
                            `${t("exchange.rate")} = ${res.data.Content.Rate}`
                          );
                        })
                        .catch((err) =>
                          addToast(
                            <div>{err.response.data.StatusMessage}</div>,
                            {
                              appearance: "error",
                              autoDismiss: true,
                            }
                          )
                        );
                    }
                  }
                }}
                bodyClass="body"
                idKey="CurrencyName"
                nameKey="CurrencyName"
              />
            </div>
            {balanceError === "debit" && fromValue ? (
              <Text color="var(--red)" mb="0.5rem">
                {t("common.insufficient_account")}
              </Text>
            ) : (
              <Text mb="0.5rem" color="#56575B" size="14px" lh="22px">
                {t("exchange.balancedetails", {
                  amount: debitBalance,
                  currency: curr1,
                })}
              </Text>
            )}

            <FlexWrapper mb="0" gap="8px">
              {AMOUNTS.map((item: number) => (
                <AmountItem
                  key={item}
                  onClick={() => handleAmountChange(item, "from")}
                  isActive={fromValue == item}
                >
                  {formatBalance(item)}
                </AmountItem>
              ))}
            </FlexWrapper>
          </BlockWrapper>
          <div className="middle">
            <FlexWrapper gap="8px" className="wrapper">
              <Divider width="100%" className="divider !ml-2" />
              <SwapButton onClick={handleSwap}>
                <Img src="/exchange/swap.svg" alt="swap" />
              </SwapButton>
              <Text color="#171922" style={{ flexShrink: 0 }}>
                {t("exchange.swapcurrencies")}
              </Text>
              <Divider width="100%" className="divider !mr-2" />
            </FlexWrapper>
            {rate && (
              <InputTextStateWrapper>{inputStatusText}</InputTextStateWrapper>
            )}
          </div>

          <BlockWrapper>
            <Text color={"#171922"} mb="0.25rem" size="14px" className="block text-left w-full">
              {t("exchange.toaccount")}
            </Text>
            <div className="d-flex align-tems-center w-100">
              <span>
                <input
                  disabled={!accounts?.length}
                  value={toValue}
                  inputMode="numeric"
                  onKeyPress={preventNonNumeric}
                  onChange={(e: any) =>
                    handleAmountChange(e.target.value, "to")
                  }
                />
              </span>{" "}
              <Select
                withCountryFlag
                height={184}
                selectImage={receiveImage}
                timeout={0}
                options={accounts}
                placeholder="USD"
                initialValue={curr2} 
                className="select"
                onChange={(val: string) => {
                  if (val === curr1) {
                    handleSwap();
                  } else {
                    setCurr2(val);
                    handleBalanceError(fromValue, curr1);
                    if (curr1 && val) {
                      let rate = getRate(curr1, val, rates);
                      setRate(rate);
                      setInpuStatusText(`${t("exchange.rate")} = ${rate}`);
                    }
                    const sellAccountId = accounts.find(
                      (item: Account) => item.CurrencyName === curr1
                    ).Id;

                    const buyAccountId = accounts.find(
                      (item: Account) => item.CurrencyName === val
                    ).Id;
                    if (toValue) {
                      if (fromValue > 0) {
                        CalculatExchange(
                          sellAccountId,
                          buyAccountId,
                          userId,
                          fromValue?.toString().replace(/,/, "."),
                          undefined
                        )
                          .then((res: any) => {
                            setToValue(res.data.Content.ExchangedAmount);
                            setInpuStatusText(
                              `${t("exchange.rate")} = ${res.data.Content.Rate}`
                            );
                            setRate(res.data.Content.Rate);
                          })
                          .catch((err) =>
                            addToast(
                              <div>{err.response.data.StatusMessage}</div>,
                              {
                                appearance: "error",
                                autoDismiss: true,
                              }
                            )
                          );
                      }
                    }
                  }
                }}
                bodyClass="body"
                idKey="CurrencyName"
                nameKey="CurrencyName"
              />
            </div>

            {balanceError === "credit" ? (
              <Text color="var(--red)" mb="0.5rem">
                {t("common.insufficient_account")}
              </Text>
            ) : (
              <Text mb="0.5rem" lh="22px" color="#56575B" size="14px">
                {t("exchange.balancedetails", {
                  amount: creditBalance,
                  currency: curr2,
                })}
              </Text>
            )}
            <FlexWrapper mb="0" gap="8px">
              {AMOUNTS.map((item: number) => (
                <AmountItem
                  key={item}
                  onClick={() => handleAmountChange(item, "to")}
                  isActive={toValue == item}
                >
                  {formatBalance(item)}
                </AmountItem>
              ))}
            </FlexWrapper>
          </BlockWrapper>
        </ContentWrapper>
        <ButtonWrapper>
          <button
            disabled={!fromValue} 
            className="btn btn-red uppercase !rounded"
            onClick={() => handlePropmtOpen()}
          >
            {t("exchange.confirm")}
          </button>
        </ButtonWrapper>
      </WhiteWrapper>
      <ResultModal
        mode={mode}
        isOpen={isSuccessOpen}
        onClose={() => setIsSuccessOpen(false)}
        transferId={transferId}
      />
      <InterruptionModal
        title={t("exchange.mainTitle")}
        text={t("exchange.modaldesc")}
        isOpen={isPromptOpen}
        onCloseClick={() => setIsPromptOpen(false)}
        onStayClick={() => handleExchange()}
        actionText={t("common.proceed")}
      />
    </Wrapper>
  );
};

export default Exchange;
