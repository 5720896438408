import { useTranslation } from "react-i18next";

import Box from "../../../../components/Tailwind/Box";
import Text from "../../../../components/Text/text";
import Title from "../../../../components/Text/title";
// import Input from "../../../../../components/Tailwind/Input";
import { useEffect, useState } from "react";
import { FORM_PATTERNS } from "../../../../constants";
import Checkmark from "../../../../components/Icons/Checkmark";
import styled from "styled-components";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { setRegistrationData } from "../../../../redux/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { registrationDataSelector } from "../../../../redux/selectors";
import FlexWrapper from "../../../../components/FlexWrapper";
import { Link } from "react-router-dom";
import Input from "../../../../components/Form/Input";
import { checkUserName } from "../../../../services/Services";
import { useToasts } from "react-toast-notifications";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { setLoading } from "../../../../redux/commonSlice";

export const StatusItem = styled.div<{ filled?: any }>`
  display: flex;
  align-items: center;
  padding: 2px;
  font-size: 14px;

  ${({ filled }) => filled && "span{color: #171922;}"}
`;

const Password = ({ onNextPress, onBackClick }: any) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [userName, setUserName] = useState("");
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const { addToast } = useToasts();
  const passed =
    FORM_PATTERNS.uppercase.value.test(password) &&
    FORM_PATTERNS.lowercase.value.test(password) &&
    FORM_PATTERNS.oneDigit.value.test(password) &&
    FORM_PATTERNS.minEightChars.value.test(password) &&
    FORM_PATTERNS.nonAlphabetic.value.test(password);

  const registrationData = useSelector(registrationDataSelector);

  useEffect(() => {
    if (registrationData?.password) {
      setPassword(registrationData?.password);
    }

    if (registrationData?.userName) {
      setUserName(registrationData?.userName);
    }
  }, [registrationData]);

  useEffect(() => {
    if (passed) {
      window.addEventListener("keydown", handleUserKeyPress);

      return () => {
        window.removeEventListener("keydown", handleUserKeyPress);
      };
    }
  }, [passed]);

  const handleUserKeyPress = (event: any) => {
    const { keyCode } = event;

    if (keyCode === 13) {
      handleNext();
    }
  };

  const handleNext = () => {
    dispatch(setLoading(true));
    checkUserName(userName)
      .then((res) => {
        dispatch(setLoading(false));
        dispatch(setRegistrationData({ password: password, userName }));
        onNextPress();
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (err.response.data.errors) {
          return Object.values(err.response.data.errors).map((item: any) => {
            addToast(<div>{item}</div>, {
              appearance: "error",
              autoDismiss: true,
            });
          });
        } else {
          addToast(<div>{err.response.data.StatusMessage}</div>, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  };

  return (
    <>
      <Box className="mx-auto  w-[480px]  max-w-[100%]">
        <div onClick={onBackClick}>
          <FlexWrapper mb="1rem" gap="0.5rem" className="pointer">
            <ArrowBackOutlinedIcon />{" "}
            <Title mb="0" className="capitalize">
              {t("common.back")}
            </Title>
          </FlexWrapper>{" "}
        </div>
        <Title size="1.5rem" weight="700" lh="24px" mb="32px" mt="1rem">
          {t("registration.youraccount")}
        </Title>
        <div className="[@media(min-width:554px)]:px-4">
          <Input
            autoComplete="one-time-code"
            label={`${t("registration.username")} *`}
            className="mb-4"
            value={userName}
            onKeyPress={(e: any) => {
              console.log(e.key);
              if (e.which === 32) {
                e.preventDefault();

                return false;
              }
            }}
            onChange={(e: any) => setUserName(e.target.value.toUpperCase())}
          />
          <div className="relative mt-5">
            <Input
              autoComplete="one-time-code"
              type={showPassword ? "text" : "password"}
              label={`${t("common.password")} *`}
              value={password}
              onKeyPress={(e: any) => {
                console.log(e.key);
                if (e.which === 32) {
                  e.preventDefault();
  
                  return false;
                }
              }}
              onChange={(e: any) => setPassword(e.target.value)}
            />
            <div className="absolute right-6 top-2">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? (
                  <VisibilityOffOutlinedIcon />
                ) : (
                  <VisibilityOutlinedIcon />
                )}
              </IconButton>
            </div>
          </div>

          <Text
            className="px-4"
            mb="0.5rem"
            mt="-0.25rem"
            color="#344054"
            size="14px"
          >
            {t("registration.passwordtext")}
          </Text>

          {/* <Title size="14px">{t("register.indicatortitle")}</Title> */}
          <StatusItem
            filled={
              password && password.match(FORM_PATTERNS.minEightChars.value)
            }
          >
            {password && password.match(FORM_PATTERNS.minEightChars.value) ? (
              <div className="w-[26px] h-[26px] rounded bg-[#0FBD5B] mr-2 flex items-center justify-center">
                <CheckOutlinedIcon style={{ fill: "#fff", width: 18 }} />
              </div>
            ) : (
              <div className="w-[26px] h-[26px]  mr-2 flex items-center justify-center">
                <RemoveOutlinedIcon style={{ width: 18 }} />
              </div>
            )}
            <span> {t("register.indicator1")}</span>
          </StatusItem>
          <StatusItem
            filled={password && password.match(FORM_PATTERNS.uppercase.value)}
          >
            {password && password.match(FORM_PATTERNS.uppercase.value) ? (
              <div className="w-[26px] h-[26px] rounded bg-[#0FBD5B] mr-2 flex items-center justify-center">
                <CheckOutlinedIcon style={{ fill: "#fff", width: 18 }} />
              </div>
            ) : (
              <div className="w-[26px] h-[26px]  mr-2 flex items-center justify-center">
                <RemoveOutlinedIcon style={{ width: 18 }} />
              </div>
            )}

            <span>{t("register.indicator2")} [A-Z] </span>
          </StatusItem>
          <StatusItem
            filled={password && password.match(FORM_PATTERNS.lowercase.value)}
          >
            {password && password.match(FORM_PATTERNS.lowercase.value) ? (
              <div className="w-[26px] h-[26px] rounded bg-[#0FBD5B] mr-2 flex items-center justify-center">
                <CheckOutlinedIcon style={{ fill: "#fff", width: 18 }} />
              </div>
            ) : (
              <div className="w-[26px] h-[26px]  mr-2 flex items-center justify-center">
                <RemoveOutlinedIcon style={{ width: 18 }} />
              </div>
            )}
            <span> {t("register.indicator3")} [a-z]</span>
          </StatusItem>
          <StatusItem
            filled={password && password.match(FORM_PATTERNS.oneDigit.value)}
          >
            {password && password.match(FORM_PATTERNS.oneDigit.value) ? (
              <div className="w-[26px] h-[26px] rounded bg-[#0FBD5B] mr-2 flex items-center justify-center">
                <CheckOutlinedIcon style={{ fill: "#fff", width: 18 }} />
              </div>
            ) : (
              <div className="w-[26px] h-[26px]  mr-2 flex items-center justify-center">
                <RemoveOutlinedIcon style={{ width: 18 }} />
              </div>
            )}
            <span>{t("register.indicator4")} [0 - 9]</span>
          </StatusItem>
          <StatusItem
            filled={
              password && password.match(FORM_PATTERNS.nonAlphabetic.value)
            }
          >
            {password?.match(FORM_PATTERNS.nonAlphabetic.value) ? (
              <div className="w-[26px] h-[26px] rounded bg-[#0FBD5B] mr-2 flex items-center justify-center">
                <CheckOutlinedIcon style={{ fill: "#fff", width: 18 }} />
              </div>
            ) : (
              <div className="w-[26px] h-[26px]  mr-2 flex items-center justify-center">
                <RemoveOutlinedIcon style={{ width: 18 }} />
              </div>
            )}

            <span>{t("register.indicator5")} [e.g: !$#@]</span>
          </StatusItem>
        </div>

        <button
          disabled={!passed}
          className="mt-6 bg-[var(--red)] text-white w-full py-3 rounded-lg capitalize disabled:bg-[#F2F4F7] disabled:text-[#98A2B3] h-11 font-semibold"
          onClick={handleNext}
        >
          {t("common.continue")}
        </button>
      </Box>
      <FlexWrapper justify="center" mt="2rem">
        <Text lh="14px" size="14px">
          {t("register.already")}
        </Text>
        <Link
          to="/login"
          className="underline ml-1 text-[12px] leading-1 color-[#171922] font-bold uppercase"
        >
          {t("login.logIn")}
        </Link>
      </FlexWrapper>
    </>
  );
};

export default Password;
