import styled from "styled-components";
import { useEffect, useState } from "react";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

import BaseModal from "./BaseModal";
import Title from "./Text/title";
import Text from "./Text/text";
import FlexWrapper from "./FlexWrapper";
import { useNavigate } from "react-router-dom";
import { LogoutService, checkToken } from "../services/Services";
import { useTranslation } from "react-i18next";
import axios from "axios";
import {
  deleteToken,
  getRefreshToken,
  getToken,
  saveStorageObject,
} from "../utils/storage";
import { useDispatch } from "react-redux";
import AxiosInstance, {
  setAuthorizationToken,
  unSetAuthorizationToken,
} from "../services/axios";
import { setToken } from "../redux/authSlice";

interface SessionModalProps {
  isOpen: boolean;
  onClose: () => void;
  onContinue: (chosenItem: any) => void;
}

const Wrapper = styled.div`
  padding: 24px 0 0;
  width: 400px;
  max-width: 100%;
  border-radius: 20px;
  height: auto;

  .btn {
    width: 50%;
  }
`;
const DELAY = 60;

const SessionModal = ({ isOpen, onClose }: SessionModalProps) => {
  const [timeLeft, setTimeLeft] = useState<number>(DELAY);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      if (timeLeft === 0) {
        handleLogout();
      }

      if (!timeLeft) return;

      const intervalId = setInterval(() => {
        setTimeLeft(timeLeft - 1);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [timeLeft, isOpen]);

  useEffect(() => {
    if (isOpen) {
      setTimeLeft(DELAY);
    }
  }, [isOpen]);

  const handleLogout = () => {
    LogoutService().then(() => {});
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/login");
  };

  const handleCheck = () => {
    checkToken()
      .then((resp) => {
        onClose();
      })
      .catch((err) => {
        if (err.response.status === 428) {
          axios
            .post(
              `https://coreapi.inexpay.ge/api/authentication/refreshtoken`,
              { token: getToken(), refreshToken: getRefreshToken() },
              {}
            )
            .then((res) => {
              if (res.status === 200 && res.data && res.data.Content.Token) {
                saveStorageObject("token", res.data.Content.Token);
                saveStorageObject(
                  "refreshtoken",
                  res.data.Content.RefreshToken
                );

                setAuthorizationToken(res.data.Content.Token);

                setTimeout(() => {
                  dispatch(setToken(res.data.Content.Token));
                }, 1000);
                onClose();
              }
            })
            .catch(() => {
              unSetAuthorizationToken();
              deleteToken();
              localStorage.removeItem("refreshtoken");
              return window.location.reload();
            });
        }
      });
  };

  return (
    <BaseModal isOpen={isOpen} customClose={() => onClose()}>
      <Wrapper>
        <div className="px-6">
          <div className="w-12 h-12 mb-3 rounded-full bg-[#FEE4E2] flex items-center justify-center">
            <LogoutOutlinedIcon style={{ fill: "#E02629" }} />
          </div>

          <Title color="#101828" size="18px" weight="600" mb="4px" lh="28px">
            {t("sessionmodal.title")}
          </Title>
          <Text color="#475467" size="14px" lh="20px">
            {t("sessionmodal.text")}
          </Text>
          <FlexWrapper mt="2rem" mb="1.5rem">
            <button className="btn uppercase" onClick={handleLogout}>
              {t("common.cancel")}
            </button>
            <button
              className="btn btn-red uppercase p-4"
              onClick={() => {
                handleCheck();
              }}
            >
              {t("sessionmodal.stayloggedin")}
            </button>
          </FlexWrapper>
        </div>

        <div className="bg-[#F4F4F4] text-center rounded-br-lg rounded-bl-lg font-[13px] text-[#746A6A] py-1">
          {t("sessionmodal.logoutin")}{" "}
          <strong className="text-[#231B38]">
            00:{timeLeft < 10 && "0"}
            {timeLeft}
          </strong>
        </div>
      </Wrapper>
    </BaseModal>
  );
};

export default SessionModal;
