import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import FlexWrapper from "../../../../components/FlexWrapper";
import Title from "../../../../components/Text/title";
import Text from "../../../../components/Text/text";
import Divider from "../../../../components/Divider";
import Input from "../../../../components/Form/Input";
import {
  billPaymentDataSelector,
  userIdSelector,
} from "../../../../redux/selectors";
import {
  GetFavouriteAccount,
  calculateService,
} from "../../../../services/Services";
import { preventNonNumeric } from "../../../../utils/utils";
import Img from "../../../../components/Img";
import { Account } from "../../../home/Accounts/accounts/types";
import { formatBalance, getCurrencySymbol, replaceComma } from "../../../../utils/helpers";
import { setBillTransferData } from "../../../../redux/billSlice";
import AccountsModal from "../../../../components/AccountsModal";
import Balance from "../../../../components/Balance";
import { setLoading } from "../../../../redux/commonSlice";
import { useToasts } from "react-toast-notifications";
import Star from "../../../../components/Icons/Star";
import { media } from "../../../../utils/theme";

interface DetailsProps {
  disabled?: boolean;
  onConfirmPress?: () => void;
  onPreviousPress?: () => void;
  isFromActiveTemplate?: boolean;
  serviceInfo: any;
  config?: any;
}

const InfoWrapper = styled.div`
  width: 500px;
  margin: 0 auto;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  margin-top: 1.5rem;
  padding: 1rem;

  .Mui-disabled {
    background: #e6e6e6;
  }

  button {
    margin: 0 auto;
    min-width: 300px;
  }

  ${media.lessThan("small")} {
    width: 100%;
    padding-bottom: 4rem;
  }
`;

const ServiceInfo = styled.div`
  padding: 0 1rem 0 0;
  border-radius: 4px;
  min-width: 50%;
  border: 1px solid #e4e4e4;
  margin-bottom: 16px;
  background: #fbfbfb;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
`;

export const AccountWrap = styled.div<{ insufficient?: boolean }>`
  background: #fbfbfb;
  padding: 1rem;
  cursor: pointer;
  border-radius: 4px;
  margin-bottom: 0.5rem;

  &:hover {
    background: #f7f7f7;
  }

  ${({ insufficient }) =>
    insufficient &&
    `
    background: #FCE9E9;
    color: var(--red) !important;

    div, p {
        color: var(--red) !important;
    }
  `}
`;
const initialAmount = (amount: number) => {
  if (amount < 0) {
    return "";
  } else if (amount !== 0) {
    return amount.toString();
  }

  return "";
};

const Info = ({ onConfirmPress, serviceInfo, config }: DetailsProps) => {
  const { t } = useTranslation();
  const userId = useSelector(userIdSelector);
  const billData = useSelector(billPaymentDataSelector);
  const [amount, setAmount] = useState(initialAmount(billData?.Debt) || "");
  const [calculateResponse, setCalculateResponse] = useState<any>();
  const [chosenAccount, setChosenAccount] = useState<Account>();
  const dispatch = useDispatch();
  const [isAccountsOpen, setIsAccountsOpen] = useState(false);
  const { addToast } = useToasts();
  const params = config?.Items?.map((item: any, index: number) => {
    return {
      key: item.RequestFieldName,
      value: serviceInfo.Abonent.split("/").reverse()[index],
    };
  });

  useEffect(() => {
    if (amount && chosenAccount) {
      dispatch(setLoading(true));
      calculateService(
        userId,
        serviceInfo.ServiceId,
        params,
        // [
        //   {
        //     key:
        //       config?.Items[0].RequestFieldName ||
        //       serviceInfo?.Parameters[0]?.Key,
        //     value: serviceInfo.Abonent || serviceInfo?.Parameters[0]?.Value,
        //   },
        // ],
        amount,
        // @ts-ignore
        chosenAccount?.Id
      )
        .then((res) => {
          dispatch(setLoading(false));
          setCalculateResponse(res.data.Content);
        })
        .catch((err) => {
          addToast(<div>{err.response.data.StatusMessage}</div>, {
            appearance: "error",
            autoDismiss: true,
          });
          dispatch(setLoading(false));
        });
    }
  }, [amount, chosenAccount]);

  useEffect(() => {
    if (userId) {
      GetFavouriteAccount(userId).then((res) =>
        setChosenAccount(res.data.Content)
      );
    }
  }, [userId]);

  const onReview = () => {
    dispatch(
      setBillTransferData({
        amount,
        fee: calculateResponse?.Fee,
        currency: chosenAccount?.CurrencyName,
        RequestId: calculateResponse.RequestId,
        calculateCode: calculateResponse.Code,
      })
    );
    onConfirmPress && onConfirmPress();
  };

  return (
    <InfoWrapper>
      <AccountWrap onClick={() => setIsAccountsOpen(true)}>
        <FlexWrapper gap="0.5rem" mb="0" style={{ flex: 1 }}>
          <FlexWrapper gap="0.5rem" style={{ flex: 1 }} mb="0">
            <span>
              <Img src="/home/greece-wallet.svg" alt="wallet" />
            </span>
            <div>
              <Text
                weight="500"
                color="var(--text-color)"
                size="14px"
                mb="0.2rem"
              >
                {chosenAccount?.CustomAccountName}
              </Text>
              <Text weight="500" color="#949494" mb="0.2rem">
                {chosenAccount?.WalletName}
              </Text>
              <Text weight="700" color="var(--text-color)" size="16px">
                {getCurrencySymbol(chosenAccount?.CurrencyName)}{" "}
                <Balance currency={chosenAccount?.CurrencyName} />
              </Text>
            </div>
          </FlexWrapper>

          <Star />
          <div>
            <Img src="/billpayments/arrows-down.svg" alt="arrows" />
          </div>
        </FlexWrapper>
      </AccountWrap>
      <div className="px-4">
        <Text size="14px" color="#CDCDCD" mb="0.25rem">{t("billpayments.abonentnumber")}</Text>
        <div className="relative mb-4">
          <Input
            disabled
            value={
              (billData?.Parameters && billData?.Parameters[0]?.Value) ||
              billData?.Abonent
            }
          />
          <object
            className="absolute right-1 top-1"
            width="50px"
            data={billData.ServiceId === "40_2" || billData.ServiceId === "40_4" ? "https://coreapi.inexpay.ge/api/image/bill/subcategory/policefine" : `https://files2.mp.ge/uploads/comunalservices/${billData.ServiceId}.png`}
            type="image/png"
          >
            <Img
              width={24}
              style={{ marginLeft: 12, marginBottom: 6, marginTop: 12 }}
              src="/billpayments/image-fill.svg"
              alt="fallback"
            />
          </object>
        </div>

        <ServiceInfo>
          <FlexWrapper mb="0">
            <div>
              <object
                width="60px"
                data={`https://files2.mp.ge/uploads/comunalservices/${billData.ServiceId}.png`}
                type="image/png"
              >
                <Img
                  width={36}
                  style={{ marginLeft: 12, marginBottom: 6, marginTop: 12 }}
                  src="/billpayments/image-fill.svg"
                  alt="fallback"
                />
              </object>
            </div>
            <div>
              <Text color="#171922">{billData.ServiceName}</Text>
              <Text>{billData.AbonentName}</Text>
              <Text>
                {(billData?.Parameters && billData?.Parameters[0]?.Value) ||
                  billData?.Abonent}
              </Text>
              {billData.Info && <Text>{billData.Info}</Text>}
            </div>
          </FlexWrapper>
          <div>
            <Text color={billData.Debt < 0 ? "#0FBD5B" : "var(--red)"}>
              {billData.Debt > 0 && "-"}
              {billData.Debt < 0 && "+"}
              {billData.Debt < 0
                ? formatBalance(billData?.Debt?.toString().replace("-", ""))
                : formatBalance(billData?.Debt)}
            </Text>
          </div>
        </ServiceInfo>
        <Input
          label={t("billpayments.paymentamount")}
          value={amount}
          onChange={(e: any) => {
            setAmount(replaceComma(e.target.value));
          }}
          onKeyPress={preventNonNumeric}
        />
        <Divider width="100%" mb="0.5rem" mt="1rem" />

        {calculateResponse?.Fee ? (
          <FlexWrapper mb="1.5rem">
            <Text>{t("common.fee")}: </Text>{" "}
            <Title ml="0.25rem" size="1.25rem" weight="700">
              {calculateResponse?.Fee} {chosenAccount?.CurrencyName}
            </Title>
          </FlexWrapper>
        ) : (
          <FlexWrapper mb="1.5rem"></FlexWrapper>
        )}
      </div>
 
      <button
        disabled={!calculateResponse?.RequestId || chosenAccount?.CurrencyName !== "GEL"}
        className="btn btn-red button h-10 text-[14px]"
        onClick={onReview}
      >
        {t("billpayments.reviewandpay")}
      </button>

      <AccountsModal
        isOpen={isAccountsOpen}
        onClose={() => setIsAccountsOpen(false)}
        onContinue={(account: Account) => {
          setIsAccountsOpen(false);
          setChosenAccount(account);
        }}
      />
    </InfoWrapper>
  );
};

export default Info;
