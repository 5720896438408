import { IconProps } from "./types";

export const Messages = ({ fill, width, height, ...rest }: IconProps) => {
    return (
        <svg width={width || "17"} height={height || "14"} viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4.04548 13.1183C4.36189 13.1183 4.63874 12.9827 5.0286 12.6493L6.85359 11.0221C7.26604 11.4572 7.89321 11.6945 8.67857 11.6945H10.6053L12.4867 13.3048C12.8597 13.6212 13.1139 13.7963 13.419 13.7963C13.8654 13.7963 14.1309 13.4799 14.1309 12.9884V11.6945H14.3852C15.809 11.6945 16.7582 10.8187 16.7582 9.34968V5.91441C16.7582 4.42278 15.7921 3.54136 14.3456 3.54136H13.3682V3.16281C13.3682 1.62598 12.4302 0.716309 10.8821 0.716309H2.6499C1.14697 0.716309 0.158203 1.62598 0.158203 3.16281V8.45131C0.158203 9.96554 1.13002 10.8639 2.61035 10.8639H3.32227V12.316C3.32227 12.8132 3.58782 13.1183 4.04548 13.1183ZM4.32234 10.3271C4.32234 9.98814 4.11328 9.81864 3.83643 9.81864H2.7742C1.83064 9.81864 1.26562 9.28753 1.26562 8.30441V3.28146C1.26562 2.29269 1.83629 1.76158 2.7742 1.76158H10.7522C11.6957 1.76158 12.2607 2.29269 12.2607 3.28146V3.54136H8.67857C7.17564 3.54136 6.26032 4.42278 6.26032 5.91441V9.35533C6.26032 9.59828 6.28292 9.82429 6.33378 10.0333L4.32234 11.9092V10.3271ZM13.1365 12.5872L11.3567 10.9769C11.1138 10.7566 10.9104 10.6605 10.5827 10.6605H8.79157C7.89886 10.6605 7.3508 10.1633 7.3508 9.21973L7.35645 6.01611C7.35645 5.0782 7.89886 4.56968 8.79157 4.56968H14.227C15.1253 4.56968 15.6678 5.0782 15.6678 6.01611V9.21973C15.6678 10.1576 15.1197 10.6605 14.227 10.6605H13.6224C13.3456 10.6605 13.1365 10.8244 13.1365 11.1634V12.5872ZM11.5149 8.27051C11.7974 8.27051 11.9556 8.11231 11.9669 7.81285L12.046 5.97656C12.0573 5.65451 11.8257 5.4398 11.5093 5.4398C11.1929 5.4398 10.9669 5.65451 10.9838 5.97091L11.0573 7.81285C11.0686 8.10666 11.2268 8.27051 11.5149 8.27051ZM11.5149 9.99379C11.8539 9.99379 12.1251 9.75084 12.1251 9.41748C12.1251 9.08978 11.8539 8.84682 11.5149 8.84682C11.1646 8.84682 10.8991 9.09542 10.8991 9.41748C10.8991 9.74519 11.1703 9.99379 11.5149 9.99379Z" fill={fill || "var(--red)"} />
        </svg>
    );
};

export default Messages;
