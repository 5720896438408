import styled from "styled-components";
import { media } from "../../utils/theme";

export const ContentWrapper = styled.div`
  margin-top: 0.5rem;

  .text-right {
    text-align: right;
  }
`;

export const TableHeader = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  padding: 1rem 0.5rem;
  font-size: 0.875rem;
  background: #fcfcfc;
  border: 0.5px solid #e4e4e4;
  border-radius: 4px;

  div {
    flex: 1;
  }
`;

export const NoTransfersWrapper = styled.div`
  border: 1px solid #ebebeb;
  border-radius: 8px;
  background: #fff;
  padding: 0.75rem 0.5rem;
  height: 550px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem;



  ${media.lessThan("small")} {
    border: 0.5px solid #e4e4e4;

    div {
      padding-right:8px;
      padding-left:8px;
    }
  }
`;

export const ListWrapper = styled.ul`
  .box {
    padding: 0px !important;
  }
  li {
    position: relative;
    padding: 0.5rem 0.75rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.75rem;
    line-height: 1rem;
    cursor: pointer;
    border-radius: 4px;

    .circle {
      background: #f7f7f7;
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-right: 8px;
    }

    &:hover {
      background: #e4e4e4 !important;
    }

    .amount {
      color: var(--green);
    }
  }

  ${media.lessThan("tablet")} {
    li {
      height: 64px;
    }
  }

  ${media.lessThan("small")} {
    li {
      padding-top: 1rem;
      position: relative;
      height: auto;
    }

    li:nth-child(2n + 1) {
      background: #fcfcfc;
    }
  }
`;

export const PicCircle = styled.div`
  margin-right: 0.4rem;
`;
