import { createSlice } from "@reduxjs/toolkit";

const sepaSlice = createSlice({
  name: "SEPA",
  initialState: {
    activeSEPATab: null,
    activeSEPATemplate: {},
    sepaTemplates: null,
    sepaTransferData: {},
    bankTransferConfigData: {},
    bankTransferData: {},
  },
  reducers: {
    setActiveSEPATab(state, { payload }) {
      state.activeSendTab = payload;
    },
    setActiveSEPATemplate(state, { payload }) {
      state.activeSEPATemplate = payload;
    },
    setSEPATransferData(state, { payload }) {
      if (payload.reset) {
        state.sepaTransferData = {};
      } else {
        state.sepaTransferData = {
          ...state.sepaTransferData,
          ...payload,
        };
      }
    },
    setSEPATemplates(state, { payload }) {
      state.sepaTemplates = payload;
    },
    setBankTransferConfigData(state, { payload }) {
      state.bankTransferConfigData = payload;
    },
    setBankTransferData(state, { payload }) {
        if (payload.reset) {
          state.bankTransferData = {};
        } else {
          state.bankTransferData = {
            ...state.bankTransferData,
            ...payload,
          };
        }
      },
  },
});

export const {
  setActiveSEPATab,
  setSEPATransferData,
  setActiveSEPATemplate,
  setSEPATemplates,
  setBankTransferConfigData,
  setBankTransferData
} = sepaSlice.actions;

export default sepaSlice.reducer;
