import styled from "styled-components";

import { media } from "../../utils/theme";

export const Wrapper = styled.div`
  ${media.lessThan("tablet")} {
    margin-right: 0;

    .title {
      font-size: 1.5rem;
      margin-left: 1rem;
      margin-top: 1.25rem;
    }
  }

  ${media.lessThan("small")} {
    margin-top: 0;
  }
`;

export const ContentWrapper = styled.div`
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  gap: 8px;

  .divider {
    display: block;
    min-width: 80px;
  }

  .middle {
    flex-grow: 0.6;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    .divider {
      width: 40%;
    }
  }

  ${media.size("large")} {
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }

  ${media.lessThan("tablet")} {
    flex-direction: column;
    justify-content: space-between;
    margin-top: 0;

    .middle {
      width: 100%;
    }

    .middle .wrapper {
      justify-content: center;
    }

    .divider {
      width: 40%;
    }
  }

  ${media.lessThan("small")} {
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
`;

export const SwapButton = styled.button`
  background: #f9d4d4;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  width: 32px;
  height: 32px;
  cursor: pointer;
  transition: all 0.25s;
  outline: none;
  border: none;
  flex-shrink: 0;

  &:hover {
    opacity: 0.9;
  }

  ${media.lessThan("small")} {
    img {
      // transform: rotate(90deg);
    }
  }
`;

export const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0 0 0.5rem;
  background: #fff;

  .select {
    width: 120px;
  }

  .body {
    width: 130px;
    margin-top: 4px;
  }

  .dd-header {
    border: 1px solid #cbcbcb;
    border-left: 0;
    border-radius: 0 8px 8px 0;
    margin-bottom: 8px;
    padding-right: 10px;

    & > div {
      border: none;
      border-radius: 0 8px 8px 0;
      padding: 0.68rem 0.5rem;
      font-weight: 600;
      font-size: 22px;
    }

    .svgImage svg {
      margin-right: 0;
    }
  }

  input {
    border: none;
    border: 1px solid #cbcbcb;
    padding: 0.84rem;
    flex: 1;
    font-size: 24px;
    color: #171922;
    // max-width: 190px;
    background: #fff;
    border-radius: 8px 0 0 8px;
    border-right: none;
    font-weight: bold;

    &: [disabled] {
      opacity: 0.5;
    }
  }

  @media (min-width: 1700px) {
    input {
      max-width: 260px;
      height: 61px;
    }

    .dd-header{
    height: 61px;
    }
  }

  @media (min-width: 1200px) and (max-width: 1700px) {
    padding: 0.5rem;

    input {
      width: 180px;
      height: 54px;
    }

    .select {
      width: 100px;
    }

    .dd-header{
    height: 54px;
    padding-right: 0px;
  
    }
  }

  @media (max-width: 1200px) {
    input,
     .dd-header{ {
      height: 54px;
    }

 
  }

  ${media.lessThan("tablet")} {
    width: 100%;
    display: flex;
    align-items: center;

    .align-tems-center {
      margin: auto;
      width: auto;
    }

    input {
      max-width: 100%;
      width: 100%;
    }
  }

  ${media.lessThan("small")} {
    .select {
      flex-shrink: 0;
    }
  }

  @media (min-width: 1680px) and (max-width: 1920px) {
    input {
      height: 54px;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  button {
    font-size: 0.875rem;
    min-width: 254px;
    padding: 12px;
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    button {
      min-width: 180px;
    }
  }

  ${media.size("large")} {
    justify-content: center;
    margin-top: 1rem;
  }

  ${media.lessThan("small")} {
    button {
      margin-top: 1rem;
      width: 100%;
      padding: 12px 10px;
    }
  }
`;

export const Balance = styled.div`
  margin-left: 1rem;

  p {
    font-size: 0.75rem;
    display: flex;
    align-items: center;
    margin-bottom: 0.25rem;

    img {
      margin-right: 0.35rem;
    }
  }

  span {
    color: var(--red);
    margin-left: 0.3rem;
    line-height: 1.1rem;
    font-weight: bold;
  }

  div {
    color: var(--text-color-2);
    font-size: 0.875rem;
  }
`;

export const InputTextStateWrapper = styled.p<{ isError?: boolean }>`
  color: ${(p) => (p.isError ? "var(--red)" : "var(--blue)")};
  font-size: 0.75rem;
  margin-top: 0.5rem;
  text-align: center;
`;

export const AmountItem = styled.div<{ isActive: boolean }>`
  height: 32px;
  padding: 6px 16px;
  border-radius: 4px;
  border: 1px solid #79747e;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;

  :hover {
    cursor: pointer;
    background: #212121;
    color: #fff;
  }

  ${({ isActive }) =>
    isActive &&
    `  cursor: pointer;
  background: #212121;
  color: #fff;`}

  ${media.lessThan("small")} {
    padding: 6px 14px;
  }
`;

export const WhiteWrapper = styled.div`
  background: #fff;
  padding: 24px;
  border-radius: 8px;
  border: 1px solid #ebebeb;

  ${media.lessThan("small")} {
    padding-bottom: 6rem;
  }
`;
