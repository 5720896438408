import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Img from "../../../components/Img";
import Title from "../../../components/Text/title";
import Text from "../../../components/Text/text";
import BaseModal from "../../../components/BaseModal";
import {
  BackButton,
  ButtonsWrapper,
  PlainButton,
  TopSection,
} from "./VerificationResultModal.styled";
import { ContentWrapper } from "./Registration.styled";
// import { ContentWrapper } from "../Registration.styled";


interface ResultModalProps {
  isOpen: boolean;
  onClose: () => void;
  imgSrc?: string;
  mode?: string;
}

const VerificationResultModal = ({
  isOpen,
  onClose,
  mode,
}: ResultModalProps) => {
  const { t } = useTranslation();
  const isSuccess = mode === "success";
  const navigate = useNavigate();

  return (
    <BaseModal isOpen={isOpen} onRequestClose={() => onClose()}>
      <ContentWrapper className="!max-w-[410px]">
        <TopSection>
          <Img
            src={isSuccess ? "/registration/done.gif" : "/registration/err.gif"}
            alt="result"
          />
          <Title size="2rem" mt="1rem" weight="700" ta="center" lh="2.3rem">
            {isSuccess
              ? t("registration.verificationsuccess")
              : t("registration.verificationfailed")}
          </Title>
        </TopSection>

        <Text ta="center" mb="1.5rem" color="#171922">
          {isSuccess
            ? t("registration.verificationsuccesstext")
            : t("registration.verificationfail")}
        </Text>
        <ButtonsWrapper>
          <BackButton className="btn btn-red h-10" onClick={() => onClose()}>
            {isSuccess
              ? t("registration.startjourney")
              : t("common.backtohome")}
          </BackButton>
          {!isSuccess && (
            <PlainButton onClick={() => navigate("/contact")} className="btn h-10 uppercase">
              {t("errorboundary.contactsupport")}
            </PlainButton>
          )}
        </ButtonsWrapper>
      </ContentWrapper>

    </BaseModal>
  );
};

export default VerificationResultModal;
