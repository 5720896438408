import React, { useEffect } from "react";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import { styled as StyledMui } from "@mui/material/styles";
import Select from "./Select";
import { media } from "../../utils/theme";

const Wrapper = styled.div<{ isAdjusted?: boolean; reverseDropdown?: boolean }>`
  display: flex;

  .form_control {
    width: 100%;

    .MuiOutlinedInput-root,
    input {
      border-radius: 4px 0 0 4px;
      border-right: none;
    }
  }

  .select {
    min-width: 82px;

    .dd-header > div {
      border-radius: 0px 4px 4px 0px;
      border-left: 0;
    }
  }

  @media (min-width: 1024px) and (max-width: 1440px) {
    input {
      padding: 0.8rem;
    }
  }

  ${media.lessThan("tablet")} {
    input {
      padding-top: 17.5px;
      padding-bottom: 17.5px;
    }

    .dd-header > div {
      padding: 1.25rem;
    }

    .dd-body {
      padding: 0 !important; 
    }
  }

  ${media.lessThan("small")} {
    .dd-header > div {
      padding: 15px 1em;
    }
    input {
      padding: 0.78rem;
    }
  }

  ${({ reverseDropdown }) =>
    reverseDropdown &&
    `
    flex-direction: row-reverse;

    .MuiOutlinedInput-root,
    input {
      border-radius: 0px 4px 4px 0px !important;
      border-right: none;
    }

    
  .select {
    min-width: 82px;

    .dd-header > div {
      border-radius: 4px 0px 0px 4px;
      border-right: 0;
      border-left: 1px solid #d6d6d6;
    }
  }
  `}
`;

interface SelectInputProps {
  name?: string;
  errorText?: string;
  onChange?: any;
  onSelect?: any;
  value?: string;
  type?: string;
  register?: any;
  autoComplete?: string;
  hintText?: string;
  label?: string;
  selectOptions: any;
  placeholder?: string;
  selectPlaceholder: string;
  initialSelectValue?: string;
  selectStyle?: any;
  disabled?: boolean;
  disabledSelect?: boolean;
  nameKey?: string;
  onKeyPress?: any;
  onFocus?: any;
  onBlur?: any;
  inputMode?: any;
  isAdjusted?: boolean;
  idKey?: string;
  isRetrigerred?: boolean;
  reverseDropdown?: boolean;
  selectImage?: string;
  shrink?: boolean;
  withCountryFlag?: any;
  withSearch?: boolean;
  countryFlagKey?: string
  onPaste?: any
}

const CustomTextField = StyledMui(TextField)({
  "& label.Mui-focused": {
    color: "var(--purple)",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      // borderColor: 'red',
    },
    "&:hover fieldset": {
      borderColor: "var(--purple)",
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--purple)",
    },
  },
});

const InputWithDropdown = React.forwardRef<HTMLInputElement, SelectInputProps>(
  (props, ref) => {
    const {
      errorText,
      onChange,
      onSelect,
      placeholder,
      selectPlaceholder,
      selectOptions,
      selectImage,
      // showPholder,
      idKey,
      onKeyPress,
      autoComplete,
      value,
      label,
      hintText,
      initialSelectValue,
      onFocus,
      onBlur,
      selectStyle,
      disabled,
      disabledSelect,
      nameKey,
      inputMode,
      isAdjusted,
      isRetrigerred,
      reverseDropdown,
      shrink,
      withCountryFlag,
      withSearch,
      countryFlagKey,
      onPaste,
      ...rest
    } = props;

    useEffect(() => {
      if (inputMode === "numeric") {
        let elements = document.querySelectorAll(
          `div[inputmode=numeric] input`
        );

        for (var i = 0; i < elements.length; i++) {
          elements[i].setAttribute("inputmode", "numeric");
        }
      }
    }, [inputMode]);

    return (
      <Wrapper isAdjusted={isAdjusted} reverseDropdown={reverseDropdown}>
        <CustomTextField
          onChange={onChange}
          placeholder={placeholder}
          // type={type}
          onKeyPress={onKeyPress}
          disabled={disabled}
          value={value}
          ref={ref}
          autoComplete={autoComplete}
          className={`form_control ${errorText ? "error-input" : ""} ${
            disabled ? "input-disabled" : ""
          }`}
          label={label}
          variant="outlined"
          InputLabelProps={{ shrink: shrink ? true : false }}
          helperText={errorText ? errorText : hintText ? hintText : ""}
          error={errorText ? true : false}
          onFocus={onFocus}
          onPaste={onPaste}
          onBlur={onBlur}
          InputProps={{
            inputMode: inputMode,
          }}
          {...rest}
        />
        <Select
          withSearch={withSearch}
          withCountryFlag={withCountryFlag}
          selectImage={selectImage}
          isRetrigerred={isRetrigerred}
          className="select"
          options={selectOptions}
          placeholder=""
          onChange={(val: any) => onSelect(val)}
          disabled={disabledSelect || disabled}
          initialValue={initialSelectValue}
          style={selectStyle}
          nameKey={nameKey}
          idKey={idKey}
          bodyClass="dd-body"
          countryFlagKey={countryFlagKey}
        />
      </Wrapper>
    );
  }
);

export default InputWithDropdown;
