import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { VerificationSessionGet } from "../../../services/Services";
import { useToasts } from "react-toast-notifications";
import VerificationResultModal from "./VerificationResultModal";
import { LoadingOverlay } from "../../../layout";
import Img from "../../../components/Img";

const AfterFinal = () => {
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("session_token");
  const [mode, setMode] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (token) {
      VerificationSessionGet(token)
        .then((res) => {
          if (res.data.Content.Status === "approved") {
            setMode("success");
            setLoading(false);
            localStorage.removeItem("registeremail");
            localStorage.removeItem("registertoken");
          } else {
            setMode("error");
            setLoading(false);
          }
        })
        .catch((err) => {
          setMode("error");
          setLoading(false);
          if (err.response.data.errors) {
            return Object.values(err.response.data.errors).map((item: any) => {
              addToast(<div>{item}</div>, {
                appearance: "error",
                autoDismiss: true,
              });
            });
          } else {
            addToast(<div>{err.response.data.StatusMessage}</div>, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        });
    } else if (!token) {
      setLoading(false);
    }
  }, []);

  if (loading) {
    return (
      <LoadingOverlay>
        <Img src="/transfers/inex-logo.png" alt="logo" />
      </LoadingOverlay>
    );
  }

  return (
    <div className="bg-[#FBFBFB] h-[100vh]">
      <VerificationResultModal
        mode={mode}
        isOpen={true}
        onClose={() => {
          navigate("/login");
        }}
      />
    </div>
  );
};

export default AfterFinal;
