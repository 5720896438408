import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Img from "../../components/Img";
import clsx from "clsx";
import { getBranches } from "../../services/Services";
import { getUserId } from "../../utils/storage";
import { FAQData } from "../FAQ/data";
import ContentHeader from "../../partials/ContentHeader";
import ContentFooter from "../../partials/ContentFooter";

interface Branches {
  Address: string;
  City: string;
  Country: string;
  Latitude: number;
  Longitude: number;
  Office: string;
  WorkingHours: string;
}

const Contact = () => {
  const [visibleParagraphIndex, setVisibleParagraphIndex] = useState<number[]>([
    -1, -1, -1,
  ]);
  const [branches, setBranches] = useState<Branches[]>([]);
  const [showMap, setShowMap] = useState<boolean>(true);

  function createMarkup(text: any) {
    return { __html: t(text) };
  }

  const userId = getUserId();

  const { t, i18n } = useTranslation();
  const languageKey = i18n.language;

  const fontClass = clsx({
    "font-Inter": languageKey !== "ge",
    "font-Noto": languageKey === "ge",
  });

  const arrayOfArrays = FAQData.map((entry) => entry.data);

  const flattenedArray = arrayOfArrays.reduce((acc, current) => {
    current.forEach((item) => {
      if (acc.length < 6) {
        acc.push({ title: item.title, text: item.text });
      }
    });
    return acc;
  }, []);

  const handleClick = (index: number) => {
    const updatedIndexes = [...visibleParagraphIndex];
    updatedIndexes.forEach((_, i) => {
      if (i !== index) {
        updatedIndexes[i] = -1;
      }
    });
    updatedIndexes[index] = updatedIndexes[index] === index ? -1 : index;
    setVisibleParagraphIndex(updatedIndexes);
  };

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        // if (userId) {
        const response = await getBranches(userId || "1");
        if (response && response.data && response.data.Content) {
          setBranches(response.data.Content);
        } else {
          console.error("Invalid response format:", response);
        }
        // }
      } catch (error) {
        console.error("Error fetching branches:", error);
      }
    };
    fetchBranches();
  }, []);

  return (
    <div className={`${fontClass}`}>
      <ContentHeader />
      <div className="bg-neutral-50 ">
        <div className="max-w-[1440px] mx-auto">
          <div className="mx-auto max-w-[1216px] flex flex-col gap-3  pt-10 md:pt-[96px] pb-[96px] justify-center items-center">
            <div className="px-4 md:px-8 w-full gap-16">
              <div className="flex flex-col gap-4 justify-center items-center mx-auto">
                <div className="mb-1 text-center">
                  <p className=" text-[#C64141] border border-rose-200 border-solid leading-tight bg-rose-50 px-3 py-1 rounded-full text-center">
                    {t("contact.tag")}
                  </p>
                </div>
                <h2 className="text-gray-900 text-4xl font-semibold leading-[44px] text-center">
                  {t("contact.title2")}
                </h2>
                <p className="text-xl leading-[30px] text-[#475467] text-center">
                  {t("contact.subtitle")}
                </p>
              </div>
              <div>
                <div className="w-full h-[480px] mt-16 mb-[96px]">
                  <Img
                    src="/contact/contactbanner.jpeg"
                    alt="contact banner"
                    className="h-full w-full object-cover"
                  />
                </div>
                <div className="flex justify-between  items-center gap-4 xl:gap-8 flex-wrap lg:flex-nowrap">
                  <div className="flex flex-col justify-start items-start bg-white p-6 w-full sm:max-w-[471px] lg:max-w-96 sm:flex-1 h-[282px] md:gap-16 gap-[48px] ">
                    <div className="w-[48px] h-[49px]">
                      <Img
                        src="/contact/email.svg"
                        alt="email"
                        className="p-2 rounded-lg bg-gray-200 w-full"
                      />
                    </div>
                    <div className="flex justify-between gap-2 items-start flex-col h-[106px]">
                      <div>
                        <p className=" text-gray-900 text-xl font-semibold pb-2">
                          {t("contact.mail.title")}
                        </p>
                        <p className="">{t("contact.mail.subtitle")}</p>
                      </div>
                      <a
                        href="mailto:info@inexpay.ge"
                        className="text-red-600 text-base font-semibold"
                      >
                        {t("contact.mail.mail")}
                      </a>
                    </div>
                  </div>
                  <div className="flex flex-col justify-start items-start bg-white p-6 w-full sm:max-w-[471px] lg:max-w-96 sm:flex-1 h-[282px] md:gap-16 gap-[48px]  ">
                    <div className="w-[48px] h-[49px]">
                      <Img
                        src="/contact/whatsapp.svg"
                        alt="whatsapp"
                        className="p-2 rounded-lg bg-gray-200 w-full"
                      />
                    </div>
                    <div className="flex justify-between gap-2 items-start flex-col h-[106px]">
                      <div>
                        <p className=" text-gray-900 text-xl font-semibold pb-2">
                          {t("contact.whatsapp.title")}
                        </p>
                        <p className="">{t("contact.whatsapp.subtitle")}</p>
                      </div>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://wa.me/17625503103"
                        className="text-red-600 text-base font-semibold"
                      >
                        {t("contact.whatsapp.phonenumber")}
                      </a>
                    </div>
                  </div>
                  <div className="flex flex-col justify-start items-start bg-white p-6 w-full lg:max-w-96 lg:flex-1 h-[282px] md:gap-16 gap-[48px]  ">
                    <div className="w-[48px] h-[49px]">
                      <Img
                        src="/contact/phone.svg"
                        alt="phone"
                        className="p-2 rounded-lg bg-gray-200 w-full"
                      />
                    </div>
                    <div className="flex justify-between gap-2 items-start flex-col h-[106px]">
                      <div>
                        <p className=" text-gray-900 text-xl font-semibold pb-2">
                          {t("contact.callus.title")}
                        </p>
                        <p className="">{t("contact.callus.subtitle")}</p>
                      </div>
                      <a
                        href="tel:032-2-83-33-83"
                        className="text-red-600 text-base font-semibold"
                      >
                        {t("contact.callus.phonenumber")}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white overflow-hidden">
          <div className="max-w-[1440px] mx-auto">
            <div className="mx-auto flex flex-col gap-16 py-[96px] justify-center items-center w-full md:w-[768px] ">
              <div className="px-4 md:mx-8">
                <div className="text-center">
                  <h2 className="text-gray-900 text-4xl font-semibold leading-[44px] mb-5 text-center">
                    {t("contact.faq.title")}
                  </h2>
                  <p className="text-xl leading-[30px] text-[#475467] text-center">
                    {t("contact.faq.subtitle")}
                  </p>
                </div>
              </div>
              <div className="px-4 md:px-0 w-full ">
                <div className="flex flex-col justify-center items-start text-wrap ">
                  {flattenedArray.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => handleClick(index)}
                      className={`flex justify-between gap-6 w-full border-t border-gray-200 border-solid py-6 first:border-none cursor-pointer hover:bg-stone-50 px-4 `}
                    >
                      <div className="relative overflow-hidden max-h-[200px] ">
                        <p className="text-lg text-gray-900 font-semibold leading-7 mb-2">
                          {t(item.title)}
                        </p>
                        <div
                          className={`grid grid-rows-[0fr] [transition:opacity_0.3s_180ms,grid_0.4s] overflow-hidden opacity-0  ${
                            visibleParagraphIndex[index] === index
                              ? " grid-rows-[1fr] opacity-100 "
                              : "opacity-0"
                          }`}
                        >
                          <p
                            dangerouslySetInnerHTML={createMarkup(item.text)}
                            className="text-slate-600 text-base leading-normal min-h-[0] cursor-default"
                          ></p>
                        </div>
                      </div>
                      <button
                        type="button"
                        onClick={() => handleClick(index)}
                        className="w-6 h-6 shrink-0 flex "
                      >
                        <Img
                          src={`/contact/${
                            visibleParagraphIndex[index] === index
                              ? "minus-circle.svg"
                              : "plus-circle.svg"
                          }`}
                          alt="minus circle"
                          className="h-full w-full"
                        />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <footer className="bg-[#212121] ">
        <div className="max-w-[1440px] mx-auto pt-10 md:pt-[64px] pb-10 md:pb-[48px]">
          <div className="px-4 md:px-8 w-full gap-16 mb-4 mx-auto">
            <div className="flex flex-col gap-4 justify-center items-center mx-auto max-w-[768px] ">
              <div className="mb-1">
                <p className=" text-gray-700 border border-gray-200 border-solid leading-tight bg-gray-50 px-3 py-1 rounded-full ">
                  {t("contact.footer.tag")}
                </p>
              </div>
              <h2 className="text-white text-4xl font-semibold leading-[44px] text-center">
                {t("contact.footer.title")}
              </h2>
              <p className="text-xl leading-[30px] text-[#CDCDCD] text-center">
                {t("contact.footer.subtitle")}
              </p>
            </div>
          </div>
          <div className="flex flex-row justify-between items-center max-w-[1280px] px-8  mx-auto">
            <div className="text-white text-xs font-normal font-['Roboto']">
              {showMap
                ? `${t("contact.footer.mapview")}`
                : `${t("contact.footer.listview")}`}
            </div>
            <div>
              <button
                type="button"
                onClick={() => setShowMap(true)}
                className="shrink-0 mr-1"
              >
                <Img
                  src="/contact/map.svg"
                  alt="map icon"
                  className={`p-2 rounded-lg hover:opacity-80 ${
                    showMap ? "bg-[#CE3B34]" : "bg-none"
                  }`}
                />
              </button>
              <button
                type="button"
                onClick={() => setShowMap(false)}
                className="shrink-0"
              >
                <Img
                  src="/contact/list.svg"
                  alt="list icon"
                  className={`p-2 rounded-lg hover:opacity-80 ${
                    !showMap ? "bg-red-500" : "bg-none"
                  }`}
                />
              </button>
            </div>
          </div>
          {showMap ? (
            <div className="max-w-[1280px] h-[480px] px-4 mx-auto mt-4">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m12!1m8!1m3!1d381219.5565072399!2d44.64105513241348!3d41.71452428251766!3m2!1i1024!2i768!4f13.1!2m1!1sIntel%20Express!5e0!3m2!1sen!2sge!4v1714651946303!5m2!1sen!2sge"
                width="100%"
                height="100%"
                loading="lazy"
                title="intelexpress"
              ></iframe>
            </div>
          ) : (
            <div className="max-w-[1280px] h-[360px] px-4 mx-auto mt-4 rounded-lg ">
              <div className="w-full h-full bg-white rounded-lg overflow-y-scroll &::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
                {branches.map((branch, index) => (
                  <a
                    href={`https://www.google.com/maps/search/?api=1&query=${branch.Latitude},${branch.Longitude}`}
                    target="_blank"
                  >
                    <div key={index} className="px-3 py-2 flex gap-2">
                      <div className="shrink-0 flex justify-center items-center">
                        <Img
                          src="/contact/location.svg"
                          alt="list icon"
                          className="p-2 bg-[#F7F7F7] rounded-full"
                        />
                      </div>
                      <div className="flex flex-col items-start justify-center gap-1">
                        <p className="text-stone-300 text-xs font-medium font-['Roboto'] uppercase">
                          {branch.WorkingHours}
                        </p>
                        <p className="text-zinc-900 text-sm font-normal font-['Roboto']">
                          {branch.Office}
                        </p>
                        <p className="text-zinc-600 text-xs font-normal font-['Roboto']">
                          {branch.City}, {branch.Address}
                        </p>
                      </div>
                    </div>
                  </a>
                ))}
              </div>
            </div>
          )}
        </div>
      </footer>
      <ContentFooter />
    </div>
  );
};

export default Contact;
