import React, { CSSProperties, useEffect } from "react";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import { InputAdornment } from "@mui/material";
import { styled as StyledMui } from "@mui/material/styles";

const Wrapper = styled.div`
  margin-bottom: 10px;
  flex: 1;

  .form_control {
    width: 100%;
  }
`;

const AdornmentWrapper = styled.div`
  position: absolute;
  right: 14px;
`;

const StartAdornmentWrapper = styled.div`
  position: absolute;
  left: 14px;
`;

// eslint-disable-next-line
interface InputProps {
  name?: string;
  errorText?: string;
  onChange?: any;
  placeholder?: string;
  showPholder?: boolean;
  value?: string;
  type?: string;
  register?: any;
  autoComplete?: string;
  withEye?: boolean;
  label?: string;
  startAdornment?: any;
  endAdornment?: any;
  onBlur?: any;
  onFocus?: any;
  onMaskClick?: () => void;
  hintText?: string;
  autoFocus?: boolean;
  onKeyUp?: any;
  className?: string;
  inputMode?: string;
  onKeyPress?: any;
  disabled?: boolean;
  showLabel?: boolean;
  style?: CSSProperties
  onPaste?: any
  wrapperClass?: string
}

export const CustomTextField = StyledMui(TextField)({
  "& label.Mui-focused": {
    color: "var(--blue)",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "green",
  },
  "& .MuiOutlinedInput-root": {
    "padding-right": 0,
    "padding-left": 0,
    "& fieldset": {},
    "&:hover fieldset": {
      borderColor: "var(--blue)",
    },
    "& input": {
      "padding-left": 14,
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--blue)",
    },
  },
});

const Input = React.forwardRef<HTMLInputElement, InputProps>((props, ref) => {
  const {
    errorText,
    onChange,
    type,
    placeholder,
    showPholder,
    autoComplete,
    withEye,
    onMaskClick,
    value,
    label,
    endAdornment,
    startAdornment,
    hintText,
    onBlur,
    onFocus,
    autoFocus,
    inputMode,
    className,
    style,
    wrapperClass,
    onPaste,
    ...rest
  } = props;

  useEffect(() => {
    if (inputMode === "numeric") {
      let elements = document.querySelectorAll(`div[inputmode=numeric] input`);

      for (var i = 0; i < elements.length; i++) {
        elements[i].setAttribute("inputmode", "numeric");
      }
    }
  }, [inputMode]);

  return (
    <Wrapper className={wrapperClass}>
      <CustomTextField
        onChange={onChange}
        placeholder={placeholder}
        type={type}
        autoFocus={autoFocus}
        value={value}
        ref={ref}
        onPaste={onPaste}
        onBlur={onBlur}
        onFocus={onFocus}
        autoComplete={autoComplete}
        className={`form_control ${className} ${errorText ? "error-input" : ""}`}
        {...rest}
        label={label}
        variant="outlined"
        helperText={errorText ? errorText : hintText ? hintText : ""}
        error={errorText ? true : false}
        InputProps={{
          style: style,
          endAdornment: (
            <AdornmentWrapper>
              <InputAdornment position="end">{endAdornment}</InputAdornment>
            </AdornmentWrapper>
          ),
          startAdornment: startAdornment && (
            <StartAdornmentWrapper>
              <InputAdornment position="start">{startAdornment}</InputAdornment>
            </StartAdornmentWrapper>
          ),
          // @ts-ignore
          inputMode: inputMode,
        }}
      />
    </Wrapper>
  );
});

export default Input;
