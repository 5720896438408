import React, { useState, useRef, useEffect, CSSProperties } from "react";
import styled from "styled-components";
import { Scrollbars } from "rc-scrollbars";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import Img from "../../components/Img";
import LoadingIndicator from "../LoadingIndicator";
import DropArrow from "../Icons/DropArrow";
import { useTranslation } from "react-i18next";
import Title from "../Text/title";
import Text from "../Text/text";
import { getImage } from "../../services/Services";

const DropDownContainer = styled.div`
  width: 100%;
  margin: 0 auto;
  position: relative;
  margin-bottom: 20px;
`;

const DropDownHeader = styled.div<{
  selected: boolean;
  hasError: boolean;
  disabled: boolean | undefined;
  isCard?: boolean;
  selectImage?: boolean | string;
}>`
  padding: 19px 1em;
  background: #fff;
  border-radius: 4px;
  border: 1px solid #d6d6d6;
  font-size: 1em;
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: pointer;
  color: ${(p) => (p.selected ? "#000" : "#9C9C9C")};
  border-color: ${(p) => (p.hasError ? "var(--red)" : "#D6D6D6")};

  .svgImage svg{
    width: 24px;
    height: 30px;
    margin-right: 8px;

  }

  ${(p) =>
    p.selectImage &&
    `
    align-items: center;
  `};

  ${(p) =>
    p.disabled &&
    `
    opacity: 0.5;
    pointer-events: none;
  `};
  
  ${(p) =>
    p.isCard &&
    `
    justify-content: flex-start;
    opacity: 1 !important;
     
    .right{
      position: absolute; 
      right: 10px;
      top: 18px;
    }`}}


  a {
    position: absolute;
    background: none;
    border: none;
    cursor: pointer;
    display: block;
    right: 40px;
    z-index: 8;
  }
  
  .rotated{
    // transform: rotate(180deg) translateX(-15px);
    transform: rotate(180deg);

    svg{
      fill: var(--red);
    }
  }

  @media (max-width: 1440px) {
    // max-height: 40px;
    padding-top: 12px;
  }
`;

const DropDownListContainer = styled.div`
  position: absolute;
  z-index: 100;
  width: 100%;
  margin-top: 11px;
`;

const DropDownList = styled.div<{ withSearch: boolean }>`
  padding  ${(p) => (p.withSearch ? "47px 0 0;" : "0px 0 0;")}
  margin: -10px 0 0;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 0 0 4px 4px;
  font-size: 14px;
  overflow:hidden;

  svg {
    width: 20px;
    height: 20px;
    margin-right: 8px;
    
  }

  div{
    overflow-x: hidden !important;
    margin-bottom: 0 !important;
  }

  .active-item{
    background: #DCEEE5;
  
  }

  .loading-indicator{
    min-height: 30px;
    margin-top: -3px;
    circle{
      stroke: var(--green)
    }
  }
`;

const ListItem = styled.li<{ isCard?: boolean; withcountryflag?: boolean }>`
  list-style: none;
  padding: 14px 0px 12px 12px;
  cursor: pointer;

  ${(p) =>
    (p.isCard || p.withcountryflag) &&
    `
    display: flex;
    align-items: center;
  `}

  &:hover {
    background: #dceee5;
    color: var(--green);
  }

  .noPhoto {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    color: #fff;
    background: #5b5bb9;
    border-radius: 50%;
    font-size: 12px;
    line-height: 12px;
    text-transform: uppercase;
    margin-right: 10px;
    padding-top: 2px;
  }

  .manager-img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
  }

  &.border-bot {
    border-bottom: 1px solid #d6d6d6;
  }
`;

const ErrorText = styled.span`
  color: var(--red);
  font-size: 12px;
  margin-top: 6px;
  display: inline-block;
`;

const NoResults = styled.div`
  padding: 2rem 0 15px;
  text-align: center;
  color: var(--dark-gray);
`;

const DropdownSearch = styled.div`
  position: absolute;
  z-index: 100;
  width: 100%;
  margin-top: 0px;

  input {
    padding: 17px 12px;
    width: calc(100% - 3px);
    margin-top: -9px;
    margin-left: 1px;
    border: none;
    border-bottom: 1px solid #e5e5e5;
  }

  svg {
    position: absolute;
    top: 8px;
    right: 20px;
  }
`;

interface SelectProps {
  options: any;
  placeholder: string;
  errorText?: string;
  onChange: Function;
  withSearch?: boolean;
  searchPlaceholder?: string;
  className?: string;
  bodyClass?: string;
  headerClass?: string;
  onSelectedDelete?: () => void;
  selectImage?: string;
  initialValue?: string;
  onBottomReach?: () => void;
  direction?: string;
  scrollTime?: number;
  style?: CSSProperties;
  idKey?: string;
  nameKey?: string;
  disabled?: boolean | undefined;
  leftContent?: React.ReactNode;
  removedItem?: string;
  withCountryFlag?: boolean;
  dropDownItemStyle?: CSSProperties;
  isRates?: boolean;
  translate?: boolean;
  isCard?: boolean;
  fullName?: boolean;
  isRetrigerred?: boolean;
  height?: number;
  onOpen?: () => void;
  onClose?: () => void;
  countryFlagKey?: string;
  timeout?: number
}

const Select = ({
  options,
  placeholder,
  errorText,
  onChange,
  withSearch,
  initialValue,
  className,
  bodyClass,
  headerClass,
  onSelectedDelete,
  selectImage,
  idKey,
  nameKey,
  style,
  direction,
  disabled,
  leftContent,
  searchPlaceholder,
  removedItem,
  withCountryFlag,
  dropDownItemStyle,
  isRates,
  translate,
  isCard,
  fullName,
  isRetrigerred,
  timeout,
  height,
  onClose,
  onOpen,
  countryFlagKey,
}: SelectProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<any>(null);
  const [data, setData] = useState(options);
  const listRef = useRef<HTMLDivElement>(null);
  const headerRef = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLDivElement>(null);
  const [showUpwards, setShowUpwards] = useState<boolean>(false);
  const selectedRef = useRef<any>(null);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>("");
  const { t } = useTranslation();
  const [flagImages, setFlagImages] = useState<any>([]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        if (
          listRef.current &&
          !listRef.current.contains(event.target) &&
          headerRef.current &&
          !headerRef.current.contains(event.target) &&
          searchRef.current &&
          !searchRef.current.contains(event.target)
        ) {
          setIsOpen(false);
          onClose && onClose();
        }
      } else {
        if (!withSearch) {
          if (
            listRef.current &&
            !listRef.current.contains(event.target) &&
            headerRef.current &&
            !headerRef.current.contains(event.target)
          ) {
            setIsOpen(false);
            onClose && onClose();
          }
        }
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  useEffect(() => {
    if (options.length && withCountryFlag) {
      var imageCodes: any = options.map(
        (item: any) =>
          (countryFlagKey && item[countryFlagKey]) ||
          item.Alpha2 ||
          item.Code ||
          item.CurrencyName
      );

      imageCodes.map((item: any) =>
        getImage(item)
          .then((res) =>
            setFlagImages((prevState: any) => ({
              ...prevState,
              [item]: res.data,
            }))
          )
          .catch((res) => {})
      );
    }
  }, [withCountryFlag, options]);

  useEffect(() => {
    if (withSearch) {
      setData(options);
    }

    var initial = options?.find((item: any) => item.name === initialValue);
    if (initial && initialValue) {
      setSelectedOption(initial && initial.name);
      if (isRetrigerred) {
        onChange(initial && initial.id);
      }
    }
    // eslint-disable-next-line
  }, [options]);
 
  useEffect(() => {
    if(timeout !== undefined){
      setTimeout(() => {
        var initial = options?.find((item: any) => item.name === initialValue);
        if (initial && initialValue) {
          setSelectedOption(initial && initial.name);
          if (isRetrigerred) {
            onChange(initial && initial.id);
          }
        } else {
          setSelectedOption(initialValue);
        }
      }, timeout);
    } else {
      setTimeout(() => {
        var initial = options?.find((item: any) => item.name === initialValue);
        if (initial && initialValue) {
          setSelectedOption(initial && initial.name);
          if (isRetrigerred) {
            onChange(initial && initial.id);
          }
        } else {
          setSelectedOption(initialValue);
        }
      }, 300);
    }
  
    // eslint-disable-next-line
  }, [initialValue, timeout]);

  useEffect(() => {
    if (isOpen) {
      // selectedRef.current && selectedRef.current.scrollIntoView({
      //   behavior: 'auto', block: "center", inline: "nearest"
      // })
      selectedRef.current && selectedRef.current.classList.add("active-item");
    }
    setData(options);
    setSearchValue("");
    // eslint-disable-next-line
  }, [isOpen]);

  useEffect(() => {
    setLoading(false);
  }, [options]);

  const toggling = () => {
    if (direction !== "up") {
      if (headerRef.current) {
        if (
          window.innerHeight -
            (headerRef.current!.getBoundingClientRect().y + 82) <
            80 &&
          !initialValue
        ) {
          setShowUpwards(true);
        } else {
          setShowUpwards(false);
        }

        // if (window.innerHeight - (listRef.current!.getBoundingClientRect().y + 82) < 80 && !initialValue) {
        //   setShowUpwards(true);
        // } else {
        //   setShowUpwards(false);
        // };
      }
    } else {
      setShowUpwards(true);
    }
    if (!isOpen) {
      onOpen && onOpen();
    } else {
      onClose && onClose();
    }
    setIsOpen(!isOpen);
  };

  const onOptionClicked = (option: any) => () => {
    nameKey && option[nameKey]
      ? setSelectedOption(nameKey && option[nameKey])
      : setSelectedOption(option.name);
    setIsOpen(false);
    onClose && onClose();
    onChange(idKey ? option[idKey] : option.id);
  };

  // const handleUnselect = () => {
  //   setSelectedOption(null);
  //   onSelectedDelete && onSelectedDelete();
  // };

  const renderHeight = () => {
    if (height) {
      return height;
    }
    if (selectImage) {
      if (!options.length) {
        return 50;
      } else if (options.length === 1) {
        return 46;
      } else if (options.length === 2) {
        return 92;
      } else if (options.length === 3) {
        return 140;
      } else if (options.length === 4) {
        return 140;
      } else {
        return 180;
      }
    }
    if (removedItem) {
      if (!options.length) {
        return 50;
      } else if (options.length === 1) {
        return 40;
      } else if (options.length === 2) {
        return 80;
      } else if (options.length === 3) {
        return 120;
      } else if (options.length === 4) {
        return 160;
      } else {
        return 160;
      }
    } else {
      if (!options.length) {
        return 50;
      } else if (options.length === 1) {
        return 40;
      } else if (options.length === 2) {
        return 80;
      } else if (options.length === 3) {
        return isRates ? 137 : 120;
      } else if (options.length === 4) {
        return 160;
      } else {
        return 200;
      }
    }
  };

  const onSearchChange = (e: any) => {
    let searchValue = e.target.value.toLowerCase();
    setSearchValue(e.target.value);
    if (!searchValue) {
      setData(options);
    } else {
      setData(
        options.filter((item: any) =>
          nameKey
            ? item[nameKey].toLowerCase().includes(searchValue)
            : item.name.toLowerCase().includes(searchValue)
        )
      );
    }
  };

  if (!options) {
    return null;
  }

  const filteredOptions = options?.filter((option: any) =>
    nameKey ? option[nameKey] !== removedItem : option.name !== removedItem
  );

  return (
    <div className={className}>
      <DropDownContainer className="dd-header">
        <DropDownHeader
          style={style}
          tabIndex={0}
          onClick={toggling}
          className={`${headerClass} notranslate ${isOpen ? "active" : ""}`}
          selected={selectedOption ? true : false}
          ref={headerRef}
          disabled={disabled}
          hasError={errorText ? true : false}
          isCard={isCard}
          selectImage={selectImage}
        >
          {leftContent} 
          {selectImage && (
            <span
              className="svgImage"
              style={{ maxWidth: 30, flexShrink: 0 }}
              dangerouslySetInnerHTML={{ __html: selectImage }}
            />
          )}
          <span style={{ lineHeight: "16px" }}>
            {isCard && (
              <Title size="0.875rem" mb="0rem">
                {fullName}
              </Title>
            )}
            {isCard ? (
              <Text> {selectedOption}</Text>
            ) : translate ? (
              t(selectedOption) || placeholder
            ) : (
              selectedOption || placeholder
            )}{" "}
          </span>
          <span
            className={`${isCard ? "right" : ""} ${isOpen ? "rotated" : ""}`}
          >
            {/* {selectedOption && <a className='close' onClick={handleUnselect}><Img src={'/icon/close-select.svg'} alt='close' /></a>} */}
            {isCard ? (
              options.length < 2 ? null : (
                <KeyboardArrowDownIcon />
              )
            ) : (
              <DropArrow className="droparrow" />
            )}
          </span>
        </DropDownHeader>

        {isOpen && (
          <DropDownListContainer
            className={bodyClass}
            style={{ bottom: showUpwards ? "100%" : "unset" }}
          >
            {withSearch && !!options.length && (
              <DropdownSearch ref={searchRef}>
                <input
                  placeholder={searchPlaceholder}
                  autoFocus
                  value={searchValue}
                  onChange={onSearchChange}
                  autoComplete="new-password"
                />
              </DropdownSearch>
            )}
            <DropDownList ref={listRef} withSearch={withSearch ? true : false}>
              <Scrollbars
                style={{ height: renderHeight(), overflowX: "hidden" }}
              >
                {!withSearch &&
                  filteredOptions.map((option: any) => (
                    <ListItem
                      isCard={isCard}
                      tabIndex={0}
                      withcountryflag={withCountryFlag}
                      className="dropdownItem notranslate"
                      style={dropDownItemStyle}
                      onKeyPress={(e) => {
                        if (e.code === "Enter") {
                          nameKey && option[nameKey]
                            ? setSelectedOption(nameKey && option[nameKey])
                            : setSelectedOption(option.name);
                          setIsOpen(false);
                          onChange(idKey ? option[idKey] : option.id);
                        }
                      }}
                      onMouseEnter={() =>
                        selectedRef.current &&
                        selectedRef.current.classList.remove("active-item")
                      }
                      onClick={onOptionClicked(option)}
                      key={option.id}
                      ref={selectedOption === option.name ? selectedRef : null}
                    >
                      {withCountryFlag && (
                        <div
                          style={{
                            borderRadius: "50%",
                            width: 20,
                            height: 20,
                            marginRight: 8,
                            overflow: "hidden",
                            flexShrink: 0,
                          }}
                          dangerouslySetInnerHTML={{
                            __html:
                              flagImages[
                                option.Alpha2 ||
                                  option.Code ||
                                  option.CurrencyName
                              ],
                          }}
                        />
                      )}

                      {isCard && (
                        <div style={{ marginRight: "0.5rem" }}>
                          <Img src="/home/intelcard.png" alt="card" />
                        </div>
                      )}
                      {nameKey && !translate
                        ? option[nameKey]
                        : translate
                        ? t(option.name)
                        : option.name}
                    </ListItem>
                  ))}

                {withSearch &&
                  data.map((option: any) => (
                    <ListItem
                      tabIndex={0}
                      className="dropdownItem"
                      withcountryflag={withCountryFlag}
                      onKeyPress={onOptionClicked(option)}
                      onMouseEnter={() =>
                        selectedRef.current &&
                        selectedRef.current.classList.remove("active-item")
                      }
                      onClick={onOptionClicked(option)}
                      key={option.id}
                      ref={selectedOption === option.name ? selectedRef : null}
                      style={dropDownItemStyle}
                    >
                      {withCountryFlag && (
                        <div
                          style={{
                            borderRadius: "50%",
                            overflow: "hidden",
                            width: 20,
                            height: 20,
                            marginRight: 5,
                            textAlign: "center",
                          }}
                          dangerouslySetInnerHTML={{
                            __html: flagImages[option.Alpha2 || option.Code],
                          }}
                        />
                      )}
                      {nameKey && !translate
                        ? option[nameKey]
                        : translate
                        ? t(option.name)
                        : option.name}
                    </ListItem>
                  ))}

                {isLoading && <LoadingIndicator />}
                {withSearch && !data.length && (
                  <NoResults>No Results</NoResults>
                )}
                {!options.length && <NoResults>No Options</NoResults>}
              </Scrollbars>
            </DropDownList>
          </DropDownListContainer>
        )}

        {errorText && <ErrorText className="error">{errorText}</ErrorText>}
      </DropDownContainer>
    </div>
  );
};

export default Select;
