import { Skeleton } from "@mui/material";

const TemplateSkeleton = () => {
  return (
    <div className={"flex justify-between p-2"}>
      <div className="flex gap-2">
        <Skeleton
          variant="rounded"
          width={48}
          height={48}
          style={{ borderRadius: "50%" }}
        />
        <div>
          <Skeleton width="150px" variant="text" sx={{ fontSize: "1.35rem" }} />
          <Skeleton width="100px" variant="text" sx={{ fontSize: "1.25rem" }} />
        </div>
      </div>
    </div>
  );
};

export default TemplateSkeleton;
