import axios from "axios";
import store from "../redux/store";
import {
  getToken,
  deleteToken,
  getRefreshToken,
  saveStorageObject,
} from "../utils/storage";
import { setToken } from "../redux/authSlice";

export const baseURL =
  window.location.href.includes("localhost") ||
  window.location.href.includes("coretestapi")
    ? "https://coreapi.inexpay.ge/api/"
    : "https://api.inexpay.ge/api/";

const languages = {
  ka: "ka-GE",
  en: "en-US",
  el: "el-GR",
  ru: "ru-RU",
  tl: "FIL-PH",
};

export const AxiosInstance = axios.create({
  baseURL: baseURL,
  timeout: 300000,
  withCredentials: false,
  headers: {
    Authorization: `${JSON.parse(localStorage.getItem("token"))}`,
    "Accept-Language": languages[localStorage.getItem("i18nextLng")],
    Platform: "web",
    "User-Agent": window.navigator.userAgent,
  },
});

AxiosInstance.CancelToken = axios.CancelToken;
AxiosInstance.isCancel = axios.isCancel;

export const setAuthorizationToken = (token) => {
  const defaultHeaders = {
    Authorization: `${token}`,
    "Accept-Language": languages[localStorage.getItem("i18nextLng")],
  };
  AxiosInstance.defaults.headers = {
    ...defaultHeaders,
  };
};

export const setAcceptLanguage = (token, language) => {
  const defaultHeaders = {
    Authorization: `${token}`,
    "Accept-Language": languages[language],
  };
  AxiosInstance.defaults.headers = {
    ...defaultHeaders,
  };
};

export const unSetAuthorizationToken = () => {
  AxiosInstance.defaults.headers.common = { ...AxiosInstance.defaults.headers };
};

let refreshed = false;
// const queue = [];

// Add a response interceptor
AxiosInstance.interceptors.response.use(
  (response) => {
    document.body.classList.remove("loading-indicator");
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    const originalToken = getToken();
    const refreshToken = getRefreshToken();

    if (error.response && error.response.status === 401 && error.response.config.url !== "/authentication/login") {
      unSetAuthorizationToken();
      deleteToken();
      localStorage.removeItem("refreshtoken");
      return window.location.reload();
    }

    if (
      error.response &&
      error.response.status === 428 &&
      originalToken &&
      !refreshed
    ) {
      refreshed = true;

      return axios
        .get(
          `${baseURL}authentication/check`,
          {
            headers: {
              Authorization: `${JSON.parse(localStorage.getItem("token"))}`,
            },
          },
          {}
        )
        .then((resp) => {
          console.log(resp);
        })
        .catch((err) => {
          if (err.response.status === 428) {
            axios
              .post(
                `${baseURL}authentication/refreshtoken`,
                { token: originalToken, refreshToken },
                {}
              )
              .then((res) => {
                if (res.status === 200 && res.data && res.data.Content.Token) {
                  saveStorageObject("token", res.data.Content.Token);
                  saveStorageObject(
                    "refreshtoken",
                    res.data.Content.RefreshToken
                  );

                  setAuthorizationToken(res.data.Content.Token);
                  originalRequest.headers.Authorization = `${res.data.Content.Token}`;
                  refreshed = false;

                  setTimeout(() => {
                    store.dispatch(setToken(res.data.Content.Token));
                  }, 1000);
                  return AxiosInstance(originalRequest);
                }
              })
              .catch(() => {
                unSetAuthorizationToken();
                deleteToken();
                localStorage.removeItem("refreshtoken");
                localStorage.removeItem("user");
                return window.location.reload();
              });
          } else if (err.response.status === 401) {
            unSetAuthorizationToken();
            deleteToken();
            localStorage.removeItem("refreshtoken");
            localStorage.removeItem("user");
            return window.location.reload();
          }
        });
    } else {
      return Promise.reject(error);
    }
  }
);

// const retryWithNewToken = async (request, axiosInstance, access_token) => {
//   request.headers["Authorization"] = `${access_token}`;

//   try {
//     return await axiosInstance(request);
//   } catch (error) {
//     // Handle the error if the request still fails after retrying with the new token
//     return Promise.reject(error);
//   }
// };

export default AxiosInstance;
