import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import emailjs from "@emailjs/browser";
import Airtable from "airtable";
import moment from "moment";

import { media } from "../../utils/theme";
import Img from "../../components/Img";
import Title from "../../components/Text/title";
import Text from "../../components/Text/text";
import { userSelector } from "../../redux/selectors";

const Wrapper = styled.div`
  textarea {
    margin-bottom: 30px;
    width: 100%;
    height: 101px;
    border: 1px solid #ececec;
    border-radius: 6px;
    resize: none;
    padding: 15px;
    font-size: 12px;
    outline: 0;
  }

  .btn-red {
    margin: 0 auto;
    width: 250px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }

  ${media.lessThan("small")} {
    margin-top: 1rem;
  }
`;

const FeedbackButton = styled.div<{ isShown?: boolean; }>`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  padding: 0 20px 45px;
  height: 75px;
  border-radius: 13px;
  background: #ffeded;
  right: -72px;
  bottom: 240px;
  transform: rotate(-90deg);
  text-transform: uppercase;
  transform-origin: top;
  font-size: 14px;
  -webkit-transform-origin: 50% 51%;
  opacity: 1 !important;
  letter-spacing: 1px;
  transition: all 0.4s;
  z-index:150;

  &:hover {
    background-color: #ff1414;
    color: #fff;
    cursor: pointer;
  }

  ${media.lessThan("small")} {
    right: -44px;
    height: 32px;
    bottom: 194px;
    padding: 16px;
    border-radius: 4px;
  }

  ${({ isShown }) =>
    isShown &&
    `
    right: -172px;

    ${media.lessThan("small")}{
      right: -172px;
    }
  `}
`;

const FeedbackForm = styled.form<{ isShown?: boolean; }>`
  opacity: 1;
  z-index:150;
  bottom: 60px;
  max-width: 100%;
  width: 350px;
  padding: 24px 50px;
  background: #fff;
  border: 1px solid #f1f1f1;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 13px;
  position: fixed;
  right: -400px;
  transition: all 0.5s;
  transition-delay: 250ms;
  text-align: center;
  min-height: 400px;

  ${({ isShown }) =>
    isShown &&
    `
    transition-delay: 0ms;
    right: 40px;

    ${media.lessThan("small")}{
      right: 20px;
      width: calc(100% - 40px);
    }
  `}
`;

const HideButton = styled.div`
  width: 33px;
  height: 44px;
  left: 0px;
  bottom: 200px;
  background: #162234;
  opacity: 1 !important;
  border-radius: 0 6px 6px 0;
  transition: all 0.3s;
  position: absolute;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    fill: white;
  }

  &:hover {
    background-color: #ff3f3f;
    cursor: pointer;
  }
`;

const StarsList = styled.ul`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 0.8rem;

  li {
    cursor: pointer;
  }
`;

const stars = [1, 2, 3, 4, 5];

const Feedback = () => {
  const { t } = useTranslation();
  const user = useSelector(userSelector);
  const [showForm, setShowForm] = useState(false);
  const [success, setSuccess] = useState(false);
  // const isFormSubmitted = localStorage.getItem("feedbackSubmitted");
  const [rating, setRating] = useState<number>(0);
  const { addToast } = useToasts();
  var base = new Airtable({ apiKey: "patJ052EJhR1Uj9bb.a6b07949425923b0615114b75da1fd141a4ff71f252d8207ec659ceba98e4ea7"}).base(
    "appyM2RSPhQ0maqTm"
  );
  const [comment, setComment] = useState("");

  const onSubmit = (e: any) => {
    e.preventDefault();

    if (rating === 0) {
      addToast(<div>{t("feedback.chooserating")}</div>, {
        appearance: "error",
        autoDismiss: true,
      });
      return null;
    }

    emailjs
      .send(
        "service_ss6hxm8",
        "template_i4rvu7d",
        {
          Fullname: user.fullName,
          Email: user.email,
          Phone: user.clientPhone,
          Rating: rating,
          Comment: comment,
          UserId: user.clientCode,
        },
        "v4otD7ygISm7JHzYT"
      )
      .then(
        (response) => {
          console.log("SUCCESS!", response.status, response.text);
        },
        (err) => {
          console.log("FAILED...", err);
        }
      );

    setSuccess(true);
    setTimeout(() => {
      // localStorage.setItem("feedbackSubmitted", "true");
      setShowForm(false);
      setSuccess(false);
    }, 5000);

    base("Table 1").create(
      [
        {
          fields: {
            Fullname: user.fullName,
            Email: user.email,
            Phone: user.clientPhone,
            Rating: rating,
            Comment: comment,
            UserId: user.clientCode,
            Date: moment().format("DD/MM/YYYY"),
          },
        },
      ],
      function (err: any) {
        if (err) {
          console.error(err);
          return;
        }
      }
    );
  };

  if (success) {
    return (
      <Wrapper>
        <FeedbackForm isShown style={{ paddingTop: 110 }}>
          <Img src="/feedback/tick.png" alt="success" />
          <Title ta="center" lh="24px" style={{ maxWidth: 240 }}>
            {t("feedback.success")}
          </Title>
        </FeedbackForm>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <FeedbackButton isShown={showForm} onClick={() => setShowForm(true)}>
        {t("feedback.title")}
      </FeedbackButton>
      <FeedbackForm onSubmit={onSubmit} isShown={showForm}>
        <HideButton onClick={() => setShowForm(false)}>
          <ArrowForwardIosRoundedIcon />
        </HideButton>
        <Img src="/logo.svg" alt="logo" />
        <Title
          ta="center"
          size="0.875rem"
          lh="17px"
          style={{ maxWidth: 240 }}
          mt="1rem"
          className="[@media(max-width:554px)]:!max-w-full"
        >
          {t("feedback.question")}
        </Title>
        <StarsList>
          {stars.map((index) => {
            return (
              <li key={index} onClick={() => setRating(index)}>
                <Img
                  src={
                    index - 1 >= rating
                      ? "/feedback/star-empty.svg"
                      : "/feedback/star.svg"
                  }
                  alt="star"
                />
              </li>
            );
          })}
        </StarsList>
        <Text mb="1rem" ta="center" mt="1.5rem">
          {t("feedback.comment")}
        </Text>
        <textarea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder={t("feedback.share")}
        ></textarea>
        <button className="btn btn-red">{t("feedback.submit")}</button>
      </FeedbackForm>
    </Wrapper>
  );
};

export default Feedback;
