import Text from "../components/Text/text";
import { Mobile, TabletOrDesktop } from "../components/responsive/responsive";
import LangSwitcher from "../translations/LangSwitcher";
import FlexWrapper from "../components/FlexWrapper";
import Img from "../components/Img";
import Divider from "../components/Divider";

const ContentFooter = () => {
  return (
    <div className="bg-white [@media(min-width:554px)]:pt-[64px] pt-12 ">
      <div className="flex items-center justify-center max-w-[1440px] mx-auto px-4 sm:px-[32px]">
        <Divider height="1px" className=" !w-full " />
      </div>
      <footer className=" max-w-[1440px] mx-auto px-4 sm:px-[32px] pt-8 pb-12  mb-8 flex justify-between flex-col [@media(min-width:554px)]:flex-row  xs:gap-4 items-center">
        <div className="flex flex-col items-center [@media(min-width:554px)]:items-start">
          <Text
            size="1rem"
            color="#667085"
            lh="1.5rem"
            mb="1rem"
            className="[@media(min-width:554px)]:text-left text-center"
          >
            © {new Date().getFullYear()} InexPay LTD. All rights reserved.
          </Text>
          <div>
            <TabletOrDesktop>
              <LangSwitcher isContentFooter />
            </TabletOrDesktop>
          </div>
        </div>

        <FlexWrapper gap="24px" mb="0">
          <a
            href="https://www.linkedin.com/company/inexpay/about/"
            target="_blank"
            rel="noreferrer"
          >
            <Img src="/common/linkedin.svg" alt="linkedin" />
          </a>
          <a
            href="https://www.facebook.com/inexpay"
            target="_blank"
            rel="noreferrer"
          >
            <Img src="/common/fb.svg" alt="facebook" />
          </a>
        </FlexWrapper>
        <Mobile>
          <LangSwitcher isContentFooter />
        </Mobile>
      </footer>{" "}
    </div>
  );
};

export default ContentFooter;
