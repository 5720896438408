import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Img from "../../components/Img";
import BackArrow from "../../components/Icons/BackArrow";
import clsx from "clsx";

const NotFound = () => {
  const { t, i18n } = useTranslation();
  let navigate = useNavigate();
  const location = useLocation();

  const handleClick = () => {
    if (location?.state?.gobackAsLogin) {
      navigate("/home");
    } else {
      navigate(-1);
    }
  };

  const languageKey = i18n.language;

  const fontClass = clsx({
    "font-Inter": languageKey !== "ge",
    "font-Noto": languageKey === "ge",
  });

  return (
    <div
      className={`flex flex-col lg:flex-row xs:flex-col items-center justify-center lg:h-screen w-full py-24 lg:py-0 overflow-x-hidden overflow-y-hidden lg:gap-0 gap-16 ${fontClass}`}
    >
      <div className="lg:py-24 flex items-center">
        <div className="lg:px-8 xs:px-4">
          <div className="lg:pr-8 xs:p-0 max-w-[528px] ">
            <div className="flex flex-col sm:gap-6 sm:pb-12 pb-6 gap-4">
              <div>
                <div className="relative ">
                  <div className="w-[768px] h-[768px] absolute top-[-358px] left-[-358px] ">
                    <Img src="/404/404circles.svg" alt="circles" />
                  </div>
                  <Img
                    src="/404/search.svg"
                    alt="search"
                    className="p-3 shadow shadow-gray-200 rounded-lg z-50"
                  />
                </div>
              </div>
              <p className="lg:text-6xl text-4xl text-gray-900 font-semibold z-50 leading-[44px] lg:leading-[72px] ">
                {t("404.title")}
              </p>
              <p className="lg:text-xl text-lg text-slate-600 z-50 leading-7 lg:leading-[30px]">
                {t("404.desc")}
              </p>
            </div>
            <div className="flex flex-col gap-3 xs:flex-col lg:flex-row">
              <button
                onClick={handleClick}
                className="border border-gray-300 shadow bg-white rounded-lg flex justify-center items-center z-50 px-[22px] py-4 gap-1 hover:opacity-70 max-h-[60px]"
              >
                <BackArrow /> &nbsp;&nbsp;&nbsp;{" "}
                <p className=" text-slate-700 text-lg font-semibold whitespace-nowrap">
                  {t("404.back")}
                </p>
              </button>
              <Link
                to="/home"
                className="bg-rose-500 rounded-lg  flex justify-center items-center z-50 px-[22px] py-4 hover:bg-rose-400"
              >
                <p className=" text-white text-lg font-semibold text-center whitespace-nowrap">
                  {t("404.home")}
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-[793px] lg:h-screen flex items-center px-4 lg:py-6 lg:pr-6">
        <Img src="/404/404.svg" alt="404" className="w-auto h-full" />
      </div>
    </div>
  );
};
export default NotFound;
