import { useTranslation } from "react-i18next";
import styled from "styled-components";

import BaseModal from "../../../../components/BaseModal";
import Title from "../../../../components/Text/title";
import Img from "../../../../components/Img";
import Text from "../../../../components/Text/text";
import FlexWrapper from "../../../../components/FlexWrapper";
import { Account } from "./types";

interface DetailsProps {
  tempAccount: Account;
  isOpen: boolean;
  onClose: () => void;
  onSet: (currency: any) => void;
}

const Wrapper = styled.div`
  padding: 16px 24px;
  max-width: 408px;
  font-weight: 700;

  svg {
    width: 40px;
    height: 40px;
  }

  .btn {
    margin-top: 0.25rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    font-weight: 400;
    outline: none;
    border-radius: 4px;
  }

  .btn-plain:hover {
    background: #f9fafb;
  }
`;

const FavouriteModal = ({
  tempAccount,
  isOpen,
  onClose,
  onSet,
}: DetailsProps) => {
  const { t } = useTranslation();

  return (
    <BaseModal isOpen={isOpen} onRequestClose={() => onClose()}>
      <Wrapper>
        <FlexWrapper justify="center">
          <Img src="/home/star_rate.png" alt="sepa" />
        </FlexWrapper>

        <Title
          size="2rem"
          mt="1rem"
          weight="700"
          ml="20px"
          mr="20px"
          ta="center"
 
          mb="1rem"
        >
          {t("accounts.setasfavorite")}
        </Title>
        <Text
          mb="1.5rem"
          size="12px"
          ta="center"
          color="#171922"
          ml="16px"
          mr="16px"
        >
          {t("accounts.favorite.text")}
        </Text>

        <button
          className="btn btn-red w-100 uppercase h-10"
          onClick={() => onSet(tempAccount?.Id)}
        >
          {t("accounts.button")}
        </button>
        <button className="btn uppercase w-100 btn-plain h-10" onClick={onClose}>
          {t("common.cancel")}
        </button>
      </Wrapper>
    </BaseModal>
  );
};

export default FavouriteModal;
