import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Scrollbars from "rc-scrollbars";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";

import {
  Result,
  SearchInputWrapper,
  SearchItem,
  SearchWrapper,
} from "../BillPayments.styled";
import Title from "../../../components/Text/title";
import Img from "../../../components/Img";
import { userIdSelector } from "../../../redux/selectors";
import { searchBillServices } from "../../../services/Services";
import Text from "../../../components/Text/text";
import {
  BillService,
  BillServiceItems,
  BillServiceSubItems,
} from "./Categories/types";
import FlexWrapper from "../../../components/FlexWrapper";
import { limitText } from "../../../utils/helpers";
import { useClickOutside } from "../../../hooks/useEvents";
import Input from "../../../components/Form/Input";
import { Mobile } from "../../../components/responsive/responsive";
import { setBillTransferData } from "../../../redux/billSlice";

const BillSearch = () => {
  const [searchValue, setSearchValue] = useState("");
  const userId = useSelector(userIdSelector);
  const [searchArray, setSearchArray] = useState([]);
  const navigate = useNavigate();
  const resultRef = useRef(null);
  const [showResults, setShowResults] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useClickOutside(resultRef, () => {
    setShowResults(false);
  });

  useEffect(() => {
    if (searchValue) {
      searchBillServices(userId, searchValue).then((res) => {
        const items = res.data.Content.map(
          (item: BillService) => item.Items
        ).filter((itemArray: any) => itemArray.length > 0);

        const flattenedArray = items?.flat();
        setSearchArray(flattenedArray);
      });
      setShowResults(true);
    }
  }, [searchValue]);

  return (
    <SearchWrapper>
      <Mobile>
        <div
          className="gap-2 flex items-center mb-4 pointer"
          onClick={() => navigate("/home")}
        >
          <ArrowBackOutlinedIcon style={{ fill: "#344054" }} />{" "}
        </div>
      </Mobile>
      <Title
        className="!text-[24px] md:!text-[32px]"
        weight="700"
        ta="center"
        mb="1rem"
      >
        {t("billpayments.searchtitle")}
      </Title>

      <SearchInputWrapper>
        <span className="w-[336px]">
          <Input
            style={{marginBottom: 0}}
            autoComplete={"off"}
            value={searchValue}
            onChange={(e: any) => setSearchValue(e.target.value)}
            label={t("billpayments.searchservice")}
          />
        </span>

        <button className="!pt-2">
          <Img src={"/SEPA/search.svg"} alt="search icon" />
        </button>
      </SearchInputWrapper>

      {!!searchArray.length && showResults && searchValue && (
        <Result ref={resultRef}>
          <Title
            ml="0.5rem"
            size="12px"
            mt="0"
            lh="12px"
            className="uppercase"
            weight="500"
            color="#000"
          >
            {t("billpayments.result")}
          </Title>
          <Scrollbars style={{ height: 190 }}>
            {searchArray.map((item: BillServiceItems) => (
              <>
                {item?.SubItems?.map((subItem: BillServiceSubItems) => (
                  <SearchItem
                    onClick={() => {
                      dispatch(setBillTransferData(subItem));
                      navigate(`/billpayments/service/${subItem.ServiceId}`)
                    }
                  
                    }
                  >
                    <FlexWrapper mb="0" justify="space-between">
                      <FlexWrapper mb="0">
                        <object
                          width="40px"
                          data={subItem.ServiceUrl}
                          type="image/png"
                        >
                          <Img
                            style={{ marginLeft: 12 }}
                            src="/billpayments/image-fill.svg"
                            alt="fallback"
                          />
                        </object>
                        <div>
                          <Text color="#CDCDCD">{item.SubCategoryName}</Text>
                          <Text color="var(--black)" size="14px">
                            {limitText(subItem.ServiceName, 30)}
                          </Text>
                        </div>
                      </FlexWrapper>
                      <span>
                        <ArrowForwardIosOutlinedIcon />
                      </span>
                    </FlexWrapper>
                  </SearchItem>
                ))}
              </>
            ))}
          </Scrollbars>
        </Result>
      )}
      {searchValue && !searchArray.length && showResults && (
        <Result ref={resultRef}>
          <Title
            ml="0.5rem"
            size="12px"
            mt="0"
            lh="12px"
            className="uppercase"
            color="#000"
            weight="500"
          >
            {t("billpayments.result")}
          </Title>
          <div>
            <Title size="16px" ta="center" color="#000" className="!tracking-tight" mb="4px">
              {t("billpayments.servicenotfound")}
            </Title>
            <Text ta="center" color='#56575B'>{t("billpayments.servicenotfoundtext")}</Text>
          </div>
        </Result>
      )}
    </SearchWrapper>
  );
};

export default BillSearch;
