import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
import cx from "classnames";

import UploadFileOutlinedIcon from "@mui/icons-material/UploadFileOutlined";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

import { setBankTransferData } from "../../../redux/sepaSlice";
import {
  bankTransferConfigDataSelector,
  bankTransferDataSelector,
  langSelector,
} from "../../../redux/selectors";
import Input from "../../../components/Form/Input";
import Text from "../../../components/Text/text";
import Title from "../../../components/Text/title";
import { DetailsForm, Switcher, TransactionType } from "./Details.styled";
import BackButton from "../components/BackButton";
import FlexWrapper from "../../../components/FlexWrapper";
import {
  CardChecker,
  GetCountryCodes,
  IbanChecker,
} from "../../../services/Services";
import Field from "../components/Field";
import BankSearch from "../components/BankSearch";
import { isEmpty } from "../../../utils/helpers";

interface DetailsProps {
  disabled?: boolean;
  onNextPress?: () => void;
  onPreviousPress?: () => void;
}

const renderTTName = (type: string) => {
  if (type === "AccountTransfer") {
    return "IBAN";
  } else if (type === "CardTransfer") {
    return "Card Number";
  }
  return type;
};

const Details = ({ disabled, onNextPress, onPreviousPress }: DetailsProps) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();
  const values = watch();
  const dispatch = useDispatch();
  const [isCompany, setIsCompany] = useState(false);
  const [ibanData, setIbanData] = useState<any>({});
  const [file, setFile] = useState(null);
  const { addToast } = useToasts();
  const bankTransferConfigData = useSelector(bankTransferConfigDataSelector);
  const fields = bankTransferConfigData?.Fields;
  const supportsIban = bankTransferConfigData?.SupportsIban;
  const supportsPhysical = bankTransferConfigData?.SupportsPhysical;
  const supportsLegal = bankTransferConfigData?.SupportsLegal;
  const bankList = fields?.find(
    (field: any) => field.FieldType === "DropDown"
  )?.Items;
  const hasBanks =
    fields?.find((field: any) => field.FieldType === "DropDown") ?? false;
  const hasMobile =
    fields?.find((field: any) => field.FieldType === "Phone") ?? false;

  const initialTransactionType =
    bankTransferConfigData?.TransactionTypes?.length &&
    bankTransferConfigData?.TransactionTypes[0];
  const [showBanks, setShowbanks] = useState(false);
  const [chosenBank, setChosenBank] = useState<any>(null);
  const bankTransferData = useSelector(bankTransferDataSelector);
  const [type, setType] = useState(initialTransactionType);
  const lang = useSelector(langSelector);
  const initialDetails =
    lang === "ka" ? "პირადი გადარიცხვა" : "Private Transfer";
  const isFromActiveTemplate = bankTransferData?.isFromActiveTemplate;
  const [ibanPassed, setIbanPassed] = useState(false);
  const [savedIban, setSavedIban] = useState("");

  const [chosenPhoneCode, setChosenPhoneCode] = useState("");
  useEffect(() => {
    if (hasMobile) {
      GetCountryCodes().then((res) => {
        setChosenPhoneCode(
          res.data.Content.find(
            (item: any) =>
              item.Alpha2 ===
              (bankTransferData?.chosenCountry?.countryCode ||
                bankTransferData?.chosenCountry?.CountryCode)
          )?.Code
        );
      });
    }
  }, [hasMobile]);

  useEffect(() => {
    if (initialTransactionType) {
      setType(initialTransactionType);
    }
  }, [initialTransactionType]);

  useEffect(() => {
    if (ibanPassed && savedIban !== values?.iban?.trim()) {
      setIbanPassed(false);
      setIbanData({});
    }
  }, [values?.iban, savedIban]);

  useEffect(() => {
    if (!isEmpty(bankTransferData)) {
      if (bankTransferData.entityType === "LegalEntity") {
        setIsCompany(true);
        setValue("company_name", bankTransferData.firstName);
      } else {
        setValue("name", bankTransferData.firstName);
        setValue("surName", bankTransferData.lastName);
      }
      setValue("iban", bankTransferData.iban);
      if (bankTransferData.iban) {
        setSavedIban(bankTransferData?.iban);
        setIbanPassed(true);
      }
      setValue("details", bankTransferData.info || initialDetails);
      setValue("accountNumber", bankTransferData.accountNumber);
      setFile(bankTransferData.file);
      bankTransferData.parameters?.map((parameter: any) =>
        setValue(parameter.name, parameter.value)
      );
      // setIbanData(bankTransferData.ibanData);
      setChosenBank(bankTransferData.chosenBank);
      setFile(bankTransferData.file);
      if (
        bankTransferConfigData?.TransactionTypes?.includes(
          bankTransferData.transactionType
        )
      ) {
        setType(bankTransferData.transactionType);
      }
    }
  }, [bankTransferData, setValue]);

  const onSubmit = (data: any) => {
    const fieldData = fields.map((item: any) => item.RequestFieldName);
    const params = Object.fromEntries(
      Object.entries(data).filter(([key]) => fieldData.includes(key))
    );

    const paramResultArray = Object.entries(params).map(([name, value]) => ({
      name,
      value,
    }));

    const detailsData: any = {};
    detailsData.firstName = data.name;
    detailsData.IFSC = data.IFSC;
    detailsData.lastName = data.surName;
    detailsData.phoneNumber = data.mobile;
    detailsData.countryPhoneCode = chosenPhoneCode;
    detailsData.info = data.details;
    detailsData.bic = ibanData?.bic || ibanData?.BankName;
    detailsData.bankName = ibanData?.BankName;
    detailsData.chosenBank = chosenBank;
    detailsData.file = file;
    detailsData.iban = data?.iban?.trim();
    detailsData.ibanData = ibanData;
    detailsData.officeCode = chosenBank?.Value || bankTransferData?.officeCode;
    detailsData.entityType = isCompany ? "LegalEntity" : "Individual";
    detailsData.parameters = paramResultArray;
    detailsData.phoneNumber = data.PhoneNumber;
    detailsData.transactionType = type;
    detailsData.accountNumber = data.accountNumber;

    if (isCompany) {
      detailsData.firstName = data.company_name;
      detailsData.lastName = data.company_name;
    }

    dispatch(setBankTransferData(detailsData));
    onNextPress && onNextPress();
  };

  const handleFileUpload = (e: any) => {
    const data = new FormData();

    data.append("file", e.target.files[0], e.target.files[0]?.name);

    setFile(e.target.files[0]);
  };

  if (showBanks) {
    return (
      <DetailsForm>
        <BankSearch
          bankList={bankList}
          chooseBank={(bank: any) => {
            setChosenBank(bank);
            setShowbanks(false);
          }}
        />
      </DetailsForm>
    );
  }

  return (
    <DetailsForm
      onSubmit={handleSubmit(onSubmit)}
      disabled={disabled}
      className="animate-fadeIn"
    >
      <FlexWrapper className="topwrapper">
        <BackButton onClick={onPreviousPress} />
      </FlexWrapper>
      <Title
        className="title"
        mb="1.5rem"
        size="2rem"
        weight="600"
        lh="2.5rem"
        ta="center"
      >
        {t("banktransfer.details.title")}
      </Title>

      {supportsPhysical && supportsLegal && (
        <div className="m-auto w-full sm:w-[360px]">
          <Switcher disabled={isFromActiveTemplate}>
            {supportsPhysical && supportsLegal && (
              <div
                onClick={() => setIsCompany(false)}
                className={isCompany ? "" : "active"}
              >
                {t("common.individual")}
              </div>
            )}
            {supportsLegal && (
              <div
                onClick={() => setIsCompany(true)}
                className={isCompany ? "active" : ""}
              >
                {t("common.business")}
              </div>
            )}
          </Switcher>
        </div>
      )}
      <div className="wrap">
        {isCompany ? (
          <Input
            disabled={isFromActiveTemplate}
            label={t("SEPA.companyname")}
            {...register("company_name", {
              required: true,
            })}
            hintText={`*${t("common.required")}`}
            errorText={errors.name ? t("common.required") : ""}
          />
        ) : (
          <div>
            <Input
              disabled={isFromActiveTemplate}
              label={t("common.name")}
              {...register("name", {
                required: {
                  message: t("common.required"),
                  value: true,
                },
                pattern: {
                  value: /^[A-Za-z\s]+$/,
                  message: t("form.nameformat"),
                },
              })}
              hintText={`*${t("common.required")}`}
              errorText={errors.name?.message}
              onKeyPress={(event: any) => {
                // eslint-disable-next-line
                if (!/[a-zA-Z\s\\/]+/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
            <Input
              disabled={isFromActiveTemplate}
              label={t("common.lastname")}
              {...register("surName", {
                required: {
                  message: t("common.required"),
                  value: true,
                },
                pattern: {
                  value: /^[A-Za-z\s]+$/,
                  message: t("form.nameformat"),
                },
              })}
              hintText={`*${t("common.required")}`}
              errorText={errors.surName?.message}
              onKeyPress={(event: any) => {
                // eslint-disable-next-line
                if (!/[a-zA-Z\s\\/]+/.test(event.key)) {
                  event.preventDefault();
                }
              }}
            />
          </div>
        )}

        {bankTransferConfigData?.TransactionTypes?.length > 1 && (
          <FlexWrapper gap="0.5rem" mt="0.5rem" mb="1.5rem">
            {bankTransferConfigData?.TransactionTypes.map((item: any) => (
              <TransactionType
                key={item}
                className={type === item ? "active" : ""}
                onClick={() => setType(item)}
              >
                {renderTTName(item)}
              </TransactionType>
            ))}
          </FlexWrapper>
        )}
        {fields?.map((item: any) => (
          <Field
            disabled={isFromActiveTemplate}
            key={item?.FieldTitle}
            onChoseBank={() => {
              setShowbanks(true);
            }}
            props={item}
            chosenBank={chosenBank}
            chosenCountry={bankTransferData?.chosenCountry}
            register={register}
            errors={errors}
            onCountryChoose={(code: any) => {
              setChosenPhoneCode(code);
            }}
          />
        ))}
        {supportsIban ? (
          <>
            <Input
              disabled={isFromActiveTemplate}
              label={type === "AccountTransfer" ? "IBAN" : "Card Number"}
              {...register("iban", {
                required: true,
              })}
              onKeyPress={(event: any) => {
                if (values?.iban?.length > 34) {
                  event.preventDefault();
                }
              }}
              style={{
                fontSize: values?.iban?.length > 18 ? "0.75rem" : "1rem",
                height: 56,
              }}
              hintText={`*${t("common.required")}`}
              errorText={errors.iban ? t("common.required") : ""}
              endAdornment={
                <>
                  {ibanPassed ? (
                    <CheckCircleRoundedIcon
                      style={{ fill: "#388e3c", width: 18, marginRight: 14 }}
                    />
                  ) : (
                    <div className="h-[56px] w-auto flex items-center pl-2 pr-4 mr-[-16px] bg-white">
                      <button
                        disabled={isFromActiveTemplate}
                        className={cx(
                          "btn uppercase",
                          lang === "ka" && "!text-xs !pt-[3px] pb-1"
                        )}
                        style={{
                          transform: "translate(0px, -8px)",
                          background: "#E02629",
                          color: "white",
                          fontWeight: "500",
                          padding: "5px 8px 3px",
                          fontSize: 14,
                          borderRadius: 4,
                          outline: "none",
                          lineHeight: "16px",
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          if (type === "AccountTransfer") {
                            IbanChecker(
                              values.iban.trim(),
                              bankTransferData?.chosenCountry?.CountryId
                            )
                              .then((res) => {
                                setSavedIban(values.iban.trim());
                                setIbanPassed(true);
                                setIbanData(res.data.Content);
                              })
                              .catch((err) => {
                                setIbanData({});
                                addToast(
                                  <div>
                                    {err.response.data.StatusMessage ||
                                      err.response.data.errors?.Iban}
                                  </div>,
                                  {
                                    appearance: "error",
                                    autoDismiss: true,
                                  }
                                );
                              });
                          } else {
                            CardChecker(values.iban)
                              .then((res) => {
                                setIbanPassed(true);
                                setIbanData(res.data.Content);
                              })
                              .catch((err) =>
                                addToast(
                                  <div>
                                    {err.response.data.StatusMessage ||
                                      err.response.data.errors?.Iban}
                                  </div>,
                                  {
                                    appearance: "error",
                                    autoDismiss: true,
                                  }
                                )
                              );
                          }
                        }}
                      >
                        {t("common.check")}
                      </button>
                    </div>
                  )}
                </>
              }
            />
          </>
        ) : (
          <Input
            label={
              type === "CardTransfer"
                ? t("intelexpresscard.cardnumber")
                : t("banktransfer.accountnumber")
            }
            {...register("accountNumber", {
              required: true,
            })}
            onKeyPress={(event: any) => {
              if (values?.iban?.length > 34) {
                event.preventDefault();
              }
            }}
            style={{
              fontSize: values?.iban?.length > 23 ? "0.875rem" : "1rem",
            }}
            hintText={`*${t("common.required")}`}
            errorText={errors.iban ? t("common.required") : ""}
            endAdornment={
              type === "CardTransfer" && (
                <>
                  {ibanPassed ? (
                    <CheckCircleRoundedIcon
                      style={{ fill: "#388e3c", width: 18, marginRight: 14 }}
                    />
                  ) : (
                    <button
                      disabled={isFromActiveTemplate}
                      className="btn uppercase"
                      style={{
                        transform: "translate(0px, -8px)",
                        background: "#E02629",
                        color: "white",
                        fontWeight: "bold",
                        padding: "3px 8px",
                        fontSize: 10,
                        borderRadius: 6,
                        outline: "none",
                      }}
                      onClick={(e) => {
                        e.preventDefault();
                        CardChecker(values.accountNumber)
                          .then((res) => {
                            setIbanPassed(true);
                            setIbanData(res.data.Content);
                          })
                          .catch((err) =>
                            addToast(
                              <div>
                                {err.response.data.StatusMessage ||
                                  err.response.data.errors?.Iban}
                              </div>,
                              {
                                appearance: "error",
                                autoDismiss: true,
                              }
                            )
                          );
                      }}
                    >
                      {t("common.check")}
                    </button>
                  )}
                </>
              )
            }
          />
        )}

        <Input
          label={t("SEPA.paymentdetails")}
          {...register("details", {
            required: true,
          })}
          hintText={`*${t("common.required")}`}
          errorText={errors.details ? t("common.required") : ""}
        />
        <div className="upload-container">
          {file ? (
            <FlexWrapper style={{ cursor: "pointer" }}>
              <span>
                <UploadFileOutlinedIcon style={{ fill: "#B8BBC9" }} />
              </span>
              <FlexWrapper>
                <Text
                  ml="0.5rem"
                  mr="0.35rem"
                  style={{ maxWidth: "90%", wordBreak: "break-all" }}
                >
                  {/* @ts-ignore */}
                  {file?.name}
                </Text>
                {"  "}
                <div
                  onClick={(e) => {
                    setFile(null);
                  }}
                >
                  <HighlightOffOutlinedIcon style={{ fill: "#B8BBC9" }} />
                </div>{" "}
              </FlexWrapper>
            </FlexWrapper>
          ) : (
            <>
              <input
                type="file"
                id="upload"
                hidden
                onChange={handleFileUpload}
                accept="image/*, .pdf, .doc, .docx"
              />
              <label htmlFor="upload">
                <FlexWrapper style={{ cursor: "pointer" }} align="start">
                  <span>
                    <UploadFileOutlinedIcon
                      style={{ fill: "#B8BBC9", width: 20 }}
                    />
                  </span>
                  <div>
                    <Text color="#20202C" ml="0.5rem" mb="0.25rem">
                      {t("SEPA.uploaddocument")}
                    </Text>
                    <Text size="0.65rem" ml="0.5rem" color="#344054">
                      {t("SEPA.filetypes")}
                    </Text>
                    <s></s>
                  </div>
                </FlexWrapper>
              </label>
            </>
          )}
        </div>

        <button
          className="btn btn-red w-100 uppercase"
          disabled={
            (supportsIban && isEmpty(ibanData) && !bankTransferData?.iban) ||
            (hasBanks && !chosenBank) ||
            ((supportsIban || type === "CardTransfer") && !ibanPassed)
          }
        >
          {t("common.next")}
        </button>
      </div>
    </DetailsForm>
  );
};

export default Details;
