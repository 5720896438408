import { useTranslation } from "react-i18next";

import {
  BackButton,
  ButtonsWrapper,
  ContentWrapper,
  PlainButton,
  TopSection,
} from "./ResultModal.styled";
import BaseModal from "../../../components/BaseModal";
import Img from "../../../components/Img";
import Text from "../../../components/Text/text";
import Title from "../../../components/Text/title";
import { getUserId } from "../../../utils/storage";
import { handlePdfDownloadService } from "../../../services/Services";
import { saveFile } from "../../../utils/utils";
import { useToast } from "../../../hooks/useToast";

interface ResultModalProps {
  isOpen: boolean;
  onClose: () => void;
  mode?: string;
  transferId?: number;
}

const ResultModal = ({
  isOpen,
  onClose,
  mode,
  transferId,
}: ResultModalProps) => {
  const { t } = useTranslation();
  const isSuccess = mode === "pending";
  const userId = getUserId();
  const toast = useToast();

  const handlePdfDownload = () => {
    if (transferId) {
      handlePdfDownloadService(userId, transferId)
        .then((res) => {
          saveFile(res, `withdraw-transfer-document-${transferId}`);
        })
        .catch((err) => toast(err.response?.data.StatusMessage, "error"));
    }
  };

  return (
    <BaseModal isOpen={isOpen} onRequestClose={() => onClose()}>
      <ContentWrapper>
        <TopSection>
          <Img
            src={isSuccess ? "/SEPA/success.gif" : "/SEPA/fail.png"}
            alt="result"
          />
          <Title size="2rem" mt="1rem" weight="700" ta="center" lh="2.3rem">
            {isSuccess ? t("withdraw.success.title") : t("withdraw.fail.title")}
          </Title>
        </TopSection>

        <Text ta="center" mb="2rem" color="#171922">
          {isSuccess ? t("withdraw.success.text") : t("withdraw.fail.text")}
        </Text>
        <ButtonsWrapper>
          <BackButton className="btn btn-red" onClick={() => onClose()}>
            {t("common.backtohome")}
          </BackButton>
          {isSuccess && (
            <PlainButton className="btn" onClick={handlePdfDownload}>
              {t("common.downloaddocument")}
            </PlainButton>
          )}
        </ButtonsWrapper>
      </ContentWrapper>
    </BaseModal>
  );
};

export default ResultModal;
