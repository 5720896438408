import styled from "styled-components";
import { DividerProps } from "./Divider";
 

const Wrapper = styled.div<{
  marginLeft?: string;
  marginRight?: string;
  height?: string;
  color?: string;
  width?: string;
}>`
  width: ${(p) => (p.width ? p.width : "1px")};
  background: ${(p) => (p.color ? p.color : "#E4E4E4")};
  margin-left: ${(p) => (p.marginLeft ? p.marginLeft : "0")};
  margin-right: ${(p) => (p.marginRight ? p.marginRight : "0")};
  height: ${(p) => (p.height ? p.height : "0")};
`;

const VerticalDivider = ({
  ml,
  mr,
  width,
  height,
  color,
  className,
}: DividerProps) => {
  return (
    <Wrapper
      className={className}
      marginLeft={ml}
      marginRight={mr}
      height={height}
      width={width}
      color={color}
    ></Wrapper>
  );
};

export default VerticalDivider;
