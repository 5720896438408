import styled from "styled-components";
import { media } from "../../utils/theme";

export const ContentWrapper = styled.div`
 padding: 1rem;
  border-radius: 0 0 11px 11px;
  width: 375px;
  

  button.btn {
    outline: none !important;
  }

  ${media.lessThan("small")} {
    width: 100%;
    padding: 1rem;
    overflow-y: scroll;
    min-height: 100%;
  }
`;

export const TopSection = styled.div`
  padding-top: 0.25rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 1.5rem;
  padding-bottom: 0.5rem;

  p.title {
    font-size: 0.87rem;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
  }

  p.text {
    color: var(--text-color-2);
    font-size: 0.75rem;
    line-height: 1rem;
  }

  p {
    text-align: center;
  }
`;

export const ButtonsWrapper = styled.div`
  button {
    width: 100%;
 
  }
`;

export const BackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  margin-bottom: 0.25rem;

  img {
    filter: brightness(0) invert(1);
    margin-right: 0.5rem;
    max-width: 1.1rem;
  }
`;

export const PlainButton = styled.button<{ disabled: boolean }>`
  margin-bottom: 0.25rem;
  border: 1px solid var(--border-color) !important;
  background: #fff;
  color: var(--text-color-2);

  &:hover {
    background: #f9fafb;
  }
    
  ${(p) =>
    p.disabled &&
    `
        opacity: 0.7;
        pointer-events: none;
    `}
`;

export const Header = styled.div`
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  .variety {
    font-size: 0.875rem;
    color: var(--text-color-2);
  }

  .value {
    color: var(--red);
    font-weight: bold;
    margin-left: 0.5rem;
  }
`;