import styled from "styled-components";
import { useTranslation } from "react-i18next";

import BaseModal from "../../../components/BaseModal";
import Title from "../../../components/Text/title";
import Text from "../../../components/Text/text";
import FlexWrapper from "../../../components/FlexWrapper";
import Img from "../../../components/Img";

interface EditProfilePictureModalProps {
  isOpen: boolean;
  onClose: () => void;
  onChange: () => void;
  onDelete: () => void;
}

const Wrapper = styled.div`
  padding: 24px 24px 16px;
  max-width: 400px;

  @media (max-width: 553px) {
    max-width: 100%;
  }
`;

const EditProfilePictureModal = ({
  isOpen,
  onClose,
  onChange,
  onDelete,
}: EditProfilePictureModalProps) => {
  const { t } = useTranslation();

  return (
    <BaseModal isOpen={isOpen} onRequestClose={() => onClose()}>
      <Img
        className="absolute left-0 top-0"
        src="/transfers/pattern.png"
        alt="pattern"
      />
      <Wrapper className="relative">
        <Title size="18px" weight="600" lh="28px" mb="4px">
          {t("profile.picturetooltiptitle")}
        </Title>
        <Text size="14px" mb="20px">
          {t("profile.changepicture")}
        </Text>
        <div>
          <FlexWrapper
            mb="1rem"
            gap="0.5rem"
            className="hover:bg-[#F7F7F7] cursor-pointer py-2"
            onClick={onChange}
          >
            <div className="w-10 h-10 rounded-full bg-[#F7F7F7] flex justify-center items-center shrink-0">
              <Img src="/home/edit.svg" alt="edit" />
            </div>
            <div>
              <Title mb="0" size="14px">
                {t("common.change")}
              </Title>
              <Text>{t("profile.newpicture")}</Text>
            </div>
          </FlexWrapper>
          <FlexWrapper
            mb="0"
            gap="0.5rem"
            className="hover:bg-[#F7F7F7] cursor-pointer py-2"
            onClick={onDelete}
          >
            <div className="w-10 h-10 rounded-full bg-[#F7F7F7] flex justify-center items-center shrink-0">
              <Img src="/profile/delete.svg" alt="edit" />
            </div>
            <div>
              <Title mb="0" size="14px">
                {t("common.delete")}
              </Title>
              <Text>{t("profile.removepicture")}</Text>
            </div>
          </FlexWrapper>
        </div>
      </Wrapper>
    </BaseModal>
  );
};

export default EditProfilePictureModal;
