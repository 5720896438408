import { useDimensionType, isTabletOrMobile, TYPES, isTabletOrDesktop, windowType  } from '../../hooks/useWindowSize';

const Desktop = (props: any) => {
  const dimensionType = useDimensionType();

  if (dimensionType !== TYPES.desktop) {
    return null;
  }

  return props.children;
};

const TabletOrDesktop = (props: any)  => {
  const dimensionType = useDimensionType();

  if (!isTabletOrDesktop(dimensionType as windowType)) {
    return null;
  }

  return props.children;
};

const TabletOrMobile = (props: any)  => {
  const dimensionType = useDimensionType();

  if (!isTabletOrMobile(dimensionType as windowType)) {
    return null;
  }

  return props.children;
};

const Mobile = (props: any)  => {
  const dimensionType = useDimensionType();

  if (dimensionType !== TYPES.mobile) {
    return null;
  }

  return props.children;
};

const Tablet = (props: any)  => {
  const dimensionType = useDimensionType();

  if (dimensionType !== TYPES.tablet) {
    return null;
  }

  return props.children;
};

export { Desktop, TabletOrMobile, TabletOrDesktop, Mobile, Tablet };