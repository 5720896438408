import { media } from "../../utils/theme";
import styled from "styled-components";

export const BottomWrapper = styled.div`
  background: #fff;
  border-radius: 8px;
  padding: 1.5rem;

  @media (max-width: 1600px) {
    width: 100%;
  }
`;

export const CategoryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;

  ${media.size("large")} {
    flex-direction: column;
  }

  ${media.lessThan("tablet")} {
    gap: 12px;
    flex-direction: column;
    align-items: center;
  }

  ${media.lessThan("small")} {
    flex-direction: column;
    padding: 1.6rem 1rem;
    background: #fcfcfc;
    border: 0.5px solid #e4e4e4;
  }
`;

export const CategoryItem = styled.div<{ disabled?: boolean }>`
  position: relative;
  overflow: hidden;
  background: #fbfbfb;
  flex: 1;
  border: 1px solid #ebebeb;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 1rem;
  cursor: pointer;

  &:hover {
    background: #f7f7f7 !important;
  }

  .bot {
    span a {
      text-decoration: underline;
      color: #3338a8;
    }
  }

  .get-detail {
    text-transform: uppercase;
    color: var(--red);
    cursor: pointer;
  }

  ${media.lessThan("tablet")} {
    max-width: 400px;
    margin-bottom: 1.5rem;

    button {
      width: 100%;
      max-width: 130px;
    }
  }

  ${media.lessThan("small")} {
    width: 100%;
    margin-right: 0;
    flex-shrink: 0;
    padding: 0.25rem 0.25rem 2rem;
    background: white;
    max-width: 100% !important;
    margin-left: 0;
    flex: 1;
    margin-bottom: 0.5rem;

    &:hover {
      background: #f7f7f7 !important;
    }

    button {
      width: 100%;
      max-width: 84px;
    }
  }
`;

export const ViaBankAccountWrapper = styled.div`
  background: #fff;
  padding: 0.5rem;
  z-index: 999;
  backdrop-filter: blur(8px);
  border-radius: 8px;

  &.hide {
    top: -100%;
  }

  .header {
    background: var(--pink);
    padding: 0.5rem;
  }

  .body {
    padding: 0.5rem;

    .account-section {
      margin-bottom: 1rem;
      display: flex;
      align-items: center;

      .account {
        font-size: 0.75rem;
        padding: 0.6rem;
        border-radius: 4px;
        border: 1px solid #e4e4e4;
        margin-left: 0.5rem;
      }
    }

    .copy {
      display: flex;

      svg {
        cursor: pointer;
        margin-left: 0.4rem;
        width: 1.2rem;
        fill: var(--red);
      }
    }

    .back-circle {
      display: flex;
      justify-content: center;
      margin-top: 0.5rem;

      path {
        fill: var(--red);
      }

      .circle {
        cursor: pointer;

        svg {
          transform: rotate(180deg) translate(1px, 0px);
        }
      }
    }
  }
`;

export const TabsWrapper = styled.div`
  background: #f2f2f2;
  padding: 0.25rem 0.25rem 0.09rem;
  border-radius: 4px;
`;

export const TabItem = styled.div<{ isBog?: boolean; isActive?: boolean }>`
  justify-content: center;
  display: flex;
  align-items: ceter;

  border: 1px solid #f7f7f7;
  border-radius: 4px;
  padding: 0.8rem;
  cursor: pointer;
  margin-bottom: 0.25rem;
  background: #f2f2f2;

  &:hover {
    background: #fff;
  }

  ${({ isBog }) =>
    isBog &&
    `
  padding: 0.4rem;
  `}

  ${({ isActive }) =>
    isActive &&
    `
    background: #fff;
  `}
`;
