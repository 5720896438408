import { createSlice } from '@reduxjs/toolkit';

const reportSlice = createSlice({
    name: 'reports',
    initialState: { activeIECard: null },
    reducers: {
        setActiveIECard(state, { payload }) {
            state.activeIECard = payload
        }
    },
})


export const { setActiveIECard } = reportSlice.actions;


export default reportSlice.reducer;
