import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import {
  BackButton,
  ButtonsWrapper,
  ContentWrapper,
  PlainButton,
  SaveasTempalateWrapper,
  TopSection,
} from "./ResultModal.styled";
import BaseModal from "../../../components/BaseModal";
import Img from "../../../components/Img";
import Text from "../../../components/Text/text";
import Title from "../../../components/Text/title";
import { walletDataSelector } from "../../../redux/selectors";
import { getUserId } from "../../../utils/storage";
import { handlePdfDownloadService } from "../../../services/Services";
import { saveFile } from "../../../utils/utils";
import { useToast } from "../../../hooks/useToast";

interface ResultModalProps {
  isOpen: boolean;
  onClose: () => void;
  mode?: string;
  transferId?: number;
}

const ResultModal = ({
  isOpen,
  onClose,
  mode,
  transferId,
}: ResultModalProps) => {
  const { t } = useTranslation();
  const isSuccess = mode === "success";
  const walletData = useSelector(walletDataSelector);
  const userId = getUserId();
  const toast = useToast();

  const handlePdfDownload = () => {
    if (transferId) {
      handlePdfDownloadService(userId, transferId)
        .then((res) => {
          saveFile(res, `wallet-transfer-document-${transferId}`);
        })
        .catch((err) => toast(err.response?.data.StatusMessage, "error"));
    }
  };
 

  return (
    <BaseModal isOpen={isOpen} onRequestClose={() => onClose()}>
      <ContentWrapper>
        <TopSection>
          <Img
            src={isSuccess ? "/SEPA/success.gif" : "/SEPA/fail.png"}
            alt="result"
          />
          <Title
            size="2rem"
            mt="1rem"
            mb="0"
            weight="700"
            ta="center"
            lh="2.3rem"
          >
            {isSuccess
              ? t("wallettowallet.success.title")
              : t("cashpickup.edit.fail.title")}
          </Title>
        </TopSection>
        {isSuccess && (
          <SaveasTempalateWrapper>
            <Img width="20px" src={`/sidebar/new/Logo-tablet.svg`} alt="logo" />
            <Text color={"var(--black)"} mt="0.5rem">
              {walletData?.ReceiverInitials}
            </Text>

            <Text color={"var(--black)"} mt="0.1rem">
              {walletData?.amount} {walletData?.currency}
            </Text>
            <Text color={"var(--black)"} mt="0.1rem">
              {walletData?.countryCodeText} {walletData?.phone}
            </Text>
          </SaveasTempalateWrapper>
        )}
        <Text ta="center" mb="1rem">
          {isSuccess
            ? t("cashpickup.edit.success.text")
            : t("cashpickup.edit.fail.text")}
        </Text>

        <ButtonsWrapper>
          <BackButton className="btn btn-red" onClick={() => onClose()}>
            {t("common.backtohome")}
          </BackButton>
          <PlainButton className="btn" onClick={handlePdfDownload}>
            {t("common.downloaddocument")}
          </PlainButton>
        </ButtonsWrapper>
      </ContentWrapper>
    </BaseModal>
  );
};

export default ResultModal;
