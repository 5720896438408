import React from "react";
import styled from "styled-components";
import { DividerProps } from "./Divider";
 

const Wrapper = styled.div<{
  mt?: string;
  mb?: string;
  ml?: string;
  mr?: string;
  color?: string;
  height?: string;
  width?: string;
}>`
  height: ${(p) => (p.height ? p.height : "6px")};
  width: ${(p) => (p.width ? p.width : "6px")};
  background: ${(p) => (p.color ? p.color : "var(--red)")};
  margin-top: ${(p) => (p.mt ? p.mt : "0")};
  margin-bottom: ${(p) => (p.mb ? p.mb : "0")};
  margin-left: ${(p) => (p.ml ? p.ml : "0")};
  margin-right: ${(p) => (p.mr ? p.mr : "0")};
  display: inline-block;
  border-radius: 50%;
`;

const Dot = ({ mt, mb, ml, mr, color, ...rest }: DividerProps) => {
  return (
    <Wrapper mt={mt} mb={mb} ml={ml} mr={mr} color={color} {...rest}></Wrapper>
  );
};

export default Dot;
