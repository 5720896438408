import styled from "styled-components";
import cx from "classnames";
import { useSelector } from "react-redux";
import { accountsSelector } from "../../../redux/selectors";
import { useEffect, useRef, useState } from "react";
import { Account } from "../../home/Accounts/accounts/types";
import { useOutsideClick } from "../../../hooks/useEvents";
import CloseIcon from "@mui/icons-material/Close";
import FlexWrapper from "../../../components/FlexWrapper";
import { DateItemsWrapper } from "../../Statements/Statements.styled";
import { useTranslation } from "react-i18next";
import { media } from "../../../utils/theme";

const ContentWrapper = styled.div`
  @media (max-width: 768px) {
    .filter {
      right: 20px;
    }
  }

  ${media.lessThan("small")} {
    .filter {
      right: 16px;
    }
  }
`;

type AccountFilterProps = {
  onApply: (filters: string[], accountIds: any) => void;
  isSelected?: boolean;
  accountIds?: number[];
};

const CurrencyFilter = ({
  accountIds,
  isSelected,
  onApply,
}: AccountFilterProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const filterRef = useRef(null);
  const headerRef = useRef(null);
  const [activeFilters, setActiveFilters] = useState<any>(["All"]);
  const [savedActiveFilters, setSavedActiveFilters] = useState<string[]>([
    "All",
  ]);
  const accounts = useSelector(accountsSelector);
  const { t } = useTranslation();

  const currencies = accounts.map((item: Account) => {
    return {
      currency: item.CurrencyName,
      id: item.CurrencyId,
    };
  });
  const handleFilterAdd = (filter: any) => {
    if (filter === "All") {
      // setActiveFilters(accounts.map((item: Account) => item.CurrencyId));
      setActiveFilters(["All"]);
    } else {
      // if (activeFilters.includes(filter)) {
      //   setActiveFilters(
      //     activeFilters.filter((item: any) => item.id !== filter.id)
      //   );
      // } else {
      //   setActiveFilters((prevState: any) => [...prevState, filter]);
      // }

      if (activeFilters.includes(filter)) {
        setActiveFilters(activeFilters.filter((item: any) => item !== filter));
      } else {
        const currentState = activeFilters.filter(
          (filter: string) => filter !== "All"
        );

        setActiveFilters(() => [...currentState, filter]);
      }
    }
  };

  useEffect(() => {
    setActiveFilters(savedActiveFilters);
  }, [isOpen]);

  const handleApplyFilters = () => {
    if (activeFilters.includes("All")) {
      onApply(
        accounts.map((item: Account) => item.CurrencyId),
        accounts.map((item: Account) => item.Id)
      );
      setIsOpen(false);
    } else {
      setIsOpen(false);
      const activeAccountIds = accounts
        .filter((item: Account) => activeFilters.includes(item.CurrencyId))
        .map((item: any) => item.Id);
      onApply(activeFilters, activeAccountIds);
      setSavedActiveFilters(activeFilters);
    }
  };

  useOutsideClick(filterRef, headerRef, () => {
    setIsOpen(false);
  });

  return (
    <ContentWrapper>
      <div
        ref={headerRef}
        className={cx(
          "whitespace-nowrap rounded-lg px-3 py-[5px] h-8 border border-solid border-[#848688] text-sm hover:bg-[#242424] hover:text-white cursor-pointer capitalize",
          isSelected && "bg-[#242424] text-white"
        )}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="font-medium">
          {" "}
          {t("transactionhistory.currency")}
        </span>{" "}
        {isSelected && (
          <span>
            <CloseIcon
              onClick={(e: any) => {
                e.stopPropagation();
                setSavedActiveFilters([]);
                onApply([], []);
              }}
              style={{ width: 18, height: 18, marginRight: -6 }}
            />
          </span>
        )}
      </div>
      <div
        className={cx(
          "absolute mt-2 p-2 w-auto bg-white z-10 border border-solid border-[#EAECF0] rounded-lg filter",
          isOpen ? "block" : "hidden"
        )}
        style={{
          boxShadow: "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814",
        }}
        ref={filterRef}
      >
        <FlexWrapper justify="space-between" mobileDirection="column">
          <div>
            <DateItemsWrapper>
              <div
                className={`item ${
                  activeFilters.includes("All") ? "active" : ""
                }`}
                onClick={() => handleFilterAdd("All")}
              >
                All
              </div>
              {currencies.map((item: any) => (
                <div
                  key={item.currency}
                  className={`item ${
                    activeFilters.includes(item.id) ? "active" : ""
                  }`}
                  onClick={() => handleFilterAdd(item.id)}
                >
                  {item.currency}
                </div>
              ))}
            </DateItemsWrapper>
          </div>
        </FlexWrapper>
        <div className="p-2 pt-6 flex justify-between">
          <button
            className="p-3 text-[14px] bg-transparent text-[#848688] w-auto uppercase"
            onClick={() => setActiveFilters([])}
          >
            {t("common.clear")}
          </button>
          <button
            className="p-3 bg-[var(--red)] text-white w-auto uppercase text-sm"
            onClick={handleApplyFilters}
          >
            {t("transactionhistory.applyfilter")}
          </button>
        </div>
      </div>
    </ContentWrapper>
  );
};

export default CurrencyFilter;
