
import { IconProps } from "./types";

const SendIcon = ({ fill, width, height }: IconProps) => {
    return (
        <svg width={width || "17"} height={height || "14"} viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.0075 2.5225L7.64 4.9375L2 4.1875L2.0075 2.5225ZM7.6325 9.0625L2 11.4775V9.8125L7.6325 9.0625ZM0.5075 0.25L0.5 5.5L11.75 7L0.5 8.5L0.5075 13.75L16.25 7L0.5075 0.25Z" fill={fill || "#8D99AE"} />
        </svg>
    );
};

export default SendIcon;
