import styled from "styled-components";
import cx from "classnames";
import { useSelector } from "react-redux";
import { accountsSelector } from "../../../redux/selectors";
import { useEffect, useRef, useState } from "react";
import { Account } from "../../home/Accounts/accounts/types";
import { useClickOutside, useOutsideClick } from "../../../hooks/useEvents";
import Text from "../../../components/Text/text";
import Scrollbars from "rc-scrollbars";
import CloseIcon from "@mui/icons-material/Close";
import { useTranslation } from "react-i18next";
import { media } from "../../../utils/theme";

const ContentWrapper = styled.div`
  .filter {
    right: 50%;
  }

  @media (max-width: 768px) {
    .filter {
      right: 20px;
    }
  }
  ${media.lessThan("small")} {
    .filter {
      right: 16px;
    }
  }
`;

type AccountFilterProps = {
  onApply: (filters?: string[]) => void;
  isSelected?: boolean;
};

const types = [
  {
    name: "All",
    key: "All",
  },
  {
    name: "Transfer in",
    key: "TransferOut",
  },
  {
    name: "Transfer out",
    key: "TransferIn",
  },
  {
    name: "Cash in",
    key: "CashIn",
  },
  {
    name: "Cash out",
    key: "CashOut",
  },
  {
    name: "Bill Payment",
    key: "BillPayment",
  },
  {
    name: "Currency Exchange",
    key: "CurrencyExchange",
  },
  {
    name: "Commission Charge",
    key: "CommissionCharge",
  },
  {
    name: "Wallet Transfer",
    key: "WalletTransfer",
  },
  {
    name: "eWallet Balance TopUp With Card",
    key: "eWalletBalanceTopUpWithCard",
  },
  {
    name: "Bank Transfer Top Up",
    key: "BankTransferTopUp",
  },
  {
    name: "Sepa Transfer",
    key: "SepaTransfer",
  },
  {
    name: "Money Transfer To BankAccount",
    key: "MoneyTransferToBankAccount",
  },
  {
    name: "TBC Transfer",
    key: "TransferToBankAccountTBC",
  },
  {
    name: "BOG Transfer",
    key: "TransferToBankAccountBOG",
  },
];

const TypeFilter = ({ isSelected, onApply }: AccountFilterProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const filterRef = useRef(null);
  const headerRef = useRef(null);
  const [activeFilters, setActiveFilters] = useState<string[]>(["All"]);
  const [savedActiveFilters, setSavedActiveFilters] = useState<string[]>([
    "All",
  ]);
  const { t } = useTranslation();

  const handleFilterAdd = (filter: any) => {
    if (filter === "All") {
      setActiveFilters(["All"]);
    } else {
      if (activeFilters.includes(filter)) {
        const filtersToSet = activeFilters.filter((item) => item !== "All");
        setActiveFilters(
          filtersToSet.filter((item: any) => item.key !== filter.key)
        );
      } else {
        setActiveFilters((prevState) => {
          const prevFilters = prevState.filter((item) => item !== "All");
          return [...prevFilters, filter];
        });
      }
    }
  };

  useEffect(() => {
    setActiveFilters(savedActiveFilters);
  }, [isOpen]);

  const handleApplyFilters = () => {
    setIsOpen(false);
    onApply(activeFilters);
    setSavedActiveFilters(activeFilters);
  };

  useOutsideClick(filterRef, headerRef, () => {
    setIsOpen(false);
  });

  return (
    <ContentWrapper>
      <div
        ref={headerRef}
        className={cx(
          "whitespace-nowrap rounded-lg px-3 py-[5px] h-8 border border-solid border-[#848688] text-sm hover:bg-[#242424] hover:text-white cursor-pointer",
          isSelected && "bg-[#242424] text-white"
        )}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="font-medium"> {t("transactionhistory.type")}</span>{" "}
        {isSelected && (
          <span>
            <CloseIcon
              onClick={(e: any) => {
                e.stopPropagation();
                setSavedActiveFilters([]);
                onApply(["All"]);
              }}
              style={{ width: 18, height: 18, marginRight: -6 }}
            />
          </span>
        )}
      </div>
      <div
        className={cx(
          "absolute mt-2 w-[240px] bg-white z-10 border border-solid border-[#EAECF0] rounded-lg filter",
          isOpen ? "block" : "hidden"
        )}
        style={{
          boxShadow: "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814",
        }}
        ref={filterRef}
      >
        <Scrollbars autoHeight autoHeightMax={287} autoHeightMin={200}>
          <div className="p-4 pb-12 filter">
            {types.map((item: any) => (
              <div
                key={item.key}
                onClick={() => handleFilterAdd(item.key)}
                className={cx(
                  "flex justify-center py-2 px-4 flex items-center border border-solid border-[#EAECF0] hover:bg-[#F2F4F7] cursor-pointer mb-2 rounded ",
                  activeFilters.includes(item.key) && ` bg-[#F2F4F7]`
                )}
              >
                <Text ta="center">{item.name}</Text>
              </div>
            ))}
          </div>
        </Scrollbars>
        <button
          className="absolute bottom-0 p-4 bg-[var(--red)] text-white w-full rounded-b-lg z-[999] uppercase text-sm"
          onClick={handleApplyFilters}
        >
          {t("transactionhistory.applyfilter")}
        </button>
      </div>
    </ContentWrapper>
  );
};

export default TypeFilter;
