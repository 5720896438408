import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";
// import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { useNavigate } from "react-router-dom";
import Tooltip from "rc-tooltip";

import {
  GetFavouriteAccount,
  // GetGreekAccounts,
  // GetGreekBalances,
  GetUserAccounts,
  SetFavouriteAccount,
  getAllBalances,
  getImage,
  getTotalBalance,
} from "../../../../services/Services";
import {
  getStorageObject,
  getToken,
  getUserId,
  saveStorageObject,
} from "../../../../utils/storage";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import {
  accountsSelector,
  balancesSelector,
} from "../../../../redux/selectors";
import Text from "../../../../components/Text/text";
import Title from "../../../../components/Text/title";
import FlexWrapper from "../../../../components/FlexWrapper";
import Img from "../../../../components/Img";
import {
  setAccounts as setAccountsFromRedux,
  setBalances as setBalancesFromRedux,
} from "../../../../redux/authSlice";
import {
  AccountItem,
  AccountsWrapper,
  // GreeceAccount,
  Wrapper,
} from "./accounts.styled";
import { Account, Balance } from "./types";
import { formatBalance, getCurrencySymbol } from "../../../../utils/helpers";
import FavouriteModal from "./FavouriteModal";
import Star from "../../../../components/Icons/Star";
import "rc-tooltip/assets/bootstrap.css";
import { Skeleton } from "@mui/material";

const Accounts = () => {
  const token = getToken();
  const [accounts, setAccounts] = useState<Account[]>([]);
  const accountsFromRedux = useSelector(accountsSelector);
  const { t } = useTranslation();
  const [active, setActive] = useState(0);
  const [showAmounts, setShowAmounts] = useState<boolean>(
    getStorageObject("showTotal")
  );
  const [isFavouriteOpen, setIsFavouriteOpen] = useState(false);
  // const [greekAccount, setGreekAcount] = useState<any>();
  // const [greekBalances, setGreekBalances] = useState<any>();
  const [favouriteCurrency, setFavouriteCurrency] = useState("");
  const dispatch = useDispatch();
  const userId = getUserId();
  const balances = useSelector(balancesSelector);
  const [tempAccount, setTempAccount] = useState<any>();
  const { addToast } = useToasts();
  const [flags, setFlags] = useState<any>([]);
  const [totalBalance, setTotalBalance] = useState<any>();
  const navigate = useNavigate();

  useEffect(() => {
    if (accounts?.length) {
      var accountCurrencies: string[] = accounts.map(
        (item: any) => item.CurrencyName
      );
      accountCurrencies.map((item: any) => {
        const type = item.split("/").pop();
        getImage(type).then((res) => {
          setFlags((prevState: any) => ({
            ...prevState,
            [item]: res.data,
          }));
        });
      });
    }
  }, [accounts]);

  useEffect(() => {
    GetFavouriteAccount(userId)
      .then((res) => {
        setFavouriteCurrency(res?.data?.Content?.CurrencyName);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    if (!accountsFromRedux.length && userId) {
      GetUserAccounts(userId).then((res) => {
        setAccounts(res.data.content);
        dispatch(setAccountsFromRedux(res.data.Content));
      });
      // GetGreekAccounts(userId).then((res) => {
      //   setGreekAcount(res.data?.Content[0]);
      // });
      // GetGreekBalances(userId).then((res) => {
      //   setGreekAcount(res.data?.Content[0]);
      // });
    }

    getAllBalances(userId)
      .then((res) => {
        dispatch(setBalancesFromRedux(res.data.Content));
        // setAccounts(res.data.Content);
      })
      .catch((err) => {});

    getTotalBalance(userId)
      .then((res) => {
        setTotalBalance(res.data.Content?.Balance);
      })
      .catch((err) => {});
  }, [userId, accountsFromRedux.length, dispatch]);

  useEffect(() => {
    if (accountsFromRedux.length) {
      setAccounts(accountsFromRedux);
    }
  }, [accountsFromRedux, token]);

  const setFavouriteAccountHandler = (account: any) => {
    SetFavouriteAccount(userId, account?.AccountId || tempAccount?.AccountId)
      .then((res) => {
        setFavouriteCurrency(
          account?.CurrencyName || tempAccount?.CurrencyName
        );
        getTotalBalance(userId)
          .then((res) => {
            setTotalBalance(res.data.Content?.Balance);
          })
          .catch((err) => {});
        addToast(<div>{res.data.StatusMessage}</div>, {
          appearance: "success",
          autoDismiss: true,
        });
        setIsFavouriteOpen(false);
      })
      .catch((err) => {
        addToast(<div>{err.response.data.StatusMessage}</div>, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  // if (!accounts?.length) {
  //   return <LoadingIndicator />;
  // }

  return (
    <Wrapper>
      <AccountsWrapper>
        <FlexWrapper justify="space-between">
          <Title size="0.875rem" mb="0" weight="500" className="uppercase">
            {t("history.accounts")}
          </Title>
        </FlexWrapper>
        <Text color="#56575B" mb="0.5rem">
          {t("accounts.totalavailable")}
        </Text>

        <div className="total" style={{ display: "inline-block" }}>
          <FlexWrapper>
            {showAmounts ? (
              <>
                {getCurrencySymbol(
                  accounts?.find(
                    (item: any) => item.CurrencyName === favouriteCurrency
                  )?.CurrencyName
                )}{" "}
                {formatBalance(totalBalance)}
              </>
            ) : (
              totalBalance
                ?.toString()
                .split("")
                .map(() => "* ")
            )}
            <div
              onClick={() => {
                saveStorageObject("showTotal", !showAmounts);
                setShowAmounts(!showAmounts);
              }}
            >
              <Text
                style={{ textDecoration: "underline", cursor: "pointer" }}
                color="var(--text-color)"
                ml="1rem"
              >
                {showAmounts ? (
                  <Img src="/home/eyeslash.svg" alt="eyeslash" />
                ) : (
                  <Img src="/home/eye.svg" alt="eye" />
                )}
              </Text>
            </div>
          </FlexWrapper>
        </div>
        {!balances.length ? (
          <div className="flex gap-4">
            <Skeleton
              variant="rectangular"
              width={123}
              height={117}
              style={{ borderRadius: 4 }}
            />
            <Skeleton
              variant="rectangular"
              width={123}
              height={117}
              style={{ borderRadius: 4 }}
            />
            <Skeleton
              variant="rectangular"
              width={123}
              height={117}
              style={{ borderRadius: 4 }}
            />
            <Skeleton
              variant="rectangular"
              width={123}
              height={117}
              style={{ borderRadius: 4 }}
            />{" "}
          </div>
        ) :   <FlexWrapper mb="0" className="accounts-wrap" gap="1rem">
        {balances?.map((item: Balance, index: number) => (
          <AccountItem
            active={active === index}
            key={item.CurrencyName}
            onClick={() => {
              navigate(`/history?accountid=${item.AccountId}`);
              setActive(index);
            }}
          >
            <FlexWrapper mb="2rem" justify="space-between" align="flex-start">
              <span>
                <div
                  className="flag"
                  style={{
                    width: 32,
                    height: 32,
                    borderRadius: "50%",
                  }}
                  // @ts-ignore
                  dangerouslySetInnerHTML={{
                    __html: flags[item.CurrencyName],
                  }}
                />
              </span>

              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setTempAccount(item);
                  setIsFavouriteOpen(true);
                }}
              >
                <Star
                  fill={
                    favouriteCurrency === item.CurrencyName
                      ? "#FFBD23"
                      : "#E6E6E6"
                  }
                />
              </div>
            </FlexWrapper>

            <Tooltip
              placement="bottom"
              overlayInnerStyle={{
                maxWidth: 300,
                padding: 12,
              }}
              overlay={
                <div className="balance truncate">
                  {showAmounts && getCurrencySymbol(item?.CurrencyName)}{" "}
                  {showAmounts
                    ? formatBalance(item.Balance)
                    : item.Balance.toString().length < 3
                    ? "****".split("").map(() => "* ")
                    : item.Balance?.toString()
                        .split("")
                        .slice(0, 5)
                        .map(() => "* ")}
                </div>
              }
            >
              <div className="balance truncate">
                {showAmounts && getCurrencySymbol(item?.CurrencyName)}{" "}
                {showAmounts
                  ? formatBalance(item.Balance)
                  : item.Balance.toString().length < 3
                  ? "****".split("").map(() => "* ")
                  : item.Balance?.toString()
                      .split("")
                      .slice(0, 5)
                      .map(() => "* ")}
              </div>
            </Tooltip>
            {/* <div className="balance truncate">
              {showAmounts && getCurrencySymbol(item?.CurrencyName)}{" "}
              {showAmounts
                ? formatBalance(item.Balance)
                : item.Balance.toString().length < 3
                  ? "****".split("").map(() => "* ")
                  : item.Balance?.toString()
                    .split("")
                    .slice(0, 5)
                    .map(() => "* ")}
            </div> */}
            <Text
              className="account-name truncate"
              mt="0.2rem"
              color="#56575B"
            >
              {
                accounts?.find(
                  (account: any) => account.Id === item.AccountId
                )?.CustomAccountName
              }
            </Text>
          </AccountItem>
        ))}
      </FlexWrapper>}
      

        {/* {greekAccount?.AccountId && (
          <>
            <Title mt="1.5rem" className="uppercase">
              {t("home.greeceaccount")}
            </Title>
            <GreeceAccount>
              <FlexWrapper mb="0" style={{ flex: 1 }}>
                <span>
                  <Img src="/home/greece-wallet.svg" alt="wallet" />
                </span>
                <div>
                  <Title ml="0.875rem" mb="0">
                    {greekAccount?.CustomAccountName || "GREECE"}
                  </Title>
                  <Text ml="0.875rem" size="1rem" color="#949494">
                    {getCurrencySymbol(greekAccount?.CurrencyName)}{" "}
                    {greekAccount?.Balance}
                  </Text>
                </div>
              </FlexWrapper>

              <ArrowForwardIosOutlinedIcon />
            </GreeceAccount>
          </>
        )} */}
      </AccountsWrapper>
      <FavouriteModal
        tempAccount={tempAccount}
        isOpen={isFavouriteOpen}
        onClose={() => setIsFavouriteOpen(false)}
        onSet={setFavouriteAccountHandler}
      />
    </Wrapper>
  );
};

export default Accounts;
