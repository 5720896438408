import React from "react";
import cx from "classnames";

const Box = ({ className, children }: any) => {
  return (
    <div
      className={cx(
        "[@media(min-width:554px)]:p-6 p-4 rounded-lg bg-white border-solid border-[1px] border-[#EBEBEB]",
        className
      )}
    >
      {children}
    </div>
  );
};

export default Box;
