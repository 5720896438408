import moment from "moment";
import { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { useTranslation } from "react-i18next";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import RemoveOutlinedIcon from "@mui/icons-material/RemoveOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import cx from "classnames";

import Title from "../../components/Text/title";
import Text from "../../components/Text/text";
import Divider from "../../components/Divider";
import FlexWrapper from "../../components/FlexWrapper";
import Img from "../../components/Img";
import Star from "../../components/Icons/Star";
import {
  GetFavouriteAccount,
  handleCardStatementDownloadService,
  handleStatementDownloadService,
} from "../../services/Services";
import { useDispatch, useSelector } from "react-redux";
import { userIdSelector } from "../../redux/selectors";
import { getCurrencySymbol } from "../../utils/helpers";
import Balance from "../../components/Balance";
import AccountsModal from "../../components/AccountsModal";
import { setLoading } from "../../redux/commonSlice";
import CalendarModal from "./CalendarModal";
import {
  AccountsItem,
  DateInputs,
  DateItemsWrapper,
  TitleWrapper,
  Wrapper,
} from "./Statements.styled";
import {
  Mobile,
  TabletOrDesktop,
} from "../../components/responsive/responsive";
import { useNavigate } from "react-router";
import { saveFile } from "../../utils/utils";
import { useToast } from "../../hooks/useToast";

const dates = [
  {
    id: 0,
    name: "history.current",
  },
  {
    id: 1,
    name: "common.1month",
  },
  {
    id: 2,
    name: "common.3months",
  },
  {
    id: 3,
    name: "common.6months",
  },
  {
    id: 3,
    name: "common.1year",
  },
];

const Statements = () => {
  const { t } = useTranslation();
  const [chosenAccount, setChosenAccount] = useState<any>();
  const userId = useSelector(userIdSelector);
  const [date1, setDate1] = useState(
    moment(new Date()).subtract("days", 30).toISOString()
  );
  const [date2, setDate2] = useState(
    moment(new Date()).add(4, "hours").toISOString()
  );
  const [accountsModalOpen, setAccountsModalOpen] = useState(false);
  const { addToast } = useToasts();
  const toast = useToast();
  const dispatch = useDispatch();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [dateText, setDateText] = useState("");
  const [showCustomInputs, setShowCustomInputs] = useState(false);
  const [chosenKey, setChosenKey] = useState("history.current");
  const [cardMode, setCardMode] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (userId) {
      GetFavouriteAccount(userId).then((res) =>
        setChosenAccount(res.data.Content)
      );
    }
  }, [userId]);

  const handlePdfDownload = () => {
    dispatch(setLoading(true));
    if (cardMode) {
      handleCardPdfDownload();
    } else {
      handleStatementDownloadService(userId, date1, date2, chosenAccount.Id)
        .then((response: any) => {
          if (response.status === 204) {
            addToast(<div>{t("statements.emptypdf")}</div>, {
              appearance: "error",
              autoDismiss: true,
            });
            dispatch(setLoading(false));
            return;
          }
          const dates = `${moment(date1).format("DD-MM-YYYY")}-${moment(
            date2
          ).format("DD-MM-YYYY")}`;
          dispatch(setLoading(false));
          saveFile(response, `statement-list-${dates}`);
        })
        .catch((err) => {
          toast(err.response.data?.StatusMessage || t("common.error"), "error");
        });
    }
  };

  const handleCardPdfDownload = () => {
    handleCardStatementDownloadService(
      userId,
      date1,
      date2,
      chosenAccount?.CardId
    )
      .then((response: any) => {
        if (response.status === 204) {
          addToast(<div>{t("statements.emptypdf")}</div>, {
            appearance: "error",
            autoDismiss: true,
          });
          dispatch(setLoading(false));
          return;
        }
        const dates = `${moment(date1).format("DD-MM-YYYY")}-${moment(
          date2
        ).format("DD-MM-YYYY")}`;
        dispatch(setLoading(false));
        saveFile(response, `card-statement-list-${dates}`);
      })
      .catch((err) => {
        dispatch(setLoading(false));
        toast(err.response.data?.StatusMessage || t("common.error"), "error");
      });
  };

  const handleDateSet = (date: string) => {
    setChosenKey(date);
    let date1;
    let date2 =
      cardMode && date === "common.1year"
        ? moment(new Date()).subtract(2, "d").toISOString()
        : moment(new Date()).add(4, "hours").toISOString();
    if (date === "history.current") {
      date1 = moment(date2).startOf("month").toISOString();
      setDate1(date1);
    } else if (date === "common.1month") {
      date1 = moment(date2).subtract(1, "M").toISOString();
      setDate1(date1);
    } else if (date === "common.3months") {
      date1 = moment(date2).subtract(3, "M").toISOString();
      setDate1(date1);
    } else if (date === "common.6months") {
      date1 = moment(date2).subtract(6, "M").toISOString();
      setDate1(date1);
    } else if (date === "common.1year") {
      date1 = moment(new Date()).subtract(12, "M").add(1, "d").toISOString();
      setDate1(date1);
    }
    setDate2(date2);

    setDateText(
      `${moment(date1).format("DD.MM.YYYY")}/${moment(date2).format(
        "DD.MM.YYYY"
      )}`
    );

    // setDateText(
    //   t("transactionhistroy.selecteddate", {
    //     from: moment(date1).format("DD.MM.YYYY"),
    //     to: moment(date2).format("DD.MM.YYYY"),
    //   })
    // );
  };

  return (
    <>
      <TitleWrapper>
        <FlexWrapper mb="0" gap="0.5rem">
          <Mobile>
            <ArrowBackOutlinedIcon
              onClick={() => navigate("/home")}
              className="cursor-pointer"
            />
            <Title size="1.5rem" weight="700" mobileSize="1.5rem" mb="0">
              {t("statements.title")}
            </Title>
          </Mobile>

          <TabletOrDesktop>
            <Title
              size="1.5rem"
              weight="700"
              mobileSize="1.5rem"
              mb="0"
              mt="22px"
            >
              {t("statements.title")}
            </Title>
          </TabletOrDesktop>
        </FlexWrapper>

        <Text
          size="14px"
          color="#171922"
          mt="1rem"
          className="font-['Roboto'] max-w-[487px]"
        >
          {t("statements.subtitle")}
        </Text>
      </TitleWrapper>

      <Wrapper>
        <Title
          size="12px"
          ml="1rem"
          mb="4px"
          className="uppercase !font-semibold"
        >
          {t("accounts.title")}
        </Title>
        <Divider width="100%" mb="1rem" />

        <FlexWrapper className="!items-start !flex-col-reverse [@media(min-width:651px)]:!flex-row !justify-between !mb-4">
          <AccountsItem onClick={() => setAccountsModalOpen(true)}>
            <FlexWrapper mb="0">
              <span className="shrink-0">
                {cardMode ? (
                  <Img width={30} src="/home/cards/card.svg" alt="card" />
                ) : (
                  <Img width={30} src="/home/greece-wallet.svg" alt="wallet" />
                )}
              </span>
              <div>
                <Title
                  ml="0.875rem"
                  mb="4px"
                  className="!text-[14px]  !font-medium font-['Roboto']"
                >
                  {cardMode
                    ? chosenAccount?.AccountId
                    : chosenAccount?.CustomAccountName}
                </Title>
                <Text ml="0.875rem" size="0.75rem" color="#949494">
                  {cardMode ? (
                    <span>
                      {" "}
                      {chosenAccount?.CurrentBalance} {chosenAccount?.Currency}
                    </span>
                  ) : (
                    <Balance currency={chosenAccount?.CurrencyName} />
                  )}
                  <strong>
                    {getCurrencySymbol(chosenAccount?.CurrencyName)}
                  </strong>
                </Text>
              </div>
            </FlexWrapper>
            <div
              style={{
                display: "inline-block",
                marginLeft: 4,
                transform: "translateY(3px)",
              }}
            >
              {chosenAccount?.IsFavourite === true ? (
                <div className="ml-[2px] h-4">
                  <Star />
                </div>
              ) : (
                <></>
              )}
              <div>
                <Img src="/billpayments/arrows-down.svg" alt="arrows" />
              </div>
            </div>
          </AccountsItem>
          <div className="justify-center items-center sm:h-[74px] flex h-[54px]">
            <button
              className="btn btn-red export items-center !py-[10px] !px-4"
              onClick={handlePdfDownload}
            >
              <Img src="/statements/cloud_download.svg" alt="export" />
              {t("common.export")}
            </button>
          </div>
        </FlexWrapper>

        <Title
          size="12px"
          ml="1rem"
          mb="4px"
          className="uppercase !font-semibold"
        >
          {t("statements.period")}
        </Title>
        <Divider width="100%" mb="1rem" />
        <Mobile>
          {dateText && (
            <Text
              mt="0.5rem"
              color={"#E02629"}
              weight="600"
              mb="0.5rem"
              size="14px"
            >
              {dateText}
            </Text>
          )}
        </Mobile>
        <FlexWrapper
          mb={"0px"}
          justify="space-between"
          gap="0.5rem"
          mobileDirection="column"
        >
          <div>
            <DateItemsWrapper>
              {dates.map((item) => (
                <div
                  key={item.name}
                  className={`item ${chosenKey === item.name ? "active" : ""}`}
                  onClick={() => handleDateSet(item.name)}
                >
                  {t(item.name)}
                </div>
              ))}
            </DateItemsWrapper>
          </div>

          <div
            className={cx("custom", showCustomInputs && "bg-[#f9fafb] rounded")}
            onClick={() => {
              setShowCustomInputs(!showCustomInputs);
            }}
          >
            {!showCustomInputs ? <AddOutlinedIcon /> : <RemoveOutlinedIcon />}{" "}
            <Text className={cx("!font-semibold text-center")}>
              {t("statements.customdate")}
            </Text>
          </div>
        </FlexWrapper>
        <TabletOrDesktop>
          {dateText && (
            <Text
              mt="0.5rem"
              color={"#E02629"}
              weight="600"
              mb="0.5rem"
              size="14px"
            >
              {dateText}
            </Text>
          )}
        </TabletOrDesktop>
        {showCustomInputs && (
          <DateInputs className="mt-4">
            <div style={{ flex: 1 }}>
              <Text mb="0.25rem" size="14px" color="#171922">
                {t("common.from")}
              </Text>
              <div
                onClick={() => setIsCalendarOpen(true)}
                className="input text-sm"
              >
                {moment(date1).format("DD.MM.YYYY") || "DD.MM.YYYY"}
              </div>
            </div>

            <div style={{ flex: 1 }}>
              <Text mb="0.25rem" size="14px" color="#171922">
                {t("common.to")}
              </Text>
              <div
                onClick={() => setIsCalendarOpen(true)}
                className="input text-sm"
              >
                {moment(date2).subtract(4, "h").format("DD.MM.YYYY") ||
                  "DD.MM.YYYY"}
              </div>
            </div>
          </DateInputs>
        )}
      </Wrapper>

      <AccountsModal
        withCards
        isOpen={accountsModalOpen}
        onClose={() => setAccountsModalOpen(false)}
        onContinue={(acc, mode) => {
          if (mode) {
            setCardMode(mode);
          } else {
            setCardMode(false);
          }
          setChosenAccount(acc);
          setAccountsModalOpen(false);
        }}
      />

      <CalendarModal
        isOpen={isCalendarOpen}
        onClose={() => setIsCalendarOpen(false)}
        onGenerateClick={(date1: any, date2: any) => {
          setDate1(moment(date1).startOf("day").add(4, "h").toISOString());
          setDate2(moment(date2).endOf("day").add(4, "h").toISOString());
          setIsCalendarOpen(false);

          setDateText(
            t("transactionhistroy.selecteddate", {
              from: moment(date1).format("DD.MM.YYYY"),
              to: moment(date2).format("DD.MM.YYYY"),
            })
          );
        }}
      />
    </>
  );
};

export default Statements;
