import { combineReducers } from "@reduxjs/toolkit";

import transferSlice from "./transferSlice";
import authSlice from "./authSlice";
import reportSlice from "./reportSlice";
import sepaSlice from "./sepaSlice";
import commonSlice from "./commonSlice";
import billSlice from "./billSlice";

const combineSlices = combineReducers({
  authSlice,
  transferSlice,
  reportSlice,
  sepaSlice,
  commonSlice,
  billSlice,
});

export default combineSlices;
