import styled from "styled-components";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";

import Input from "../../../../components/Tailwind/Input";
import { FORM_PATTERNS } from "../../../../constants";
import BaseModal from "../../../../components/BaseModal";
import { userSelector } from "../../../../redux/selectors";
import Text from "../../../../components/Text/text";
import Title from "../../../../components/Text/title";
import Img from "../../../../components/Img";

interface DetailsProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (email: string, password: string) => void;
}

const Wrapper = styled.div`
  padding: 24px;
  max-width: 400px;

  @media (max-width: 553px) {
    max-width: 100%;
  }

  svg {
    width: 40px;
    height: 40px;
  }

  .text {
    background: #e0e7f1;
    padding: 0.3rem 1rem;
    border-radius: 4px;
  }

  .btn {
    width: 50%;
  }
`;

const EmailChangeModal = ({ isOpen, onClose, onConfirm }: DetailsProps) => {
  const { t } = useTranslation();
  const [password, setPassword] = useState("");
  const [step, setStep] = useState(0);
  const [email, setEmail] = useState("");
  const [errorText, setErrorText] = useState("");
  const user = useSelector(userSelector);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (email) {
      !FORM_PATTERNS.email.value.test(email)
        ? setErrorText("Invalid format")
        : setErrorText("");
    } else if (!email) {
      setErrorText("");
    }
  }, [email]);

  useEffect(() => {
    if (!isOpen) {
      setStep(0);
      setEmail("");
      setPassword("");
    }
  }, [isOpen]);

  if (step === 0) {
    return (
      <BaseModal isOpen={isOpen} onRequestClose={() => onClose()}>
        <Img
          className="absolute left-0 top-0"
          src="/transfers/pattern.png"
          alt="pattern"
        />
        <Wrapper className="relative">
          <Title weight="bold" lh="28px" size="1.5rem" mb="4px">
            {t("profile.changeinfo")}
          </Title>
          <Text mb="1.25rem" size="14px">
            {t("profile.phonesubtitle")}
          </Text>
          <Input
            autoComplete="off"
            placeholder={`${t("common.password")}*`}
            value={password}
            onChange={(e: any) => setPassword(e.target.value)}
            type={showPassword ? "text" : "password"}
            endAdornment={
              <img
                src={
                  showPassword
                    ? "/assets/auth/eyeslash.svg"
                    : "/assets/auth/eye.svg"
                }
                alt={showPassword ? "eyeslash" : "eye"}
                className={` hover:bg-[#F5F5F5] inline-block cursor-pointer hover:rounded-full p-2 focus:outline-none outline-none focus:bg-[#F5F5F5] focus:rounded-full overflow-visible `}
                aria-label="toggle password visibility"
                onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                  e.stopPropagation();
                  setShowPassword(!showPassword);
                }}
                onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
                  if (e.key === " ") {
                    e.preventDefault();
                    setShowPassword(!showPassword);
                  }
                }}
              />
            }
          />
          <div className="flex gap-4 items-center mt-6">
            <button
              className="w-[50%] border-[1px] h-11 border-solid border-[#D0D5DD] rounded-lg py-3"
              onClick={onClose}
            >
              {t("common.cancel")}
            </button>
            <button
              disabled={!password}
              className="btn btn-red py-3 h-[44px] !rounded-lg"
              onClick={() => setStep(1)}
            >
              {t("common.confirm")}
            </button>
          </div>
        </Wrapper>
      </BaseModal>
    );
  }

  return (
    <BaseModal isOpen={isOpen} onRequestClose={() => onClose()}>
      <Wrapper className="!p-4">
        <Title weight="700" lh="28px" size="1.5rem">
          {t("profile.editemail")}
        </Title>
        <Text mb="1rem" size="14px">
          {t("profile.editsubtitle")}
        </Text>
        <Text mb="0.5rem" size="12px" color="#56575B">
          {t("profile.existing")}
        </Text>

        <Text mb="1rem" size="16px" color="#171922">
          {user?.email}
        </Text>
        <Input
        className="mb-6"
          placeholder={t("profile.newemail")}
          errorText={errorText}
          value={email}
          onChange={(e: any) => setEmail(e.target.value)}
        />
        <div className="flex gap-4 items-center mt-8 mb-6">
          <button
            className="w-[50%] border-[1px] h-11 border-solid border-[#D0D5DD] rounded-lg py-3"
            onClick={onClose}
          >
            {t("common.cancel")}
          </button>
          <button
            //   @ts-ignore
            disabled={!email || errorText.length}
            className="btn btn-red py-3 h-[44px] !rounded-lg"
            onClick={() => (errorText ? {} : onConfirm(email, password))}
          >
            {t("common.confirm")}
          </button>
        </div>
      </Wrapper>
    </BaseModal>
  );
};

export default EmailChangeModal;
