import { IconProps } from "./types";


const Checkmark = ({ fill, width, height, ...rest }: IconProps) => {
    return (
        <svg width={width || "18"} height={height || "13"} viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest} >
            <path d="M5.50012 10.2001L2.00012 6.70007C1.61012 6.31007 0.990117 6.31007 0.600117 6.70007C0.210117 7.09007 0.210117 7.71007 0.600117 8.10007L4.79012 12.2901C5.18012 12.6801 5.81012 12.6801 6.20012 12.2901L16.8001 1.70007C17.1901 1.31007 17.1901 0.690068 16.8001 0.300068C16.4101 -0.0899316 15.7901 -0.0899316 15.4001 0.300068L5.50012 10.2001Z" fill={fill || "#757582"} />
        </svg>
    );
};

export default Checkmark;
