import styled from "styled-components";
import { useEffect, useState } from "react";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
import { useTranslation } from "react-i18next";

import Input from "../../../components/Form/Input";
import InputWithDropdown from "../../../components/Form/InputWithDropdown";
import FlexWrapper from "../../../components/FlexWrapper";
import { GetCountryCodes, getImage } from "../../../services/Services";
import { preventNonNumeric } from "../../../utils/utils";
import { FORM_PATTERNS } from "../../../constants";

const BankChooser = styled.div<{ disabled?: boolean }>`
    flex: 1;
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    padding: 18px 0;
    box-sizing: border-box;
    position: relative;
    cursor: text;
    display: inline-flex;
    align-items: center;
    position: relative;
    border-radius: 4px;
    padding-left: 14px;
    padding-right: 14px;
    border: 1px solid rgba(0, 0, 0, 0.20);
    cursor: pointer;
    width: 300px;
    margin-bottom: 20px;

      p {
          flex: 1;
      }

      svg {
          height: 20px;
      }
    }

    ${({disabled}) => disabled && `
      opacity: 0.5;
      pointer-events: none;
    `}
 `;

const MobileWrapper = styled.div`
  .select {
    min-width: 100px;
  }

  .notranslate {
    padding: 12px;
  }
`;

const Field = ({
  props,
  chosenCountry,
  chosenBank,
  onChoseBank,
  register,
  disabled,
  errors,
  onCountryChoose,
}: any) => {
  const { t } = useTranslation();
  const [countryCodes, setCountryCodes] = useState<any>([]);
  const [chosenCode, setChosenCode] = useState("");
  const [sendImage, setSendImage] = useState<any>(null);

  useEffect(() => {
    if (chosenCountry) {
      getImage(chosenCountry?.CountryCode || chosenCountry.countryCode).then(
        (res) => setSendImage(res.data)
      );
    }
  }, [chosenCountry]);

  useEffect(() => {
    if (props.FieldType === "Phone") {
      GetCountryCodes().then((res) => {
        setCountryCodes(res.data.Content);
        setChosenCode(
          res.data.Content.find(
            (item: any) =>
              item.Alpha2 ===
              (chosenCountry?.countryCode || chosenCountry?.CountryCode)
          )?.Code
        );
      });
    }
  }, [chosenCode, props?.FieldType]);

  if (props.FieldType === "DropDown") {
    return (
      <FlexWrapper justify="between">
        <BankChooser onClick={onChoseBank} disabled={disabled}>
          <p>{chosenBank?.Name || chosenBank?.OfficeName || props.FieldTitle || `${t('SEPA.bankname')}*`}</p>{" "}
          <ArrowForwardIosOutlinedIcon />
        </BankChooser>
      </FlexWrapper>
    );
  }

  if (props.FieldType === "Phone") {
    return (
      <MobileWrapper>
        <InputWithDropdown
          disabled={disabled}
          withSearch
          selectOptions={countryCodes}
          initialSelectValue={chosenCode}
          selectPlaceholder="+995"
          idKey="Id"
          nameKey="Code"
          shrink={true}
          selectImage={sendImage}
          reverseDropdown={true}
          onSelect={(val: number) => {
            const country = countryCodes.find((item: any) => item.Id === val);
            getImage(country?.Alpha2).then((res) => setSendImage(res.data));
            onCountryChoose(country?.Code);
          }}
          label={props.FieldTitle}
          {...register(props.RequestFieldName, {
            required: props.IsRequired,
            validate: (value: any) =>
              FORM_PATTERNS.minThreeMaxTwentyFiveChars.value.test(value),
          })}
          onKeyPress={preventNonNumeric}
          hintText={`*${t("common.required")}`}
          errorText={errors[props.FieldTitle] ? t("common.required") : ""}
          withCountryFlag={true}
          countryFlagKey={"Alpha2"}
        />
      </MobileWrapper>
    );
  }

  return (
    <Input
      disabled={disabled}
      label={props.FieldTitle}
      {...register(props.FieldTitle, {
        required: props.IsRequired,
      })}
      hintText={props.IsRequired ?? `*${t("common.required")}`}
      errorText={errors[props.FieldTitle] ? t("common.required") : ""}
    />
  );
};

export default Field;
