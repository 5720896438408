import styled from "styled-components";
import { media } from "../../../../utils/theme";

export const CategoryWrapper = styled.div`
  background: #fff;
  border-radius: 8px;
  border: 1px solid #ebebeb;
  margin-top: 1.5rem;
  padding: 1rem;


  .items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
  }

  .subitems {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
  }

  ${media.lessThan("tablet")} {
    .items {
      display: flex;
      flex-direction: column;
    }
  }

 @media (max-width:800px) {
    .subitems {
      grid-template-columns: repeat(3, 1fr);
    }
  }

  @media (max-width:650px) {
    .subitems {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width:360px) {
    .subitems {
      grid-template-columns: repeat(1, 1fr);
    }
  }

`;

export const CategoryItem = styled.div<{ isActive?: boolean; }>`
  border-radius: 4px;
  border: 1px solid #ebebeb;
  background: #fbfbfb;
  padding: 1rem;

  ${({ isActive }) =>
    isActive &&
    ` 
  cursor: pointer;
    background: var(--black);

    p {
      color: #fff;
    }`}

  &:hover {
    cursor: pointer;
    background: var(--black);

    p {
      color: #fff;
    }
  }

  ${media.lessThan("small")}{
    padding: 0.75rem;
  }
`;

export const ServiceItem = styled.div`
  border-radius: 4px;
  border: 1px solid #ebebeb;
  background: #fff;
  padding: 0.75rem 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  &:hover {
    cursor: pointer;
    background: #fbfbfb;
  }
`;
