import { useToasts, AppearanceTypes, Options } from "react-toast-notifications";

type ToastType = AppearanceTypes;

export type ToastFunction = (
  message: React.ReactNode,
  type?: ToastType,
  options?: ToastOptions
) => void;

interface ToastOptions extends Omit<Options, "appearance"> {}

const useToastManager = () => {
  const { addToast } = useToasts();

  const showToast = (message: React.ReactNode, options: Options) => {
    addToast(
      typeof message === "string" ? message : <div>{message}</div>,
      options
    );
  };

  return showToast;
};

export const useToast = () => {
  const showToast = useToastManager();

  return (
    message: React.ReactNode,
    type: ToastType = "success",
    options: ToastOptions = {}
  ) => {
    const defaultOptions: Options = {
      appearance: type,
      autoDismiss: true,
    };

    const mergedOptions: Options = { ...defaultOptions, ...options };
    showToast(message, mergedOptions);
  };
};
