import styled from "styled-components";
import { media } from "../../../utils/theme";

export const DestinationForm = styled.div<{
  disabled?: boolean;
  focused?: boolean;
}>`
  background: #fff;
  box-shadow: 0px 2px 3px rgba(111, 118, 137, 0.1);
  border: 1px solid #eaecf0;
  border-radius: 10px;
  width: 736px;
  padding: 1rem;
  margin-left: 1.5rem;
  flex-shrink: 0;
  margin: 0 auto;

  .wrap {
    width: 360px;
    margin: auto;
    position: relative;

    button {
      background: none;
      outline: none;
      border: none;
      position: absolute;
      right: 12px;
      top: 10px;
      cursor: pointer;
      padding: 0.5rem 0.5rem 0.25rem;
      border-radius: 4px;
    }
  }

  button.red {
    background: #e02629;
    width: 44px;
    height: 44px;
    position: absolute;
    top: 6px;
    right: 1rem;

    img {
      filter: brightness(0) invert(1);
    }
  }

  input {
    flex: 1 !important;
    width: 100%;
    min-width: 224px;
  }

  .MuiInputBase-root {
    &:hover {
      fieldset {
        border-color: rgba(0, 0, 0, 0.23) !important;
      }
    }
  }

  ${({ focused }) =>
    focused &&
    `
    .MuiInputBase-root {
    fieldset {
          margin-top:0.5px;
        }
      &:hover {
        fieldset {
          border-color: var(--blue) !important;
        }
      }
    }

  `}

  .mobile-input {
    flex: 1 !important;
    border-left: none !important;

    fieldset {
      border-left: none;
      border-radius: 0 4px 4px 0;
    }
  }

  .search-wrapper {
    input {
      flex: 1 !important;
      width: 100%;
      min-width: 300px;
    }
    button {
      right: 0px;
    }
  }

  opacity: ${(p) => (p.disabled ? "0.5" : "1")};
  pointer-events: ${(p) => (p.disabled ? "none" : "auto")};

  ${media.lessThan("tablet")} {
    box-shadow: none;
    margin-left: 0;
    width: 100%;
    width: 80%;
    margin: 0 auto;
  }

  ${media.lessThan("small")} {
    .wrap {
      width: 100%;
    }

    input {
      min-width: 140px;
    }

    box-shadow: none;
    margin-left: 0;
    width: 100%;
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  @media (max-width: 480px) {
    .topwrapper {
      flex-direction: column;
      align-items: flex-start;
    }

    .title {
      font-size: 22px;
      margin-left: 0;
      text-align: left;
    }
  }
`;

export const CountryCode = styled.div<{ focused?: boolean }>`
  display: flex;
  align-items: center;
  height: 56px;
  padding: 0px 6px 0px 12px;
  border: 1px solid rgba(0, 0, 0, 0.23);
  border-right: 0;
  border-radius: 4px 0 0 4px;
  cursor: pointer;
  flex: 1;

  ${({ focused }) =>
    focused &&
    `  
    border: 2px solid var(--blue);
       border-right: 0;
       height: 56px;
       `}

  svg {
    width: 24px;
    height: 24px;
  }

  @media (max-width: 800px) {
    flex: 0;
    max-height: 56px;
    margin-top: 0px;

    ${({ focused }) =>
      focused &&
      ` 
       padding: 26px 6px 26px 12px;
      `}
    }
  }
`;
