import React from "react";
import { useSelector } from "react-redux";
// import type { Rate } from "../containers/Exchange/Exchange";
import { ratesSelector } from "../redux/selectors";
import { Rate } from "../containers/home/Rates/Rates";

interface SelleRateProps {
  currency1: string;
  currency2: string;
}

export const getSellRate = (
  rates: any,
  currency1: string,
  currency2: string
) => {
  let rate = "";

  if (currency1 === "GBP") {
    rate = rates.find(
      (item: Rate) =>
        item.CreditCurrency === currency1 && item.DebitCurrency === currency2
    )?.SellingRate;
  } else if (currency1 === "EUR" && currency2 === "GBP") {
    rate = rates.find(
      (item: Rate) => item.DebitCurrency === currency1
    )?.SellingRate;
  }  else if (currency1 === "EUR" && currency2 === "GEL") {
    rate = rates.find(
      (item: Rate) =>
        item.DebitCurrency === currency2 && item.CreditCurrency === currency1
    )?.SellingRate;
 
  }  else if (currency1 === "EUR" && currency2 === "USD") {
    rate = rates.find(
      (item: Rate) =>
        item.DebitCurrency === currency2 && item.CreditCurrency === currency1
    )?.SellingRate;
 
  } else if (currency1 === "GEL" && currency2 === "EUR") {
    rate = rates.find(
      (item: Rate) =>
        item.DebitCurrency === currency1 && item.CreditCurrency === currency2
    )?.SellingRate;
  } else if (currency1 === "GEL") {
    rate = rates.find(
      (item: Rate) =>
        item.DebitCurrency === currency1 && item.CreditCurrency === currency2
    )?.SellingRate;
  } else if (currency1 === "USD" && currency2 === "GEL") {
    rate = rates.find(
      (item: Rate) =>
        item.DebitCurrency === currency2 && item.CreditCurrency === currency1
    )?.SellingRate;
  } else if (currency1 === "USD" && currency2 === "EUR") {
    rate = rates.find(
      (item: Rate) =>
        item.DebitCurrency === currency1 && item.CreditCurrency === currency2
    )?.SellingRate;
  }  else if (currency1 === "USD" && currency2 === "GBP") {
    rate = rates.find(
      (item: Rate) =>
        item.DebitCurrency === currency1 && item.CreditCurrency === currency2
    )?.SellingRate;
  }  else if (currency1 === "USD") {
    rate = rates.find(
      (item: Rate) => item.DebitCurrency === currency1
    )?.SellingRate;
  }

  return parseFloat(rate);
};

const SellRate = ({ currency1, currency2 }: SelleRateProps) => {
  const rates = useSelector(ratesSelector);

  return <span>{getSellRate(rates, currency1, currency2)}</span>;
};

export default SellRate;
