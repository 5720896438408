import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useToasts } from "react-toast-notifications";

import Title from "../../../components/Text/title";
import InputWithDropdown from "../../../components/Form/InputWithDropdown";
import {
  GetUserAccounts,
  calculateMoneytransferCommision,
  getAllBalances,
  getImage,
  getOffice,
} from "../../../services/Services";
import {
  balancesSelector,
  sendTransferDataSelector,
  userIdSelector,
} from "../../../redux/selectors";
import { FinancialForm } from "./FinancialDetails.styled";
import BackButton from "../components/BackButton";
import { formatBalance, isEmpty, replaceComma } from "../../../utils/helpers";
import Text from "../../../components/Text/text";
import { setSendTransferData } from "../../../redux/transferSlice";
import { debounce, preventNonNumeric } from "../../../utils/utils";
import { Balance } from "../../home/Accounts/accounts/types";

interface DetailsProps {
  disabled?: boolean;
  onNextPress?: () => void;
  onPreviousPress?: () => void;
}

const FinancialDetails = ({
  disabled,
  onNextPress,
  onPreviousPress,
}: DetailsProps) => {
  const { t } = useTranslation();
  const {
    handleSubmit,
    setValue,
    trigger,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const [fromValue, setFromValue] = useState<any>();
  const [toValue, setToValue] = useState<any>();
  const [feeAmount, setFeeAmount] = useState<any>();
  const [rate, setRate] = useState<any>();
  const sendTransferData = useSelector(sendTransferDataSelector);
  const [accounts, setAccounts] = useState<any>([]);
  const userId = useSelector(userIdSelector);
  const { addToast } = useToasts();
  const balancesFromRedux = useSelector(balancesSelector);
  const [balances, setBalances] = useState<Balance[]>(balancesFromRedux || []);
  const officeCode = sendTransferData?.destinationOfficeId;
  const [selectedAccount, setSelectedAccount] = useState<any>([]);
  const [workingCurrencies, setWorkingCurrencies] = useState<any>([]);
  const [creditCurrency, setCreditCurrency] = useState("");
  const [sendImage, setSendImage] = useState<any>(null);
  const [receiveImage, setReceiveImage] = useState<any>(null);
  const balance = balances.find(
    (item: Balance) => item.CurrencyName === selectedAccount.CurrencyName
  )?.Balance;

  useEffect(() => {
    if (!balancesFromRedux.length && userId) {
      getAllBalances(userId)
        .then((res) => setBalances(res.data.Content))
        .catch((res) => {});
    }
  }, [balancesFromRedux, userId]);

  useEffect(() => {
    if (!isEmpty(sendTransferData)) {
      setRate(sendTransferData.exchangeRate);
      setFromValue(sendTransferData.amount);
      setToValue(sendTransferData.creditAmount);
      setFeeAmount(sendTransferData.fee);
    }
  }, [sendTransferData, setValue]);

  const onSubmit = () => {
    if (balance && balance < fromValue) {
      addToast(<div>{t("common.insufficient_account")}</div>, {
        appearance: "error",
        autoDismiss: true,
      });
    } else {
      const detailsData: any = {};
      detailsData.fee = feeAmount;
      detailsData.debitCurrency = selectedAccount.CurrencyName;
      detailsData.recipientCurrency = creditCurrency;
      detailsData.creditCurrency = creditCurrency;
      detailsData.creditAmount = parseFloat(toValue);
      detailsData.amount = parseFloat(fromValue);
      detailsData.accountId = selectedAccount.Id;
      detailsData.exchangeRate = rate;
      dispatch(setSendTransferData(detailsData));
      onNextPress && onNextPress();
    }
  };

  useEffect(() => {
    if (selectedAccount?.CurrencyName) {
      getImage(selectedAccount?.CurrencyName).then((res) =>
        setSendImage(res.data)
      );
    }
  }, [selectedAccount?.CurrencyName]);

  useEffect(() => {
    if (creditCurrency) {
      getImage(creditCurrency).then((res) => setReceiveImage(res.data));
    }
  }, [creditCurrency]);

  useEffect(() => {
    if (userId) {
      GetUserAccounts(userId).then((res: any) => {
        if (!sendTransferData.debitCurrency) {
          setSelectedAccount(res.data.Content[0]);
        } else {
          setSelectedAccount(
            res.data.Content.find(
              (item: any) =>
                item.CurrencyName === sendTransferData.debitCurrency
            )
          );
        }
        setAccounts(res.data.Content);
      });

      if (officeCode || sendTransferData?.destinationOfficeId) {
        getOffice(officeCode || sendTransferData?.destinationOfficeId).then(
          (res) => {
            setWorkingCurrencies(res.data.Content?.WorkingCurrencies);
            setTimeout(() => {
              if (sendTransferData?.creditCurrency) {
                const currencies = res.data.Content?.WorkingCurrencies.map(
                  (item: any) => item.Code
                );

                if (currencies.includes(sendTransferData?.creditCurrency)) {
                  setCreditCurrency(sendTransferData?.creditCurrency);
                } else {
                  setCreditCurrency(
                    res.data.Content?.WorkingCurrencies?.length &&
                      res.data.Content?.WorkingCurrencies[0]?.Code
                  );
                }
              } else {
                setCreditCurrency(
                  res.data.Content?.WorkingCurrencies?.length &&
                    res.data.Content?.WorkingCurrencies[0]?.Code
                );
              }
            }, 10);
          }
        );
      }
    }
  }, [userId, officeCode, sendTransferData?.destinationOfficeId]);

  const handleAmountChangeDebounced = useCallback(
    debounce(
      (
        amount: number,
        type: string,
        selectedAccId?: number | null,
        selectedCreditCurrency?: string
      ) => {
        if (type === "send" && amount > 0) {
          calculateMoneytransferCommision(
            userId,
            selectedAccId || selectedAccount?.Id,
            selectedCreditCurrency || creditCurrency,
            undefined,
            replaceComma(amount),
            officeCode
          )
            .then((res: any) => {
              if (res.data.Content.fee === 0) {
                setFeeAmount(0);
              } else {
                setFeeAmount(res.data.Content.fee);
              }
              trigger("receiveAmount");
              setToValue(res.data.Content.Amount);
              setValue("receiveAmount", res.data.Content.Amount);
              setFeeAmount(res.data.Content.Fee);
              setRate(res.data.Content.ExchangeRate);
            })
            .catch((err) => {
              if (err.response.data.errors) {
                return Object.values(err.response.data.errors).map(
                  (item: any) => {
                    addToast(<div>{item}</div>, {
                      appearance: "error",
                      autoDismiss: true,
                    });
                  }
                );
              } else {
                addToast(<div>{err.response.data.StatusMessage}</div>, {
                  appearance: "error",
                  autoDismiss: true,
                });
              }
            });
        } else if (amount > 0) {
          calculateMoneytransferCommision(
            userId,
            selectedAccount?.Id,
            selectedCreditCurrency || creditCurrency,
            replaceComma(amount),
            undefined,
            officeCode
          )
            .then((res) => {
              setFeeAmount(res?.data.Content.Fee);
              setRate(res?.data.Content.ExchangeRate);
              if (!isNaN(res?.data.Content.Amount)) {
                setFromValue(res.data.Content.Amount);
                setValue("sendAmount", res.data.Content.Amount);
              }
            })
            .catch((err) => {
              if (err.response.data.errors) {
                return Object.values(err.response.data.errors).map(
                  (item: any) => {
                    addToast(<div>{item}</div>, {
                      appearance: "error",
                      autoDismiss: true,
                    });
                  }
                );
              } else {
                addToast(<div>{err.response.data.StatusMessage}</div>, {
                  appearance: "error",
                  autoDismiss: true,
                });
              }
            });
        } else if (type === "send" && amount == 0.0) {
          setToValue("");
        } else if (type === "receive" && amount == 0.0) {
          setFromValue("");
        }
      },
      300
    ),
    [selectedAccount?.Id, creditCurrency]
  );

  const handleAmountChange = (
    amountValue: number,
    type: string,
    selectedAccId?: number | null,
    selectedCreditCurrency?: string
  ) => {
    let amount: any = replaceComma(amountValue);
    if (!amountValue) {
      setFromValue(null);
      setToValue(null);
      setRate(null);
      setTimeout(() => {
        setFromValue(null);
        setToValue(null);
        setRate(null);
      }, 400);
      return;
    } else {
      if (
        String(amount).split(".")[1]?.length === 3 ||
        String(amount).split(",")[1]?.length === 3
      ) {
        amount = replaceComma(amount);
      } else {
        type === "send" ? setFromValue(amount) : setToValue(amount);
      }

      handleAmountChangeDebounced(
        amount,
        type,
        selectedAccId || selectedAccount?.Id,
        selectedCreditCurrency
      );
    }
  };

  return (
    <FinancialForm onSubmit={handleSubmit(onSubmit)} disabled={disabled}>
      <BackButton onClick={onPreviousPress} />{" "}
      <Title className="title" mb="1.5rem" ta="center" size="2rem" weight="600">
        {t("banktransfer.financialdetails.title")}
      </Title>
      <div style={{ maxWidth: 360, margin: "auto" }}>
        <div style={{ marginBottom: 12 }}>
          <InputWithDropdown
            initialSelectValue={
              sendTransferData?.debitCurrency ||
              (accounts?.length && accounts[0]?.CurrencyName)
            }
            selectStyle={{
              padding: "10px 16px 10px 10px",
              width: 120,
              display: "flex",
            }}
            withCountryFlag={true}
            value={fromValue ?? ""}
            selectImage={sendImage}
            selectPlaceholder={""}
            selectOptions={accounts}
            nameKey="CurrencyName"
            idKey="Id"
            shrink={true}
            inputMode="numeric"
            label={t("common.yousend")}
            onChange={(e: any) => handleAmountChange(e.target.value, "send")}
            onSelect={(val: any) => {
              const account = accounts.find((item: any) => item.Id === val);
              if (fromValue && val) {
                handleAmountChange(fromValue, "send", account?.Id);
              }
              setSelectedAccount(account);
            }}
            errorText={errors.surName ? t("common.required") : ""}
            onKeyPress={preventNonNumeric}
            onPaste={(e: any) => {
              e.preventDefault();
              const contents = e.clipboardData.getData("text");
              if (/^\d+$/.test(contents)) {
                setFromValue(contents.replace(/[^0-9]/g, ""));
              }
            }}
          />
          <Text ml="0.8rem" mt="-0.8rem" mb="1.2rem">
            {balances?.length
              ? t("cashpickup.balancedetails", {
                  currency: selectedAccount.CurrencyName,
                  amount: formatBalance(
                    balances.find(
                      (item: Balance) =>
                        item.CurrencyName === selectedAccount.CurrencyName
                    )?.Balance
                  ),
                })
              : ""}
          </Text>
        </div>
        <div>
          <InputWithDropdown
            selectStyle={{
              padding: "10px 16px 10px 10px",
              width: 120,
              display: "flex",
            }}
            shrink={true}
            selectImage={receiveImage}
            withCountryFlag={true}
            initialSelectValue={
              creditCurrency ||
              (workingCurrencies?.length && workingCurrencies[0]?.Code)
            }
            value={toValue ?? ""}
            onChange={(e: any) => handleAmountChange(e.target.value, "receive")}
            selectPlaceholder={""}
            selectOptions={workingCurrencies}
            nameKey="Code"
            idKey="Code"
            inputMode="numeric"
            onSelect={(val: any) => {
              setCreditCurrency(
                workingCurrencies.find((item: any) => item.Code === val)?.Code
              );
              if (val && fromValue) {
                handleAmountChange(
                  fromValue,
                  "send",
                  null,
                  workingCurrencies.find((item: any) => item.Code === val)?.Code
                );
              }
            }}
            label={t("SEPA.gets")}
            // hintText={`*${t("common.required")}`}
            errorText={errors.surName ? t("common.required") : ""}
            onKeyPress={preventNonNumeric}
            onPaste={(e: any) => {
              e.preventDefault();
              const contents = e.clipboardData.getData("text");
              if (/^\d+$/.test(contents)) {
                setToValue(contents.replace(/[^0-9]/g, ""));
              }
            }}
          />
        </div>
        {rate && rate !== 1 && (
          <Text ml="1rem" mt="-1rem" color="#3338A8">
            [{t("common.rate")} {rate}]
          </Text>
        )}
        {feeAmount && rate ? (
          <Text mb="12px" mt="0.5rem">
            {t("banktransfer.financialdetails.fee")}: {feeAmount}{" "}
            {selectedAccount?.CurrencyName}
          </Text>
        ) : (
          <Title mb="12px"></Title>
        )}

        <button
          className="btn btn-red w-100 uppercase h-[40px] mt-4"
          disabled={!fromValue || !toValue || !rate}
        >
          {t("common.continue")}
        </button>
      </div>
    </FinancialForm>
  );
};

export default FinancialDetails;
