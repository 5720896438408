import { IconProps } from "./types";

export const Logout = ({ fill, width, height, ...rest }: IconProps) => {
    return (
        <svg width={width || "15"} height={height || "13"} viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2.5443 12.7027H8.56169C9.77078 12.7027 10.3979 12.0699 10.3979 10.8494V8.30695H9.2905V10.7817C9.2905 11.3072 9.01368 11.5953 8.46558 11.5953H2.6347C2.08663 11.5953 1.81543 11.3072 1.81543 10.7817V2.39125C1.81543 1.86579 2.08663 1.57764 2.6347 1.57764H8.46558C9.01368 1.57764 9.2905 1.86579 9.2905 2.39125V4.87165H10.3979V2.32345C10.3979 1.10868 9.77078 0.470215 8.56169 0.470215H2.5443C1.33517 0.470215 0.708008 1.10868 0.708008 2.32345V10.8494C0.708008 12.0699 1.33517 12.7027 2.5443 12.7027ZM6.18299 7.10345H11.8783L12.7145 7.06392L12.302 7.41421L11.4601 8.20525C11.3584 8.29563 11.302 8.42555 11.302 8.55554C11.302 8.80979 11.4941 9.0245 11.754 9.0245C11.8896 9.0245 11.9913 8.968 12.0873 8.87195L13.918 6.97912C14.0479 6.84355 14.0931 6.71922 14.0931 6.58364C14.0931 6.44806 14.0479 6.32373 13.918 6.18815L12.0873 4.28967C11.9913 4.19364 11.8896 4.14279 11.754 4.14279C11.4941 4.14279 11.302 4.34617 11.302 4.60608C11.302 4.73041 11.3584 4.86599 11.4601 4.95637L12.302 5.75307L12.7201 6.10336L11.8783 6.05817H6.18299C5.90611 6.05817 5.66883 6.29551 5.66883 6.58364C5.66883 6.87177 5.90611 7.10345 6.18299 7.10345Z" fill={fill || "var(--red)"} />
        </svg>
    );
};

export default Logout;
