import { useState } from "react";
import FlexWrapper from "../../../components/FlexWrapper";
import Text from "../../../components/Text/text";
// import { useNavigate } from "react-router-dom";
import Header from "./Parts/Header";
import Img from "../../../components/Img";
import { useTranslation } from "react-i18next";
import { VerificationSession } from "../../../services/Services";
import Box from "../../../components/Tailwind/Box";
import Title from "../../../components/Text/title";
import Offline from "./Offline";
import { getStorageObject } from "../../../utils/storage";
import { useToasts } from "react-toast-notifications";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../redux/commonSlice";

export type RegProps = {
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  password: string;
  phoneNumber: string;
  smsCode: string;
  countryId: number;
  hasAgreedToTerms: boolean;
};

const Final = () => {
  // const navigate = useNavigate();
  const { t } = useTranslation();
  const [isOffline, setIsOffline] = useState(false);
  const { addToast } = useToasts();
  const dispatch = useDispatch();

  const handleIdentomat = () => {
    dispatch(setLoading(true));
    VerificationSession(getStorageObject("registertoken"))
      .then((res) => {
        dispatch(setLoading(false));
        window.location.href = res.data.Content.RedirectUri;
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (err.response.data.errors) {
          return Object.values(err.response.data.errors).map((item: any) => {
            addToast(<div>{item}</div>, {
              appearance: "error",
              autoDismiss: true,
            });
          });
        } else {
          addToast(<div>{err.response.data.StatusMessage}</div>, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  };

  if (isOffline) {
    return <Offline onBackClick={() => setIsOffline(false)} />;
  }

  return (
    <div className="bg-[#FBFBFB] h-[100vh] [@media(max-width:554px)]:px-2">
      <Header />
      <Box className="mx-auto w-[480px] max-w-[100%] p-10">
        <div className="text-center mb-2">
          <Img src="/registration/scan.png" alt="scan" />
        </div>

        <Title ta="center" size="2rem" weight="700" lh="2.4rem">
          {t("registration.chooseverification")}
        </Title>
        <Text ta="center" mt="4px">
          {t("registration.chooseverificationtext")}
        </Text>
        <FlexWrapper gap="1rem" mb="1rem" mt="2rem" mobileDirection="column">
          <div
            className="flex-1 flex flex-col border-solid border-[1px] border-[#EBEBEB] p-4 rounded pointer hover:bg-[#F7F7F7] xs:w-full"
            onClick={handleIdentomat}
          >
            <div className="text-center">
              <Img width={80} src="/registration/online.gif" alt="identomat" />
            </div>

            <Title ta="center">{t("registration.onlineverif")}</Title>
          </div>
          <div
            className="flex-1 flex flex-col border-solid border-[1px] border-[#EBEBEB] p-4 rounded pointer hover:bg-[#F7F7F7] xs:w-full"
            onClick={() => setIsOffline(true)}
          >
            <div className="text-center">
              <Img width={80} src="/registration/offline.gif" alt="offline" />
            </div>

            <Title ta="center">{t("registration.offlineverif")}</Title>
          </div>
        </FlexWrapper>
      </Box>
      <div className="w-full py-3 bg-[#242424] mt-8">
        <div className="max-w-full w-[480px] px-8 m-auto flex gap-4 items-center">
          <div className="w-12 h-12 bg-white flex items-center justify-center shrink-0 rounded-[10px]">
            <Img src="/auth/info.svg" alt="info" />
          </div>
          <div>
            <Title color="#fff" weight="700" size="20px">
              {t("registration.verificationdisclaimertitle")}
            </Title>
            <Text color="#fff" size="13px" lh="16px">
              {t("registration.verificationdisclaimertext")}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Final;
