import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";

import Title from "../../../../components/Text/title";
import Text from "../../../../components/Text/text";
import Divider from "../../../../components/Divider";
import FlexWrapper from "../../../../components/FlexWrapper";
import Scrollbars from "rc-scrollbars";
import { getUserId } from "../../../../utils/storage";
import {
  getLoanDetails,
  getLoanSchedule,
  handleLoanDownloadService,
} from "../../../../services/Services";
import moment from "moment";
import { formatBalance, getCurrencySymbol } from "../../../../utils/helpers";
import Img from "../../../../components/Img";
import { saveFile } from "../../../../utils/utils";
import { useToast } from "../../../../hooks/useToast";

export const ContentWrapper = styled.div`
  button.btn {
    outline: none !important;
  }

  .top {
    width: 100%;
    border-bottom: 1px solid #ebebeb;
    padding: 1rem;
    margin-bottom: 1rem;
    background: #fbfbfb;
    display: flex;
    flex-direction: column;
    align-items: center;

    .btn {
      min-width: 170px;
      margin-top: 8px;
    }

    .btn-plain {
      color: var(--red);
      border: none !important;
      font-weight: bold;
    }
  }

  .price {
    color: #56575b;
    background: #e6e7e7;
    border-radius: 12px;
    font-size: 11px;
    padding: 2px 4px;
  }

  .day-container-red {
    background: #fce9e9;
    border-left: 4px solid #e02629;
    padding: 0.5rem;

    .fine {
      background: #e02629;
      color: white;
      border-radius: 20px;
      padding: 4px 8px;
      margin-top: 4px;
    }
  }

  .day-container {
    border-left: 4px solid #bababa;
  }
`;

const Schedule = ({ onBack }: any) => {
  const { t } = useTranslation();
  const userId = getUserId();
  const toast = useToast();
  const [schedule, setSchedule] = useState<any>([]);
  const [details, setDetails] = useState<any>([]);
  const [maxHeight, setMaxHeight] = useState("");

  useEffect(() => {
    getLoanSchedule(userId).then((res) => setSchedule(res.data.Content));
    getLoanDetails(userId).then((res) => setDetails(res.data.Content));
  }, []);

  const handlePdfDownload = () => {
    handleLoanDownloadService(userId)
      .then((res) => {
        saveFile(res, "loan-schedule-document");
      })
      .catch((err) =>
        toast(err.response.data?.StatusMessage || t("common.error"), "error")
      );
  };

  useEffect(() => {
    const updateMaxHeight = () => {
      const isDesktopScreen = window.innerWidth > 768;
      const isSmallScreen = window.innerWidth < 768;

      if (isDesktopScreen) {
        setMaxHeight("360px");
      } else if (isSmallScreen) {
        setMaxHeight(`Calc(100vh - 320px)`);
      }
    };

    updateMaxHeight();
    window.addEventListener("resize", updateMaxHeight);

    return () => {
      window.removeEventListener("resize", updateMaxHeight);
    };
  }, []);

  return (
    <ContentWrapper>
      <FlexWrapper gap="0.5rem" ml="16px">
        <ArrowBackOutlinedIcon className="pointer" onClick={() => onBack()} />
        <Title
          size="16px"
          weight="500"
          mb="0"
          className="pointer"
          onClick={() => onBack()}
        >
          {t("cards.schedule")}
        </Title>
      </FlexWrapper>
      <div className="top">
        <Title ta="center" weight="500">
          {t("cards.overdraft")}
        </Title>
        <Text className="uppercase !text-[10px] font-['Roboto']">
          {t("cards.totalpaid")}
        </Text>
        <Title ta="center" size="1.25rem" weight="700" mt="0.25rem">
          {getCurrencySymbol(details?.CurrencyCode)}
          {details?.Limit}
        </Title>
        <button className="btn btn-red disabled !px-[24px] !py-[10px]">
          <Text
            weight="700"
            ta="center"
            color="white"
            size="16px"
            className="font-['Roboto'] "
          >
            {t("cards.topupcard")}
          </Text>
        </button>
        <div
          className="btn btn-plain uppercase !flex items-center justify-center gap-2"
          onClick={handlePdfDownload}
        >
          <div className="flex justify-center">
            <Img
              src="/transactions/article.svg"
              alt="article"
              className="!text-[#E02629] "
            />
          </div>
          <div className="font-['Roboto'] font-medium !text-[#E02629] pt-[1px]">
            {t("cards.downloadschedule")}
          </div>
        </div>
      </div>
      <Scrollbars style={{ height: maxHeight }}>
        <div className="bottom px-4">
          {schedule.map((item: any) => (
            <div>
              <FlexWrapper justify="space-between" mb="0">
                <Title
                  size="12px"
                  className="uppercase font-['Roboto']"
                  weight="500"
                >
                  {moment(item.ExpirationDate).format("dddd, MMMM D")}
                </Title>
                <div className="price">
                  {getCurrencySymbol(details?.CurrencyCode)} {item.Total}
                </div>
              </FlexWrapper>
              <Divider width="100%" mb="8px" />
              <div className="day-container mb-6">
                {item.Items.map((subItem: any, index: number) => (
                  <div
                    className="pr-4 pl-[12px] py-2"
                    key={index}
                    style={
                      item.Items.length > 1
                        ? { borderBottom: "1px solid #EBEBEB" }
                        : {}
                    }
                  >
                    <Text
                      color="var(--red)"
                      size="12px"
                      weight="500"
                      className="uppercase"
                      mb="0.25rem"
                    >
                      {subItem.Title}
                    </Text>
                    <FlexWrapper mb="8px" justify="space-between">
                      <div>
                        {/* <Title mb="0">Name Surname</Title> */}
                        <Text>
                          {t("intelexpresscard.interest")}:{" "}
                          {formatBalance(subItem?.CurrentPercent)}
                        </Text>
                        <Text>
                          {t("intelexpresscard.principalamount")}:{" "}
                          {formatBalance(subItem?.DueSum)}
                        </Text>
                      </div>
                      <div>
                        <Text
                          weight="900"
                          size="14px"
                          color="#E02629"
                          ta="right"
                        >
                          {getCurrencySymbol(details?.CurrencyCode)}{" "}
                          {formatBalance(subItem?.Total)}
                        </Text>
                        {!!subItem?.CurrentPenalty && (
                          <Text className="fine">
                            {t("intelexpresscard.fine")}:{" "}
                            {getCurrencySymbol(details?.CurrencyCode)}
                            {subItem?.CurrentPenalty}
                          </Text>
                        )}
                      </div>
                    </FlexWrapper>
                  </div>
                ))}
              </div>
            </div>
          ))}
          {/* <FlexWrapper justify="space-between" mb="0">
          <Title size="12px" className="uppercase">
            Monday, January 1
          </Title>
          <div className="price">€500.00</div>
        </FlexWrapper> */}
          {/* 
        <div className="day-container-red">
          <Text
            color="var(--red)"
            size="14px"
            weight="bold"
            className="uppercase"
          >
            {t("intelexpresscard.maincard")}
          </Text>
          <FlexWrapper mb="0" justify="space-between">
            <div>
              <Title mb="0">Name Surname</Title>
              <Text>Interest: 16.00</Text>
              <Text>Principal Amount: 84.00</Text>
            </div>
            <div>
              <Text weight="900" size="14px" color="#E02629" ta="right">
                € 200.00
              </Text>
              <Text className="fine">Fine: €25.00</Text>
            </div>
          </FlexWrapper>
          <Text
            color="var(--red)"
            size="14px"
            weight="bold"
            className="uppercase"
            mt="1rem"
          >
            {t("intelexpresscard.maincard")}
          </Text>
          <FlexWrapper mb="0" justify="space-between">
            <div>
              <Title mb="0">Name Surname</Title>
              <Text>Interest: 16.00</Text>
              <Text>Principal Amount: 84.00</Text>
            </div>
            <div>
              <Text weight="900" size="14px" color="#E02629" ta="right">
                € 200.00
              </Text>
              <Text className="fine">Fine: €25.00</Text>
            </div>
          </FlexWrapper>
        </div> */}

          {/* <FlexWrapper justify="space-between" mb="0" mt="1rem">
          <Title size="12px" className="uppercase">
            Monday, January 8
          </Title>
          <div className="price">€500.00</div>
        </FlexWrapper> */}
          {/* <Divider width="100%" /> */}
          {/* <div className="day-container">
          <Text
            color="var(--red)"
            size="14px"
            weight="bold"
            className="uppercase"
          >
            {t("intelexpresscard.maincard")}
          </Text>
          <FlexWrapper mb="0" justify="space-between">
            <div>
              <Title mb="0">Name Surname</Title>
              <Text>Interest: 16.00</Text>
              <Text>Principal Amount: 84.00</Text>
            </div>
            <div>
              <Text weight="900" size="14px" color="#E02629" ta="right">
                € 200.00
              </Text>
              <Text className="fine">Fine: €25.00</Text>
            </div>
          </FlexWrapper>
          <Text
            color="var(--red)"
            size="14px"
            weight="bold"
            className="uppercase"
            mt="1rem"
          >
            {t("intelexpresscard.additionalcard")}
          </Text>
          <FlexWrapper mb="0" justify="space-between">
            <div>
              <Title mb="0">Name Surname</Title>
              <Text>Interest: 16.00</Text>
              <Text>Principal Amount: 84.00</Text>
            </div>
            <div>
              <Text weight="900" size="14px" color="#E02629" ta="right">
                € 200.00
              </Text>
              <Text className="fine">Fine: €25.00</Text>
            </div>
          </FlexWrapper>
        </div> */}
        </div>
      </Scrollbars>
    </ContentWrapper>
  );
};

export default Schedule;
