import { useTranslation } from "react-i18next";
import FlexWrapper from "../../../../components/FlexWrapper";
import Img from "../../../../components/Img";
import Box from "../../../../components/Tailwind/Box";
import Text from "../../../../components/Text/text";
import Title from "../../../../components/Text/title";

import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";

// import InputWithDropdown from "../../../../../components/Form/InputWithDropdown";
import CheckBox from "../../../../components/Form/Checkbox";
import { useEffect, useState } from "react";
import TwoFactorModal from "../../../../components/TwoFactor/Index";
import Select from "../../../../components/Form/Select";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { setRegistrationData } from "../../../../redux/authSlice";
import { FORM_PATTERNS } from "../../../../constants";
import {
  CheckMobileNumber,
  GetCountryCodes,
  checkEmail,
  getImage,
} from "../../../../services/Services";
import { useToasts } from "react-toast-notifications";
import { registrationDataSelector } from "../../../../redux/selectors";
import { Link } from "react-router-dom";
import cx from "classnames";
import VerticalDivider from "../../../../components/VerticalDivider";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import { preventNonNumeric } from "../../../../utils/utils";
import Input from "../../../../components/Form/Input";
import CountrySearch from "../../../BankTransfer/components/CountrySearch";
import { CountryCode } from "../Registration.styled";
import { saveStorageObject } from "../../../../utils/storage";

const Wrapper = styled.div`
  .dd-header {
    margin: 0;
  }

  .dd-header > div {
    border-right: none;
    border-radius: 8px 0 0 8px;
    border-color: rgba(0, 0, 0, 0.23);

    svg {
      margin-top: 0px;
      margin-left: 0px;
    }
  }

  .select-header {
    padding: 12px 1.35em 12px 1rem;
    margin-top: -2px;
    color: #212121;
  }

  .droparrow {
    display: none;
  }

  .body {
    svg {
      margin-top: 24px;
      margin-left: 24px;
    }
  }

  .mobile fieldset {
    border-left: 0;
    border-radius: 0 4px 4px 0;
    width: 100%;
  }

  .relative {
    width: 100%;
  }

  input + label:before {
    top: -14px;
  }

  input + label:after {
    top: -10px;
  }
`;

const EmailAndMobile = ({ onBackClick, onNextPress }: any) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const [email, setEmail] = useState("");
  const [isTwofactorOpen, setIsTwofactorOpen] = useState(false);
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [countryId, setCountryId] = useState(73);
  const [errorText, setErrorText] = useState("");
  const { addToast } = useToasts();
  const [SMSCode, setSMSCode] = useState("");
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isCodeVerified, setIsCodeVerified] = useState(false);
  const registrationData = useSelector(registrationDataSelector);
  const [countryCodes, setCountryCodes] = useState<any>([]);
  const [selectImage, setSelectImage] = useState<any>(null);
  const [savedPhone, setSavedPhone] = useState("");
  const [showCountrySearch, setShowCountrySearch] = useState(false);
  const [chosenCode, setChosenCode] = useState("+995");
  const [focused, setFocused] = useState(false);

  useEffect(() => {
    GetCountryCodes().then((res) => {
      setCountryCodes(res.data.Content);
    });
    getImage("GE").then((res) => setSelectImage(res.data));
  }, []);

  useEffect(() => {
    if (registrationData?.email) {
      setEmail(registrationData?.email);
    }

    if (registrationData?.phoneNumber) {
      setPhone(registrationData?.phoneNumber);
      setCountryId(registrationData.countryId);
    }
  }, [registrationData]);

  useEffect(() => {
    if (isCodeVerified && phone !== savedPhone) {
      setIsCodeVerified(false);
      setIsCodeSent(false);
    }
  }, [phone]);

  const handleNext = () => {
    checkEmail(email)
      .then((res) => {
        dispatch(
          setRegistrationData({
            email,
            hasAgreedToTerms: checked,
            phoneNumber: phone,
            countryId,
            smsCode: SMSCode,
          })
        );
        saveStorageObject("registerphone", phone);
        onNextPress && onNextPress(email, phone, checked, countryId);
        setTimeout(() => {
          setIsCodeVerified(false);
        }, 1000);
      })
      .catch((err) => {
        if (err.response.data.errors) {
          return Object.values(err.response.data.errors).map((item: any) => {
            addToast(<div>{item}</div>, {
              appearance: "error",
              autoDismiss: true,
            });
          });
        } else {
          addToast(<div>{err.response.data.StatusMessage}</div>, {
            appearance: "error",
            autoDismiss: true,
          });
        }
      });
  };

  useEffect(() => {
    if (checked && isCodeVerified && FORM_PATTERNS.phone.value.test(email)) {
      window.addEventListener("keydown", handleUserKeyPress);

      return () => {
        window.removeEventListener("keydown", handleUserKeyPress);
      };
    }
  }, [checked, isCodeVerified]);

  const handleUserKeyPress = (event: any) => {
    const { keyCode } = event;

    if (keyCode === 13) {
      handleNext();
    }
  };

  // useEffect(() => {
  //   if (email) {
  //     !FORM_PATTERNS.email.value.test(email)
  //       ? setErrorText("Invalid format")
  //       : setErrorText("");
  //   } else if (!email) {
  //     setErrorText("");
  //   }
  // }, [email]);

  const handleSendSMS = () => {
    const number = phone.replace(/[_-]/g, "");

    if (FORM_PATTERNS.phone.value.test(number)) {
      CheckMobileNumber(number, countryId)
        .then((res) => {
          setIsCodeSent(true);
          setSavedPhone(phone);
          setIsTwofactorOpen(true);
        })
        .catch((err) => {
          if (err.response.data.errors) {
            return Object.values(err.response.data.errors).map((item: any) => {
              addToast(<div>{item}</div>, {
                appearance: "error",
                autoDismiss: true,
              });
            });
          } else {
            addToast(<div>{err.response.data.StatusMessage}</div>, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        });
    } else {
      addToast(<div>{t("common.incorrectformat")}</div>, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  };

  if (showCountrySearch) {
    return (
      <Wrapper className="animate-fadeIn">
        <Box className="mx-auto w-[730px]  max-w-[100%]">
          <div onClick={() => setShowCountrySearch(false)}>
            <FlexWrapper mb="1rem" gap="0.5rem" className="pointer">
              <ArrowBackOutlinedIcon />{" "}
              <Title mb="0" className="capitalize">
                {t("common.back")}
              </Title>
            </FlexWrapper>{" "}
          </div>
          <Title size="2rem" lh="38px" ta="center" weight="600">
            {t("cashpickup.countrycodes")}
          </Title>
          <CountrySearch
            outside
            openSepaInfoModal={(country: any) => {}}
            chooseCountry={(country: any) => {
              setCountryId(country?.Id);
              setChosenCode(country?.Code);
              setShowCountrySearch(false);
              getImage(country?.Alpha2).then((res) => setSelectImage(res.data));
            }}
          />
        </Box>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Box className="mx-auto w-[480px]  max-w-[100%]">
        <div onClick={onBackClick}>
          <FlexWrapper mb="1rem" gap="0.5rem" className="pointer">
            <ArrowBackOutlinedIcon />{" "}
            <Title mb="0" className="capitalize">
              {t("common.back")}
            </Title>
          </FlexWrapper>{" "}
        </div>
        <Title size="1.5rem" weight="700" mb="32px" mt="1rem">
          {t("login.register")}
        </Title>
        <div className="[@media(min-width:554px)]:px-4">
          <div className={cx("", errorText && "mb-4")}>
            <Input
              label={`${t("register.email")}*`}
              errorText={errorText}
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
            />
          </div>

          <FlexWrapper className="relative" mb="-2px
          ">
            <CountryCode
              focused={focused}
              onClick={() => setShowCountrySearch(true)}
            >
              <div
                className="flag"
                style={{
                  marginRight: 8,
                  width: 24,
                  height: 24,
                  overflow: "hidden",
                  borderRadius: "50%",
                }}
                // @ts-ignore
                dangerouslySetInnerHTML={{ __html: selectImage }}
              />
              {chosenCode}{" "}
              <ArrowForwardIosOutlinedIcon style={{ maxHeight: 18 }} />
              <VerticalDivider height="40px" ml="0.5rem" />
            </CountryCode>

            <div className="w-full mt-2 mobile">
              <Input
                placeholder={`${t("common.mobile")}*`}
                style={{ paddingRight: 64, height: 56 }}
                className={cx(" w-full")}
                value={phone}
                onBlur={() => setFocused(false)}
                onFocus={() => setFocused(true)}
                onChange={(e: any) => setPhone(e.target.value)}
                onKeyPress={(e: any) => {
                  preventNonNumeric(e);
                }}
                onKeyUp={(e: any) => {
                  if (e.keyCode === 13) {
                    handleSendSMS();
                  }
                }}
              />
            </div>

            {isCodeVerified ? (
              <div className="w-5 h-5 rounded-full bg-[#0FBD5B] flex items-center justify-center absolute right-4">
                <CheckOutlinedIcon style={{ fill: "#fff", width: 16 }} />
              </div>
            ) : (
              <div
                className="text-[var(--red)] text-sm absolute right-4 top-7 pointer hover:underline"
                onClick={handleSendSMS}
              >
                {t("common.verify")}
              </div>
            )}
            {/* <VerticalDivider height="36px" className="absolute left-[84px]" /> */}
          </FlexWrapper>
          <Text color="#56575B" mb="0.75rem">
            {t("registration.emailtext")}
          </Text>
          <FlexWrapper mb="0" className="pr-2">
            <CheckBox checked={checked} onClick={() => setChecked(!checked)} />

            <Text mb="0" color="#141525" style={{ wordBreak: "break-word" }}>
              <span> {t("register.bottomtext")}</span>{" "}
              <a
                target="_blank"
                href="/privacy"
                rel="noreferrer"
                className="text-[#4F46E5]"
              >
                {t("common.terms")}
              </a>{" "}
              <span className=""> {t("common.and")}</span>{" "}
              <a
                target="_blank"
                className="text-[#4F46E5]"
                href="/privacy"
                rel="noreferrer"
              >
                {t("common.privacy")}
              </a>
              .
            </Text>
          </FlexWrapper>
        </div>
        <button
          disabled={
            !checked ||
            !isCodeVerified ||
            !FORM_PATTERNS.phone.value.test(email)
          }
          className="mt-6 bg-[var(--red)] font-semibold text-white w-full py-3 rounded-lg disabled:bg-[#F2F4F7] disabled:text-[#98A2B3] h-11"
          onClick={handleNext}
        >
          {t("login.register")}
        </button>
      </Box>
      <FlexWrapper justify="center" mt="2rem">
        <Text lh="14px" size="14px">
          {t("register.already")}
        </Text>
        <Link
          to="/login"
          className="underline ml-1 text-[12px] leading-1 color-[#171922] font-bold uppercase"
        >
          {t("login.logIn")}
        </Link>
      </FlexWrapper>
      <TwoFactorModal
        displayCode={phone}
        isOpen={isTwofactorOpen}
        onResend={handleSendSMS}
        onClose={() => setIsTwofactorOpen(false)}
        onConfirm={(pin: any) => {
          if (isTwofactorOpen) {
            dispatch(setRegistrationData({ smsCode: pin }));
            setIsCodeVerified(true);
            setIsTwofactorOpen(false);
            setSMSCode(pin);
          }
        }}
      />
    </Wrapper>
  );
};

export default EmailAndMobile;
