import SendIcon from "../../components/Icons/Send";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import HomeIcon from "../../components/Icons/HomeIcon";
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';


export const FAQLinks = [{
    title: "FAQ.sendtitle",
    text: "FAQ.sendtext",
    to: "sendtransfers",
    icon: <SendIcon fill={'var(--text-color)'} />,
    type: 'icon'
},
{
    title: "FAQ.receivetitle",
    text: "FAQ.receivetext",
    to: "receivetransfers",
    icon: <SwapHorizIcon />
},
{
    title: "FAQ.SEPAtitle",
    text: "FAQ.SEPAtext",
    to: "sepa",
    icon: <AccountBalanceIcon />
},
{
    title: "FAQ.topuptitle",
    text: "FAQ.topuptext",
    to: "topup",
    icon: <AccountBalanceWalletOutlinedIcon />
},
{
    title: "FAQ.exchangetitle",
    text: "FAQ.exchangetext",
    to: "exchange",
    icon: <CurrencyExchangeOutlinedIcon />
},
{
    title: "FAQ.billtitle",
    text: "FAQ.billtext",
    to: "billpayments",
    icon: <HomeIcon />
},
{
    title: "FAQ.securitytitle",
    text: "FAQ.securitytext",
    to: "security",
    icon: <SettingsOutlinedIcon />
},
{
    title: "FAQ.cardtitle",
    text: "FAQ.cardtext",
    to: "iecards",
    icon: <CreditCardOutlinedIcon />
}]





export const FAQData = [
    // Send
    {
        key: "sendtransfers",
        title: "FAQ.sendtitle",
        // title: "FAQ.sendmoney.question1",
        backText: 'FAQ.sendtitle',
        data: [{
            title: 'FAQ.sendmoney.question1',
            text: `FAQ.sendmoney.answer1`
        },
        {
            title: 'FAQ.sendmoney.question2',
            text: `FAQ.sendmoney.answer2`
        },
        {
            title: 'FAQ.sendmoney.question3',
            text: `FAQ.sendmoney.answer3`
        },
        {
            title: 'FAQ.sendmoney.question4',
            text: `FAQ.sendmoney.answer4`
        },
        {
            title: 'FAQ.sendmoney.question5',
            text: `FAQ.sendmoney.answer5`
        },
        {
            title: 'FAQ.sendmoney.question6',
            text: `FAQ.sendmoney.answer6`
        }]
    },
    // Receive
    {
        key: "receivetransfers",
        title: "FAQ.withdrawmoney.question1",
        backText: 'FAQ.receivetitle',
        data: [{
            title: 'FAQ.withdrawmoney.question1',
            text: `FAQ.withdrawmoney.answer1`
        },
        {
            title: 'FAQ.withdrawmoney.question2',
            text: `FAQ.withdrawmoney.answer2`
        },
        {
            title: 'FAQ.withdrawmoney.question3',
            text: `FAQ.withdrawmoney.answer3`
        } ]
    },
    // SEPA
    {
        key: "sepa",
        title: "FAQ.SEPA.title",
        backText: 'FAQ.SEPAtitle',
        data: [{
            title: 'FAQ.SEPA.question1',
            text: "FAQ.SEPA.answer1"
        },
        {
            title: 'FAQ.SEPA.question2',
            text: "FAQ.SEPA.answer2"
        },
        {
            title: 'FAQ.SEPA.question3',
            text: "FAQ.SEPA.answer3"
        },
        {
            title: 'FAQ.SEPA.question4',
            text: "FAQ.SEPA.answer4"
        },
        {
            title: 'FAQ.SEPA.question5',
            text: "FAQ.SEPA.answer5"
        },
        {
            title: 'FAQ.SEPA.question6',
            text: "FAQ.SEPA.answer6"
        },
        {
            title: 'FAQ.SEPA.question7',
            text: "FAQ.SEPA.answer7"
        },
        {
            title: 'FAQ.SEPA.question8',
            text: "FAQ.SEPA.answer8"
        },
        {
            title: 'FAQ.SEPA.question9',
            text: "FAQ.SEPA.answer9"
        },
        {
            title: 'FAQ.SEPA.question10',
            text: "FAQ.SEPA.answer10"
        }]
    },
    // Topup
    {
        key: "topup",
        title: "FAQ.balance.title",
        backText: "FAQ.topuptitle",
        data: [{
            title: "FAQ.balance.question1",
            text: "FAQ.balance.answer1"
        },
        {
            title: "FAQ.balance.question2",
            text: "FAQ.balance.answer2"
        },
        {
            title: "FAQ.balance.question3",
            text: "FAQ.balance.answer3"
        },
        {
            title: "FAQ.balance.question4",
            text: "FAQ.balance.answer4"
        }]
    },
    // exchange
    {
        key: "exchange",
        title: "FAQ.exchange.title",
        backText: 'FAQ.exchangetitle',
        data: [{
            title: "FAQ.exchange.question1",
            text: "FAQ.exchange.answer1"
        },
        {
            title: "FAQ.exchange.question2",
            text: "FAQ.exchange.answer2"
 
        },
        ]
    },
    // Bill Payments
    {
        key: "billpayments",
        title: "FAQ.billpayments.title",
        backText: 'FAQ.billtitle',
        data: [{
            title: "FAQ.billpayments.question1",
            text: "FAQ.billpayments.answer1"
        },
        {
            title: "FAQ.billpayments.question2",
            text: "FAQ.billpayments.answer2"
        },
        {
            title: "FAQ.billpayments.question3",
            text: "FAQ.billpayments.answer3"
        },
        {
            title: "FAQ.billpayments.question4",
            text: "FAQ.billpayments.answer4"
        },
        ]
    },
    // Security
    {
        key: "security",
        title: "FAQ.security.title",
        backText: 'FAQ.securitytitle',
        data: [{
            title: "FAQ.security.question1",
            text: "FAQ.security.answer1"
        },
        {
            title: "FAQ.security.question2",
            text: "FAQ.security.answer2"
        },
        ]
    },
    // IECards
    {
        key: "iecards",
        title: "FAQ.card.title",
        backText: 'FAQ.cardtitle',
        data: [{
            title: "FAQ.card.question1",
            text: "FAQ.card.answer1"
        },
        {
            title: "FAQ.card.question2",
            text: "FAQ.card.answer2"
        },
        {
            title: "FAQ.card.question3",
            text: "FAQ.card.answer3"
        },
        {
            title: "FAQ.card.question4",
            text: "FAQ.card.answer4"
        }
        ]
    }
]