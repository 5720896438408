import { useTranslation } from "react-i18next";
import styled from "styled-components";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";

import {
  ButtonsWrapper,
  ContentWrapper,
} from "../../../../components/ResultModal/ResultModal.styled";
import Img from "../../../../components/Img";
import BaseModal from "../../../../components/BaseModal";
import Title from "../../../../components/Text/title";
import Text from "../../../../components/Text/text";
import FlexWrapper from "../../../../components/FlexWrapper";

const Container = styled.div`
  border-radius: 8px;
  padding: 16px;
  background: #f7f7f7;
  margin-top: 1rem;
  margin-bottom: 1rem;

  .box {
    background: #fff;
    display: flex;
    padding: 12px 8px;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .circle {
      border-radius: 50%;
      padding: 0.5rem;
      background: #f7f7f7;
    }
  }
`;

const NonDeactivationModal = ({ isOpen, onClose }: any) => {
  const { t } = useTranslation();

  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={() => {
        onClose();
      }}
    >
      <ContentWrapper>
        <Title size="2rem" ta="center" weight="700" mt="2rem" lh="2.5rem">
          {t("manage.deactivation_title")}
        </Title>
        <Text ta="center" size="14px">
          {t("manage.deactivation_text")}
        </Text>
        <Container>
          <div className="box" onClick={() => onClose()}>
            <FlexWrapper gap="8px" mb="0">
              <span className="circle">
                <Img src="/manage/circles_ext.svg" alt="text" />
              </span>

              <Text>{t("manage.withdraw")}</Text>
            </FlexWrapper>

            <span>
              <KeyboardArrowRightOutlinedIcon />
            </span>
          </div>
        </Container>

        <Text size="1rem" ml="1rem">
          {t("manage.questions")}
        </Text>
        <Text
          mb="1.5rem"
          color="var(--red)"
          className="underline"
          size="1rem"
          ml="1rem"
        >
          {t("manage.contact_us")}
        </Text>
        <ButtonsWrapper>
          <button
            className="btn btn-red w-100 uppercase"
            onClick={() => onClose()}
          >
            {t("common.backtohome")}
          </button>
        </ButtonsWrapper>
      </ContentWrapper>
    </BaseModal>
  );
};

export default NonDeactivationModal;
