const MultiRenderer = ({
  children,
  quantity,
}: {
  children: any;
  quantity?: number;
}) => {
  const skeletonLength = Array.from(Array(quantity).keys());

  if (skeletonLength) {
    return <>{skeletonLength.map(() => children)}</>;
  }

  return children;
};

export default MultiRenderer;
