import { useTranslation } from "react-i18next";

import Title from "../../../../components/Text/title";
import Text from "../../../../components/Text/text";
import Img from "../../../../components/Img";
import { Noresults } from "./Templates.styled";

const NoResults = () => {
  const { t } = useTranslation();


  return (
    <Noresults>
      <span>
        <Img src="/billpayments/icon_wrap.svg" alt="icon" />
      </span>
      <Title size="1rem" weight="700" mb="0.5rem" mt="1rem">
        {t("billpayments.notemplates")}
      </Title>
      <Text size="14px">{t("billpayments.noresultstext")}</Text>
    </Noresults>
  );
};

export default NoResults;
