import { Skeleton } from "@mui/material";

const RateSkeleton = () => {
  return (
    <div className={"flex justify-between  items-center mb-4"}>
      <div className="flex gap-4 items-center">
        <Skeleton
          variant="rounded"
          width={24}
          height={24}
          style={{ borderRadius: "50%" }}
        />{" "}
        <Skeleton
          width="50px"
          height={20}
          variant="text"
          sx={{ fontSize: "1rem" }}
        />
      </div>

      <div className="flex gap-4">
        <Skeleton
          height={20}
          width="50px"
          variant="text"
          sx={{ fontSize: "1rem" }}
        />
        <Skeleton
          height={20}
          width="50px"
          variant="text"
          sx={{ fontSize: "1rem", textAlign: "right" }}
        />
      </div>
    </div>
  );
};

export default RateSkeleton;
