import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Scrollbars from "rc-scrollbars";
import { useDispatch, useSelector } from "react-redux";
import { useToasts } from "react-toast-notifications";

import Title from "../../../../components/Text/title";
import Text from "../../../../components/Text/text";
import Divider from "../../../../components/Divider";
import FlexWrapper from "../../../../components/FlexWrapper";
import { AccountsWrapper, TemplatesList, TypeItem } from "./Templates.styled";
import {
  GetBankTransferTemplates,
  GetCashPickupTransferTemplates,
  GetOffices,
  deleteTemplateType,
  getBillTemplates,
  renameTemplateType,
} from "../../../../services/Services";
import Template from "./TemplateItem";
import InterruptionModal from "../../../../components/InterruptionModal";
import { userIdSelector } from "../../../../redux/selectors";
import SendInfoModal from "../../../Cashpickup/components/SendInfoModal";
import EditModal from "./editmodal";

const TYPES = [
  { title: "All", key: "manage.templates_all" },
  { title: "Cash-pick", key: "manage.templates_cash" },
  { title: "Bank Account", key: "manage.templates_bank" },
  { title: "Bill Payments", key: "manage.templates_bill" },
];

const Templates = () => {
  const { t } = useTranslation();
  const [bankTemplates, setBankTemplates] = useState<any>([]);
  const [cashTemplates, setCashTemplates] = useState<any>([]);
  const [billTemplates, setBillTemplates] = useState<any>([]);
  const [type, setType] = useState("All");
  const [isInterruptionOpen, setIsInterruptionOpen] = useState(false);
  const userId = useSelector(userIdSelector);
  const dispatch = useDispatch();
  const [tempId, setTempId] = useState<any>();
  const [tempType, setTempType] = useState<any>();
  const [tempName, setTempName] = useState("");
  const [sendInfoModal, setSendInfoModal] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const { addToast } = useToasts();
  const [chosenCountry, setChosenCountry] = useState({});
  const [officesLength, setOfficesLength] = useState(0);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    GetCashPickupTransferTemplates().then((res) =>
      setCashTemplates(res.data.Content)
    );
    getBillTemplates().then((res) => {
      setBillTemplates(res.data.Content);
    });
    GetBankTransferTemplates().then((res) => {
      setBankTemplates(res.data.Content);
    });
  }, []);

  const handleGetTemplates = (type: string) => {
    if (type === "Money Transfer") {
      GetCashPickupTransferTemplates().then((res) =>
        setCashTemplates(res.data.Content)
      );
    } else if (type === "Bank Transfer") {
      GetBankTransferTemplates().then((res) => {
        setBankTemplates(res.data.Content);
      });
    } else {
      getBillTemplates().then((res) => {
        setBillTemplates(res.data.Content);
      });
    }
  };
  const handleDelete = () => {
    dispatch(setLoading(true));
    deleteTemplateType(userId, tempId, tempType)
      .then((res: any) => {
        dispatch(setLoading(false));
        handleGetTemplates(tempType);
        setIsInterruptionOpen(false);
        addToast(<div>{res.data.StatusMessage}</div>, {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((err) => {
        dispatch(setLoading(false));
        setIsInterruptionOpen(false);
        addToast(<div>{err.response.data.StatusMessage}</div>, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const handleRename = (name: string) => {
    dispatch(setLoading(true));
    renameTemplateType(userId, tempId, name, tempType)
      .then((res: any) => {
        dispatch(setLoading(false));
        handleGetTemplates(tempType);
        setIsEditOpen(false);
        addToast(<div>{res.data.StatusMessage}</div>, {
          appearance: "success",
          autoDismiss: true,
        });
      })
      .catch((err) => {
        dispatch(setLoading(false));
        setIsEditOpen(false);
        addToast(<div>{err.response.data.StatusMessage}</div>, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  return (
    <AccountsWrapper>
      <Title size="18px" weight="600" color="#101828">
        {t("manage.templates.title")}
      </Title>
      <Text>{t("manage.templates.text")}</Text>
      <Divider color="#EAECF0" width="100%" mt="1rem" mb="1rem" />
      <div className="flex flex-start flex-col lg:flex-row">
        <div style={{ flex: 0.5 }}>
          <Title size="14px" weight="600" color="#101828">
            {t("banktransfer.mytemplates")}
          </Title>
        </div>
        <TemplatesList style={{ flex: 0.5 }}>
          <div className="w-full overflow-x-auto overflow-y-hidden ">
            <FlexWrapper
              gap="0.5rem"
              className="flex-1 overflow-x-auto overflow-y-hidden whitespace-nowrap pb-2"
            >
              {TYPES.map((item: any) => (
                <TypeItem
                  className=""
                  key={item.key}
                  onClick={() => setType(item.title)}
                  isActive={type === item.title}
                >
                  {t(item.key)}
                </TypeItem>
              ))}
            </FlexWrapper>
          </div>
          {!bankTemplates?.length && !cashTemplates?.length && !billTemplates.length && (
            <Title ta="center" mt="2rem">
              {t("manage.notemplates")}
            </Title>
          )}
          <Scrollbars style={{ height: 400 }}>
            {(type === "All" || type === "Cash-pick") &&
              cashTemplates?.map((template: any) => (
                <Template
                  key={template?.Id}
                  template={template}
                  type="Money Transfer"
                  onCentralizedClick={(country: any) => {
                    setChosenCountry(country);
                    GetOffices(country.CountryId).then((res) =>
                      setOfficesLength(res.data.length)
                    );
                    setSendInfoModal(true);
                  }}
                  onEdit={(id: number, type: string) => {
                    setTempName(template?.TemplateName);
                    setTempId(id);
                    setTempType(type);
                    setIsEditOpen(true);
                  }}
                  onDelete={(id: number, type: string) => {
                    setTempId(id);
                    setTempType(type);
                    setIsInterruptionOpen(true);
                  }}
                />
              ))}
            {(type === "All" || type === "Bank Account") &&
              bankTemplates?.map((template: any) => (
                <Template
                  key={template?.Id}
                  template={template}
                  onEdit={(id: number, type: string) => {
                    setTempName(template?.TemplateName);
                    setTempId(id);
                    setTempType(type);
                    setIsEditOpen(true);
                  }}
                  type="Bank Transfer"
                  onDelete={(id: number, type: string) => {
                    setTempId(id);
                    setTempType(type);
                    setIsInterruptionOpen(true);
                  }}
                />
              ))}

            {(type === "All" || type === "Bill Payments") &&
              billTemplates?.map((template: any) => (
                <Template
                  key={template?.Id}
                  template={template}
                  type="Bill Payment"
                  onEdit={(id: number, type: string) => {
                    setTempName(template?.AbonentName || template.ServiceName);
                    setTempId(id);
                    setTempType(type);
                    setIsEditOpen(true);
                  }}
                  onDelete={(id: number, type: string) => {
                    setTempId(id);
                    setTempType(type);
                    setIsInterruptionOpen(true);
                  }}
                />
              ))}
          </Scrollbars>
        </TemplatesList>
      </div>
      <InterruptionModal
        title={t("manage.templates_delete")}
        text={t("manage.templates_delete_text")}
        actionText={t("common.delete")}
        isOpen={isInterruptionOpen}
        onStayClick={handleDelete}
        onCloseClick={() => {
          setIsInterruptionOpen(false);
        }}
      />

      <EditModal
        templateName={tempName}
        isOpen={isEditOpen}
        onClose={() => setIsEditOpen(false)}
        onConfirm={(name: string) => handleRename(name)}
      />

      {sendInfoModal && (
        <SendInfoModal
          fromTemplates={true}
          officeList={officesLength}
          chosenCountry={chosenCountry}
          onContinue={() => {}}
          isOpen={sendInfoModal}
          onClose={() => setSendInfoModal(false)}
        />
      )}
    </AccountsWrapper>
  );
};

export default Templates;
