import styled from "styled-components";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Img from "../components/Img";
import { media } from "../utils/theme";

import FlexWrapper from "../components/FlexWrapper";
import LangSwitcher from "../translations/LangSwitcher";

const Wrapper = styled.div`
  padding: 1.5rem 1rem;
  display: none;
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 9;

  ${media.lessThan("small")} {
    display: block;

    ul {
      display: flex;
      gap: 36px;
      color: #6b7280;
      margin-top: 24px;

      li {
        font-size: 14px;
      }
    }
  }
`;

const AuthHeader = () => {

  const { t } = useTranslation();

  return (
    <Wrapper id="auth-header">
      <FlexWrapper justify="space-between">
        <div className="flex-1">
          <Link to="/home">
            <Img src={"/logo.svg"} alt="logo" />
          </Link>
        </div>

        <LangSwitcher onlyFlag={true} />
      </FlexWrapper>

      <ul>
        <li>
          <Link to="/about"> {t("about.company")}</Link>
        </li>
        <li>
          <Link to="/contact"> {t("footer.contact")}</Link>
        </li>
        <li>
          <Link to="/privacy"> {t("common.policy")}</Link>
        </li>
      </ul>
    </Wrapper>
  );
};

export default AuthHeader;
