import styled from "styled-components";
import { media } from "../../utils/theme";

export const Wrapper = styled.div`
  ${media.lessThan("small")} {
    padding: 0;
  }
`;

export const SearchWrapper = styled.div`
  background-color: #ffefef;
  padding: 36px 1rem 26px;
  margin-top: 1.5rem;
  border-radius: 8px;
  background-image: url(/assets/billpayments/bill-pattern.png);
  background-size: cover;
  position: relative;

  fieldset {
    max-height: 60px !important;
  }

  ${media.lessThan("small")} {
    padding: 1rem;
  }
`;

export const SearchInputWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 336px;
  align-items: center;
  position: relative;
  margin: auto;

  input {
    border: 1.19142px solid #e4e4e4;
    width: 336px;
    max-width: 336px;
    border-radius: 4.76568px;
    background: #fff;
    height: 56px !important;
    box-sizing: border-box;
  }

  label {
    color: #b1b1b1;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.1px;
  }

  button {
    position: absolute;
    right: 0;
    top: 8px;
    margin-top: 0 !important;
    border: none;
    outline: none;
    padding: 0.8rem 1rem 0.6rem;
    border-radius: 0px 4.76568px 4.76568px 0px;
    background: none;
    z-index: 9;
    pointer-events: none;
  }

  ${media.lessThan("small")} {
    max-width: 100%;
    input {
      width: 100%;
      max-width: 100%;
    }
  }
`;

export const Result = styled.div`
  position: absolute;
  top: 154px;
  width: 336px;
  padding: 16px 8px;
  left: 0;
  right: 0;
  margin: 0 auto;
  border-radius: 4px;
  border: 1px solid #ebebeb;
  box-shadow: 0px 2px 1px 0px rgba(111, 118, 137, 0.1);
  background: #fff;
  z-index: 101;

  @media (max-width: 768px) {
    top: 150px;
  }

  ${media.lessThan("small")} {
    top: 166px;
  }

  @media (max-width: 362px) {
    width: 100%;
    top: 196px;
  }
`;

export const SearchItem = styled.div`
  cursor: pointer;
  border-radius: 4px;
  border: 1px solid #ebebeb;
  background: #fbfbfb;
  padding: 0.5rem 0.75rem 0.5rem 0;
  margin-bottom: 0.5rem;
  margin-left: 8px;
  margin-right: 8px;

  svg {
    width: 16px;
  }

  &:hover {
    background: #e6e7e7;
  }
`;
