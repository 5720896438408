import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import cx from "classnames";
import i18next from "i18next";
import { useLocation } from "react-router";

import BaseModal from "../components/BaseModal";
import { setLang } from "../redux/authSlice";
import { getToken, getUserId } from "../utils/storage";
import { setAcceptLanguage } from "../services/axios";
import Img from "../components/Img";
import Text from "../components/Text/text";
import {
  GetAvailableLanguages,
  changeLanguageService,
  getImage,
} from "../services/Services";
import Title from "../components/Text/title";
import FlexWrapper from "../components/FlexWrapper";
import { Desktop, Mobile } from "../components/responsive/responsive";
import {
  CurrentLocale,
 
  LangItem,
  LangModalContent,
  LangsWrapper,
} from "./LangSwitcher.styled";
import { isEmpty } from "../utils/helpers";
import "./i18n";
 

type LangDataItem = {
  name: string;
  code: string;
  actualCode: string;
};

interface LangSwitcherProps {
  isAuthorized?: boolean;
  onlyFlag?: boolean;
  isContentFooter?: boolean;
}

const langData = [
  {
    name: "English",
    code: "gb",
    actualCode: "en",
    img: "/home/rates/gb-flag.svg",
  },
  {
    name: "ქართული",
    code: "ge",
    actualCode: "ka",
    img: "/home/rates/ge-flag.svg",
  },
  {
    name: "Ελληνικά",
    code: "gr",
    actualCode: "el",
    img: "/home/rates/gr-flag.svg",
  },
  {
    name: "Русский",
    code: "ru",
    actualCode: "ru",
    img: "/home/rates/ru-flag.svg",
  },
  {
    name: "Filipino",
    code: "ph",
    actualCode: "tl",
    img: "/home/rates/tl-flag.png",
  },
];

const handleCurrentLang = (lang: String) => {
  if (lang === "en") {
    return "gb";
  } else if (lang === "ka") {
    return "ge";
  } else if (lang === "el") {
    return "gr";
  } else if (lang === "tl") {
    return "ph";
  } else {
    return lang;
  }
};

const getAvailableLanguageId = (
  availableLanguages: any,
  currentLang: string
) => {
  if (currentLang === "gb") {
    return availableLanguages.find((item: any) => item.Alpha2 === "US")?.Id;
  } else if (currentLang === "ka") {
    return availableLanguages.find((item: any) => item.Alpha2 === "GE")?.Id;
  } else if (currentLang === "el") {
    return availableLanguages.find((item: any) => item.Alpha2 === "GR")?.Id;
  } else if (currentLang === "tl") {
    return availableLanguages.find((item: any) => item.Alpha2 === "PH")?.Id;
  }

  return availableLanguages.find(
    (item: any) => item.Alpha2.toLowerCase() === currentLang
  )?.Id;
};

const LangSwitcher = ({
  isAuthorized,
  onlyFlag,
  isContentFooter,
}: LangSwitcherProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [currentLang, setCurrentLang] = useState<string | null>(
    i18next.language || localStorage.getItem("locale")
  );
  const token = getToken();
  const [displayImage, setDisplayImage] = useState("");
  const [flags, setFlags] = useState<any>([]);
  const [chosenLang, setChosenLang] = useState("");
  const location = useLocation();
  const isLoginPage = location.pathname === "/login";
  const [availableLanguages, setAvailableLanguage] = useState([]);
  const userId = getUserId();

  useEffect(() => {
    if (isAuthorized) {
      var langDataCodes: any = langData.map((item: any) => item.code);

      langDataCodes.map((item: any) => {
        const type = item.split("/").pop();
        getImage(type).then((res) => {
          setFlags((prevState: any) => ({
            ...prevState,
            [item]: res.data,
          }));
        });
      });

      GetAvailableLanguages()
        .then((res) => setAvailableLanguage(res.data.Content))
        .catch((err) => {});
    }
  }, [isAuthorized]);

  const changeLanguage = () => {
    let languageToSwitch;
    if (chosenLang === "ge") {
      languageToSwitch = "ka";
    } else if (chosenLang === "gr") {
      languageToSwitch = "el";
    } else if (chosenLang === "ph") {
      languageToSwitch = "tl";
    } else {
      languageToSwitch = chosenLang;
    }

    i18next.changeLanguage(languageToSwitch);
    setAcceptLanguage(token, languageToSwitch);
    dispatch(setLang(languageToSwitch));
    setCurrentLang(languageToSwitch);

    if (isAuthorized) {
      changeLanguageService(
        userId,
        getAvailableLanguageId(availableLanguages, languageToSwitch)
      );
    }

    if (isAuthorized) {
      getImage(chosenLang).then((res) => setDisplayImage(res.data));
    }

    setIsOpen(false);
  };

  const langCode = langData.find(
    (item) => item.actualCode === currentLang
  )?.code;
  const langName =
    langData.find((item) => item.actualCode === currentLang)?.name || "English";
  const flagLangCode = langData.find((item) => item.actualCode === currentLang)?.code || "gb";
  const currentLangToDisplay: any = isAuthorized && langName;

  useEffect(() => {
    if (langCode && isAuthorized) {
      getImage(langCode).then((res) => setDisplayImage(res.data));
      setChosenLang(langCode);
    } else if (!isAuthorized && langCode) {
      setChosenLang(langCode);
    }
  }, [isAuthorized, langCode]);

  return (
    <>
      <CurrentLocale
        tabIndex={0}
        className={cx(
          isLoginPage &&
            "!bg-transparent hover:!bg-white sm:ml-[-1.5rem]  group"
        )}
        onClick={() => setIsOpen(true)}
        isAuthorized={isAuthorized}
        isLoginPage={isLoginPage}
        isContentFooter={isContentFooter}
      >
        {isAuthorized && (
          <div
            style={{
              borderRadius: "50%",
              width: 24,
              height: 24,
              flexShrink: 0,
            }}
            dangerouslySetInnerHTML={{
              // @ts-ignore
              __html: flags[handleCurrentLang(currentLang)] || displayImage,
            }}
          />
        )}

        <Text
          ml={!isAuthorized ? "0" : "0.75rem"}
          size="14px"
          className="group-hover:!text-black"
        >
          <Desktop>{currentLangToDisplay}</Desktop>
          <Desktop>{!isAuthorized && !isLoginPage && !isContentFooter && langName}</Desktop>
          {isLoginPage || isContentFooter ? (
            <div>
              {" "}
              <FlexWrapper mb="0" gap="0.75rem">
                {!location.pathname.includes("register") && (
                  <Img
                    width={24}
                    src={`/flags/new/${flagLangCode}-flag.svg`}
                    alt={"flag"}
                  />
                )}
                {!onlyFlag && langName}
              </FlexWrapper>
            </div>
          ) : (
            <Mobile>
              <FlexWrapper
                mb="0"
                gap="0.75rem"
                style={{
                  marginLeft: !location.pathname.includes("register") ? -35 : 0,
                }}
              >
                {!location.pathname.includes("register") && (
                  <Img
                    width={24}
                    src={`/flags/new/${chosenLang}-flag.svg`}
                    alt={"flag"}
                  />
                )}
                {!onlyFlag && langName}
              </FlexWrapper>
            </Mobile>
          )}
        </Text>
      </CurrentLocale>

      <BaseModal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        className="modal-large"
      >
        <LangModalContent>
          <FlexWrapper gap="1rem" style={{padding: "1.5rem 1.5rem 0"}} mb="0.25rem">
            <span>
              <Img src="/home/languages/global.svg" alt="globe" />
            </span>
            <div>
              <Title color="#171922" mb="0">{t("lang.languages")}</Title>
              <Text>{t("lang.choose")}</Text>
            </div>
          </FlexWrapper>
          <LangsWrapper>
            <FlexWrapper gap="0.5rem" mb='0'>
              {isAuthorized ? (
                <div
                  style={{
                    borderRadius: "50%",
                    width: 48,
                    height: 48,
                    border: "2px solid #212121",
                    padding: "0.35rem",
                    boxSizing: "content-box",
                  }}
                  className="main-image"
                  dangerouslySetInnerHTML={{
                    __html: displayImage,
                  }}
                />
              ) : (
                <Img
                  width={48}
                  src={`/flags/new/${chosenLang}-flag.svg`}
                  alt={langName}
                />
              )}
              <div>
                <Title size="14px" mb="0" color="#171922">{t("lang.selectedlanguage")}</Title>
                <Text>
                  {langData.find((item) => item.code === chosenLang)?.name}
                </Text>
              </div>
            </FlexWrapper>

            <FlexWrapper>
              {langData
                .filter((item) => item.code !== chosenLang)
                .map((item: LangDataItem) => (
                  <LangItem
                    key={item.code}
                    className={`lang-item
                 ${chosenLang === item.code ? "active" : ""}`}
                    onClick={() => {
                      setChosenLang(item.code);
                      if (isAuthorized && isEmpty(flags)) {
                        getImage(item.code).then((res) =>
                          setDisplayImage(res.data)
                        );
                      } else if (!isEmpty(flags) && isAuthorized) {
                        setDisplayImage(flags[item.code]);
                      }
                    }}
                  >
                    {isAuthorized ? (
                      <div
                        style={{
                          borderRadius: "50%",
                          width: 24,
                          height: 24,
                        }}
                        dangerouslySetInnerHTML={{
                          __html: flags[item.code],
                        }}
                      />
                    ) : (
                      <Img
                        width={24}
                        src={`/flags/new/${item.code}-flag.svg`}
                        alt={item.code}
                      />
                    )}
                  </LangItem>
                ))}
            </FlexWrapper>
          </LangsWrapper>
          <FlexWrapper justify="flex-end" mb="0" gap="12px" style={{padding: 24}}>
            <button className="btn font-semibold btn-plain !px-4" onClick={() => setIsOpen(false)}>
              {t("common.cancel")}
            </button>
            <button className="btn btn-red !px-4 !rounded-lg" onClick={changeLanguage}>
              {t("lang.savechanges")}
            </button>
          </FlexWrapper>
        </LangModalContent>
      </BaseModal>
    </>
  );
};

export default LangSwitcher;
