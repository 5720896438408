import { useTranslation } from "react-i18next";
import { Collapse } from "@mui/material";
import { useToasts } from "react-toast-notifications";
import { useState } from "react";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import KeyboardDoubleArrowDownOutlinedIcon from "@mui/icons-material/KeyboardDoubleArrowDownOutlined";
import { useSelector } from "react-redux";

import Title from "../../components/Text/title";
import Box from "../../components/Tailwind/Box";
import FlexWrapper from "../../components/FlexWrapper";
import Text from "../../components/Text/text";
import Divider from "../../components/Divider";
import { accountsSelector } from "../../redux/selectors";
import Img from "../../components/Img";
import Radio from "../../components/Form/Radio";
import {
  Mobile,
  TabletOrDesktop,
} from "../../components/responsive/responsive";

type ViaBankAccountProps = {
  onClick: (value: boolean) => void;
  className: string;
  onBackClick: () => void;
};

const BankAccount = ({ onBackClick }: ViaBankAccountProps) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const accounts = useSelector(accountsSelector);
  const walletName = accounts && accounts[0]?.WalletName;
  const handleCopy = (account: string) => {
    navigator.clipboard.writeText(account);
    addToast(<div>{t("topup.copied")}</div>, {
      appearance: "success",
      autoDismiss: true,
    });
  };
  const [chosen, setChosen] = useState("accounts");
  const [mode, setMode] = useState("");

  return (
    <>
      <div className="[@media(max-width:553px)]:pt-6 ">
        <TabletOrDesktop>
          <Title
            mb="1rem"
            size="1.5rem"
            color="#171922"
            weight={700}
            className="title !ml-0 !mt-[30px] w-[calc(100%-111px)]"
          >
            {t("topup.addfunds")}
          </Title>
        </TabletOrDesktop>
        <Box>
          <TabletOrDesktop>
            <FlexWrapper gap="0.5rem" mb="0">
              <FlexWrapper
                gap="0.5rem"
                onClick={onBackClick}
                className="pointer"
              >
                <ArrowBackOutlinedIcon />
              </FlexWrapper>
              <FlexWrapper gap="0.5rem">
                <Title mb="0" size="1.5rem" weight="700" color="#000">
                  {t("topup.frominternetbanking")}
                </Title>
              </FlexWrapper>
            </FlexWrapper>
          </TabletOrDesktop>
          <Mobile>
            <FlexWrapper gap="0.5rem" onClick={onBackClick} className="pointer">
              <ArrowBackOutlinedIcon />
            </FlexWrapper>
            <FlexWrapper gap="0.5rem" mb="0">
              <Title mb="0" size="1.5rem" weight="700" color="#000">
                {t("topup.frominternetbanking")}
              </Title>
            </FlexWrapper>
          </Mobile>
          <Text size="1rem" lh="1.5rem" color="#475467">
            {t("topup.text")}
          </Text>
          <Divider width="100%" mt="1.25rem" mb="1rem" />
          <Text size="1rem" color="#101828" weight="500">
            {t("topup.subtitlenew")}
          </Text>

          <div className=" flex flex-col lg:flex-row gap-4 mb-0 mt-4">
            <div
              className="rounded-xl p-3 py-4 bg-[#F6F6F6] flex-1 border-solid border-[1px] border-[#EBEBEB] pointer relative"
              onClick={() => setChosen("accounts")}
            >
              <FlexWrapper mb="0">
                <Img src="/sidebar/new/Logo-tablet.svg" alt="logo" />{" "}
                <Text mb="0" ml="0.5rem" color="#171922" size="14px">
                  {t("accounts.accounts")}
                </Text>
              </FlexWrapper>
              <Radio
                className="absolute top-3 right-2"
                checked={chosen === "accounts"}
              />
            </div>
            <div className="rounded-xl p-3 py-4 bg-[#F6F6F6] flex-1 border-solid border-[1px] border-[#EBEBEB] pointer relative opacity-[0.5]">
              <FlexWrapper>
                <Img width={35} src="/home/cards/card.svg" alt="card" />
                <Text mb="0" ml="0.5rem" color="#171922" size="14px">
                  {t("intelexpresscard.intelexpresscard")}
                </Text>
              </FlexWrapper>
            </div>
            {/* <div className="rounded-xl p-3 py-4 bg-[#F6F6F6] flex-1 border-solid border-[1px] border-[#EBEBEB] pointer relative opacity-[0.5]">
            <FlexWrapper>
              <Img src="/home/greece-wallet.svg" alt="wallet" />
              <Text mb="0" ml="0.5rem" color="#171922" size="14px">
                {t("home.greeceaccount")}
              </Text>
            </FlexWrapper>
          </div> */}
          </div>

          {chosen === "accounts" && (
            <div>
              <Text size="14px" mt="1rem" color="#101828" mb="2px">
                {t("topup.banktext")}
              </Text>
              <Text mb="1rem">{t("topup.subtext")}</Text>
              <div className="flex flex-col lg:flex-row mb-0 gap-6">
                <div className="flex-1 font-['Roboto']">
                  <div
                    className={`bg-[#E9F9FF] p-4 flex items-center justify-between rounded-lg flex-1 pointer ${
                      mode === "tbc" ? "rounded-b-none" : ""
                    }`}
                    onClick={() => {
                      if (mode === "tbc") {
                        setMode("none");
                      } else {
                        setMode("tbc");
                      }
                    }}
                  >
                    <FlexWrapper mb="0" gap="0.5rem">
                      <div className="shrink-0">
                        <Img src="/topup/TBC.png" alt="tbc" />
                      </div>
                      <div>
                        <Title mb="4px">TBC Bank</Title>
                        <Text>{t("topup.multicurrecy")}</Text>
                      </div>
                    </FlexWrapper>
                    <FlexWrapper mb="0" gap="4px">
                      <div className="text-white text-[14px] bg-[#00A4E0] leading-normal rounded-lg py-[2px] px-[10px]">
                        {t("common.details")}
                      </div>
                      <div className="p-2">
                        <KeyboardDoubleArrowDownOutlinedIcon
                          style={{
                            fill: "#00A4E0",
                            transform:
                              mode === "tbc"
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                          }}
                        />
                      </div>
                    </FlexWrapper>
                  </div>
                  <Collapse in={mode === "tbc"}>
                    <div>
                      <div className="py-2 px-4 flex items-center justify-between border-[1px] border-solid border-[#EBEBEB]">
                        <div>
                          <Text
                            className="uppercase"
                            mb="4px"
                            weight="500"
                            color="#CDCDCD"
                          >
                            {t("topup.gelaccount")}
                          </Text>
                          <Title mb="0" size="14px">
                            GE13TB7908936080100005
                          </Title>
                        </div>{" "}
                        <Img
                          className="pointer"
                          src="/topup/copy.svg"
                          onClick={() => handleCopy("GE13TB7908936080100005")}
                          alt="copy"
                        />
                      </div>
                      <div className="py-2 px-4 flex items-center justify-between border-[1px] border-solid border-[#EBEBEB]">
                        <div>
                          <Text
                            className="uppercase"
                            mb="4px"
                            weight="500"
                            color="#CDCDCD"
                          >
                            {t("topup.formultiaccount")}
                          </Text>
                          <Title mb="0" size="14px">
                            GE77TB7908936120100004
                          </Title>{" "}
                        </div>

                        <Img
                          className="pointer"
                          src="/topup/copy.svg"
                          onClick={() => handleCopy("GE77TB7908936120100004")}
                          alt="copy"
                        />
                      </div>

                      <div className="py-2 px-4 flex items-center justify-between border-[1px] border-solid border-[#EBEBEB]">
                        <div>
                          <Text
                            className="uppercase"
                            mb="4px"
                            weight="500"
                            color="#CDCDCD"
                          >
                            {t("topup.receivername")}
                          </Text>
                          <Title mb="0" size="14px">
                            LTD INEXPAY
                          </Title>{" "}
                        </div>{" "}
                        <Img
                          className="pointer"
                          src="/topup/copy.svg"
                          onClick={() => handleCopy("LTD INEXPAY")}
                          alt="copy"
                        />
                      </div>
                      <div className="py-2 px-4 flex items-center justify-between border-[1px] border-solid border-[#EBEBEB]">
                        <div>
                          {" "}
                          <Text
                            className="uppercase"
                            mb="4px"
                            weight="500"
                            color="#CDCDCD"
                          >
                            {t("topup.paymentdetails")}
                          </Text>
                          <Title mb="0" size="14px" className="max-w-[300px]">
                            Wallet ID {walletName} transfer to Inexpay wallet
                          </Title>{" "}
                        </div>{" "}
                        <Img
                          className="pointer"
                          src="/topup/copy.svg"
                          onClick={() =>
                            handleCopy(
                              `Wallet ID ${walletName} transfer to Inexpay wallet`
                            )
                          }
                          alt="copy"
                        />
                      </div>
                    </div>
                  </Collapse>
                </div>

                <div className="flex-1 font-['Roboto']">
                  <div
                    className={`bg-[#FFF1EB] p-4 flex items-center justify-between rounded-lg flex-1 pointer ${
                      mode === "bog" ? "rounded-b-none" : ""
                    }`}
                    onClick={() => {
                      if (mode === "bog") {
                        setMode("none");
                      } else {
                        setMode("bog");
                      }
                    }}
                  >
                    <FlexWrapper mb="0" gap="0.5rem">
                      <div className="shrink-0">
                        <Img src="/topup/BOG.png" alt="tbc" />
                      </div>
                      <div>
                        <Title mb="4px">Bank Of Georgia</Title>
                        <Text>{t("topup.multicurrecy")}</Text>
                      </div>
                    </FlexWrapper>
                    <FlexWrapper mb="0" gap="4px">
                      <div className="text-white text-[14px] bg-[#FF671B] leading-normal rounded-lg py-[2px] px-[10px]">
                        {t("common.details")}
                      </div>
                      <div className="p-2">
                        <KeyboardDoubleArrowDownOutlinedIcon
                          style={{
                            fill: "#FF671B",
                            transform:
                              mode === "bog"
                                ? "rotate(180deg)"
                                : "rotate(0deg)",
                          }}
                        />
                      </div>
                    </FlexWrapper>
                  </div>

                  <Collapse in={mode === "bog"}>
                    <div>
                      <div className="py-2 px-4 flex items-center justify-between border-[1px] border-solid border-[#EBEBEB]">
                        <div>
                          <Text
                            className="uppercase"
                            mb="4px"
                            weight="500"
                            color="#CDCDCD"
                          >
                            {t("topup.formultiaccountbog")}
                          </Text>
                          <Title mb="0" size="14px">
                            GE35BG0000000683720200
                          </Title>{" "}
                        </div>
                        <Img
                          className="pointer"
                          src="/topup/copy.svg"
                          onClick={() => handleCopy(`GE35BG0000000683720200`)}
                          alt="copy"
                        />
                      </div>
                      <div className="py-2 px-4 flex items-center justify-between border-[1px] border-solid border-[#EBEBEB]">
                        <div>
                          <Text
                            className="uppercase"
                            mb="4px"
                            weight="500"
                            color="#CDCDCD"
                          >
                            {t("topup.receivername")}
                          </Text>
                          <Title mb="0" size="14px">
                            LTD INEXPAY
                          </Title>{" "}
                        </div>{" "}
                        <Img
                          className="pointer"
                          src="/topup/copy.svg"
                          onClick={() => handleCopy(`LTD INEXPAY`)}
                          alt="copy"
                        />
                      </div>
                      <div className="py-2 px-4 flex items-center justify-between border-[1px] border-solid border-[#EBEBEB]">
                        <div>
                          {" "}
                          <Text
                            className="uppercase"
                            mb="4px"
                            weight="500"
                            color="#CDCDCD"
                          >
                            {t("topup.paymentdetails")}
                          </Text>
                          <Title mb="0" size="14px" className="max-w-[300px]">
                            Wallet ID {walletName} transfer to Inexpay wallet
                          </Title>{" "}
                        </div>{" "}
                        <Img
                          className="pointer"
                          src="/topup/copy.svg"
                          onClick={() =>
                            handleCopy(
                              `Wallet ID ${walletName} transfer to Inexpay wallet`
                            )
                          }
                          alt="copy"
                        />
                      </div>
                    </div>
                  </Collapse>
                </div>
              </div>
            </div>
          )}
        </Box>
      </div>
    </>
  );
};

export default BankAccount;
