import styled from "styled-components";
import { useTranslation } from "react-i18next";

import BaseModal from "../../../components/BaseModal";
import Text from "../../../components/Text/text";
import Title from "../../../components/Text/title";
import Img from "../../../components/Img";

const ContentWrapper = styled.div`
  max-width: 400px;
  padding: 1.5rem;

  .standart {
    color: #3538cd;
    background: #eef4ff;
    font-size: 12px;
    border-radius: 6px;
    padding: 2px 6px;
    line-height: 12px;
    transform: translateY(-2px);
    display: inline-block;
    border: 1px solid #c7d7fe;
    margin-left: 3px;
    letter-spacing: 0;
  }

  @media (max-width: 553px) {
    max-width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    padding: 10px;
    width: 50%;
    height: 44px;
  }

  .btn-plain {
    border: 1px solid #e4e4e4;
    border-radius: 4px;

    &:hover {
      background: #f9fafb;
    }
  }

  button:first-child {
    margin-right: 12px;
  }
`;

const ImageWrapper = styled.div`
  text-align: center;
  margin-top: 0.5rem;
`;

const CardApplicationModal = ({ isOpen, onClose, onOrder }: any) => {
  const { t } = useTranslation();

  return (
    <BaseModal isOpen={isOpen} onRequestClose={() => onClose()}>
      <ContentWrapper>
        <ImageWrapper>
          <Img src="/home/cards/card.svg" alt="freepik" />
        </ImageWrapper>
        <Title
          color="#171922"
          ta="center"
          mt="1rem"
          size="18px"
          weight="500"
          mb="4px"
        >
          {t("intelexpresscard.physicalcard")}{" "}
          <span className="standart">Standart</span>
        </Title>
        <Text ta="center" mb="2rem" size="14px">
          {t("intelexpresscard.physicalcardtext")}
        </Text>
        <ButtonWrapper>
          <button onClick={() => onClose()} className="btn btn-plain uppercase">
            {t("common.close")}
          </button>
          <button className="btn btn-red uppercase" onClick={onOrder}>
            {t("intelexpresscard.ordercard")}
          </button>
        </ButtonWrapper>
      </ContentWrapper>
    </BaseModal>
  );
};

export default CardApplicationModal;
