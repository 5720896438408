import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";

import { sendTransferDataSelector } from "../../../redux/selectors";
import Input from "../../../components/Form/Input";
import Title from "../../../components/Text/title";
import { CountryCode, DetailsForm } from "./Details.styled";
import BackButton from "../components/BackButton";
import FlexWrapper from "../../../components/FlexWrapper";
import { GetCountryCodes, getImage } from "../../../services/Services";
import { isEmpty } from "../../../utils/helpers";
import { setSendTransferData } from "../../../redux/transferSlice";
import Text from "../../../components/Text/text";
import CountryCodeSearch from "../components/CountryCodeSearch";
import VerticalDivider from "../../../components/VerticalDivider";
import { preventNonNumeric } from "../../../utils/utils";
import { FORM_PATTERNS } from "../../../constants";

interface DetailsProps {
  disabled?: boolean;
  onNextPress?: () => void;
  onPreviousPress?: () => void;
}

const Details = ({ disabled, onNextPress, onPreviousPress }: DetailsProps) => {
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const sendTransferData = useSelector(sendTransferDataSelector);
  const [countryCodes, setCountryCodes] = useState<any>([]);
  const [countryCode, setCountryCode] = useState("");
  const [chosenCode, setChosenCode] = useState("");
  const [countryNameCode, setCountryName] = useState("");
  const [countrySvg, setCountrySvg] = useState(null);
  const [showCountryCodes, setShowCountryCodes] = useState(false);
  const [countryId, setCountryId] = useState("");
  const isFromActiveTemplate = sendTransferData?.isFromActiveTemplate;

  useEffect(() => {
      GetCountryCodes().then((res) => {
        setCountryCodes(res.data.Content);
        const chosenCode = res.data.Content.find(
          (item: any) =>
            item.Alpha2 ===
            (sendTransferData.chosenCountry?.countryCode ||
              sendTransferData.chosenCountry?.CountryCode ||
              sendTransferData.Alpha2)
        )?.Code;
        const chosenCountry = res.data.Content.find(
          (item: any) =>
            item.Alpha2 ===
            (sendTransferData.chosenCountry?.countryCode ||
              sendTransferData.chosenCountry?.CountryCode)
        )?.Alpha2;
        setChosenCode(chosenCode);

        getImage(
          sendTransferData.Alpha2 || sendTransferData.alpha2 || chosenCountry
        )
          .then((res) => setCountrySvg(res.data))
          .catch((res) => {});
      });
  }, []);

  useEffect(() => {
    if (!isEmpty(sendTransferData)) {
      setValue("name", sendTransferData.firstName);
      setValue("surName", sendTransferData.lastName);
      setValue("mobile", sendTransferData.phoneNumber);

      // setChosenCode(sendTransferData.chosenCode);
      setCountryCode(sendTransferData.country);
      setCountryName(sendTransferData.countryNameCode);
      setCountryId(sendTransferData.countryId);

      if (isFromActiveTemplate) {
        getImage(sendTransferData.Alpha2)
          .then((res) => setCountrySvg(res.data))
          .catch((res) => {});
      } else {
        if (sendTransferData.country || sendTransferData.alpha2) {
          getImage(
            sendTransferData.countryNameCode ||
              sendTransferData.alpha2 ||
              sendTransferData?.chosenCountry?.CountryCode ||
              sendTransferData.country
          )
            .then((res) => setCountrySvg(res.data))
            .catch((res) => {});
        }
      }
    }
  }, [sendTransferData, setValue]);

  const onSubmit = (data: any) => {
    const detailsData: any = {};
    const chosenCountry = countryCodes.find(
      (item: any) =>
        item.Alpha2 ===
        (sendTransferData.chosenCountry?.countryCode ||
          sendTransferData.chosenCountry?.CountryCode)
    )?.Alpha2;

    detailsData.firstName = data.name;
    detailsData.lastName = data.surName;
    detailsData.phoneNumber = data.mobile;
    detailsData.chosenCode = chosenCode;
    detailsData.alpha2 = chosenCountry;

    detailsData.country =
      countryId || countryCode || chosenCode || sendTransferData?.countryId;
    detailsData.countryNameCode = countryNameCode;

    dispatch(setSendTransferData(detailsData));
    onNextPress && onNextPress();
  };

  if (showCountryCodes) {
    return (
      <DetailsForm className="!pb-0">
        <CountryCodeSearch
          onPreviousPress={() => setShowCountryCodes(false)}
          chooseCountryCode={(countryCode: any) => {
            setCountryId(countryCode?.Id);
            setShowCountryCodes(false);
            setCountryCode(countryCode.Alpha2);
            setChosenCode(countryCode.Code);
            setCountryName(countryCode.Alpha2);
            getImage(countryCode.Alpha2)
              .then((res) => setCountrySvg(res.data))
              .then((res) => {});
          }}
        />
      </DetailsForm>
    );
  }

  return (
    <DetailsForm
      onSubmit={handleSubmit(onSubmit)}
      disabled={disabled}
      className="animate-fadeIn"
    >
      <BackButton onClick={onPreviousPress} />
      <Title
        className="title"
        mb="1.5rem"
        size="2rem"
        weight="600"
        lh="2.5rem"
        ta="center"
      >
        {t("banktransfer.details.title")}
      </Title>

      <div className="wrap">
        <div>
          <Input
            className="textual"
            disabled={isFromActiveTemplate}
            label={t("common.name")}
            {...register("name", {
              required: {
                message: t("common.required"),
                value: true,
              },
              pattern: {
                value: /^[A-Za-z\s]+$/,
                message: t("form.nameformat"),
              },
            })}
            hintText={`*${t("common.required")}`}
            errorText={errors.name?.message}
            onKeyPress={(event: any) => {
              // eslint-disable-next-line
              if (!/[a-zA-Z\s\\/]+/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
          <Input
            className="textual"
            disabled={isFromActiveTemplate}
            label={t("common.lastname")}
            {...register("surName", {
              required: {
                message: t("common.required"),
                value: true,
              },
              pattern: {
                value: /^[A-Za-z\s]+$/,
                message: t("form.nameformat"),
              },
            })}
            hintText={`*${t("common.required")}`}
            errorText={errors.surName?.message}
            onKeyPress={(event: any) => {
              // eslint-disable-next-line
              if (!/[a-zA-Z\s\.\/]+/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
        </div>

        <FlexWrapper align="flex-start" style={{ flex: 1, width: "100%" }}>
          <Input
            startAdornment={
              <CountryCode
                onClick={() =>
                  isFromActiveTemplate ? null : setShowCountryCodes(true)
                }
              >
                <div
                  className="flag"
                  style={{
                    marginRight: 8,
                    width: 24,
                    height: 24,
                    overflow: "hidden",
                    borderRadius: "50%",
                  }}
                  // @ts-ignore
                  dangerouslySetInnerHTML={{ __html: countrySvg }}
                />
                <Text size="14px" color="#212121" mr="4px">
                  {chosenCode}{" "}
                </Text>
                <ArrowForwardIosOutlinedIcon
                  style={{ maxHeight: 16, fill: "#000000" }}
                />
                <VerticalDivider height="40px" ml="0.5rem" color="#CBCBCB" />
              </CountryCode>
            }
            disabled={isFromActiveTemplate}
            className="mobile-input"
            label={t("register.mobile")}
            {...register("mobile", {
              required: false,
              validate: (value) => {
                return (
                  value?.length &&
                  FORM_PATTERNS.minThreeMaxTwentyFiveChars.value.test(value)
                );
              },
            })}
            onPaste={(event: any) => {
              event.preventDefault();
              const contents = event.clipboardData.getData("text");
              setValue("mobile", contents.replace(/[^0-9]/g, ""));
            }}
            onKeyPress={preventNonNumeric}
            errorText={errors.mobile?.message}
          />
        </FlexWrapper>

        <Text color="#56575B" mt="-0.7rem">
          {t("cashpickup.infotext")}
        </Text>
        <button className="btn btn-red w-100 uppercase max-h-[40px] !mt-7">
          {t("common.next")}
        </button>
      </div>
    </DetailsForm>
  );
};

export default Details;
