import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ErrorIcon from "@mui/icons-material/Error";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";

import Title from "../../components/Text/title";
import Box from "../../components/Tailwind/Box";
import FlexWrapper from "../../components/FlexWrapper";
import Text from "../../components/Text/text";
import Divider from "../../components/Divider";
import Img from "../../components/Img";
import { useEffect, useState } from "react";

import Select from "../../components/Form/Select";
import { getRate, preventNonNumeric } from "../../utils/utils";
import { AmountItem } from "../Exchange/Exchange.styled";
import { formatBalance, replaceComma } from "../../utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import {
  accountsSelector,
  balancesSelector,
  ratesSelector,
} from "../../redux/selectors";
import {
  TopupWithCard,
  deleteTopupCard,
  getAllBalances,
  getImage,
  getTopupCards,
} from "../../services/Services";
import {
  Account,
  Balance as BalanceProps,
} from "../home/Accounts/accounts/types";
import { media } from "../../utils/theme";
// import CustomTooltip from "../../../components/CustomTooltip";
import Tooltip from "rc-tooltip";
import { setLoading } from "../../redux/commonSlice";
import InterruptionModal from "../../components/InterruptionModal";
import Radio from "../../components/Form/Radio";
import { useToasts } from "react-toast-notifications";
import {
  Mobile,
  TabletOrDesktop,
} from "../../components/responsive/responsive";
import { getUserId } from "../../utils/storage";

export const BlockWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0;
  box-sizing: border-box;
  border-radius: 4px;
  margin: auto;
  background: #fff;
  max-width: 360px;

  .select {
    width: 120px;
  }

  .body {
    width: 130px;
    margin-top: 4px;
  }

  .dd-header {
    border: 1px solid #cbcbcb;
    border-left: 0;
    border-radius: 0 8px 8px 0;
    height: 100%;

    & > div {
      border: none;
      padding: 12px 24px 10px 4px;
      font-weight: bold;
      background: transparent;
    }
  }

  input {
    border: none;
    border: 1px solid #cbcbcb;
    padding: 0.84rem;
    flex: 1;
    height: 56px !important;
    font-size: 1.15rem;
    color: #171922;
    width: 100%;
    background: #fff;
    border-radius: 8px 0 0 8px;
    border-right: none;
    font-weight: bold;

    &: [disabled] {
      opacity: 0.5;
    }
  }

  @media (min-width: 1200px) and (max-width: 1600px) {
    padding: 0;

    input {
      width: 100%;
    }

    .select {
      width: 100px;
    }
  }

  ${media.lessThan("tablet")} {
    width: 100%;
    display: flex;
    align-items: center;

    .align-items-center {
      margin: auto;
      justify-content: center;
      width: auto;
    }

    input {
      max-width: 100%;
      width: 100%;
    }
  }

  ${media.lessThan("small")} {
    .select {
      flex-shrink: 0;
    }
  }

  @media (min-width: 1680px) and (max-width: 1920px) {
    input {
      height: 54px;
    }
  }
`;

const AMOUNTS = [10, 20, 50, 100];

const Wrap = styled.div``;

const CardTopup = ({ onClick, className, onBackClick }: any) => {
  const { t } = useTranslation();
  const accounts = useSelector(accountsSelector);
  const [amount, setAmount] = useState<any>("");
  const [selectedAccount, setSelectedAccount] = useState<Account | null>(
    accounts && accounts[0]
  );
  const balancesFromRedux = useSelector(balancesSelector);
  const [balances, setBalances] = useState<BalanceProps[]>(balancesFromRedux);
  const debitBalance = formatBalance(
    balances.find(
      (item: BalanceProps) =>
        item.CurrencyName === selectedAccount?.CurrencyName
    )?.Balance
  );
  const rates = useSelector(ratesSelector);
  const [rate, setRate] = useState<any>(0);
  const [cardMode, setCardMode] = useState(false);
  const [sendImage, setSendImage] = useState<any>(null);
  const dispatch = useDispatch();
  const [isInterruptionOpen, setIsInterruptionOpen] = useState(false);
  const [saveCard, setSaveCard] = useState(false);
  const [savedCards, setSavedCards] = useState([]);

  const [chosenCard, setChosenCard] = useState<any>(null);
  const [isDeleteInterruptionOpen, setIsDeleteInterruptionOpen] =
    useState(false);
  const { addToast } = useToasts();
  const [cardToDelete, setCardToDelete] = useState("");
  const userId = getUserId();

  useEffect(() => {
    if (userId && !balancesFromRedux.length) {
      getAllBalances(userId).then((res) => setBalances(res.data.Content));
    }
  }, [userId, balancesFromRedux]);

  const handleCardDelete = () => {
    deleteTopupCard(cardToDelete)
      .then((res) => {
        addToast(<div>{t("topup.carddeletesuccess")}</div>, {
          appearance: "success",
          autoDismiss: true,
        });
        setIsDeleteInterruptionOpen(false);
        getTopupCards().then((res) => setSavedCards(res.data.Content));
      })
      .catch((err) => {
        setIsDeleteInterruptionOpen(false);
        addToast(<div>{err?.response?.data?.StatusMessage}</div>, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  useEffect(() => {
    if (selectedAccount?.CurrencyName) {
      getImage(selectedAccount?.CurrencyName).then((res) =>
        setSendImage(res.data)
      );
    }
  }, [selectedAccount?.CurrencyName]);

  useEffect(() => {
    if (selectedAccount?.CurrencyName) {
      let rate = getRate("GEL", selectedAccount?.CurrencyName, rates);
      setRate(rate);
    }
  }, [rates, selectedAccount?.CurrencyName]);

  const handleTopup = () => {
    dispatch(setLoading(true));

    TopupWithCard(
      amount,
      "web", // @ts-ignore
      selectedAccount?.Id,
      saveCard,
      saveCard ? null : chosenCard
    )
      .then((res) => {
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
        if (err.response.status === 302) {
          return (window.location.href =
            err.response.data?.Content?.RedirectUrl);
        } else {
          if (err.response.data.errors) {
            return Object.values(err.response.data.errors).map((item: any) => {
              addToast(<div>{item}</div>, {
                appearance: "error",
                autoDismiss: true,
              });
            });
          } else {
            addToast(<div>{err.response.data.StatusMessage}</div>, {
              appearance: "error",
              autoDismiss: true,
            });
          }
        }
      });
  };

  useEffect(() => {
    if (cardMode) {
      getTopupCards().then((res) => {
        setSavedCards(res.data.Content);
        if (res.data.Content[0]) {
          setChosenCard(res.data.Content[0]?.CardId);
        }
      });
    }
  }, [cardMode]);

  if (cardMode) {
    return (
      <Wrap>
        <TabletOrDesktop>
          <Title
            mb="1rem"
            size="1.5rem"
            color="#171922"
            weight={700}
            className="title !ml-0 !mt-[30px] w-[calc(100%-111px)]"
          >
            {t("topup.addfunds")}
          </Title>
        </TabletOrDesktop>
        <Box>
          <FlexWrapper gap="0.5rem">
            <ArrowBackOutlinedIcon
              className="pointer"
              onClick={() => {
                onClick = { onBackClick };
                setCardMode(false);
              }}
            />
            <Title mb="0" weight="600" size="24px">
              {t("topup.paymenttitle")}
            </Title>
          </FlexWrapper>
          <Text size="16px" mb="20px">
            {t("topup.paymenttext")}
          </Text>
          <Divider width="100%" mb="32px" mt="0.5rem" />
          <div className="w-[360px] max-w-full m-auto">
            <div className=" border-[#EBEBEB] border border-solid p-4 py-2 rounded bg-[#FBFBFB]">
              <Text className="uppercase" ta="center" size="10px" mb="4px">
                {t("topup.amount")}
              </Text>
              <Title
                ta="center"
                size="24px"
                mb="16px"
                color="#171922"
                weight="700"
                className="leading-normal"
              >
                {formatBalance(amount)} {selectedAccount?.CurrencyName}
              </Title>
              <Title
                size="14px"
                color="#171922"
                weight="500"
                className="uppercase "
                mb="4px"
              >
                {t("topup.mycards")}
              </Title>
              <Divider width="100%" />
              {!savedCards?.length ? (
                <Text size="14px" mt="8px" mb="1rem" color="#171922">
                  {t("topup.notadded")}
                </Text>
              ) : (
                savedCards.map((item: any) => (
                  <div className="p-4 flex justify-between mb-2">
                    <div
                      className="flex gap-4 items-center"
                      onClick={() => setChosenCard(item.CardId)}
                    >
                      <div onClick={() => setChosenCard(item.CardId)}>
                        <Radio
                          checked={
                            item.CardId === chosenCard ||
                            savedCards.length === 1
                          }
                        />
                      </div>
                      <div>
                        <Img src="/topup/savedcard.svg" alt="card" />
                      </div>
                      <Text>{item.Mask}</Text>
                    </div>
                    <div
                      className="p-2 pointer"
                      onClick={() => {
                        setCardToDelete(item.CardId);
                        setIsDeleteInterruptionOpen(true);
                      }}
                    >
                      <Img src="/topup/delete.svg" alt="remove" />
                    </div>
                  </div>
                ))
              )}
              <FlexWrapper justify="between" className="flex-1" mb="0">
                <FlexWrapper mb="0" gap="0.5rem" className="flex-1">
                  <div
                    className="w-8 h-8 flex items-center justify-center bg-[var(--red)] rounded-full pointer"
                    onClick={() => {
                      setIsInterruptionOpen(true);
                      setSaveCard(true);
                    }}
                  >
                    <AddOutlinedIcon style={{ fill: "#fffc" }} />
                  </div>
                  <Text size="14px" color="#171922">
                    {t("topup.addnew")}
                  </Text>
                </FlexWrapper>

                <Tooltip
                  placement="top"
                  overlayInnerStyle={{
                    maxWidth: 300,
                    padding: 12,
                  }}
                  overlay={
                    <div>
                      <Text color="#fff">{t("topup.addnew")}</Text>
                      <Text color="#D0D5DD">{t("topup.addnewtext")}</Text>
                    </div>
                  }
                >
                  <HelpOutlineOutlinedIcon
                    style={{ width: 16, cursor: "pointer", fill: "#98A2B3" }}
                  />
                </Tooltip>
              </FlexWrapper>
            </div>
            <div></div>
            <button
              className="btn btn-red mt-4 w-full uppercase"
              onClick={() => handleTopup()}
            >
              {t("common.continue")}
            </button>
          </div>
        </Box>
        <InterruptionModal
          actionText={t("topup.saveandcontinue")}
          closeText={t("topup.nothanks")}
          title={t("topup.savecard")}
          text={t("topup.savecardtext")}
          isOpen={isInterruptionOpen}
          onStayClick={handleTopup}
          onCloseClick={() => {
            setIsInterruptionOpen(false);
            setSaveCard(false);
          }}
        />

        <InterruptionModal
          actionText={t("topup.deletecard")}
          closeText={t("topup.nothanks")}
          title={t("manage.templates_delete")}
          text={t("topup.deletetext")}
          isOpen={isDeleteInterruptionOpen}
          onStayClick={handleCardDelete}
          onCloseClick={() => {
            setIsDeleteInterruptionOpen(false);
          }}
        />
      </Wrap>
    );
  }

  return (
    <Wrap className="[@media(max-width:553px)]:pt-6 ">
      <TabletOrDesktop>
        <Title
          mb="1rem"
          size="1.5rem"
          color="#171922"
          weight={700}
          className="title !ml-0 !mt-[30px] w-[calc(100%-111px)]"
        >
          {t("topup.addfunds")}
        </Title>
      </TabletOrDesktop>
      <Box>
        <TabletOrDesktop>
          <FlexWrapper gap="0.5rem" mb="0">
            <FlexWrapper gap="0.5rem" onClick={onBackClick} className="pointer">
              <ArrowBackOutlinedIcon />
            </FlexWrapper>
            <FlexWrapper gap="0.5rem">
              <Title mb="0" size="1.5rem" weight="700" color="#000">
                {t("topup.cardtitle")}
              </Title>
            </FlexWrapper>
          </FlexWrapper>
        </TabletOrDesktop>
        <Mobile>
          <FlexWrapper gap="0.5rem" onClick={onBackClick} className="pointer">
            <ArrowBackOutlinedIcon />
          </FlexWrapper>
          <FlexWrapper gap="0.5rem">
            <Title mb="0" size="1.5rem" weight="700" color="#000">
              {t("topup.cardtitle")}
            </Title>
          </FlexWrapper>
        </Mobile>
        <Text color="#475467" size="1rem">{t("topup.cardtext")}</Text>
        <Divider width="100%" mb="1.5rem" mt="1rem" />

        <BlockWrapper>
          <div className="w-full">
            <Text color="#171922" size="14px" mb="0.25rem" ml="1.25rem">
              {t("topup.indicate")}
            </Text>
          </div>

          <div className="d-flex align-tems-center w-[320px] max-w-full">
            <span>
              <input
                autoFocus
                onKeyPress={preventNonNumeric}
                inputMode="numeric"
                placeholder="0.00"
                value={amount}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setAmount(replaceComma(e.target.value));
                }}
              />
            </span>
            <Select
              style={{ gap: "2px" }}
              withCountryFlag
              selectImage={sendImage}
              options={accounts?.length ? accounts : []}
              placeholder="GEL"
              initialValue={selectedAccount?.CurrencyName || "GEL"}
              height={184}
              className="select !h-[56px]"
              onChange={(val: string) => {
                setSelectedAccount(
                  accounts.find((item: Account) => item.CurrencyName === val)
                );
                let rate = getRate("GEL", val, rates);

                setRate(rate);
              }}
              bodyClass="body"
              idKey="CurrencyName"
              nameKey="CurrencyName"
            />
          </div>
          <Text mb="0rem" mt="8px" ml="-3rem" size="14px">
            {t("exchange.balancedetails", {
              amount: debitBalance,
              currency: selectedAccount?.CurrencyName,
            })}
          </Text>

          <FlexWrapper
            mb="0"
            gap="8px"
            mt="0.5rem"
            className="text-center w-[320px] max-w-full"
          >
            {AMOUNTS.map((item: number) => (
              <AmountItem
                key={item}
                onClick={() => setAmount(item)}
                isActive={amount == item}
              >
                {formatBalance(item)}
              </AmountItem>
            ))}
          </FlexWrapper>
          <Divider width="320px" mt="1rem" />
          {selectedAccount?.CurrencyName !== "GEL" && (
            <>
              <div className="w-full ml-8">
                {" "}
                <Text mt="0.5rem" mb="0rem" size="14px">
                  {t("topup.rate")}: {rate}
                </Text>
              </div>

              <div className="bg-[#EEF1F7] p-2 border-l-[6px] mt-4 border-solid border-[#2C4160] rounded flex gap-4">
                <div>
                  <div className="bg-[#C0CEE2] rounded-full  inline-flex w-8 h-8 justify-center items-center">
                    <ErrorIcon style={{ fill: "#2C4160", width: 16 }} />
                  </div>
                </div>

                <Text color="#2C4160" size="14px" lh="16px">
                  {t("topup.walletdesc")}
                </Text>
              </div>
            </>
          )}
          <button
            disabled={!amount}
            className="btn btn-red mt-[32px] w-full uppercase"
            onClick={() => setCardMode(true)}
          >
            {t("common.continue")}
          </button>
        </BlockWrapper>
      </Box>
    </Wrap>
  );
};

export default CardTopup;
