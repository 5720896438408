import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";

import {
  BackButton,
  ButtonsWrapper,
  ContentWrapper,
  PlainButton,
  SaveasTempalateWrapper,
  TopSection,
} from "./ResultModal.styled";
import BaseModal from "../../../../components/BaseModal";
import Img from "../../../../components/Img";
import Text from "../../../../components/Text/text";
import Title from "../../../../components/Text/title";
import {
  sendTransferDataSelector,
} from "../../../../redux/selectors";
import { useEffect, useState } from "react";
import { GetCountryDescription, getImage, handlePdfDownloadService } from "../../../../services/Services";
import FlexWrapper from "../../../../components/FlexWrapper";
import { useToast } from "../../../../hooks/useToast";
import { saveFile } from "../../../../utils/utils";
import { getUserId } from "../../../../utils/storage";


interface ResultModalProps {
  isOpen: boolean;
  onClose: () => void;
  imgSrc?: string;
  mode?: string;
  addTemplate: () => void;
  isTemplateSuccess?: boolean;
  transferCode: number;
  transferId: number;
}

const ResultModal = ({
  isOpen,
  onClose,
  mode,
  addTemplate,
  isTemplateSuccess,
  transferCode,
  transferId
}: ResultModalProps) => {
  const { t } = useTranslation();
  const sendTransferData = useSelector(sendTransferDataSelector);
  const [countrySvg, setCountrySvg] = useState(null);
  const isSuccess = mode === "success";
  const [description, setDesrcription] = useState("");
  const userId = getUserId();
  const isFromActiveTemplate = sendTransferData?.isFromActiveTemplate;
  const toast = useToast();

  useEffect(() => {
    if (sendTransferData?.CountryCode || sendTransferData?.Alpha2 || sendTransferData?.alpha2) {
      getImage(sendTransferData.CountryCode || sendTransferData?.Alpha2 || sendTransferData?.alpha2).then(
        (res) => setCountrySvg(res.data)
      );
    }
  }, [sendTransferData]);

  const handleCopy = () => {
    navigator.clipboard.writeText(transferCode.toString());
    toast("Transfer Code Copied", "success")
  };



  const handlePdfDownload = () => {
    handlePdfDownloadService(userId, transferId)
      .then((res) => {
        saveFile(res, `transfer-document-${transferId}`);
      })
      .catch((err) => toast(err.response?.data.StatusMessage, "error"));
  };
 
  useEffect(() => {
    if (isSuccess) {
      GetCountryDescription(sendTransferData?.countryId).then(res => {
        setDesrcription(res.data.Content);
      });
    }

  }, [isSuccess]);

  return (
    <BaseModal isOpen={isOpen} onRequestClose={() => onClose()}>
      <ContentWrapper>
        <TopSection>
          <Img
            src={isSuccess ? "/SEPA/success.gif" : "/SEPA/fail.png"}
            alt="result"
          />
          <Title size="2rem" mt="1rem" weight="700" ta="center" lh="2.3rem">
            {isSuccess
              ? t("cashpickup.success.title")
              : t("cashpickup.fail.title")}
          </Title>
        </TopSection>
        {isSuccess && (
          <SaveasTempalateWrapper>
            <div
              style={{ marginRight: 8 }}
              // @ts-ignore
              dangerouslySetInnerHTML={{ __html: countrySvg }}
            />
            <Text color="#171922" ta="center" size="16px" mb="0.2rem">
              {sendTransferData?.firstName} {sendTransferData?.lastName}
            </Text>
            <Text color="#171922"  mb="0.25rem">
              {sendTransferData?.creditAmount}{" "}
              {sendTransferData?.creditCurrency ||
                sendTransferData?.recipientCurrency}
            </Text>
            <FlexWrapper mb="0">
              <Text mr="0.45rem">{t("cashpickup.code")}: </Text>
              <Text
                mr="0.25rem"
                display="inline"
                weight="bold"
                color="var(--text-color)"
                size="1rem"
              >
                {transferCode}
              </Text>{" "}
              <span
                className="pointer"
                onClick={() => {
                  handleCopy();
                }}
              >
                <ContentCopyOutlinedIcon style={{ height: 18 }} />
              </span>{" "}
            </FlexWrapper>

            {!isTemplateSuccess && !isFromActiveTemplate && (
              <button onClick={addTemplate} className="text-xs pt-2">
                <AddIcon /> {t("banktransfer.result.saveastemplate")}
              </button>
            )}
          </SaveasTempalateWrapper>
        )}
        <Text color="#171922" ta="center" mb="1.5rem" ml="1rem" mr="1rem">
          {isSuccess
            ? description
            : t("cashpickup.fail.text")}
        </Text>
        <ButtonsWrapper>
          <BackButton className="btn btn-red" onClick={() => onClose()}>
            <Img src="/sidebar/home.svg" alt="home" /> {t("common.backtohome")}
          </BackButton>
          <PlainButton className="btn" onClick={handlePdfDownload}>
            {t("common.downloaddocument")}
          </PlainButton>
        </ButtonsWrapper>
      </ContentWrapper>
    </BaseModal>
  );
};

export default ResultModal;
