import { IconProps } from "./types";

const AtSign = ({ fill, width, height, ...rest }: IconProps) => {
  return (
    <svg
      width={width || "33"}
      height={height || "33"}
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1135_14912)">
        <path
          d="M17.3215 2.78458C9.11993 2.20548 2.34586 8.97955 2.92496 17.1812C3.40978 24.3054 9.63169 29.6788 16.7694 29.6788H21.7388C22.4795 29.6788 23.0856 29.0728 23.0856 28.3321C23.0856 27.5914 22.4795 26.9854 21.7388 26.9854H16.7963C11.773 26.9854 7.16717 23.7263 5.91471 18.8646C3.90807 11.0535 11.1804 3.76769 18.9915 5.78779C23.8667 7.02679 27.1258 11.6326 27.1258 16.6559V18.1373C27.1258 19.2013 26.1696 20.2517 25.1057 20.2517C24.0417 20.2517 23.0856 19.2013 23.0856 18.1373V16.4539C23.0856 13.0736 20.6884 10.03 17.3485 9.55865C12.7696 8.89875 8.90445 12.8581 9.72596 17.464C10.1839 20.0362 12.1905 22.1641 14.7358 22.7701C17.2138 23.3492 19.5706 22.5546 21.1193 20.9789C22.3179 22.622 24.7151 23.4839 26.9103 22.6085C28.7149 21.8947 29.8192 20.0497 29.8192 18.1104V16.6425C29.8192 9.49131 24.4458 3.2694 17.3215 2.78458V2.78458ZM16.3519 20.2517C14.1163 20.2517 12.3117 18.4471 12.3117 16.2115C12.3117 13.9759 14.1163 12.1713 16.3519 12.1713C18.5875 12.1713 20.3921 13.9759 20.3921 16.2115C20.3921 18.4471 18.5875 20.2517 16.3519 20.2517Z"
          fill={fill || "#757582"}
        />
      </g>
      <defs>
        <clipPath id="clip0_1135_14912">
          <rect
            width="32.3216"
            height="32.3216"
            fill="white"
            transform="translate(0.190918 0.0507812)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AtSign;
