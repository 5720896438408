import styled from "styled-components";
import { media } from "../../utils/theme";

const Wrapper = styled.div<{
  size?: string;
  color: string;
  mt: string;
  mb?: string;
  ml?: string;
  weight?: string;
  ta?: string;
  lh?: string;
  ls?: string;
  mobileSize?: string;
  mobileLh?: string;
  mr?: string;
}>`
  font-size: ${(p) => (p.size ? p.size : "1rem")};
  color: ${(p) => (p.color ? p.color : "var(--text-color)")};
  margin-top: ${(p) => (p.mt ? p.mt : "0px")};
  margin-bottom: ${(p) => (p.mb ? p.mb : "0.5rem")};
  margin-left: ${(p) => (p.ml ? p.ml : "0")};
  margin-right: ${(p) => (p.mr ? p.mr : "0")};
  font-weight: ${(p) => (p.weight ? p.weight : "400")};
  text-align: ${(p) => (p.ta ? p.ta : "left")};
  line-height: ${(p) => (p.lh ? p.lh : "normal")};
  letter-spacing: ${(p) => (p.ls ? p.ls : "0.5px")};

  ${media.lessThan("small")} {
    ${({ mobileSize }) => mobileSize && `font-size: ${mobileSize} !important;`}
    ${({ mobileLh }) => mobileLh && `line-height: ${mobileLh} !important;`}
  }
`;

const Title = ({
  size,
  color,
  weight,
  mt,
  mb,
  ml,
  mr,
  onClick,
  children,
  className,
  ta,
  lh,
  ls,
  mobileSize,
  mobileLh,
  ...rest
}: any) => {
  return (

    <Wrapper
      size={size}
      color={color}
      className={className}
      weight={weight}
      mt={mt}
      mb={mb}
      ml={ml}
      ta={ta}
      lh={lh}
      mr={mr}
      ls={ls}
      mobileSize={mobileSize}
      mobilelh={mobileLh}
      {...rest}
      onClick={onClick}
    >
      {children}
    </Wrapper>
  );
};

export default Title;
