import styled from "styled-components";

import { media } from "../utils/theme";

export const CurrentLocale = styled.div<{
  isAuthorized?: boolean;
  isLoginPage?: boolean;
  isContentFooter?: boolean;
}>`
  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
  }

  ${(p) =>
    !p.isAuthorized &&
    `
          padding: 0.75rem 1.5rem;
          border-radius: 4px;
          transition: all 0.25s;
          background: white;
  
  
          ${media.between("small", "tablet")}{
            background: transparent;
        }


  
          ${media.lessThan("small")}{
              border-top: none;
          }
      `}

  ${(p) =>
    p.isLoginPage &&
    `
       
        padding: 0.75rem 1.5rem 0.75rem 1.5rem;
     
        p {
          color: #CDCDCD !important;
        } 

        @media (max-width: 1024px) {
          padding: 0.75rem 1.5rem;
        }

        ${media.lessThan("small")}{
          padding: 0.75rem 0;
        }
      `}
  ${(p) =>
    p.isContentFooter &&
    `
        background: #F9FAFB;
        width: auto;
        display: inline-block;
        padding: 0.75rem 1rem;
        border-radius: 6px;

        &:hover {
          background: #F0F0F0;
        }

        p {
          color: #182230 !important;
        } 
 
      `}
`;

export const LangModalContent = styled.div`
  width: 500px;
  border-radius: 16px;
  background: white;
  border-radius: 10px;
  background: white;

  ${media.lessThan("small")} {
    width: 100%;
  }
`;

export const LangItem = styled.div`
  margin-top: 1rem;
  cursor: pointer;
  box-sizing: border-box;
  border-radius: 6px;
  margin-right: 0.5rem;
  padding: 0.5rem;
  border: 1px solid transparent;

  svg {
    width: 24px;
    height: 24px;
  }

  .name {
    margin-bottom: 0.25rem;
    display: block;
    font-size: 0.875rem;
  }

  .subtitle {
    color: var(--text-color-2);
    font-size: 0.7rem;
    letter-spacing: 0.5px;
  }

  &.active,
  &:hover {
    border: 1px solid #66616b;

    .name {
      color: var(--light-red);
    }
  }
`;
 

export const LangsWrapper = styled.div`
  background: #fbfbfb;
  padding: 1.5rem 1.5rem 0.5rem;

  .main-image {
    svg {
      width: 48px;
      height: 48px;
    }
  }
`;
