import { IconProps } from "./types";

const Close = ({ fill, width, height, ...rest }: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || "13"}
      height={height || "12.999"}
      viewBox="0 0 13 12.999"
      {...rest}
    >
      <defs></defs>
      <g transform="translate(0 -0.016)">
        <g transform="translate(0 0.016)">
          <path
            fill={fill || "#757582"}
            d="M7.932,6.516l4.861-4.861a.712.712,0,0,0,0-1.005L12.366.224a.713.713,0,0,0-1.006,0L6.5,5.084,1.639.224a.712.712,0,0,0-1.005,0L.208.649a.712.712,0,0,0,0,1.005L5.069,6.516.208,11.376a.713.713,0,0,0,0,1.006l.426.426a.712.712,0,0,0,1.005,0L6.5,7.947l4.861,4.861a.705.705,0,0,0,.5.208h0a.705.705,0,0,0,.5-.208l.426-.426a.713.713,0,0,0,0-1.006Z"
            transform="translate(0 -0.016)"
          />
        </g>
      </g>
    </svg>
  );
};

export default Close;
