import { useEffect, useState } from "react";
import styled from "styled-components";
import { Navigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Drawer from "@mui/material/Drawer";
import { useIdleTimer } from "react-idle-timer";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";

import { getStorageObject, getToken, getUserId } from "../utils/storage";
import Sidebar from "../partials/Sidebar";
import Rates from "../containers/home/Rates/Rates";
import Header from "../partials/Header";
import { media } from "../utils/theme";
import {
  Desktop,
  Mobile,
  Tablet,
  TabletOrDesktop,
} from "../components/responsive/responsive";
import Feedback from "../containers/Feedback";
import {
  drawerStatusSelector,
  globalLoadingSelector,
  userSelector,
} from "../redux/selectors";
import Img from "../components/Img";
import Templates from "../containers/home/Templates";
import Title from "../components/Text/title";
import TopSection from "../containers/home/TopSection";
import FlexWrapper from "../components/FlexWrapper";
import BottomMenu from "../partials/BottomMenu/Index";
import { GetRates, GetUserAccounts, LogoutService } from "../services/Services";
import { setAccounts, setRates } from "../redux/authSlice";
import Notifications from "../containers/Notifications";
import { setNotificationDrawerOpen } from "../redux/commonSlice";
import SessionModal from "../components/SessionModal";
import RetryPromisesProvider from "../RetryPromisesProviderts";
import { LoadingOverlay } from ".";

export const MainContent = styled.div<{
  display?: string;
  isRightHidden?: boolean;
  hidePh?: boolean;
}>`
  display: ${({ display }) => (display ? display : "flex")};
  flex: 1;
  width: ${({ hidePh }) => (hidePh ? "" : "Calc(100vw - 21px)")};

  .content {
    width: 100%;
    background: #fbfbfb;
    padding: 1.5rem;

    padding-top: ${({ isRightHidden }) => (isRightHidden ? "0" : "1.5rem")};
  }

  ${media.lessThan("tablet")} {
    background: #fbfbfb;
    .content {
      padding-top: 0 !important;
      width: calc(100vw - 100px);
      padding-right: 0;
    }
  }

  ${media.lessThan("small")} {
    width: 100%;

    .content {
      padding: 0 16px 0 16px;
      width: 100vw;
    }

    .hello {
      padding: 1rem;
      margin-bottom: -2rem;
    }
  }
`;

const RightWrapper = styled.div`
  padding-right: 24px;
  padding-bottom: 3rem;
  flex-shrink: 0;
  width: 335px;
  background: #fbfbfb;

  circle {
    stroke: var(--red);
  }

  ${media.lessThan("xxlarge")} {
    width: auto;
  }

  ${media.size("large")} {
    width: auto;
  }

  ${media.lessThan("tablet")} {
    padding-left: 24px;
    padding-right: 0;
    width: Calc(100vw - 100px);
  }

  ${media.lessThan("small")} {
    display: none;
  }

  @media (max-width: 1200px) and (min-width: 1024px) {
    max-width: 280px;
  }
`;

const TabletWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;

  ${media.lessThan("tablet")} {
    flex-direction: column;
  }
`;

const MainLayoutWrapper = ({ hideRightSide, children }: any) => {
  const user = useSelector(userSelector);
  const userId = getUserId();
  const isLoading = useSelector(globalLoadingSelector);
  const { t } = useTranslation();
  const location = useLocation();
  const isHome = location.pathname === "/home";
  const dispatch = useDispatch();
  const open = useSelector(drawerStatusSelector);
  const [state, setState] = useState<string>("Active");

  const onIdle = () => {
    setState("Idle");
  };

  const onActive = () => {
    if (state !== "Idle") {
      setState("Active");
    }
  };

  const { reset } = useIdleTimer({
    onIdle,
    onActive,
    timeout: 120000,
  });

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden) {
        reset();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [reset]);

  useEffect(() => {
    GetRates().then((res) => {
      dispatch(setRates(res.data.Content));
    });
    GetUserAccounts(userId).then((res) => {
      dispatch(setAccounts(res.data.Content));
    });
  }, [dispatch, userId]);

  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.setItem("dateOfClose", JSON.stringify(new Date()));
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    const timestamp1 = moment(getStorageObject("dateOfClose"));
    const timestamp2 = moment(new Date());

    const differenceInMinutes = timestamp2.diff(timestamp1, "minutes");

    if (differenceInMinutes >= 3) {
      LogoutService().then(() => {});
      localStorage.removeItem("token");
      localStorage.removeItem("dateOfClose");
      localStorage.removeItem("user");
      window.location.href = "/login";
    } else {
      localStorage.removeItem("dateOfClose");
    }

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <>
      <SessionModal
        isOpen={state === "Idle"}
        onClose={() => setState("Active")}
        onContinue={() => {}}
      />
      <Header />
      <MainContent isRightHidden={hideRightSide}>
        <Sidebar />
        <TabletWrapper>
          <div className="content">
            <FlexWrapper mb="0" className="hello">
              {isHome && (
                <TabletOrDesktop>
                  <Title weight="700" size="1.5rem" color="#000">
                    {t("common.hello")}, {user.fullName} 👋
                  </Title>
                  <Tablet>
                    <TopSection />
                  </Tablet>
                </TabletOrDesktop>
              )}
              {/* <TabletOrDesktop >
              <div className="absolute left-0 top-[-2] overflow-hidden h-20 z-1">
                <Img  src="/home/dot-grid.svg"  alt="dot grid"/>
              </div>
              </TabletOrDesktop> */}
            </FlexWrapper>
            {hideRightSide && (
              <FlexWrapper justify="right">
                <TabletOrDesktop>
                  <div className="absolute top-0">
                    <TopSection />
                  </div>
                </TabletOrDesktop>
              </FlexWrapper>
            )}
            <RetryPromisesProvider>{children}</RetryPromisesProvider>
          </div>

          {!hideRightSide && (
            <RightWrapper>
              <Desktop>
                <TopSection />
                {/* <Application /> */}
                <Templates />
                <Rates />
              </Desktop>
            </RightWrapper>
          )}
        </TabletWrapper>
      </MainContent>
      <Mobile>
        <BottomMenu />
      </Mobile>
      <Feedback />
      {isLoading && (
        <LoadingOverlay>
          <Img src="/transfers/inex-logo.png" alt="logo" />
        </LoadingOverlay>
      )}
      <Drawer
        anchor="right"
        open={open}
        onClose={() => dispatch(setNotificationDrawerOpen(false))}
      >
        <Notifications
          onClose={() => dispatch(setNotificationDrawerOpen(false))}
        />
      </Drawer>
    </>
  );
};

const MainLayout = ({ component: Component, hideRightSide }: any) => {
  const searchParams = new URLSearchParams(window.location.search);

  return getToken() ? (
    <MainLayoutWrapper hideRightSide={hideRightSide}>
      <Component />
    </MainLayoutWrapper>
  ) : (
    <Navigate to={`/login?${searchParams}`} />
  );
};

export default MainLayout;
