import { useTranslation } from "react-i18next";

import {
  BackButton,
  ButtonsWrapper,
  ContentWrapper,
  PlainButton,
  TopSection,
} from "./ResultModal.styled";
import BaseModal from "../../../components/BaseModal";
import Img from "../../../components/Img";
import Text from "../../../components/Text/text";
import Title from "../../../components/Text/title";
import { useNavigate } from "react-router-dom";

interface ResultModalProps {
  isOpen: boolean;
  onClose: () => void;
  imgSrc?: string;
  mode?: string;
}

const ResultModal = ({ isOpen, onClose, mode }: ResultModalProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const isPending = mode === "pending";
  const isSuccess = mode === "success";

  if (isSuccess) {
    return (
      <BaseModal isOpen={isOpen} onRequestClose={() => onClose()}>
        <ContentWrapper>
          <TopSection>
            <Img
              style={{ maxWidth: 200, margin: "auto" }}
              src={"/SEPA/success.gif"}
              alt="result"
            />
            <Title size="2rem" mt="1rem" weight="700" ta="center" lh="2.3rem">
              {t("cashpickup.cancel.success.titlefinished")}
            </Title>
          </TopSection>

          <Text ta="center" mb="1.5rem" color="#171922">
            {t("cashpickup.cancel.fail.textfinished")}
          </Text>
          <ButtonsWrapper>
            {!isSuccess && (
              <BackButton className="btn btn-red" onClick={() => onClose()}>
                {t("common.backtohome")}
              </BackButton>
            )}
            {isSuccess && (
              <>
                <BackButton className="btn btn-red uppercase h-[38px]" onClick={() => navigate("/cashpickup")}>
                  {t("canceltransfer.makenewtransfer")}
                </BackButton>
                <PlainButton className="btn h-[38px]" onClick={() => onClose()}>
                {t("common.backtohome")}
                </PlainButton>
              </>
            )}
          </ButtonsWrapper>
        </ContentWrapper>
      </BaseModal>
    );
  }

  return (
    <BaseModal isOpen={isOpen} onRequestClose={() => onClose()}>
      <ContentWrapper>
        <TopSection>
          <Img
            style={{ maxWidth: 150, margin: "auto" }}
            src={
              isPending ? "/transfers/processing-card.gif" : "/SEPA/fail.png"
            }
            alt="result"
          />
          <Title
            size="2rem"
            mt="1rem"
            weight="700"
            ta="center"
            lh="2.3rem"
            mb="0"
          >
            {isPending
              ? t("cashpickup.cancel.success.title")
              : t("cashpickup.cancel.fail.title")}
          </Title>
        </TopSection>

        <Text ta="center" mb="1.5rem" color="#171922">
          {isPending
            ? t("cashpickup.cancel.success.text")
            : t("cashpickup.cancel.fail.text")}
        </Text>
        <ButtonsWrapper>
          <BackButton className="btn btn-red h-[38px]" onClick={() => onClose()}>
            {t("common.backtohome")}
          </BackButton>
          {!isSuccess && (
            <PlainButton
              onClick={() => navigate("/contact")}
              className="btn uppercase h-[38px]"
            >
              {t("contact.title")}
            </PlainButton>
          )}
        </ButtonsWrapper>
      </ContentWrapper>
    </BaseModal>
  );
};

export default ResultModal;
