import React, { useEffect, useState, isValidElement } from 'react';
import { useSelector } from 'react-redux';
import { tokenSelector } from './redux/selectors';

const RetryPromisesProvider = ({ children }: any) => {
  const [key, setKey] = useState(0);
  const token = useSelector(tokenSelector);

  useEffect(() => {
    setKey((prevKey) => prevKey + 1);
  }, [token]);

  const child = React.Children.only(children);

  if (!isValidElement(child)) {
    return null; // or return a fallback component
  }

  return React.cloneElement(child, { key });
};

export default RetryPromisesProvider;