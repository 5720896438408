import { IconProps } from "./types";

const PhoneIcon = ({ fill, width, height, ...rest }: IconProps) => {
    return (
        <svg width={width || "26"} height={height ||  "25"} viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M17.1355 12.2114H19.829C19.829 8.49444 16.8123 5.47776 13.0953 5.47776V8.17122C15.3309 8.17122 17.1355 9.97585 17.1355 12.2114ZM22.5225 12.2114H25.2159C25.2159 5.51816 19.7886 0.0908203 13.0953 0.0908203V2.78429C18.3072 2.78429 22.5225 6.99956 22.5225 12.2114ZM23.8692 16.925C22.1858 16.925 20.5697 16.6556 19.0614 16.1574C18.9267 16.117 18.7786 16.09 18.6439 16.09C18.2937 16.09 17.957 16.2247 17.6877 16.4806L14.7249 19.4434C10.9136 17.5041 7.7892 14.3931 5.84991 10.5684L8.81272 7.59213C9.18981 7.24198 9.29755 6.71675 9.1494 6.24539C8.65111 4.73705 8.38177 3.12097 8.38177 1.43755C8.38177 0.69685 7.77574 0.0908203 7.03503 0.0908203H2.32147C1.58076 0.0908203 0.974731 0.69685 0.974731 1.43755C0.974731 14.0834 11.2234 24.332 23.8692 24.332C24.6099 24.332 25.2159 23.726 25.2159 22.9853V18.2717C25.2159 17.531 24.6099 16.925 23.8692 16.925ZM3.7086 2.78429H5.7287C5.82297 3.96941 6.02498 5.14107 6.33473 6.25886L4.71865 7.88841C4.17996 6.25886 3.82981 4.56198 3.7086 2.78429V2.78429ZM22.5225 21.5982C20.7448 21.477 19.021 21.1268 17.4049 20.5746L19.021 18.9586C20.1657 19.2818 21.3373 19.4838 22.5225 19.5646V21.5982Z" fill={fill || "#757582"} />
        </svg>
    );
};

export default PhoneIcon;
