import { Provider } from "react-redux";
import { ToastProvider } from "react-toast-notifications";

import store from "./redux/store";
import Routes from "./routes";
import CustomToast from "./components/Toast";
import "./translations/i18n";

import GlobalStyles from "./global-styles";

function App() {
  return (
    <ToastProvider placement="top-center" components={{ Toast: CustomToast }}>
      <Provider store={store}>
          <GlobalStyles />
          <Routes />
      </Provider>
    </ToastProvider>
  );
}

export default App;
