import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import Scrollbars from "rc-scrollbars";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import AddCardOutlinedIcon from "@mui/icons-material/AddCardOutlined";
import PasswordOutlinedIcon from "@mui/icons-material/PasswordOutlined";
import ManageAccountsOutlinedIcon from "@mui/icons-material/ManageAccountsOutlined";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import cx from "classnames";
import BaseModal from "../../../../components/BaseModal";
import Text from "../../../../components/Text/text";
import Title from "../../../../components/Text/title";
import Divider from "../../../../components/Divider";
import Img from "../../../../components/Img";

import { getCurrencySymbol } from "../../../../utils/helpers";
import {
  Actions,
  AdditionalCard,
  CardAction,
  CardModalWrapper,
  CardNumber,
  ContentWrapper,
  Expiry,
  Limit,
  NextPayment,
  TopWrapper,
  Valid,
} from "./Cardmodal.styled";
import {
  GetBlockedAmounts,
  GetIECardsListing,
  getNextPayment,
} from "../../../../services/Services";
import { userIdSelector } from "../../../../redux/selectors";
import Transactions from "../../Transactions";
import FlexWrapper from "../../../../components/FlexWrapper";
import Schedule from "./Schedule";
import { IECard } from "../IntelexpressCard";
import {
  Desktop,
  TabletOrMobile,
} from "../../../../components/responsive/responsive";

const CardModal = ({
  activeItem,
  isOpen,
  onBlockCard,
  onResetPin,
  onClose,
  onManageLimit,
  additionalCards,
  onProductPress,
  onAdditionalClick,
  onBackClick,
  details,
}: any) => {
  const { t } = useTranslation();
  const { addToast } = useToasts();
  const userId = useSelector(userIdSelector);
  const [transactionList, setTransactionList] = useState([]);
  const [date1, setDate1] = useState(
    moment(new Date()).subtract("days", 15).toISOString()
  );
  const [date2, setDate2] = useState(
    moment(new Date()).add(4, "hours").toISOString()
  );
  const [schedule, setSchedule] = useState(false);
  const isBlocked = activeItem?.CardStatus === "ბლოკირებული";
  const scrollbarsRef = useRef(null);
  const [scrollMode, setScrollMode] = useState(false);
  const [loanData, setLoanData] = useState<any>(null);
  const [blockedAmounts, setBlockedAmounts] = useState([]);
  const [maxHeight, setMaxHeight] = useState("");

  useEffect(() => {
    if (isOpen) {
      GetIECardsListing(userId, activeItem?.AccountId, date1, date2)
        .then((res: any) => setTransactionList(res.data.Content))
        .catch((err) => {
          setTransactionList([]);
          addToast(<div>{err.response.data.StatusMessage}</div>, {
            appearance: "error",
            autoDismiss: true,
          });
        });

      GetBlockedAmounts(userId, activeItem?.CardId).then((res) =>
        setBlockedAmounts(res.data.Content)
      );
    }

    getNextPayment(userId)
      .then((res) => setLoanData(res.data.Content))
      .catch(() => {});

    if (!isOpen) {
      setSchedule(false);
      setScrollMode(false);
    }
  }, [isOpen, activeItem]);

  const getHeight = () => {
    if (!activeItem?.IsPrimary) {
      return 0;
    } else if (activeItem?.IsPrimary === true && additionalCards?.length < 4) {
      return additionalCards?.length * 68.1;
    } else {
      return 104;
    }
  };

  useEffect(() => {
    const updateMaxHeight = () => {
      const isDesktopScreen = window.innerWidth > 768;
      const isSmallScreen = window.innerWidth < 768;
      const listLength =
        (transactionList?.length || 0) + (blockedAmounts?.length || 0);

      if (isDesktopScreen) {
        if (listLength < 9 || transactionList === undefined) {
          setMaxHeight(632 - 108 - 52 - 50 + "px");
        } else if (listLength > 8) {
          setMaxHeight(scrollMode ? 632 - 100 - 43 + "px" : 632 - 168 + "px");
        }
      } else if (isSmallScreen) {
        if (listLength < 9 || transactionList === undefined) {
          setMaxHeight(`Calc(100vh - ${308 + 100}px)`);
        } else if (listLength > 8) {
          setMaxHeight(
            scrollMode ? `Calc(100vh - 188px)` : `Calc(100vh - 412px)`
          );
        }
      }
    };

    updateMaxHeight();
    window.addEventListener("resize", updateMaxHeight);

    return () => {
      window.removeEventListener("resize", updateMaxHeight);
    };
  }, [transactionList, blockedAmounts, scrollMode]);

  const handleScrollStop = () => {
    const scrollbars: any = scrollbarsRef.current;

    const { scrollTop, clientHeight } = scrollbars.view;
    const listLength =
      (transactionList?.length || 0) + (blockedAmounts?.length || 0);

    const threshold = window.innerWidth > 768 ? 8 : 12;

    if (listLength > threshold) {
      setScrollMode(scrollTop > 0);
    }
  };

  if (!activeItem) {
    return null;
  }

  if (schedule) {
    return (
      <BaseModal isOpen={isOpen} onRequestClose={() => onClose()}>
        <ContentWrapper>
          <Schedule onBack={() => setSchedule(false)} />
        </ContentWrapper>
      </BaseModal>
    );
  }

  return (
    <BaseModal isOpen={isOpen} onRequestClose={() => onClose()}>
      <ContentWrapper className="transition-height duration-300 overflow-hidden">
        <FlexWrapper mb="16px" className="h-[20px]">
          {!activeItem?.IsPrimary && (
            <ArrowBackOutlinedIcon
              onClick={() => onBackClick()}
              style={{
                marginRight: -10,
                marginLeft: 16,
                cursor: "pointer",
              }}
            />
          )}
          <Title ml="1rem" mb="0" className="h-[20px] !font-medium ">
            {t("cards.cardmanagement")}
          </Title>
        </FlexWrapper>

        <Divider />

        <CardModalWrapper
          className={`sticky top-0 z-10 overflow-hidden transition-height duration-500 ease-in-out ${
            scrollMode ? "h-[100px]" : "h-[312px] md:h-[168px]"
          }`}
        >
          {!scrollMode ? (
            <TopWrapper>
              <div className="animate-fadeIn relative h-[125px]">
                <Img width={210} src="/home/cards/card.svg" alt="card" />
                <CardNumber>{activeItem?.CardNumber}</CardNumber>
                <Valid>{activeItem?.CardHolderName}</Valid>
                <Expiry>{activeItem?.ExpiryDate}</Expiry>
              </div>
              <div className="flex-1 animate-fadeIn">
                <Limit className="relative">
                  <Text ta="center">
                    {t("intelexpresscard.total")}:{" "}
                    {getCurrencySymbol(activeItem.Currency)}
                    {activeItem.CreditLimit}
                  </Text>
                  <Title ta="center" color="#171922" weight="700" size="18px">
                    {getCurrencySymbol(activeItem.Currency)}
                    {activeItem.AvailableAmount}
                  </Title>
                  {activeItem?.IsPrimary ? (
                    <></>
                  ) : (
                    <div>
                      <Desktop>
                        <span className="standart absolute right-2 top-[6px] ">
                          {t("cards.additional")}
                        </span>
                      </Desktop>
                      <TabletOrMobile>
                        <span className="standart absolute right-2 top-[6px] ">
                          {t("cards.additional")}
                        </span>
                      </TabletOrMobile>
                    </div>
                  )}
                </Limit>
                <Actions>
                  <CardAction className="disabled">
                    <div className="d-flex justify-content-center">
                      <AddCardOutlinedIcon />
                    </div>
                    <Title size="0.6rem" ta="center">
                      {t("cards.topup")}
                    </Title>
                  </CardAction>

                  {activeItem?.IsPrimary ? (
                    <CardAction
                      onClick={onProductPress}
                      className={cx(!details?.LoanId ? "disabled" : "")}
                    >
                      <div
                        className="d-flex justify-content-center"
                        style={{ padding: 4 }}
                      >
                        <Img src="/home/cards/data_info_alert.svg" alt="lock" />
                      </div>

                      <Title size="0.6rem" ta="center">
                        {t("cards.product")}
                      </Title>
                    </CardAction>
                  ) : (
                    <CardAction
                      onClick={() => onManageLimit()}
                      className="disabled"
                    >
                      <div className="d-flex justify-content-center">
                        <ManageAccountsOutlinedIcon />
                      </div>
                      <Title size="0.6rem" ta="center" lh="14px">
                        {t("intelexpresscard.managelimit")}
                      </Title>
                    </CardAction>
                  )}
                  <CardAction onClick={onResetPin}>
                    <div className="d-flex justify-content-center">
                      <PasswordOutlinedIcon />
                    </div>
                    <Title size="0.6rem" ta="center" lh="14px">
                      {t("cards.renewpin")}
                    </Title>
                  </CardAction>
                  <CardAction onClick={onBlockCard} isBlocked={isBlocked}>
                    <div className="d-flex justify-content-center svg-wrap">
                      {isBlocked ? (
                        <LockOutlinedIcon />
                      ) : (
                        <Img src="/home/cards/lock_open_right.svg" alt="lock" />
                      )}
                    </div>

                    <Title
                      size="0.6rem"
                      ta="center"
                      color={isBlocked ? "var(--red)" : "var(--text-color-2)"}
                      lh="14px"
                    >
                      {isBlocked
                        ? t("intelexpresscard.unblockcard")
                        : t("intelexpresscard.blockcard")}
                    </Title>
                  </CardAction>
                </Actions>
              </div>
            </TopWrapper>
          ) : (
            <TopWrapper className="animate-fadeIn">
              {" "}
              <div className="relative flex justify-center align-center text-center flex-1">
                <Img
                  width={100}
                  src="/home/cards/card.svg"
                  alt="card"
                  className="m-auto"
                />
              </div>
            </TopWrapper>
          )}
        </CardModalWrapper>
        <Scrollbars
          ref={scrollbarsRef}
          style={{
            maxHeight: maxHeight,
          }}
          onScrollStop={handleScrollStop}
        >
          {activeItem?.IsPrimary && loanData?.Id && (
            <NextPayment onClick={() => setSchedule(true)}>
              <div>
                <Text color="#CDCDCD" mb="4px">
                  {t("cards.nextpayment")}:{" "}
                  {moment(loanData.ExpirationDate).format("DD.MM.YYYY")}
                </Text>
                <Text color="white" weight="700" size="16px">
                  ₾{loanData.DueSum}
                </Text>
              </div>
              <div className="flex justify-center items-center">
                <KeyboardArrowRightOutlinedIcon className="scale-150" />
              </div>
            </NextPayment>
          )}

          {activeItem?.IsPrimary && !!additionalCards?.length && (
            <Scrollbars
              style={{
                height: getHeight(),
              }}
            >
              {additionalCards.map((additionalCard: IECard) => (
                <AdditionalCard
                  onClick={() => {
                    onAdditionalClick(additionalCard);
                    setScrollMode(false);
                  }}
                >
                  <div>
                    <FlexWrapper mb="0" gap="8px">
                      <Img width={60} src="/home/cards/card.svg" alt="card" />
                      <div>
                        <div className="pb-1 font-['Roboto']">
                          <Title mb="0" size="14px">
                            {additionalCard?.CardHolderName}
                          </Title>
                        </div>
                        <div className="font-['Roboto']">
                          <Text>
                            {t("cards.available")}:{" "}
                            {additionalCard?.AvailableAmount}{" "}
                            {additionalCard?.Currency}
                          </Text>
                        </div>
                      </div>

                      <span className="standart">{t("cards.additional")}</span>
                    </FlexWrapper>
                  </div>
                  <div>
                    <KeyboardArrowRightOutlinedIcon className="scale-150" />
                  </div>
                </AdditionalCard>
              ))}
            </Scrollbars>
          )}

          <div>
            {blockedAmounts?.length && (
              <Title ml="1rem" mt="1rem" mb="1rem" size="14px">
                {t("intelexpresscard.blockedamount")}
              </Title>
            )}
            {blockedAmounts?.map((item) => (
              <div></div>
            ))}
            <Transactions
              IECardType={true}
              title=""
              data={blockedAmounts}
              blockedType={true}
            ></Transactions>

            <Title ml="1rem" mt="1rem" mb="1rem" size="14px">
              {t("home.lasttransactions")}
            </Title>

            <div>
              <Transactions
                IECardType={true}
                title=""
                dataLength={transactionList?.length}
                data={transactionList}
              ></Transactions>
            </div>
          </div>
        </Scrollbars>
      </ContentWrapper>
    </BaseModal>
  );
};

export default CardModal;
