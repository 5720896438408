import { saveAs } from "file-saver";
import type { Rate } from "../containers/home/Rates/Rates";

export const preventNonNumeric = (event: any) => {
  if (event.target.value === "") {
    if (event.which === 46 || event.which === 44) {
      event.preventDefault();
    }
  }

  if (event.target.value === "0") {
    if (event.which !== 46 && event.which !== 44) {
      event.preventDefault();
    }
  }

  if (event.target.value.indexOf(".") !== -1 && event.key === ",") {
    event.preventDefault();
  }

  if (event.target.value.indexOf(",") !== -1 && event.key === ".") {
    event.preventDefault();
  }

  if (/,/.test(event.target.value) && event.key === ",") {
    event.preventDefault();
  }

  if (event.target.value.indexOf(".") !== -1 && event.key === ".") {
    event.preventDefault();
  }

  if (!/^[0-9.,]+$/.test(event.key)) {
    event.preventDefault();
  }
};

// Custom debounce function for search
export const debounce = (debouncedFunction: any, delay: number) => {
  let timer: ReturnType<typeof setTimeout>;

  return function (...args: any) {
    clearTimeout(timer);

    timer = setTimeout(() => {
      debouncedFunction(...args);
    }, delay);
  };
};

// Exchange rate
export const getRate = (
  currency1: string,
  currency2: string,
  rates: Rate[]
) => {
  if (!rates.length) {
    return undefined;
  }
  let rate: number | undefined = 0;

  if (currency1 === "GBP" && currency2 === "GEL") {
    rate = rates.find(
      (item: Rate) =>
        item.CreditCurrency === currency1 && item.DebitCurrency === currency2
    )?.SellingRate;
  } else if (currency1 === "GBP" && currency2 === "USD") {
    rate = rates.find(
      (item: Rate) =>
        item.CreditCurrency === currency1 && item.DebitCurrency === currency2
    )?.SellingRate;
  } else if (currency1 === "GBP" && currency2 === "EUR") {
    rate = rates.find(
      (item: Rate) =>
        item.CreditCurrency === currency1 && item.DebitCurrency === currency2
    )?.SellingRate;
  } else if (currency1 === "EUR" && currency2 === "GBP") {
    rate = rates.find(
      (item: Rate) =>
        item.CreditCurrency === currency2 && item.DebitCurrency === currency1
    )?.BuyingRate;
  } else if (currency1 === "EUR" && currency2 === "GEL") {
    rate = rates.find(
      (item: Rate) =>
        item.DebitCurrency === currency2 && item.CreditCurrency === currency1
    )?.SellingRate;
  } else if (currency1 === "EUR" && currency2 === "USD") {
    rate = rates.find(
      (item: Rate) =>
        item.DebitCurrency === currency2 && item.CreditCurrency === currency1
    )?.BuyingRate;
  } else if (currency2 === "EUR" && currency1 === "USD") {
    rate = rates.find(
      (item: Rate) =>
        item.DebitCurrency === currency1 && item.CreditCurrency === currency2
    )?.SellingRate;
  } else if (currency1 === "USD" && currency2 === "GEL") {
    rate = rates.find(
      (item: Rate) =>
        item.DebitCurrency === currency2 && item.CreditCurrency === currency1
    )?.SellingRate;
  } else if (currency1 === "USD" && currency2 === "GBP") {
    rate = rates.find(
      (item: Rate) =>
        item.DebitCurrency === currency1 && item.CreditCurrency === currency2
    )?.BuyingRate;
  } else if (currency1 === "USD" && currency2 === "EUR") {
    rate = rates.find(
      (item: Rate) =>
        item.DebitCurrency === currency1 && item.CreditCurrency === currency2
    )?.BuyingRate;
  } else if (currency1 === "USD") {
    rate = rates.find(
      (item: Rate) =>
        item.CreditCurrency === currency1 && item.DebitCurrency === currency2
    )?.BuyingRate;
  } else if (currency1 === "GEL") {
    rate = rates.find(
      (item: Rate) =>
        item.DebitCurrency === currency1 && item.CreditCurrency === currency2
    )?.BuyingRate;
  }

  return rate;
};

// export const getBalance = (currency: string) => {
//   return balances.find((item: BalanceProps) => item.CurrencyName === curr2)
//     ?.Balance;
// };

export function getUniqueElements(arr1: any, arr2: any) {
  const result = [];

  // Add elements from arr1 that are not in arr2
  for (const num of arr1) {
    if (!arr2.includes(num)) {
      result.push(num);
    }
  }

  // Add elements from arr2 that are not in arr1
  for (const num of arr2) {
    if (!arr1.includes(num)) {
      result.push(num);
    }
  }

  return result;
}

export function extractTimeFromDateString(dateTimeString: string) {
  const dateObj = new Date(dateTimeString);
  const hours = String(dateObj.getHours()).padStart(2, "0");
  const minutes = String(dateObj.getMinutes()).padStart(2, "0");
  const seconds = String(dateObj.getSeconds()).padStart(2, "0");
  return `${hours}:${minutes}:${seconds}`;
}

export function saveFile(res: any, fileName: string, type?: string) {
  var blob = new Blob([res.data], { type: type || "application/pdf" });
  return saveAs(blob, fileName || "");
}
