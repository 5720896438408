import styled from "styled-components";
import { media } from "../../utils/theme";

export const EditWrapper = styled.div`
  max-width: 90%;
  margin: auto;

  ${media.lessThan("small")} {
    max-width: 100%;
    margin: 24px 8px 8px 8px;
  }

  .close {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #e6e6e6;
    border-radius: 50%;

    svg {
      fill: #757582;
    }

    &:hover {
      background: #f4f4f4;
    }
  }
`;

export const ReviewForm = styled.div<{ disabled?: boolean }>`
  background: #fff;
  box-shadow: 0px 2px 3px rgba(111, 118, 137, 0.1);
  border-radius: 10px;
  width: 736px;
  padding: 1rem;
  margin-left: 1.5rem;
  flex-shrink: 0;
  border: 1px solid #eaecf0;
  margin: 0 auto;

  button {
    padding: 0.8rem;
    margin-top: 1rem;
  }

  opacity: ${(p) => (p.disabled ? "0.5" : "1")};
  pointer-events: ${(p) => (p.disabled ? "none" : "auto")};

  ${media.lessThan("tablet")} {
    box-shadow: none;
    margin: 0 auto;
    width: 100%;
    max-width: 80%;
  }

  ${media.lessThan("small")} {
    box-shadow: none;
    margin-left: 0;
    width: 100%;
    max-width: 100%;
  }

  .contain {
    padding: 10px 25px;
  }

  @media (max-width: 768px) {
    width: 100% !important;
  }

  @media (max-width: 480px) {
    .topwrapper {
      flex-direction: column;
      align-items: flex-start;
    }

    .contain {
      padding: 10px 0;
    }

    .title {
      font-size: 24px;
      margin-left: 0;
      line-height: 30px;
    }
  }
`;

export const ListItem = styled.p`
  margin-bottom: 12px;
  padding-left: 4px;
  padding-right: 4px;

  span {
    color: var(--text-color-2);
  }

  span.red {
    color: var(--red);
    font-weight: bold;
  }
`;

export const ResponsiveWrapper = styled.div`
  padding: 1rem;
  position: relative;
  background: #fbfbfb;

  .edit {
    padding: 0.3rem 0.1rem;
    cursor: pointer;
    border-radius: 4px;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      width: 11px;
    }
  }

  .edit:hover {
    background: var(--red);

    svg {
      fill: white;
    }
  }

  .section {
    padding: 16px;
    border-radius: 8px;
    // border: 1px solid #ebebeb;
    background: #fff;
  }

  .modified {
    background: #e0e7f1;
    padding: 4px 8px;
    color: #4a6ea1;
    font-size: 14px;
    border-radius: 4px;
  }
`;

export const SEPAWrapper = styled.div`
  position: absolute;
  top: 60px;
  right: 24px;
  color: white;
  background: #000;
  font-size: 14px;
  padding: 2px 8px;
  border-radius: 4px;
`;

export const Dot = styled.span`
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #3b5981;
  border-radius: 50%;
  margin-right: 4px;
  transform: translateY(-2px);
`;
