import styled from "styled-components";
import { media } from "../../utils/theme";

const Wrapper = styled.p<{
  size?: string;
  color?: string;
  mb?: string;
  mt?: string;
  mr?: string;
  ml?: string;
  ta?: string;
  weight?: string;
  lh?: string;
  display?: string;
  mobileSize?: string;
  mobileLh?: string;
}>`
  font-size: ${(p) => (p.size ? p.size : "0.75rem")};
  color: ${(p) => (p.color ? p.color : "#56575B")};
  margin-bottom: ${(p) => (p.mb ? p.mb : "0")};
  margin-top: ${(p) => (p.mt ? p.mt : "0")};
  margin-left: ${(p) => (p.ml ? p.ml : "0")};
  margin-right: ${(p) => (p.mr ? p.mr : "0")};
  line-height: ${(p) => (p.lh ? p.lh : "normal")};
  text-align: ${(p) => (p.ta ? p.ta : "left")};
  font-weight: ${(p) => (p.weight ? p.weight : "normal")};
  display: ${(p) => (p.display ? p.display : "block")};

  ${media.lessThan("small")} {
    ${({ mobileSize }) => mobileSize && `font-size: ${mobileSize} !important;`}
    ${({ mobileLh }) => mobileLh && `line-height: ${mobileLh} !important;`}
  }
`;

const Text = ({
  size,
  color,
  mt,
  mb,
  mr,
  ml,
  onClick,
  children,
  className,
  ta,
  weight,
  lh,
  display,
  mobileSize,
  mobileLh,
  ...rest
}: any) => {
  return (
    <Wrapper
      size={size}
      color={color}
      mt={mt}
      mb={mb}
      mr={mr}
      ml={ml}
      className={className}
      ta={ta}
      weight={weight}
      lh={lh}
      display={display}
      mobileSize={mobileSize}
      mobileLh={mobileLh}
      {...rest}
    >
      {children}
    </Wrapper>
  );
};

export default Text;
