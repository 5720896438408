import styled from "styled-components";
import { media } from "../../../utils/theme";

export const ContentWrapper = styled.div`
  padding: 1rem;
  border-radius: 0 0 11px 11px;
  width: 375px;

  ${media.lessThan("small")} {
    width: 100%;
    padding: 1rem;
  }
`;

export const TopSection = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: 0.5rem;

  p.title {
    font-size: 0.87rem;
    margin-bottom: 1rem;
    margin-top: 1.5rem;
  }

  p.text {
    color: var(--text-color-2);
    font-size: 0.75rem;
    line-height: 1rem;
  }

  p {
    text-align: center;
  }
`;

export const ButtonsWrapper = styled.div`
  button {
    width: 100%;
  }
`;

export const BackButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
  border-radius: 4px !important;

  img {
    filter: brightness(0) invert(1);
    margin-right: 0.5rem;
    max-width: 1.1rem;
  }
`;

export const PlainButton = styled.button`
  border: 1px solid var(--border-color) !important;
  background: #fff;
  color: var(--text-color-2);
  font-size: 14px;

  &:hover {
    background: #f9fafb;
  }
`;

export const Header = styled.div`
  padding: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: flex-end;

  .variety {
    font-size: 0.875rem;
    color: var(--text-color-2);
  }

  .value {
    color: var(--red);
    font-weight: bold;
    margin-left: 0.5rem;
  }
`;

