import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import Divider from "../../../../components/Divider";
import FlexWrapper from "../../../../components/FlexWrapper";
import Text from "../../../../components/Text/text";
import Title from "../../../../components/Text/title";
import {
  CategoryItem,
  CategoryWrapper,
  ServiceItem,
} from "./Categories.styled";
import { getCategoryServices, getIcon } from "../../../../services/Services";
import { userIdSelector } from "../../../../redux/selectors";
import {
  BillService,
  BillServiceItems,
  BillServiceSubItems,
  Category,
} from "./types";
import { setLoading } from "../../../../redux/commonSlice";
import Img from "../../../../components/Img";
import { setBillTransferData } from "../../../../redux/billSlice";

type Props = {
  categories: Category[];
};

const Categories = ({ categories }: Props) => {
  const [svgs, setSvgs] = useState<any>([]);
  const [services, setServices] = useState<BillService[]>([]);
  const userId = useSelector(userIdSelector);
  const [innerServices, setInnerServices] = useState<BillServiceSubItems[]>([]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState("");
  const [row, setRow] = useState(0);

  useEffect(() => {
    if (categories?.length) {
      var imageCodes: any = categories.map((item: Category) => item.IconUrl);

      imageCodes.map((item: any) => {
        const type = item.split("/").pop();
        getIcon(type, "billing").then((res) => {
          setSvgs((prevState: any) => ({
            ...prevState,
            [item]: res.data,
          }));
        });
      });
    }
  }, [categories]);

  useEffect(() => {
    if (services?.length) {
      var serviceItems: any = services[0].Items;

      const subItems = serviceItems.map(
        (item: BillServiceItems) => item.SubItems
      );

      const flattenedArray = [].concat(...subItems);

      setInnerServices(flattenedArray);
    }
  }, [services]);

  const handleGetServices = (categoryId: string) => {
    dispatch(setLoading(true));
    getCategoryServices(userId, categoryId)
      .then((res: any) => {
        setServices(res.data.Content);
        dispatch(setLoading(false));
      })
      .catch((err) => {
        dispatch(setLoading(false));
      });
  };

  return (
    <CategoryWrapper>
      <Title size="12px" weight="500">
        {t("billpayments.categories")}
      </Title>
      <Divider width="100%" mb="1rem" />
      <div className="items">
        {categories?.slice(0, 3).map((item: Category, index: number) => (
          <CategoryItem
            isActive={selectedCategory === item.Category}
            key={item.CategoryId}
            onClick={() => {
              if (selectedCategory === item.Category) {
                setSelectedCategory("");
                setServices([]);
              } else {
                setRow(index);
                setSelectedCategory(item.Category);
                handleGetServices(item.CategoryId);
              }
            }}
          >
            <FlexWrapper mb="0">
              <div>
                <div className="icon">
                  <div
                    style={{
                      width: 40,
                      height: 40,
                      overflow: "hidden",
                      borderRadius: "6px",
                      marginRight: 8,
                    }}
                    // @ts-ignore
                    dangerouslySetInnerHTML={{
                      __html: svgs[item.IconUrl],
                    }}
                  />
                </div>
              </div>
              <div>
                <Text size="16px" mb="0.5rem" color="#56575B">
                  {item.Category}
                </Text>
                <Text color="#56575B">{item.Description}</Text>
              </div>
            </FlexWrapper>
          </CategoryItem>
        ))}
      </div>

      {!!services.length && row < 3 && (
        <div className="bg-[#F7F7F7] p-4 mt-4">
          <Title className="uppercase font-bold" weight="bold">
            {t("billpayments.selectservice")}
          </Title>
          <Divider width="100%" mb="0.5rem" mt="-0.25rem" />
          <div className="subitems">
            {!!services.length &&
              innerServices?.map((item: BillServiceSubItems) => (
                <ServiceItem
                  key={item.ServiceName}
                  onClick={() => {
                    dispatch(setBillTransferData(item));
                    navigate(`/billpayments/service/${item.ServiceId}`);
                  }}
                >
                  <object width="60px" data={item.ServiceUrl} type="image/png">
                    <Img
                      width={36}
                      style={{ marginLeft: 12, marginBottom: 6, marginTop: 12 }}
                      src="/billpayments/image-fill.svg"
                      alt="fallback"
                    />
                  </object>
                  {/* <img width="55px" src={item.ServiceUrl} alt={item.ServiceName} /> */}
                  <Text mb="0" ta="center" color="#56575B">
                    {item.ServiceName}
                  </Text>
                </ServiceItem>
              ))}
          </div>
        </div>
      )}

      {/* second row */}
      <div className="items mt-4">
        {categories?.slice(3, 6).map((item: Category, index: number) => (
          <CategoryItem
            isActive={selectedCategory === item.Category}
            key={item.CategoryId}
            onClick={() => {
              if (selectedCategory === item.Category) {
                setSelectedCategory("");
                setServices([]);
              } else {
                setRow(index + 3);
                setSelectedCategory(item.Category);
                handleGetServices(item.CategoryId);
              }
            }}
          >
            <FlexWrapper mb="0">
              <div>
                <div className="icon">
                  <div
                    style={{
                      width: 40,
                      height: 40,
                      overflow: "hidden",
                      borderRadius: "6px",
                      marginRight: 8,
                    }}
                    // @ts-ignore
                    dangerouslySetInnerHTML={{
                      __html: svgs[item.IconUrl],
                    }}
                  />
                </div>
              </div>
              <div>
                <Text size="16px" mb="0.5rem" color="#56575B">
                  {item.Category}
                </Text>
                <Text color="#56575B">{item.Description}</Text>
              </div>
            </FlexWrapper>
          </CategoryItem>
        ))}
      </div>
      {!!services.length && row > 2 && (
        <div className="bg-[#F7F7F7] p-4 mt-4">
          <Title className="uppercase font-bold" weight="bold">
            {t("billpayments.selectservice")}
          </Title>
          <Divider width="100%" mb="0.5rem" mt="-0.25rem" />
          <div className="subitems">
            {!!services.length &&
              innerServices?.map((item: BillServiceSubItems) => (
                <ServiceItem
                  key={item.ServiceName}
                  onClick={() => {
                    dispatch(setBillTransferData(item));
                    navigate(`/billpayments/service/${item.ServiceId}`, {
                      state: { serviceUrl: item.ServiceUrl },
                    });
                  }}
                >
                  <object
                    style={{
                      margin:
                        item.ServiceId === "40_2" || item.ServiceId === "40_4"
                          ? "10px 0"
                          : "0px",
                    }}
                    width={"60px"}
                    data={item.ServiceUrl}
                    type="image/png"
                  >
                    <Img
                      width={36}
                      style={{ marginLeft: 12, marginBottom: 6, marginTop: 12 }}
                      src="/billpayments/image-fill.svg"
                      alt="fallback"
                    />
                  </object>
                  {/* <img width="55px" src={item.ServiceUrl} alt={item.ServiceName} /> */}
                  <Text mb="0" ta="center" color="#56575B">
                    {item.ServiceName}
                  </Text>
                </ServiceItem>
              ))}
          </div>
        </div>
      )}
    </CategoryWrapper>
  );
};

export default Categories;
