import styled from "styled-components";
import cx from "classnames";
import { useEffect, useRef, useState } from "react";
import { useOutsideClick } from "../../../hooks/useEvents";
import Text from "../../../components/Text/text";
import CloseIcon from "@mui/icons-material/Close";
import FlexWrapper from "../../../components/FlexWrapper";
import { DateItemsWrapper } from "../../Statements/Statements.styled";
import { useTranslation } from "react-i18next";
import { media } from "../../../utils/theme";
import moment from "moment";
import CalendarModal from "../../Statements/CalendarModal";

const ContentWrapper = styled.div`
  ${media.lessThan("tablet")} {
    .filter {
      left: 104px;
    }
  }
  ${media.lessThan("small")} {
    .filter {
      left: 16px;
    }
  }
`;

const DateInputs = styled.div`
  border-radius: 4px;
  gap: 16px;
  display: flex;

  .input {
    flex: 1;
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid #cbcbcb;
    background: #fff;
    height: 56px;
    padding: 18px 16px 12px;
  }

  ${media.lessThan("tablet")} {
    flex-direction: column;
  }
`;

type AccountFilterProps = {
  onApply: (date1: string, date2: string, reset?: boolean) => void;
  isSelected?: boolean;
};

const dates = [
  {
    id: 0,
    name: "history.current",
  },
  {
    id: 1,
    name: "common.1month",
  },
  {
    id: 2,
    name: "common.3months",
  },
  {
    id: 3,
    name: "common.6months",
  },
  {
    id: 3,
    name: "common.1year",
  },
];

const DateFilter = ({ isSelected, onApply }: AccountFilterProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const filterRef = useRef(null);
  const headerRef = useRef(null);
  const [activeFilters, setActiveFilters] = useState<any>(dates[0]?.name);
  const [savedActiveFilters, setSavedActiveFilters] = useState<string[]>([]);
  const [date1, setDate1] = useState<any>(
    moment().startOf("month").toISOString()
  );
  const [date2, setDate2] = useState(
    moment(new Date()).add("hours", 3.9).toISOString()
  );
  const { t } = useTranslation();
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const handleFilterAdd = (date: any) => {
    setActiveFilters(date);
    let date1;
    let date2 = moment(new Date()).add(4, "hours").toISOString();
    if (date === "history.current") {
      date1 = moment(date2).startOf("month").toISOString();
      setDate1(date1);
    } else if (date === "common.1month") {
      date1 = moment(date2).subtract(1, "M").toISOString();
      setDate1(date1);
    } else if (date === "common.3months") {
      date1 = moment(date2).subtract(3, "M").toISOString();
      setDate1(date1);
    } else if (date === "common.6months") {
      date1 = moment(new Date()).subtract(6, "M").add(0, "h").toISOString();
      setDate1(date1);
    } else if (date === "common.1year") {
      date1 = moment(new Date()).subtract(12, "M").add(2, "d").toISOString();
      setDate1(date1);
    }
    setDate2(date2);
  };

  useEffect(() => {
    if (savedActiveFilters?.length) {
      setActiveFilters(savedActiveFilters);
    }
  }, [isOpen]);

  const handleApplyFilters = () => {
    setIsOpen(false);
    onApply(date1, date2);
    // setSavedActiveFilters(activeFilters);
  };

  useOutsideClick(filterRef, headerRef, () => {
    setIsOpen(false);
  });

  return (
    <ContentWrapper>
      <div
        ref={headerRef}
        className={cx(
          "whitespace-nowrap rounded-lg px-3 py-[5px] h-8 border border-solid border-[#848688] text-sm hover:bg-[#242424] hover:text-white cursor-pointer ",
          isSelected && "bg-[#242424] text-white"
        )}
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="font-medium">{t("transactionhistory.date")} </span>

        {isSelected && (
          <span>
            <CloseIcon
              onClick={(e: any) => {
                e.stopPropagation();
                setSavedActiveFilters([]);
                // @ts-ignore
                onApply(
                  moment().startOf("month").toISOString(),
                  moment(new Date()).add("hours", 3.9).toISOString(),
                  true
                );
              }}
              style={{ width: 18, height: 18, marginRight: -6 }}
            />
          </span>
        )}
      </div>
      <div
        className={cx(
          "absolute mt-2 w-auto bg-white z-10 border border-solid border-[#EAECF0] rounded-lg filter",
          isOpen ? "block" : "hidden"
        )}
        style={{
          boxShadow: "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814",
        }}
        ref={filterRef}
      >
        <div className="bg-[#FBFBFB] p-4 ">
          <DateInputs>
            <div style={{ flex: 1 }}>
              <Text mb="0.2rem" color="#171922">{t("common.from")}</Text>
              <div
                onClick={() => setIsCalendarOpen(true)}
                className="input p-2  text-sm"
              >
                {moment(date1).format("DD/MM/YYYY") || "DD/MM/YYYY"}
              </div>
            </div>

            <div style={{ flex: 1}}>
              <Text mb="0.2rem" color="#171922">{t("common.to")}</Text>
              <div onClick={() => setIsCalendarOpen(true)} className="input text-sm h-12">
                {moment(date2).subtract(4, "h").format("DD/MM/YYYY") ||
                  "DD/MM/YYYY"}
              </div>
            </div>
          </DateInputs>
          <FlexWrapper
            justify="space-between"
            mobileDirection="column"
            mt="1rem"
          >
            <div>
              <DateItemsWrapper>
                {dates.map((item: any) => (
                  <div
                    key={item.currency}
                    className={`item ${
                      // @ts-ignore
                      activeFilters === item.name ? "bg-[#F2F4F7]" : ""
                    }`}
                    onClick={() => handleFilterAdd(item.name)}
                  >
                    {t(`${item.name}`)}
                  </div>
                ))}
              </DateItemsWrapper>
            </div>
          </FlexWrapper>{" "}
        </div>
        <div className="p-4 flex justify-between">
          <button
            className="p-3 text-[14px] bg-transparent text-[#848688] w-auto uppercase"
            onClick={() => {
              setActiveFilters(dates[0]?.name);
              setDate1(moment().startOf("month").toISOString());
              setDate2(moment(new Date()).add("hours", 3.9).toISOString());
            }}
          >
            {t("common.clear")}
          </button>
          <button
            className="p-3 bg-[var(--red)] text-white w-auto uppercase text-sm"
            onClick={handleApplyFilters}
          >
            {t("transactionhistory.applyfilter")}
          </button>
        </div>
      </div>
      <div>
        <CalendarModal
          isOpen={isCalendarOpen}
          onClose={() => setIsCalendarOpen(false)}
          onGenerateClick={(date1: any, date2: any) => {
            setDate1(moment(date1).startOf("day").add(4, "h").toISOString());
            setDate2(moment(date2).endOf("day").add(4, "h").toISOString());
            setIsCalendarOpen(false);
            setIsOpen(true);
          }}
        />
      </div>
    </ContentWrapper>
  );
};

export default DateFilter;
