import { useTranslation } from "react-i18next";
import styled from "styled-components";

import BaseModal from "../../../../components/BaseModal";
import { media } from "../../../../utils/theme";
import Title from "../../../../components/Text/title";
import Text from "../../../../components/Text/text";
import { formatBalance } from "../../../../utils/helpers";
import moment from "moment";

export const ContentWrapper = styled.div`
  padding: 1.5rem;
  border-radius: 0 0 11px 11px;
  width: 460px;

  button.btn {
    outline: none !important;
  }

  .content {
    border: 1px solid #ebebeb;
    border-radius: 4px;
    padding: 1.25rem;
    margin-bottom: 1rem;
  }

  ${media.lessThan("small")} {
    width: 100%;
    padding: 1rem;
  }
`;

const ProductModal = ({ isOpen, onClose, details }: any) => {
  const { t } = useTranslation();

  return (
    <BaseModal
      isOpen={isOpen}
      onRequestClose={() => {
        onClose();
      }}
    >
      <ContentWrapper>
        <Title size="24px" weight="900" mb="1.5rem">
          {t("intelexpresscard.productdetails")}
        </Title>
        <div className="content">
          <div>
            <Text color="#56575B">{t("intelexpresscard.limit")}:</Text>
            <Title size="14px">
              {formatBalance(details?.Limit)} {details?.CurrencyCode}
            </Title>
          </div>
          <div>
            <Text color="#56575B">{t("cards.loanid")}</Text>
            <Title size="14px" mb="0.5rem">
              #{details?.LoanId}
            </Title>
          </div>
          <div>
            <Text style={{ textTransform: "capitalize" }} color="#56575B">
              {t("cards.graceperiod")}
            </Text>
            <Title size="14px" mb="0.5rem">
              {details?.GracePeriod}
            </Title>
          </div>
          <div>
            <Text color="#56575B">{t("cards.sms")}</Text>
            <Title size="14px" mb="0.5rem">
              {details?.SmsService}
            </Title>
          </div>
          <div>
            <Text color="#56575B">{t("cards.interestrate")}</Text>
            <Title size="14px" mb="0.5rem">
              {details?.Percent} %
            </Title>
          </div>
          <div>
            <Text color="#56575B">{t("cards.effective")}</Text>
            <Title size="14px" mb="0.5rem">
              {details?.EffectivePercent} %
            </Title>
          </div>
          <div>
            <Text color="#56575B">{t("cards.terms")}</Text>
            <Title size="14px" mb="0">
              {moment(details?.ContractDate).format("DD.MM.YYYY")}
            </Title>
          </div>
        </div>
        <button className="btn btn-red w-100" onClick={onClose}>
          {t("common.close")}
        </button>
      </ContentWrapper>
    </BaseModal>
  );
};

export default ProductModal;
