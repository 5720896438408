import { useEffect } from "react";
import { getWelcomeDeepLink } from "../../../services/Services";

const Welcome = () => {
  useEffect(() => {
    getWelcomeDeepLink().then(
      (res) => (window.location.href = res.data.deepLink)
    );
  }, []);

  return <div className="p-4 md:p-10"></div>;
};

export default Welcome;
