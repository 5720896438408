import { useTranslation } from "react-i18next";

import {
  BackButton,
  ButtonsWrapper,
  ContentWrapper,
  PlainButton,
  TopSection,
} from "./ResultModal.styled";
import BaseModal from "../../../components/BaseModal";
import Img from "../../../components/Img";
import Text from "../../../components/Text/text";
import Title from "../../../components/Text/title";
import { getUserId } from "../../../utils/storage";
import { handlePdfDownloadService } from "../../../services/Services";
import { useToast } from "../../../hooks/useToast";
import { saveFile } from "../../../utils/utils";
interface ResultModalProps {
  isOpen: boolean;
  onClose: () => void;
  imgSrc?: string;
  mode?: string;
  transferId: number;
}

const ResultModal = ({
  isOpen,
  onClose,
  mode,
  transferId,
}: ResultModalProps) => {
  const { t } = useTranslation();
  const isSuccess = mode === "success";
  const userId = getUserId();
  const toast = useToast();

  const handlePdfDownload = () => {
    handlePdfDownloadService(userId, transferId)
      .then((res) => {
        saveFile(res, `exchange-document-${transferId}`);
      })
      .catch((err) => toast(err.response?.data.StatusMessage, "error"));
  };

  return (
    <BaseModal isOpen={isOpen} onRequestClose={() => onClose()}>
      <ContentWrapper>
        <TopSection>
          <Img
            src={isSuccess ? "/SEPA/success.gif" : "/SEPA/fail.png"}
            alt="result"
          />
          <Title
            size="2rem"
            mt="1rem"
            mb="1rem"
            weight="700"
            ta="center"
            lh="2.3rem"
          >
            {isSuccess
              ? t("exchange.success.title")
              : t("cashpickup.edit.fail.title")}
          </Title>
        </TopSection>

        <Text ta="center" mb="1.5rem" color="#171922">
          {isSuccess
            ? t("cashpickup.edit.success.text")
            : t("cashpickup.edit.fail.text")}
        </Text>
        <ButtonsWrapper>
          <BackButton
            className="btn btn-red uppercase"
            onClick={() => onClose()}
          >
            {t("common.close")}
          </BackButton>
          {isSuccess && (
            <PlainButton className="btn" onClick={handlePdfDownload}>
              {t("history.documentview.download")}
            </PlainButton>
          )}
        </ButtonsWrapper>
      </ContentWrapper>
    </BaseModal>
  );
};

export default ResultModal;
