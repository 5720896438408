import styled from "styled-components";
import { media } from "../../utils/theme";

export const ContentWrapper = styled.div`
padding: 0.5rem;
max-width: 90%;
margin: auto;
padding-bottom: 3rem;
overflow-x:hidden;



.logo {
  width: 85px;
  cursor: pointer;
}

.close {
  width: 40px;
  height: 40px;
  display:flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #E6E6E6;
  border-radius: 50%;
  flex-shrink: 0;

  svg {
    fill: #757582;
  }

  &:hover{
    background: #F4F4F4;
  }
}

// .tablist {
//   display: flex;
//   max-width: 1000px;
//   width: 75%;
//   margin: 40px auto 20px auto;
//   justify-content: space-between;

//   .dot {
//     width: 8px;
//     height: 8px;
//     background: #D0D5DD;
//     margin: auto;
//     border-radius: 50%;
//   }

//   .active {
//     .dot{
//       background: white;
//     }


//     .indicator {
//       cursor: pointer;
//       border-radius: 50%;
//       width: 24px;
//       height: 24px;
//       background: var(--red);
//       font-size: 0.875rem;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       border: none;
//       box-shadow: 0px 0px 0px 4px rgba(237, 119, 119, 0.24);
//       flex: 1;
//       position: relative;
//       margin-bottom: 12px;
//     }
//   }


//   .passed {
//     .dot{
//       background: white;
//     }

//   .indicator {
//     cursor: pointer;
//     border-radius: 50%;
//     width: 24px;
//     height: 24px;
//     background: var(--red);
//     font-size: 0.875rem;
//     border: none !important;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     flex: 1;
//     position: relative;
//     margin-bottom: 12px;
//     z-index: 2 !important;
//    }

//     .line {
//       background: var(--red);
//     }
//   }

//      li {
//     .text {
//       font-weight: 600;
//       width: 100%;
//       text-align: center;
//       display:block;
//       color: #344054;
//      }
//     }

//   li:nth-child(2),
//   li:nth-child(3) {
//     .indicator {
//       margin: 0 auto 12px;
//       z-index: 1;
//     }

//     span {
//       display: block;
//       text-align: center;
//     }
//   }

//   li:last-child {
//     text-align: right;

//     .indicator{
//       position: absolute;
//       right: 50%;
//     }

//     span {
//       margin-top: 2.25rem;
//       display: inline-block;
//     }
//   }

//   li {
//     position: relative;
//     cursor: pointer;
//     width: calc(100% - 24px);
//     z-index: 1;
//     font-size: 14px;

//     .indicator {
//       z-index: 9;
//       cursor: pointer;
//       border-radius: 50%;
//       width: 24px;
//       height: 24px;
//       background: #fff;
//       font-size: 0.875rem;
//       border: 2px solid #EAECF0;
//       flex: 1;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       position: relative;
//       margin: 0 auto 12px;
//     }

//   .line {
//       display: inline-block;
//       height: 2px;
//       width: 100%;
//       top: 12px;
//       left: 50%;
//       margin: 0 auto;
//       background: #f0eff1;
//       position: absolute;
//     }

//   }

//   li.active {
//     space-between
//       background: var(--red);
    
//   }

// }


 

//   ${media.lessThan("large")} {
//     max-width: 950px;

//     .tablist {
//       max-width: 640px;
//     }

    
//   }
//   ${media.size("large")} {
//     max-width: 900px;

//     .tablist{
//       max-width: 550px;
//     }
//   }

  // ${media.lessThan("tablet")} {
  //   max-width: 800px;
  // }


  
  // @media (min-width: 555px) and (max-width: 1200px) {
  //   .tablist {
  //     transform: translateX(-20px) !important;
  //   }
  // }

  // @media (max-width: 800px) {
  //   max-width: 700px;
  //   .tablist{
  //     max-width: 400px;
  //     transform: translateX(-10px) !important;
  //   }

  //   .tablist .text {
  //     display: none !important;
  //   }
  // }

  // @media (max-width: 700px) {
  //   max-width: 600px;

  //   .tablist{
  //     max-width: 320px;
  //   }
  // }

  ${media.lessThan("small")} {
    width: 100%;
  }
`;
