import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Title from "../../../../components/Text/title";
import Img from "../../../../components/Img";
import FlexWrapper from "../../../../components/FlexWrapper";
import Text from "../../../../components/Text/text";
import { Desktop, Mobile } from "../../../../components/responsive/responsive";

const Agreements = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <>
      <Desktop>
        <Title weight="700" size="2rem" color="#000" mt="1.5rem" mb="1.5rem">
          {t("profile.agreementstitle")}
        </Title>
      </Desktop>

      <Mobile>
        <Title
          weight="700"
          size="18px"
          color="#000"
          mt="1.5rem"
          mb="1.5rem"
          ml="1rem"
        >
          {t("profile.agreementstitle")}
        </Title>
      </Mobile>
      <div className="bg-white p-6 rounded-lg flex items-center justify-start border-solid border-[1px] border-[#EBEBEB] sm:flex-row xs:flex-col gap-2">
        <FlexWrapper
          gap="0.5rem"
          className="hover:bg-[#F7F7F7] rounded pointer p-2 pr-4 pl-3"
          onClick={() => navigate("/privacy")}
        >
          <div className="rounded-full w-10 h-10 bg-[#F7F7F7] flex items-center justify-center">
            <Img src="/profile/policy.svg" alt="privacy" />
          </div>
          <div>
            <Title size="14px" color="#171922" mb="0">
              {t("common.privacy")}
            </Title>
            <Text>{t("profile.policytext")}</Text>
          </div>
        </FlexWrapper>
        <FlexWrapper
          gap="0.5rem"
          className="hover:bg-[#F7F7F7] rounded pointer p-2 pr-4 pl-3"
          onClick={() => navigate("/privacy")}
        >
          <div className="rounded-full w-10 h-10 bg-[#F7F7F7] flex items-center justify-center">
            <Img src="/profile/demography.svg" alt="privacy" />
          </div>
          <div>
            <Title size="14px" color="#171922" mb="0">
              {t("common.termsandconditions")}
            </Title>
            <Text>{t("profile.termstext")}</Text>
          </div>
        </FlexWrapper>

        {/* <FlexWrapper
          gap="0.5rem"
          className="hover:bg-[#F7F7F7] rounded pointer p-2  "
       
        >
          <div className="rounded-full w-10 h-10 bg-[#F7F7F7] flex items-center justify-center">
            <Img src="/profile/handshake.svg" alt="privacy" />
          </div>
          <div>
            <Title size="14px" color="#171922" mb="0">
              {t("profile.myagreement")}
            </Title>
            <Text>{t("profile.agreementtext")}</Text>
          </div>
        </FlexWrapper> */}
      </div>
    </>
  );
};

export default Agreements;
