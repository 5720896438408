import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

import Img from "../components/Img";
import { profilePhotoSelector, userSelector } from "../redux/selectors";
import Settings from "../components/Icons/Settings";
import Logout from "../components/Icons/Logout";
import Messages from "../components/Icons/Messages";
import { useOutsideClick } from "../hooks/useEvents";
import { media } from "../utils/theme";
import { Desktop } from "../components/responsive/responsive";
import { LogoutService } from "../services/Services";
import { LogoutWrapper } from "./Sidebar.styled";
import { getToken, getUserId } from "../utils/storage";
import { baseURL } from "../services/axios";
import { setProfilePhoto } from "../redux/authSlice";

const UserElement = styled.div`
  display: flex;
  align-items: center;
  padding: 0.875rem 1.5rem 1rem 1.25rem;
  border-radius: 4px;
  position: relative;
  width: 100%;
  max-width: 411px;
  box-sizing: border-box !important;
  gap: 0.5rem;

  img {
    flex-shrink: 0;
  }

  svg.rotated {
    transform: rotate(180deg);

    path {
      fill: var(--red);
    }
  }

  .profilePhoto {
    width: 38px;
    height: 38px;
  }

  ${media.lessThan("tablet")} {
    padding: 0.7rem;
    margin-bottom: -1rem;
    margin-top: 1rem;
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
    border: none;
    box-shadow: none;

    .profilename {
      display: none;
    }
  }

  ${media.lessThan("small")} {
    margin-top: 0.5rem;
    box-shadow: none;
    border: none;
    padding:  1rem; 
    margin-bottom: 0.25rem;


    .profilename {
      display: block;
    }
  }
`;

const UserName = styled.div`
  font-size: 1.1rem;
  margin-left: 0.9rem;
  margin-right: 1.4rem;
  margin-bottom: 0.4rem;
  color: #171922;
  text-transform: capitalize;
  text-align: left;
  min-width: 150px;

  ${media.lessThan("small")} {
    margin-right: -0.5rem;
    font-size: 12px;
    margin-left: 0rem;
  }
`;

const EmailText = styled.div`
  font-size: 0.875rem;
  color: var(--text-color-2);
  margin-left: 0.9rem;
  margin-right: 1.4rem;

  ${media.lessThan("small")} {
    margin-right: 0;
    margin-left: 0rem;
    font-size: 12px;
    text-align: left;
  }
`;

const NotificationWrapper = styled.div`
  margin-left: 1.5rem;
  margin-right: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const ProfileDropDownButton = styled.div`
  cursor: pointer;
`;

const ProfileDropDown = styled.ul`
  background: #fff;
  width: 250px;
  padding: 0rem 1rem 1rem;
  color: white;
  position: absolute;
  top: 80px;
  left: 1rem;
  box-shadow: 0px 2px 7px 2px rgba(22, 21, 36, 0.13);
  border-radius: 4px;
  z-index: 999;

  ::before {
    content: "";
    height: 2px;
    width: 100%;
    background: var(--red);
    position: absolute;
    left: 0;
    top: -20px;
  }

  li {
    margin-top: 1rem;
    color: var(--text-color);
    border-bottom: 1px solid #e4e4e4;
    cursor: pointer;
    padding: 0.5rem 0;
    font-size: 0.875rem;
    display: flex;
    justify-content: space-between;

    a {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }

    &:hover {
      color: var(--red);

      a {
        color: var(--red);
      }
    }
  }

  ${media.lessThan("small")} {
    width: 100%;
    top: 72px;
    padding: 8px;
    left: 0;

    ::before {
      top: -12px;
    }
  }
`;

const ProfileElement = () => {
  const user = useSelector(userSelector);
  const navigate = useNavigate();
  const [showDropDown, setShowDropDown] = useState(false);
  const dropdownRef = useRef(null);
  const profilePhoto = useSelector(profilePhotoSelector);
  const { t } = useTranslation();
  const userId = getUserId();
  const dispatch = useDispatch();
  const [source, setSource] = useState("");
  const userFullName = user.fullName?.split("");
  const nameInitial = userFullName && userFullName[0].split("")[0];
  const lastNameInitial = userFullName && userFullName[1].split("")[0];
  async function fetchImageStream() {
    const response = await fetch(
      `${baseURL}profile/profilePicture?userid=${userId}`,
      {
        method: "GET",
        headers: {
          Accept: "image/jpeg",
          Authorization: getToken(),
        },
      }
    );
    if (response.status === 200) {
      const blob = await response.blob();
      const imageUrl = URL.createObjectURL(blob);
      setSource(imageUrl);
      dispatch(setProfilePhoto(imageUrl));
    } else {
      dispatch(setProfilePhoto(""));
    }
  }

  useEffect(() => {
    if (!profilePhoto) {
      fetchImageStream();
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");

    LogoutService().then((res) => console.log(res));

    navigate("/login");
  };

  useOutsideClick(dropdownRef, () => {
    setShowDropDown(false);
  });

  return (
    <UserElement>
      {profilePhoto || source ? (
        <div className="shrink-0 w-[40px] h-[40px] rounded-full bg-[#F2F4F7] flex justify-center items-center">
          <img
            className="h-full w-full rounded-full"
            style={{ objectFit: "cover" }}
            src={profilePhoto || source}
            alt="Profile"
          />
        </div>
      ) : (
        <div className="shrink-0 w-[40px] h-[40px] rounded-full bg-[#F2F4F7] flex justify-center items-center">
          <span className="text-[#667085] uppercase font-semibold">
            {nameInitial}
            {lastNameInitial}
          </span>
        </div>
      )}{" "}
      <ProfileDropDownButton
        className="profilename"
      >
        <UserName>{user.fullName}</UserName>
        <EmailText>{user.email}</EmailText>
      </ProfileDropDownButton>
      {showDropDown && (
        <ProfileDropDown ref={dropdownRef}>
          <li onClick={() => setShowDropDown(false)}>
            <Link to="/profile">
              <Settings /> <span>{t("sidebar.settings")}</span>
            </Link>
          </li>
          <li onClick={() => setShowDropDown(false)}>
            <Link to="/faq">
              {" "}
              <Messages /> <span>FAQ</span>
            </Link>
          </li>

          <li onClick={handleLogout}>
            <Logout /> <span> {t("header.logout")}</span>
          </li>
        </ProfileDropDown>
      )}
      {/* <DropArrowWrapper onClick={() => setShowDropDown(!showDropDown)}>
        <DropArrow className={`${showDropDown ? "rotated" : ""}`} />
      </DropArrowWrapper> */}
      <Desktop>
        <NotificationWrapper>
          <Img src="/letter.svg" alt="email" /> &nbsp;
        </NotificationWrapper>
      </Desktop>
      <LogoutWrapper onClick={handleLogout} className="absolute right-0">
        <LogoutOutlinedIcon />
      </LogoutWrapper>
    </UserElement>
  );
};

export default ProfileElement;
