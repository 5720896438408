import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

import { userIdSelector } from "../../redux/selectors";
import {
  CancelTransfer as CancelTransferHandler,
  SearchTransferForEdit,
} from "../../services/Services";
import Title from "../../components/Text/title";
import Text from "../../components/Text/text";
import Img from "../../components/Img";
import FlexWrapper from "../../components/FlexWrapper";
import { media } from "../../utils/theme";
import BackButton from "../Cashpickup/components/BackButton";
import Input from "../../components/Form/Input";
import Review from "./Review";
import { setLoading } from "../../redux/commonSlice";
import ResultModal from "./ResultModal";
import InterruptionModal from "../../components/InterruptionModal";
import { TabletOrDesktop } from "../../components/responsive/responsive";

export const CancelForm = styled.div`
  background: #fff;
  box-shadow: 0px 2px 3px rgba(111, 118, 137, 0.1);
  border: 1px solid #eaecf0;
  border-radius: 10px;
  width: 736px;
  padding: 1.5rem;
  margin-left: 1.5rem;
  flex-shrink: 0;
  margin: 0 auto;

  button {
    padding: 0.8rem;
    margin-top: 1rem;
  }

  ${media.lessThan("tablet")} {
    box-shadow: none;
    margin-left: 0;
    width: 80%;
    margin: 0 auto;
  }

  ${media.lessThan("small")} {
    box-shadow: none;
    margin-left: 0;
    width: 100%;
    padding: 0;
  }

  @media (max-width: 768px) {
    width: 100% !important;
  }

  @media (max-width: 480px) {
    .topwrapper {
      flex-direction: column;
      align-items: flex-start;
    }

    .title {
      font-size: 24px;
      margin-left: 4px;
      line-height: 30px;
    }
  }
`;

const Wrapper = styled.div`
  text-transform: capitalize;
  // margin: 10px 20px 20px 0;
  line-height: 24px;
  font-size: 14px;

  svg {
    fill: #757582;
    margin-right: 8px;
  }

  ${media.lessThan("small")} {
    margin: 0 20px 20px;
    padding-top: 16px;
  }
`;

const SearchWrapper = styled.div<{ showButton?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: auto;

  input {
    border: 1.19142px solid #e4e4e4;
    padding: 0.9rem 1rem;
    width: 18.75rem;
    border-radius: 4.76568px;
  }

 button {
    position: absolute;
    right: 10px;
    top: 10px;
    margin-top: 0 !important;
    border: none;
    outline: none;
    width: 32px;
    height: 32px;
    padding: 0;
    display:flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    background: none;
    z-index: 11;
    cursor: pointer;

    ${({ showButton }) =>
      showButton &&
      `
      background: var(--red);

      img {
        flex-shrink:0;
        filter: brightness(0) invert(1);
      }
    `}
  }

  ${media.lessThan("small")} {
    input {
      width: calc(100vw - 80px);
      margin: auto;
    }

    button {
      right: 12px;
    }
  }
`;

const CancelWrapper = styled.div`
  max-width: 90%;
  margin: auto;

  ${media.lessThan("small")} {
    max-width: 100%;
    padding: 24px 8px 8px 8px;
  }

  .close {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #e6e6e6;
    border-radius: 50%;

    svg {
      fill: #757582;
    }

    &:hover {
      background: #f4f4f4;
    }
  }
`;

const CancelTransfer = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userId = useSelector(userIdSelector);
  const [searchValue, setSearchValue] = useState<string>("");
  const { addToast } = useToasts();
  const [transfer, setTransfer] = useState<any>(null);
  const [error, setError] = useState("");
  const [resultModalOpen, setIsResultModalOpen] = useState(false);
  const [resultMode, setResultMode] = useState("");
  const navigate = useNavigate();
  const [isInterruptionOpen, setIsInterruptionOpen] = useState(false);

  useEffect(() => {
    setTransfer(null);
  }, []);

  const handleSearch = () => {
    dispatch(setLoading(true));
    SearchTransferForEdit(userId, searchValue)
      .then((res) => {
        dispatch(setLoading(false));
        setTransfer(res.data.Content);
      })
      .catch((err) => {
        setError(err.response.data.StatusMessage || "something went wrong");
        dispatch(setLoading(false));
      });
  };

  const handleCancel = () => {
    CancelTransferHandler(userId, searchValue)
      .then((res) => {
        if (
          res.data.StatusMessage ===
          "Transaction operation completed successfully"
        ) {
          setResultMode("success");
          setIsResultModalOpen(true);
        } else {
          setIsResultModalOpen(true);
          setResultMode("pending");
        }
      })
      .catch((err) => {
        setIsResultModalOpen(true);
        setResultMode("fail");
        addToast(<div>{err.response.data.StatusMessage}</div>, {
          appearance: "error",
          autoDismiss: true,
        });
      });
  };

  const handleGoBack = () => {
    if (!transfer) {
      navigate("/home");
    } else {
      setIsInterruptionOpen(true);
    }
  };

  if (transfer) {
    return (
      <>
        <Review
          onPreviousPress={() => {
            setTransfer(null);
          }}
          onConfirmPress={handleCancel}
          transfer={transfer}
        />
        <ResultModal
          mode={resultMode}
          isOpen={resultModalOpen}
          onClose={() => {
            setTransfer(null);
            setIsResultModalOpen(false);
            setSearchValue("");
            navigate("/home");
          }}
        />
      </>
    );
  }

  return (
    <CancelWrapper>
      <TabletOrDesktop>
        <FlexWrapper justify="space-between" style={{ padding: 30 }}>
          <div onClick={handleGoBack} className="pointer max-w-[85px]">
            <Img src={"/logo.svg"} alt="logo" />
          </div>

          <div className="close" onClick={handleGoBack}>
            <CloseRoundedIcon />
          </div>
        </FlexWrapper>
      </TabletOrDesktop>

      <CancelForm>
        <Wrapper>
          <BackButton onClick={handleGoBack} />
          <Title
            className="title"
            size="2rem"
            weight="700"
            lh="2.5rem"
            mb="1.4rem"
            ta="center"
            color="#171922"
          >
            {t("cashpickup.canceltitle")}
          </Title>
          <FlexWrapper align="center">
            <SearchWrapper showButton={!!searchValue.length}>
              <span>
                <Input
                  autoFocus={true}
                  label={t("moneytransfer.code")}
                  value={searchValue}
                  onChange={(e: any) => {
                    setError("")
                    setSearchValue(e.target.value)
                  }}
                  onKeyPress={(event: any) => {
                    if (event.key === "Enter") {
                      handleSearch();
                    }
                  }}
                  errorText={error}
                />
              </span>

              <button onClick={handleSearch}>
                <Img src={"/SEPA/search.svg"} alt="search icon" />
              </button>
            </SearchWrapper>
          </FlexWrapper>
          {!error && (
            <Text ta="center" mt="-0.75rem" ml="-10rem" color="#344054">
              {t("cashpickup.indicate")}
            </Text>
          )}
        </Wrapper>
      </CancelForm>
      <InterruptionModal
        title={t("cashpickup.canceltransfer")}
        text={t("common.interruption")}
        isOpen={isInterruptionOpen}
        onStayClick={() => setIsInterruptionOpen(false)}
        onCloseClick={() => {
          navigate("/home");
          setIsInterruptionOpen(false);
        }}
      />
    </CancelWrapper>
  );
};

export default CancelTransfer;
