import { createSlice } from "@reduxjs/toolkit";

const billSlice = createSlice({
  name: "bill",
  initialState: {
    billTransferData: {},
  },
  reducers: {
    setBillTransferData(state, { payload }) {
      if (payload.reset) {
        state.billTransferData = {};
      } else {
        state.billTransferData = {
          ...state.billTransferData,
          ...payload,
        };
      }
    },
  },
});

export const { setBillTransferData } = billSlice.actions;

export default billSlice.reducer;
