import SellRate from "../../../components/SellRate";
import BuyRate from "../../../components/BuyRate";
import Title from "../../../components/Text/title";
import { RateItemWrapper, RatesWrapper } from "./Rates.styled";

export type CurrencyItem = {
  DebitCurrency: string;
  CreditCurrency: string;
  BuyingRate: number;
  SellingRate: number;
};

export const CurrencyItems: any[] = [
  {
    DebitCurrency: "GEL",
    flag: "home/rates/ge-flag.svg",
    text: "Georgian Lari",
    id: 0,
  },
  {
    DebitCurrency: "EUR",
    flag: "home/rates/eu-flag.svg",
    text: "EURO",
    id: 2,
  },
  {
    DebitCurrency: "USD",
    flag: "home/rates/us-flag.svg",
    text: "US Dollar",
    id: 1,
  },

  {
    DebitCurrency: "GBP",
    flag: "home/rates/gb-flag.svg",
    text: "British Pound",
    id: 3,
  },
];

const RateItem = ({ currency, chosenCurrency, flags }: any) => {
  const CurrencyItem = CurrencyItems.find(
    (item: CurrencyItem) => item.DebitCurrency === currency
  );

  return (
    <RateItemWrapper>
      <div className="currency-name">
        <div
          style={{
            width: 24,
            height: 24,
            borderRadius: "50%",
          }}
          // @ts-ignore
          dangerouslySetInnerHTML={{
            __html: flags[currency],
          }}
        />

        <Title ml="1rem">{CurrencyItem?.text}</Title>
      </div>
      <RatesWrapper>
        <Title size="14px" className="rate" weight="bold">
          <BuyRate currency1={chosenCurrency} currency2={currency} />
        </Title>
        <Title size="14px" className="rate" weight="bold">
          <SellRate currency1={chosenCurrency} currency2={currency} />
        </Title>
      </RatesWrapper>
    </RateItemWrapper>
  );
};

export default RateItem;
