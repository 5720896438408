import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";

import Header from "./Parts/Header";
import Box from "../../../components/Tailwind/Box";
import Title from "../../../components/Text/title";
import Text from "../../../components/Text/text";
import Img from "../../../components/Img";
import { useEffect, useState } from "react";
import Scrollbars from "rc-scrollbars";
import { getBranches } from "../../../services/Services";


const Offline = ({ onBackClick }: any) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [showMode, setShowMode] = useState("map");
  const [branches, setBranches] = useState<any>([]);

  useEffect(() => {
    getBranches("1").then((res) => setBranches(res.data.Content));
  }, []);

  return (
    <div className="bg-[#FBFBFB]  [@media(max-width:554px)]:px-2 !pb-[87px]">
      <Header />
      <Box className="mx-auto w-[600px] max-w-[100%]  ">
        <div
          className="gap-2 flex items-center mb-2 pointer"
          onClick={onBackClick}
        >
          <ArrowBackOutlinedIcon style={{ fill: "#344054" }} />{" "}
          <Text className="capitalize"> {t("common.back")}</Text>
        </div>
        <Title size="1.5rem" weight="700" lh="1.8rem" mb="1rem">
          {t("register.offlinetitle")}:
        </Title>
        <div className="p-4 pt-0">
          <Text color="#171922" size="14px" mb="0.5rem">
            1. {t("register.offlinepoint1")}
          </Text>
          <Text color="#171922" size="14px" mb="0.5rem">
            2. {t("register.offlinepoint2")}
          </Text>
          <Text color="#171922" size="14px" mb="0.5rem">
            3. {t("register.offlinepoint3")}
          </Text>
          <Text color="#171922" size="14px" mb="0rem">
            4. {t("register.offlinepoint4")}
          </Text>
        </div>
        <div className="flex justify-between items-center p-4 py-3 h-11 bg-[#FBFBFB]">
          <div>
            <Text color="#171922">
              {t("registration.showin")} {showMode}
            </Text>
          </div>
          <div className="flex gap-2">
            <span className="px-1 pointer" onClick={() => setShowMode("map")}>
              <Img src="/registration/map.svg" alt="map " />
            </span>
            <span className="px-1 pointer" onClick={() => setShowMode("list")}>
              <Img src="/registration/event_list.svg" alt="map " />
            </span>
          </div>
        </div>
        {showMode === "map" ? (
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d47663.06451644298!2d44.762338507500196!3d41.70019786997093!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sIntel%20Express!5e0!3m2!1sen!2sge!4v1711967888054!5m2!1sen!2sge"
            width="100%"
            height="450"
            style={{ border: 0 }}
            loading="lazy"
          ></iframe>
        ) : (
          <div>
            <Scrollbars style={{ height: 340 }}>
              {branches.map((item: any) => (
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${item.Latitude},${item.Longitude}`}
                  target="_blank"
                  className="p-4 py-2 pl-0 flex gap-2 items-center hover:bg-[#F7F7F7] mb-2"
                >
                  <div>
                    <div className="flex w-10 h-10 bg-[#F7F7F7] rounded-full flex items-center justify-center">
                      <Img
                        width={24}
                        height={24}
                        src="/registration/location_on.png"
                        alt="location"
                      />
                    </div>
                  </div>
                  <div>
                    <Text color="#CDCDCD">{item.WorkingHours}</Text>
                    <Text color="#171922" size="14px" mb="0.25rem">
                      {t(item.Office)}
                    </Text>
                    <Text mb="0.25rem">
                      {t(item.City)}, {t(item.Address)}
                    </Text>
                  </div>
                </a>
              ))}{" "}
            </Scrollbars>
          </div>
        )}
        <button className="capitalize btn btn-red w-full mt-8 h-11">
          {t("common.backtohome")}
        </button>
      </Box>
    </div>
  );
};

export default Offline;
