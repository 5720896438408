import styled from "styled-components";
import { media } from "../../utils/theme";

export const ReviewForm = styled.div<{ disabled?: boolean; }>`
  background: #fff;
  box-shadow: 0px 2px 3px rgba(111, 118, 137, 0.1);
  border-radius: 10px;
  width: 736px;
  padding: 1rem;
  margin-left: 1.5rem;
  flex-shrink: 0;
  border: 1px solid #eaecf0;
  margin: 0 auto;

  button {
    padding: 0.8rem;
    margin-top: 1rem;
  }

  .flag {
    svg {
      width: 24px;
      height: 24px;
    }
  }

  opacity: ${(p) => (p.disabled ? "0.5" : "1")};
  pointer-events: ${(p) => (p.disabled ? "none" : "auto")};

  ${media.lessThan("tablet")} {
    box-shadow: none;
    margin: 0 auto;
    width: 100%;
    max-width: 80%;
  }

  ${media.lessThan("small")} {
    box-shadow: none;
    margin-left: 0;
    width: 100%;
    max-width: 100%;
  }

  .contain {
    padding: 10px 25px;
  }

  .account {
    background: #212121;
    margin-top: 2rem;
    border-radius: 8px;
    margin-bottom: 24px;
    padding: 12px 16px;

    svg {
      fill: white;
    }
  }

  @media (max-width: 768px) {
    width: 100% !important;
  }

  @media (max-width: 480px) {
    .topwrapper {
      flex-direction: column;
      align-items: flex-start;
    }

    .contain {
      padding: 10px 0;
    }

    .title {
      font-size: 24px;
      margin-left: 0;
      line-height: 30px;
    }
  }
`;

export const ListItem = styled.p`
  margin-bottom: 0.5rem;
  padding: 0rem 1.5rem;
  span {
    color: var(--text-color-2);
  }

  span.red {
    color: var(--red);
    font-weight: bold;
  }
`;

export const ResponsiveWrapper = styled.div`
  padding: 1rem 0;
  position: relative;
  background: #f7f7f7;
`;

export const SEPAWrapper = styled.div`
  position: absolute;
  top: 60px;
  right: 24px;
  color: white;
  background: #000;
  font-size: 14px;
  padding: 2px 8px;
  border-radius: 4px;
`;
