import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";

import {
  BackButton,
  ButtonsWrapper,
  ContentWrapper,
  SaveasTempalateWrapper,
  TopSection,
} from "./ResultModal.styled";
import BaseModal from "../../../../components/BaseModal";
import Img from "../../../../components/Img";
import Text from "../../../../components/Text/text";
import Title from "../../../../components/Text/title";
import { billPaymentDataSelector } from "../../../../redux/selectors";
import { formatBalance, getCurrencySymbol } from "../../../../utils/helpers";

interface ResultModalProps {
  isOpen: boolean;
  onClose: () => void;
  imgSrc?: string;
  mode?: string;
  onTemplateAdd: () => void;
  isTemplateSuccess?: boolean;
}

const ResultModal = ({
  isOpen,
  onClose,
  mode,
  onTemplateAdd,
  isTemplateSuccess,
}: ResultModalProps) => {
  const { t } = useTranslation();
  const isSuccess = mode === "success";
  const billData = useSelector(billPaymentDataSelector);

  return (
    <BaseModal isOpen={isOpen} onRequestClose={() => onClose()}>
      <ContentWrapper>
        <TopSection>
          <Img
            src={isSuccess ? "/SEPA/success.gif" : "/SEPA/fail.png"}
            alt="result"
          />
          <Title size="2rem" mt="1rem" weight="700" ta="center" lh="2.3rem">
            {isSuccess
              ? t("billpayments.result_success")
              : t("cashpickup.edit.fail.title")}
          </Title>
        </TopSection>

        {isSuccess && (
          <SaveasTempalateWrapper>
            <div className="flex items-center justify-center">
              {billData?.processes?.length ? (
                billData?.processes?.map((item: any) => (
                  <object
                    width="40px"
                    data={
                      billData.ServiceId === "40_2" ||
                      billData.ServiceId === "40_4"
                        ? "https://coreapi.inexpay.ge/api/image/bill/subcategory/policefine"
                        : `https://files2.mp.ge/uploads/comunalservices/${billData.ServiceId}.png`
                    }
                    type="image/png"
                    style={{
                      marginTop:
                        billData.ServiceId === "40_2" ||
                        billData.ServiceId === "40_4"
                          ? 6
                          : 0,
                      marginBottom:
                        billData.ServiceId === "40_2" ||
                        billData.ServiceId === "40_4"
                          ? 4
                          : 0,
                    }}
                  >
                    <Img
                      width={36}
                      style={{ marginLeft: 12, marginBottom: 6, marginTop: 12 }}
                      src="/billpayments/image-fill.svg"
                      alt="fallback"
                    />
                  </object>
                ))
              ) : (
                <object
                  width="60px"
                  data={
                    billData.ServiceId === "40_2" ||
                    billData.ServiceId === "40_4"
                      ? "https://coreapi.inexpay.ge/api/image/bill/subcategory/policefine"
                      : `https://files2.mp.ge/uploads/comunalservices/${billData.ServiceId}.png`
                  }
                  type="image/png"
                  style={{
                    marginTop:
                      billData.ServiceId === "40_2" ||
                      billData.ServiceId === "40_4"
                        ? 6
                        : 0,
                    marginBottom:
                      billData.ServiceId === "40_2" ||
                      billData.ServiceId === "40_4"
                        ? 4
                        : 0,
                  }}
                >
                  <Img
                    width={36}
                    style={{ marginLeft: 12, marginBottom: 6, marginTop: 12 }}
                    src="/billpayments/image-fill.svg"
                    alt="fallback"
                  />
                </object>
              )}
            </div>
            <Text color="#171922" mb="0.2rem">
              {billData?.ServiceName}
            </Text>
            <Text color="#171922">{billData?.AbonentName}</Text>
            {!!billData?.processes?.length && (
              <Text color="#171922">{t("billpayments.combined")}</Text>
            )}
            <Text color="#171922">
              {billData?.Abonent} {billData?.Info}{" "}
              {/* {!!billData?.Amount &&
                `[${formatBalance(
                  // @ts-ignore
                  parseFloat(billData?.Amount) + parseFloat(billData?.fee)
                )}${getCurrencySymbol(billData?.currency)}]`}{" "} */}
              {billData?.amount &&
                `[${formatBalance(
                  parseFloat(billData?.amount) + parseFloat(billData?.fee)
                )}${getCurrencySymbol(billData?.currency)}]`}{" "}
            </Text>
            {!isTemplateSuccess &&
              !billData?.processes?.length &&
              !billData.isFromTemplate && (
                <button onClick={onTemplateAdd}>
                  <AddIcon /> {t("banktransfer.result.saveastemplate")}
                </button>
              )}
          </SaveasTempalateWrapper>
        )}
        <Text ta="center" mb="1.5rem" color="#171922">
          {isSuccess
            ? t("cashpickup.edit.success.text")
            : t("cashpickup.edit.fail.text")}
        </Text>

        <ButtonsWrapper>
          <BackButton className="btn btn-red" onClick={() => onClose()}>
            {t("common.backtohome")}
          </BackButton>
        </ButtonsWrapper>
      </ContentWrapper>
    </BaseModal>
  );
};

export default ResultModal;
