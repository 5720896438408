import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";

import { CountryCode, DestinationForm } from "./Destination.styled";
import FlexWrapper from "../../../components/FlexWrapper";
import Title from "../../../components/Text/title";
import Text from "../../../components/Text/text";
import {
  GetCountryCodes,
  SearchClientByPhone,
  getImage,
} from "../../../services/Services";
import BackButton from "../../Cashpickup/components/BackButton";
import Input from "../../../components/Form/Input";
import VerticalDivider from "../../../components/VerticalDivider";
import Img from "../../../components/Img";
import { setLoading } from "../../../redux/commonSlice";
import { setWalletTransferData } from "../../../redux/transferSlice";
import CountryCodeSearch from "../../Cashpickup/components/CountryCodeSearch";
import { walletDataSelector } from "../../../redux/selectors";
import { isEmpty } from "../../../utils/helpers";
import { useDimensionType } from "../../../hooks/useWindowSize";

interface DetailsProps {
  disabled?: boolean;
  onNextPress?: () => void;
  onPreviousPress?: () => void;
}

const Destination = ({
  disabled,
  onNextPress,
  onPreviousPress,
}: DetailsProps) => {
  const { t } = useTranslation();
  const walletData = useSelector(walletDataSelector);
  const [chosenCode, setChosenCode] = useState(73);
  const [displayCode, setDisplayCode] = useState("");
  const [showCountryCodes, setShowCountryCodes] = useState(false);
  const [countrySvg, setCountrySvg] = useState();
  const [phone, setPhone] = useState("");
  const dispatch = useDispatch();
  const [errorText, setErrorText] = useState("");
  const [countryCodes, setCountryCodes] = useState<any>();
  const [focused, setFocused] = useState(false);
  const [codeText, setCodeText] = useState("+995");
  const deviceType = useDimensionType();
  const isMobile = deviceType === "mobile";

  useEffect(() => {
    if (!isEmpty(walletData)) {
      setPhone(walletData?.phone);
    }
  }, [walletData]);

  useEffect(() => {
    getImage("GE").then((res) => setCountrySvg(res.data));
    GetCountryCodes().then((res) => {
      setCountryCodes(res.data.Content);
      setDisplayCode(
        res.data.Content.find((item: any) => item.Id === chosenCode)?.Code
      );
    });
  }, []);

  useEffect(() => {
    getImage("GE").then((res) => setCountrySvg(res.data));
    GetCountryCodes().then((res) => {
      setCountryCodes(res.data.Content);
      setDisplayCode(
        res.data.Content.find((item: any) => item.Id === chosenCode)?.Code
      );
    });
  }, []);

  const handleSearch = () => {
    dispatch(setLoading(true));
    SearchClientByPhone(chosenCode, phone)
      .then((res) => {
        dispatch(setLoading(false));
        setErrorText("");

        dispatch(
          setWalletTransferData({
            ...res.data.Content,
            phone,
            countryId: chosenCode,
            countryCodeText: codeText,
          })
        );
        onNextPress && onNextPress();
      })
      .catch((err) => {
        dispatch(setLoading(false));

        if (err.response.data?.errors) {
          if (err.response.data.errors?.PhoneNumber[0]) {
            setErrorText(err.response.data.errors?.PhoneNumber[0]);
          }
        } else {
          setErrorText(t(err.response.data.StatusMessage));
        }
      });
  };

  if (showCountryCodes) {
    return (
      <DestinationForm>
        <CountryCodeSearch
          onPreviousPress={() => setShowCountryCodes(false)}
          chooseCountryCode={(countryCode: any) => {
            setCodeText(countryCode.Code);
            setChosenCode(countryCode.Id);
            const code = countryCodes.find(
              (item: any) => item.Id === countryCode.Id
            )?.Code;
            const alpha = countryCodes.find(
              (item: any) => item.Id === countryCode.Id
            )?.Alpha2;
            setDisplayCode(code);
            setShowCountryCodes(false);
            getImage(alpha).then((res) => setCountrySvg(res.data));
          }}
        />
      </DestinationForm>
    );
  }

  return (
    <DestinationForm disabled={disabled} focused={focused}>
      <BackButton onClick={onPreviousPress} />
      <Title
        ta="center"
        mobileSize="1.5rem"
        size="2rem"
        weight="bold"
        mb="1.5rem"
        lh="38px"
      >
        {t("wallettowallet.title")}
      </Title>
      <div className="wrap">
        <FlexWrapper align="flex-start" style={{ flex: 1, width: "100%" }}>
          <CountryCode
            focused={focused}
            onClick={() => setShowCountryCodes(true)}
          >
            <div
              className="flag"
              style={{
                marginRight: 8,
                width: 24,
                height: 24,
                overflow: "hidden",
                borderRadius: "50%",
              }}
              // @ts-ignore
              dangerouslySetInnerHTML={{ __html: countrySvg }}
            />
            {displayCode}{" "}
            <ArrowForwardIosOutlinedIcon style={{ maxHeight: 18 }} />
            <VerticalDivider height="40px" ml="0.5rem" />
          </CountryCode>
          <Input
            autoFocus
            value={phone}
            placeholder={t("transfertowallet.phone")}
            inputMode="numeric"
            onBlur={() => setFocused(false)}
            onFocus={() => setFocused(true)}
            onChange={(e: any) => {
              setErrorText("");
              setPhone(e.target.value);
            }}
            className="mobile-input"
            onPaste={(event: any) => {
              event.preventDefault();
              const contents = event.clipboardData.getData("text");

              setPhone(contents.replace(/[^0-9]/g, ""));
              setErrorText("");
            }}
            onKeyPress={(event: any) => {
              if (event.key === "Enter") {
                handleSearch();
              }

              if (event.target.value === "") {
                if (event.which === 46 || event.which === 44) {
                  event.preventDefault();
                }
              }

              if (event.target.value === "0") {
                if (event.which !== 46 && event.which !== 44) {
                  event.preventDefault();
                }
              }

              if (event.target.value.indexOf(".") !== -1 && event.key === ",") {
                event.preventDefault();
              }

              if (event.target.value.indexOf(",") !== -1 && event.key === ".") {
                event.preventDefault();
              }

              if (/,/.test(event.target.value) && event.key === ",") {
                event.preventDefault();
              }

              if (event.target.value.indexOf(".") !== -1 && event.key === ".") {
                event.preventDefault();
              }

              if (!/^[0-9.,]+$/.test(event.key)) {
                event.preventDefault();
              }
            }}
          />
          <button
            onClick={handleSearch}
            className={phone.length > 2 ? "red" : ""}
          >
            <Img
              className="w-[20px] h-[20px]"
              src={"/SEPA/search.svg"}
              alt="search icon"
            />
          </button>
        </FlexWrapper>
        <Text
          mt="-0.5rem"
          color={errorText ? "var(--red)" : "var(--text-color-2)"}
        >
          {errorText || t("wallettowallet.subtext")}
        </Text>
      </div>
    </DestinationForm>
  );
};

export default Destination;
