import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import { Scrollbars } from "rc-scrollbars";
import cx from "classnames";

import { getUserId } from "../../utils/storage";
import {
  deleteNotification,
  getIcon,
  getNotifications,
  markAllAsRead,
  markAsRead,
} from "../../services/Services";
import Text from "../../components/Text/text";
import Title from "../../components/Text/title";
import FlexWrapper from "../../components/FlexWrapper";
import Dot from "../../components/Dot";
import Img from "../../components/Img";
import { formatDate } from "../../utils/helpers";
import { extractTimeFromDateString } from "../../utils/utils";

type Notification = {
  CreateDate: string;
  Id: number;
  IsRead: boolean;
  Message: string;
  NotificationType: string;
  Title: string;
  Url: string;
};

const NoResults = () => {
  const { t } = useTranslation();
  return (
    <div className="p-4 flex items-center justify-center flex-col mt-24">
      <Img src="/notifications/illustration.svg" alt="no results" />
      <Title weight="600" mt="1rem">{t("notifications.noresulttitle")}</Title>
      <Text size="14px" ta="center" color="#56575B" mr="1rem" ml="1rem" lh="20px">
        {t("notifications.noresulttext")}
      </Text>
    </div>
  );
};

const Notifications = ({ onClose }: any) => {
  const { t } = useTranslation();
  const userId = getUserId();
  const [notifications, setNotifications] = useState<any>([]);
  const [svgs, setSvgs] = useState<any>([]);
  const [activeNotification, setActiveNotification] =
    useState<Notification | null>(null);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [pageSize, setPageSize] = useState<number>(10);
  const scrollbarsRef = useRef(null);

  const fetchNotifications = (pageSizeArg?: number) => {
    getNotifications(userId, 1, pageSizeArg || pageSize).then((res) =>
      setNotifications(res.data.Content)
    );
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const handleNotificationOpen = (item: Notification) => {
    if (!item.IsRead) {
      markAsRead(userId, item.Id).then((res) => {
        fetchNotifications();
      });
    }

    setActiveNotification(item);
  };

  const handleDelete = (id: number) => {
    deleteNotification(userId, id).then((res) => {
      fetchNotifications();
    });
  };

  const handleMarkAll = () => {
    markAllAsRead(userId).then((res) => {
      fetchNotifications();
    });
  };

  const handleScrollStop = () => {
    const scrollbars: any = scrollbarsRef.current;

    const { scrollTop, clientHeight } = scrollbars?.view;

    if (scrollTop + clientHeight >= scrollbars?.getScrollHeight()) {
      fetchNotifications(pageSize + 10);
      setPageSize(pageSize + 10);
    }
  };

  useEffect(() => {
    if (notifications?.Items?.length) {
      var imageCodes: any = notifications.Items?.map((item: any) => item.Url);

      imageCodes.map((item: any) => {
        getIcon(item, "", true)
          .then((res) => {
            setSvgs((prevState: any) => ({
              ...prevState,
              [item]: res.data,
            }));
          })
          .catch((err) => {});
      });
    }
  }, [notifications]);

  const lines = activeNotification?.Message.split("\n");

  if (activeNotification) {
    return (
      <div className="py-2 sm:min-w-[400px] sm:max-w-[400px] max-w-[300px] min-w-[300px]">
        <FlexWrapper className="p-4" mb="1rem" justify="space-between">
          <Title size="20px" weight="600" mb="0">
            {t("notifications.title")}
          </Title>
          <CloseOutlinedIcon
            onClick={onClose}
            className="cursor-pointer"
            style={{ fill: "#98A2B3" }}
          />
        </FlexWrapper>

        <div className="px-4">
          <FlexWrapper justify="space-between">
            <FlexWrapper gap="0.5rem" mb="0">
              <ArrowBackOutlinedIcon
                className="pointer"
                onClick={() => setActiveNotification(null)}
              />{" "}
              <Text size="14px" color="#171922" weight="600">
                {activeNotification?.Title}
              </Text>{" "}
              <Text size="14px" color="#CDCDCD">
                {t(formatDate(activeNotification?.CreateDate))}
              </Text>{" "}
            </FlexWrapper>
            <Text size="14px" color="#848688" ml="2rem">
              {extractTimeFromDateString(activeNotification?.CreateDate)}
            </Text>
          </FlexWrapper>
          <Text color="#848688" size="14px">
            {lines?.map((line, index) => (
              <>
                <div className="mb-2" key={index}>
                  {line}
                </div>
              </>
            ))}
          </Text>
        </div>
      </div>
    );
  }

  return (
    <div className="py-2 sm:min-w-[400px] sm:max-w-[400px] max-w-[300px] min-w-[300px]">
      <FlexWrapper className="p-4" justify="space-between">
        <Title size="20px" weight="600" mb="0">
          {t("notifications.title")}
        </Title>
        <CloseOutlinedIcon
          onClick={onClose}
          className="cursor-pointer"
          style={{ fill: "#98A2B3" }}
        />
      </FlexWrapper>
      {notifications?.Items?.length && (
        <div onClick={handleMarkAll}>
          <Title
            className="underline uppercase pointer"
            ml="1rem"
            weight="500"
            size="12px"
            color="#E02629"
          >
            {t("notifications.markall")}
          </Title>
        </div>
      )}
      {notifications?.Items?.length ? (
        <Scrollbars
          autoHeightMin={180}
          autoHeight
          autoHeightMax={"85vh"}
          ref={scrollbarsRef}
          onScrollStop={handleScrollStop}
        >
          {notifications?.Items?.map((item: Notification) => (
            <div
              className={cx(
                "p-4 gap-4 flex items-start border-b border-solid border-[#EBEBEB] cursor-pointer hover:bg-[#F7F7F7] relative justify-between",
                !item.IsRead && "bg-[#F7F7F7]"
              )}
              style={
                deleteId === item.Id
                  ? { background: "rgba(22, 20, 20, 0.15)" }
                  : {}
              }
              key={item.Id}
              onClick={() => handleNotificationOpen(item)}
            >
              <div className="flex gap-4">
                <div className="w-9 h-9 rounded-full bg-[#EEE] flex items-center justify-center shrink-0">
                  <div
                    style={{
                      width: 22,
                      height: 22,
                      overflow: "hidden",
                      borderRadius: "50%",
                    }}
                    // @ts-ignore
                    dangerouslySetInnerHTML={{
                      __html: svgs[item.Url],
                    }}
                  />
                </div>
                <div>
                  <div>
                    <div className="flex items-center gap-2">
                      <Title mb="0.25rem" weight="600" size="14px">
                        {item.Title}
                      </Title>
                      <Text size="14px" mb="0.25rem" color="#CDCDCD">
                        {t(formatDate(item.CreateDate))}
                      </Text>
                      {!item?.IsRead && (
                        <Dot width="10px" height="10px" color="#E02629" />
                      )}
                    </div>
                  </div>
                  <p
                    className="line-clamp-2 text-sm text-[#848688]"
                    color="#848688"
                  >
                    {item.Message}
                  </p>
                </div>
              </div>
              <div
                className="shrink-0"
                onClick={(e) => {
                  e.stopPropagation();
                  setDeleteId(item.Id);
                }}
              >
                <Img src="/notifications/delete.svg" alt="delete" />
              </div>
              {deleteId === item.Id && (
                <div className="absolute right-0 top-0 h-full p-4 pt-2 bg-[#E02629] flex items-center justify-between flex-col gap-1 cursor-auto">
                  <Img
                    src="/notifications/delete.svg"
                    alt="delete"
                    style={{ filter: "brightness(0) invert(1)" }}
                  />
                  <Title
                    size="10px"
                    className="uppercase"
                    color="#fff"
                    lh="10px"
                    mb="0"
                  >
                    {t("notifications.delete")}
                  </Title>
                  <FlexWrapper mb="0" gap="0.25rem">
                    <div
                      onClick={(e: any) => {
                        console.log(e);
                        e.stopPropagation();
                        handleDelete(item.Id);
                      }}
                    >
                      <Text
                        color="#fff"
                        size="14px"
                        className="pointer hover:underline"
                      >
                        {t("common.yes")}
                      </Text>{" "}
                    </div>
                    <Text size="9px" color="#fff">
                      |
                    </Text>{" "}
                    <div
                      onClick={(e: any) => {
                        console.log(e);
                        e.stopPropagation();
                        setDeleteId(null);
                      }}
                    >
                      <Text
                        color="#fff"
                        size="14px"
                        className="pointer hover:underline"
                      >
                        {t("common.no")}
                      </Text>
                    </div>
                  </FlexWrapper>
                </div>
              )}
            </div>
          ))}
        </Scrollbars>
      ) : (
        <NoResults />
      )}
    </div>
  );
};

export default Notifications;
