import styled from "styled-components";
import BaseModal from "../../../components/BaseModal";
import Text from "../../../components/Text/text";
import Title from "../../../components/Text/title";
import Img from "../../../components/Img";
import FlexWrapper from "../../../components/FlexWrapper";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { GetCountryDescription, getImage } from "../../../services/Services";

interface DetailsProps {
  isOpen: boolean;
  onClose: () => void;
  onContinue: () => void;
  chosenCountry: any;
  officeList: number;
  fromTemplates?: boolean;
}

const Wrapper = styled.div`
  padding: 22px;
  max-width: 400px;

  svg {
    width: 40px;
    height: 40px;
  }

  .text {
    background: #e0e7f1;
    padding: 0.3rem 1rem;
    border-radius: 4px;
  }

  .btn {
    width: 50%;
    height: 44px;
    outline: none;
  }

  .btn-plain:hover {
    background: #fbfbfb;
  }

  @media (max-width: 553px) {
    max-width:100%;
  }
  

`;

const SendInfoModal = ({
  isOpen,
  onClose,
  onContinue,
  chosenCountry,
  officeList,
  fromTemplates,
}: DetailsProps) => {
  const { t } = useTranslation();
  const [countrySvg, setCountrySvg] = useState(null);
  const [countryDesc, setCountryDesc] = useState("");

  useEffect(() => {
    if (isOpen) {
      if (chosenCountry?.CountryCode || chosenCountry?.countryCode) {
        getImage(chosenCountry?.CountryCode || chosenCountry?.countryCode)
          .then((res) => {
            setCountrySvg(res.data);
          })
          .catch(() => { });
      }

      GetCountryDescription(chosenCountry?.CountryId || chosenCountry?.countryId)
        .then((res: any) => {
          setCountryDesc(res.data.Content);
        })
        .catch(() => { });
    }

  }, [chosenCountry]);

  return (
    <BaseModal isOpen={isOpen} onRequestClose={() => onClose()}>
      <Wrapper>
        <Img className="absolute left-0 top-0" src="/transfers/pattern.png" alt='pattern'/>
        <Img src="/transfers/info-modal.svg" alt="sepa" />
        <Title mt="18px" mb="4px" weight="bold" lh="28px">
          {t("cashpickup.infomodal.title")}
        </Title>
        <Text mb="20px" size="14px">
          {t("cashpickup.infomodal.text")}
        </Text>
        <FlexWrapper style={{ padding: 16 }}>
          <span style={{ marginRight: 8 }}>
            <div
              className="flag"
              style={{
                marginRight: 8,
                width: 40,
                height: 40,
                overflow: "hidden",
                borderRadius: "50%",
              }}
              // @ts-ignore
              dangerouslySetInnerHTML={{ __html: countrySvg }}
            />
          </span>
          <div>
            <Title mb="0">{chosenCountry?.Country || chosenCountry?.country}</Title>
            <Text mb="0.5rem">
              {officeList} {t("cashpickup.infomodal.pointin")}{" "}
              {chosenCountry?.Country || chosenCountry?.country}
            </Text>
          </div>
        </FlexWrapper>
        {countryDesc && (
          <div className="text">
            <Text size="12px" color="var(--text-color)">
              {countryDesc}
            </Text>
          </div>
        )}

        {fromTemplates ? (
          <button
            className="btn btn-red uppercase w-100"
            style={{ width: "100%", marginTop: 30 }}
            onClick={onClose}
          >
            {t("cards.noted")}
          </button>
        ) : (
          <FlexWrapper gap="12px" mb="0" mt="2rem" justify="space-around">
            {" "}
            <button className="btn btn-plain uppercase text-sm !rounded" onClick={onClose}>
              {t("common.close")}
            </button>
            <button className="btn btn-red uppercase text-sm !rounded" onClick={onContinue}>
              {t("SEPA.sepainfomodal.noted")}
            </button>
          </FlexWrapper>
        )}
      </Wrapper>
    </BaseModal>
  );
};

export default SendInfoModal;
