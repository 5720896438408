import React from 'react';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
`;

const Svg = styled.svg`
  animation: ${rotate} 2s linear infinite;
  z-index: 2;
  width: 25px;
  height: 100%;

  circle {
    fill: none;
    stroke-width: 5px;
    stroke: #fff;
    stroke-linecap: round;
    animation: ${dash} 1.5s ease-in-out infinite;
  }
`;

const Circle = () => (
  <Svg viewBox="0 0 50 50">
    <circle cx="25" cy="25" r="20" />
  </Svg>
);

export default Circle;