import { useTranslation } from "react-i18next";

import {
  BackButton,
  ButtonsWrapper,
  ContentWrapper,
  TopSection,
} from "./ResultModal.styled";
import BaseModal from "../../../../../components/BaseModal";
import Img from "../../../../../components/Img";
import Title from "../../../../../components/Text/title";
import Text from "../../../../../components/Text/text";
 

interface ResultModalProps {
  isOpen: boolean;
  onClose: () => void;
  imgSrc?: string;
  mode?: string;
}

const ResultModal = ({ isOpen, onClose, mode }: ResultModalProps) => {
  const { t } = useTranslation();
  return (
    <BaseModal isOpen={isOpen} onRequestClose={() => onClose()}>
      <ContentWrapper>
        <TopSection>
          <Img
            src={"/SEPA/success.gif"}
            alt="result"
          />
          <Title size="2rem" mt="1rem" weight="700" ta="center" lh="2.3rem" mb="0">
            {t("reset.resulttitle")}
          </Title>
        </TopSection>

        <Text ta="center" mb="2rem" color="#171922">
          {t("reset.resulttext")}
        </Text>
        <ButtonsWrapper>
          <BackButton className="btn btn-red" onClick={() => onClose()}>
            {t("common.backtohome")}
          </BackButton>
        </ButtonsWrapper>
      </ContentWrapper>
    </BaseModal>
  );
};

export default ResultModal;
