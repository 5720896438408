import ArrowOutwardOutlinedIcon from "@mui/icons-material/ArrowOutwardOutlined";
import CallReceivedOutlinedIcon from "@mui/icons-material/CallReceivedOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import SendIcon from "../../../components/Icons/Send";

export const Icons = [
    {
      key: "CurrencyExchange",
      icon: <CurrencyExchangeOutlinedIcon />,
      documentName: "money-exchange",
    },
    {
      key: "MoneyTransfer",
      icon: <SendIcon fill={"var(--text-color)"} height="19" width="24" />,
      documentName: "money-transfer",
    },
    {
      key: "WalletTransfer",
      icon: <AccountBalanceWalletOutlinedIcon />,
      documentName: "wallet-transfer",
    },
    {
      key: "WalletTransferIn",
      icon: <AccountBalanceWalletOutlinedIcon />,
      documentName: "wallet-transfer",
    },
    {
      key: "WalletTransferOut",
      icon: <AccountBalanceWalletOutlinedIcon />,
      documentName: "wallet-transfer",
    },
    {
      key: "TransferIn",
      icon: <CallReceivedOutlinedIcon />,
      documentName: "transfer-in",
    },
    {
      key: "TransferOut",
      icon: <ArrowOutwardOutlinedIcon />,
      documentName: "transfer-out",
    },
    {
      key: "MoneyTransferToBankAccount",
      icon: <AccountBalanceOutlinedIcon />,
      documentName: "transfer-out",
    },
    {
      key: "BillPayment",
      icon: (
        <div
          style={{
            width: "24px",
            height: "24px",
            overflow: "hidden",
            borderRadius: "50%",
          }}
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_5480_116270"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="24"
              height="24"
            >
              <rect width="24" height="24" fill="#D9D9D9"></rect>
            </mask>
            <g mask="url(#mask0_5480_116270)">
              <path
                d="M5.99997 21.499C5.30127 21.499 4.70993 21.257 4.22595 20.7731C3.74198 20.2891 3.5 19.6993 3.5 19.0036V16.4991H6.5V2.69141L7.88462 3.88371L9.28845 2.69141L10.6923 3.88371L12.0961 2.69141L13.5 3.88371L14.9038 2.69141L16.3077 3.88371L17.7115 2.69141L19.1153 3.88371L20.5 2.69141V18.9991C20.5 19.6978 20.258 20.2891 19.774 20.7731C19.29 21.257 18.6987 21.499 18 21.499H5.99997ZM18 19.9991C18.2833 19.9991 18.5208 19.9032 18.7125 19.7116C18.9041 19.5199 19 19.2824 19 18.9991V4.99906H7.99998V16.4991H17V18.9991C17 19.2824 17.0958 19.5199 17.2875 19.7116C17.4791 19.9032 17.7166 19.9991 18 19.9991ZM9.1923 8.74903V7.24908H14.8654V8.74903H9.1923ZM9.1923 11.749V10.2491H14.8654V11.749H9.1923ZM16.9423 8.88366C16.6974 8.88366 16.4888 8.79744 16.3163 8.62501C16.1439 8.45257 16.0577 8.24392 16.0577 7.99906C16.0577 7.75419 16.1439 7.54554 16.3163 7.37311C16.4888 7.20067 16.6974 7.11446 16.9423 7.11446C17.1871 7.11446 17.3958 7.20067 17.5682 7.37311C17.7407 7.54554 17.8269 7.75419 17.8269 7.99906C17.8269 8.24392 17.7407 8.45257 17.5682 8.62501C17.3958 8.79744 17.1871 8.88366 16.9423 8.88366ZM16.9423 11.8837C16.6974 11.8837 16.4888 11.7974 16.3163 11.625C16.1439 11.4526 16.0577 11.2439 16.0577 10.9991C16.0577 10.7542 16.1439 10.5455 16.3163 10.3731C16.4888 10.2007 16.6974 10.1145 16.9423 10.1145C17.1871 10.1145 17.3958 10.2007 17.5682 10.3731C17.7407 10.5455 17.8269 10.7542 17.8269 10.9991C17.8269 11.2439 17.7407 11.4526 17.5682 11.625C17.3958 11.7974 17.1871 11.8837 16.9423 11.8837ZM5.99997 19.9991H15.5V17.9991H4.99997V18.9991C4.99997 19.2824 5.09581 19.5199 5.28747 19.7116C5.47914 19.9032 5.71664 19.9991 5.99997 19.9991Z"
                fill="#212121"
              ></path>
            </g>
          </svg>
        </div>
      ),
      documentName: "bill-payment",
    },
  ];