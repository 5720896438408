import React, { useState } from "react";

import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import { sendTransferDataSelector } from "../../../redux/selectors";
import Divider from "../../../components/Divider";

import Title from "../../../components/Text/title";
import Text from "../../../components/Text/text";
import LoadingStateButton from "../../../components/LoadingStateButton";
import { useTranslation } from "react-i18next";
import { formatBalance } from "../../../utils/helpers";
import { ListItem, ResponsiveWrapper, ReviewForm } from "./Review.styled";
import BackButton from "../components/BackButton";
import FlexWrapper from "../../../components/FlexWrapper";

interface DetailsProps {
  disabled?: boolean;
  onConfirmPress?: () => void;
  onPreviousPress?: () => void;
  isFromActiveTemplate?: boolean;
}

const Review = ({
  disabled,
  onConfirmPress,
  onPreviousPress,
}: DetailsProps) => {
  const { t } = useTranslation();

  const { handleSubmit } = useForm();
  const sendTransferData = useSelector(sendTransferDataSelector);
  const [loading, setLoading] = useState(false);
  const onSubmit = (data: any) => {};

  return (
    <ReviewForm onSubmit={handleSubmit(onSubmit)} disabled={disabled}>
      <FlexWrapper className="topwrapper">
        <BackButton onClick={onPreviousPress} />
      </FlexWrapper>
      <Title className="title" size="2rem" weight="700" ta="center">
        {t("banktransfer.review.title")}
      </Title>
      <div className="contain">
        <ResponsiveWrapper>
          <FlexWrapper style={{ width: "100%" }} mb="0" justify="between">
            <Title
              style={{
                flex: 1,
              }}
              mb="0"
              className="uppercase"
              color="#56575B"
              ls="0px"
              size="12px"
              weight="500"
            >
              {t("banktransfer.review.transferdetails")}
            </Title>

            <div onClick={onPreviousPress}>
              <Text
                className="uppercase"
                color="#212121"
                weight="500"
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                {t("common.edit")}
              </Text>
            </div>
          </FlexWrapper>
          <Divider mb="1rem" width="100%" mt="1rem" />

          {(sendTransferData.chosenCountry?.Country ||
            sendTransferData.Country) && (
            <ListItem>
              <Text color="#56575B">{t("banktransfer.review.sendingto")}</Text>{" "}
              <Text
                className="uppercase"
                mt="0.25rem"
                size="1rem"
                weight="500"
                color={"var(--text-color)"}
              >
                {sendTransferData.chosenCountry?.Country ||
                  sendTransferData.Country}
              </Text>
            </ListItem>
          )}
          <ListItem>
            <Text color="#56575B">{t("topup.receiver")}</Text>{" "}
            <Text
              className="uppercase"
              mt="0.25rem"
              size="1rem"
              weight="500"
              color={"var(--text-color)"}
            >
              {sendTransferData.firstName + " " + sendTransferData.lastName}
            </Text>
          </ListItem>
          <ListItem>
            <Text color="#56575B">{t("SEPA.willget")}</Text>{" "}
            <Text
              className="uppercase"
              mt="0.25rem"
              size="1rem"
              weight="500"
              color={"var(--text-color)"}
            >
              {formatBalance(sendTransferData.creditAmount)}{" "}
              {sendTransferData.recipientCurrency}
            </Text>
          </ListItem>

          <Divider mt="1.25rem" mb="1.25rem" width="100%" />

          <ListItem>
            <Text color="#56575B">
              {t("banktransfer.review.sendingexactly")}
            </Text>
            <Text
              className="uppercase"
              mt="0.25rem"
              size="1rem"
              weight="500"
              color={"var(--text-color)"}
            >
              {formatBalance(sendTransferData.amount)}{" "}
              {sendTransferData.debitCurrency}
            </Text>
          </ListItem>
          <ListItem>
            <Text color="#56575B">
              {t("banktransfer.financialdetails.fee")}
            </Text>{" "}
            <Text
              className="uppercase"
              mt="0.25rem"
              size="1rem"
              weight="500"
              color={"var(--text-color)"}
            >
              {formatBalance(sendTransferData.fee)}{" "}
              {sendTransferData.debitCurrency}
            </Text>
          </ListItem>

          <ListItem>
            <Text color="#56575B">
              {t("banktransfer.review.totalsending")}{" "}
            </Text>{" "}
            <Text
              className="uppercase"
              mt="0.25rem"
              size="1rem"
              weight="500"
              color={"var(--text-color)"}
            >
              {formatBalance(
                parseFloat(sendTransferData.fee) +
                  parseFloat(sendTransferData.amount)
              )}{" "}
              {sendTransferData.debitCurrency}
            </Text>
          </ListItem>

          <ListItem>
            <Text color="#56575B">{t("banktransfer.review.exchangerate")}</Text>{" "}
            <Text
              className="uppercase"
              mt="0.25rem"
              size="1rem"
              weight="500"
              color={"var(--text-color)"}
            >
              {formatBalance(sendTransferData.exchangeRate)}
            </Text>
          </ListItem>
        </ResponsiveWrapper>
        <LoadingStateButton
          style={{ maxWidth: 300, margin: "20px auto" }}
          loading={loading}
          className="btn btn-red w-100 uppercase !mt-7 !rounded"
          onClick={() => {
            setLoading(true);
            onConfirmPress && onConfirmPress();
            setTimeout(() => {
              setLoading(false);
            }, 2000);
          }}
        >
          {t("banktransfer.sendmoney")}
        </LoadingStateButton>
      </div>
    </ReviewForm>
  );
};

export default Review;
