import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Title from "../../../../components/Text/title";
import Text from "../../../../components/Text/text";
import FlexWrapper from "../../../../components/FlexWrapper";
import { TemplateItem } from "./Templates.styled";
import Img from "../../../../components/Img";
import Flag from "../../../../components/Flag";
import { getIcon } from "../../../../services/Services";

const Template = ({
  template,
  type,
  onEdit,
  onDelete,
  onCentralizedClick,
}: any) => {
  const { t } = useTranslation();
  const [icon, setIcon] = useState("");
  const isBillingTemplate = type === "Bill Payment";

  useEffect(() => {
    if (!isBillingTemplate) {
      getIcon(template.Url, "", true)
        .then((res: any) => {
          setIcon(res.data);
        })
        .catch((err) => {});
    }
  }, []);

  return (
    <TemplateItem>
      <FlexWrapper justify="space-between" mb="0">
        <FlexWrapper>
          {!isBillingTemplate ? (
            <div className="circle shrink-0	">
              <div
                style={{
                  borderRadius: "50%",
                  width: 24,
                  height: 24,
                  overflow: "hidden",
                }}
                dangerouslySetInnerHTML={{
                  __html: icon,
                }}
              />
            </div>
          ) : (
            <img
              width={40}
              className="rounded-full bg-[#F7F7F7] mr-[10px]"
              src={template.ServiceUrl}
              alt={type}
            />
          )}
          <Text className="!text-[#CDCDCD]">
            {isBillingTemplate ? template?.SubCategoryId : type}
          </Text>
        </FlexWrapper>
        <FlexWrapper>
          {template.IsCentralized && (
            <span
              className="edit"
              onClick={(e) => {
                onCentralizedClick({
                  Country: template?.Receiver.Country,
                  CountryCode: template?.Receiver.Alpha2,
                  CountryId: template?.Receiver.CountryId,
                });
              }}
            >
              <Img width={28} src="/transfers/state-layer.svg" alt="sepa" />
            </span>
          )}
          <div className="edit" onClick={() => onEdit(template?.Id, type)}>
            <Img src="/home/editTemplate.svg" alt="edit" />
          </div>
          <div
            className="delete edit"
            onClick={() => onDelete(template?.Id, type)}
          >
            <Img src="/home/delete.svg" alt="edit" />
          </div>
        </FlexWrapper>
      </FlexWrapper>

      <Title weight="700" size="20px" className="truncate">
        {isBillingTemplate ? (
          template?.ServiceName
        ) : (
          <>
            {template?.TemplateName ||
              template.Receiver?.FirstName ||
              template?.AbonentName ||
              template.ServiceName}{" "}
            {!template?.TemplateName && template.Receiver?.LastName}
          </>
        )}
      </Title>
      {isBillingTemplate && (
        <div className="flex  item-center gap-3 truncate">
          {template.AbonentName && (
            <div className="flex item-center truncate">
              <div className="truncate">
                <Text mb="0" mr="0.25rem" className="truncate">
                  <span className="!text-[#CDCDCD] !text-[8px]">
                    {t("billpayments.abonentname")}:{" "}
                  </span>
                  <span className="truncate">{template.AbonentName}</span>
                </Text>
              </div>{" "}
            </div>
          )}

          <div className="flex item-center truncate">
            <div className="truncate">
              <Text mb="0" mr="0.25rem" className="truncate">
                <span className="!text-[#CDCDCD] !text-[8px]">
                  {t("common.templatename")}:{" "}
                </span>
                <span className="truncate">{template.TemplateName}</span>
              </Text>
            </div>{" "}
          </div>
        </div>
      )}
      <FlexWrapper mb="0" className="truncate" mobileAlign="flex-start">
        <FlexWrapper
          mr={template.Alpha2 || template.Country ? "1rem" : "0"}
          mb="0.35rem"
        >
          {template.Alpha2 && (
            <Flag code={template.Alpha2 || template.Receiver.Alpha2 || "ge"} />
          )}
          {template.Country && <Text> {template.Country}</Text>}
        </FlexWrapper>

        {template.AccountNumber && (
          <Text mr="10px" mb="0.35rem" className="truncate">
            <span className="!text-[#CDCDCD] !text-[8px]">IBAN: </span>
            {template.AccountNumber}
          </Text>
        )}
 
        {/* {template.BankName && !template.IsSepa && (
          <Text mb="0.35rem">
            <span className="!text-[#CDCDCD] !text-[8px]">
              {t("SEPA.bankname")}:
            </span>{" "}
            {template.BankName}
          </Text>
        )} */}

        {type === "Money Transfer" && (
          <div className="flex gap-[10px]">
            <div className="flex gap-[2px]">
              <Flag code={template.Receiver?.Alpha2} />{" "}
              <Text> {template.Receiver?.Country}</Text>
            </div>
            <Text>
              {`${template?.OfficeDetail?.OfficeName}`}
              {template?.OfficeDetail?.OfficeName !== "Cash Pick Up Anywhere" &&
                ","}{" "}
              {template?.OfficeDetail?.OfficeName !== "Cash Pick Up Anywhere" &&
                template?.OfficeDetail?.CityName}
            </Text>
          </div>
        )}
      </FlexWrapper>
    </TemplateItem>
  );
};

export default Template;
