import { IconProps } from "./types";

const DropArrow = ({ fill, width, height, ...rest }: IconProps) => {
    return (
        <svg width={width || "11"} height={height || "6"} viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path d="M10.2132 0.0703125L5.2132 5.07031L0.213196 0.0703125L10.2132 0.0703125Z" fill={fill || "#757582"} />
        </svg>
    );
};

export default DropArrow;
